import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';


const generateColors = (numColors) => {
    return Array.from({ length: numColors }, (_, i) => 
      `hsl(${(i * 360) / numColors}, 70%, 50%)`
    );
};

// Hardcoded array of 50 distinct colors
let colors = [
    
  '#FF8C33', '#33FFF6', '#8C33FF', '#FFD700', '#FF4500',
  '#BA55D3', '#DDA0DD', '#FF6347', '#FF7F50', '#ADFF2F',
  '#40E0D0', '#3CB371', '#6A5ACD', '#191970', '#87CEEB',
  '#FFA500', '#FF69B4', '#8B0000', '#556B2F', '#9400D3',
  '#BDB76B', '#FF00FF', '#EE82EE', '#7FFF00', '#A52A2A',
  '#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#A133FF',
  '#228B22', '#1E90FF', '#DA70D6', '#D2691E', '#00CED1',
  '#DC143C', '#20B2AA', '#7B68EE', '#FF1493', '#B8860B',
  '#E9967A', '#008080', '#9932CC', '#2E8B57', '#4682B4',
  '#8B4513', '#C71585', '#DB7093', '#B22222', '#32CD32',
  
];


const PieChart = ({ data, label }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const newChartInstance = new Chart(chartRef.current, {
        type: 'pie',
        data: {
          labels: data.map((company) => company.name),
          datasets: [
            {
              label: label || 'Número de clientes',
              data: data.map((company) => company.total),
              backgroundColor: colors.slice(0, data.length), // Use only required colors
              borderColor: '#fff',
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'right',
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) => `${tooltipItem.label}: ${tooltipItem.raw}`,
              },
            },
            datalabels: {
              color: '#000', // Black text for visibility
              font: {
                weight: 'bold',
                size: 14,
              },
              anchor: 'end',
              align: 'start',
              formatter: (value) => {
                  if(value){
                    let total=data.map((company) => company.total).reduce((acc, curr) => acc + curr, 0)
                    return `${(value / total * 100).toFixed(2)}% (${value})`
                  }else{
                    return ''
                  }
              },
            },
          },
        },
        plugins: [ChartDataLabels], // Register the datalabels plugin
      });

      chartInstance.current = newChartInstance;

      return () => {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
      };
    }
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default PieChart;
