import { createContext, useContext,useEffect,useState} from 'react';
import { useAuth } from './AuthContext';
const DataContext = createContext();


export const DataProvider = ({ children }) => {

   
    const [isDeleting,setIsDeleting]=useState(false)

    let initial_popups={
      basic_popup:false
    }
    let not_closing_popups=[
      'support_messages'
    ]

    
    const [_openPopUps, _setOpenPopUps] = useState(initial_popups);
  
    function _closeAllPopUps(){
          _setOpenPopUps(initial_popups)
          document.removeEventListener('click', handleOutsideClick)
    }
    function _closeThisPopUp(option){
      _setOpenPopUps({..._openPopUps,[option]:false})
    }

    const handleOutsideClick = (event) => {

      let close=true
      Object.keys(initial_popups).forEach(f=>{
          if(event?.target?.closest(`._${f}`))  {
            close=false
          }
      })


      Object.keys(initial_popups).forEach(k=>{
          if(not_closing_popups.includes(k) && _openPopUps[k]){
             close=false
          }
      })
  
      if(close){
        document.removeEventListener('click', handleOutsideClick); 
        _closeAllPopUps()
      }
    };

 
    const  _showPopUp = (option,value) => {
        setTimeout(()=>document.addEventListener('click', handleOutsideClick),200)
        _setOpenPopUps({...initial_popups,[option]:value || true})
    }

    function _search(search,array){
        function search_from_object(object,text){
             text=search
             let add=false
             Object.keys(object).forEach(k=>{
                object[k] = JSON.stringify(object[k])
                if(object[k].toLowerCase().trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.toLowerCase().trim().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))){
                      add=true
                }
             })
             return add
        }

        if (!array){ 
          return []
        }
    
        let d=JSON.parse(JSON.stringify(array))
    
        let res=[]

        d.forEach((t,_)=>{
          if(search_from_object(t)) {
              res.push(array.filter(j=>t.id.toString().includes(j.id))[0])
          }
        })
    
        return res
    }
   
    const {makeRequest,APP_BASE_URL,isLoading,setIsLoading,user,APP_FRONDEND,selectedMicrocredit,setSelectedMicrocredit} = useAuth()

    const [_loaded,setLoaded]=useState([])
    const [_managers,setManagers]=useState([])
    const [_operators,setOperators]=useState([])
    const [_clients,setClients]=useState([])
    const [_interest_rates,setInterestRates]=useState([])
    const [_late_payment_interests,setLatePaymentInterests]=useState([])
    const [_managers_list,setManagersList]=useState([])
    const [_microcredits,setMicrocredits]=useState([])
    const [_microcredits_list,setMicrocreditsList]=useState([])
    const [_clients_list,setClientsList]=useState([])
    const [_accounts,setAccounts]=useState([])
    const [_account_categories,setAccountCategories]=useState([])
    const [_inventory_categories,setInventoryCategories]=useState([])
    const [_contracts,setContracts]=useState([])
    const [_transations,setTransitions]=useState([])
    const [_transations_stats,setTransationsStats]=useState([])
    const [_account_balances,setAccountBalances]=useState([])
    const [_contract_assets,setContractAssets]=useState([])
    const [_delayed_clients,setDelayedClients]=useState([])
    const [_clients_on_blacklist,setClientsOnBlacklist]=useState([])
    const [_dashboard,setDashboard]=useState([])
    const [_settings,setSettings]=useState([])
    const [_plans,setPlans]=useState([])
    const [_plan_payments,setPlanPayments]=useState([])
    const [_tmrp_classes,setTmrpClasses]=useState([])
    const [updateTable,setUpdateTable]=useState(null)
    const [_microcredit_managers_and_operators,setMicrocreditManagersAndOperators]=useState([])
    
    let dbs=[
      {name:'microcredit_managers_and_operators',update:setMicrocreditManagersAndOperators,get:_microcredit_managers_and_operators},
      {name:'managers',update:setManagers,get:_managers},
      {name:'plan_payments',update:setPlanPayments,get:_plan_payments},
      {name:'plans',update:setPlans,get:_plans},
      {name:'microcredits',update:setMicrocredits,get:_microcredits},
      {name:'managers_list',update:setManagersList,get:_managers_list},
      {name:'microcredits_list',update:setMicrocreditsList,get:_microcredits_list},
      {name:'operators',update:setOperators,get:_operators},
      {name:'clients',update:setClients,get:_clients},
      {name:'late_payment_interests',update:setLatePaymentInterests,get:_late_payment_interests},
      {name:'interest_rates',update:setInterestRates,get:_interest_rates},
      {name:'accounts',update:setAccounts,get:_accounts},
      {name:'clients_list',update:setClientsList,get:_clients_list}, 
      {name:'account_categories',update:setAccountCategories,get:_account_categories},
      {name:'inventory_categories',update:setInventoryCategories,get:_inventory_categories},
      {name:'contracts',update:setContracts,get:_contracts},
      {name:'transations',update:setTransitions,get:_transations},
      {name:'transations_stats',update:setTransationsStats,get:_transations_stats},
      {name:'account_balances',update:setAccountBalances,get:_account_balances},
      {name:'contract_assets',update:setContractAssets,get:_contract_assets},
      {name:'delayed_clients',update:setDelayedClients,get:_delayed_clients},
      {name:'tmrp_classes',update:setTmrpClasses,get:_tmrp_classes},
      {name:'clients_on_blacklist',update:setClientsOnBlacklist,get:_clients_on_blacklist},
      {name:'dashboard',update:setDashboard,get:_dashboard},
      {name:'settings',update:setSettings,get:_settings},
    ]
    

    function handleLoaded(action,item){

      if(Array.isArray(item) && action=="remove"){
        setLoaded((prev)=>prev.filter(i=>!item.includes(i)))
        return
      }

      if(action=='add'){
         setLoaded((prev)=>[...prev.filter(i=>i!=item),item])
      }else{
         setLoaded((prev)=>prev.filter(i=>i!=item))
      }
      
    }   


     function _cn(number){
        return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(typeof "string" ? parseFloat(number) : number)
     }
   
     function _cn_n(string){
        string=string.toString()
        if (string.startsWith('0')) {
          string = string.replace('0', '');
        }
        return string.replace(/[^0-9]/g, '')
     }

    
     async function _get(from, params, wait = true) {
      let items = typeof from === "string" ? [from] : from;
      let _data = {};
  
      if (wait) {
          for (let f = 0; f < items.length; f++) {
              let selected = dbs.find(i => i.name === items[f]);
              try {
                  let response = await makeRequest({
                      params: params?.[items[f]],
                      method: 'get',
                      url: `api/${items[f].replaceAll('_', '-')}`,
                      withToken: true,
                      error: ``
                  }, 100);
                  handleLoaded('add', items[f]);
                  selected.update(response);
                  _data[items[f]] = response;
              } catch (e) {
                  console.log(items[f]);
                  console.log({ e });
                  _data[items[f]] = [];
              }
          }
      } else {
          let requests = items.map(async (item) => {
              let selected = dbs.find(i => i.name === item);
              try {
                  let response = await makeRequest({
                      params: params?.[item],
                      method: 'get',
                      url: `api/${item.replaceAll('_', '-')}`,
                      withToken: true,
                      error: ``
                  }, 100);
                  handleLoaded('add', item);
                  selected.update(response);
                  return { item, response };
              } catch (e) {
                  console.log(item);
                  console.log({ e });
                  return { item, response: [] };
              }
          });
  
          let results = await Promise.all(requests);
          results.forEach(({ item, response }) => {
              _data[item] = response;
          });
      }
  
      return _data;
     }
  


    let initial_filters={
      search: '',
      email:'',
      name:'',
      page:'',
      scheduled_doctor:'',
      scheduled_hours:'',
      scheduled_weekday:'',
      scheduled_date:'',
      scheduled_type_of_care:'',
      canceled_appointment_id:'',
      type_of_care:'',
      add_info:'',
      adding_appointment:'',
      add_from_doctor_request_id:''
    }
    
    const [_filters, setFilters] = useState(initial_filters);

    function _sendFilter(searchParams){

        let params_names=Object.keys(_filters)

        let options={}

        params_names.forEach(p=>{

          if(typeof _filters[p]=="object"){
             options[p]=searchParams.get(p) ? searchParams.get(p).split(',') : []
          }else{
             options[p]=searchParams.get(p) || ''
          }

        })

        setFilters(options);
        return options
        
       
    }


    const _updateFilters = async (newFilters,setSearchParams) => {

      let params_names=Object.keys(_filters)
     
      const updatedFilters = { ..._filters, ...newFilters };

      const queryParams = {};

      params_names.forEach(p=>{
          if(p=="end_date" || p=="start_date"){
            if(typeof updatedFilters[p] == "object"){
               queryParams[p] = updatedFilters[p].toISOString().split('T')[0]
            }
          }else if(typeof _filters[p]=="object"){
              if(updatedFilters[p].length > 0) {
                 queryParams[p] = updatedFilters[p].join(',');
              }
          }else{
              if(updatedFilters[p])  {
                queryParams[p] = updatedFilters[p];
              }
              
          }
      })

      setSearchParams(queryParams);

      return
    };


   
    const _scrollToSection = (to,behavior) => {
        const Section = document.getElementById(to);
        if (Section) {
          Section.scrollIntoView({ behavior: behavior || 'smooth'});
        }else{
          setTimeout(()=>_scrollToSection(to),1000)
        }

    }

    const [_selectedTableItems,setSelectedTableItems]=useState([])

    const [itemsToDelete,setItemsToDelete]=useState({
      items:[],
      url:null,
      deleteFunction:'default'
    })

    function add_remove_table_item(id){
        if(_selectedTableItems.includes(id)){
           setSelectedTableItems(_selectedTableItems.filter(i=>i!=id))
        }else{
           setSelectedTableItems([..._selectedTableItems,id])
        }
    }
  

    const [showFilters,setShowFilters]=useState(false)

    function getParamsFromFilters(options){
         let params={}
         options.forEach(o=>{
            if(params[o.param]){
              if(o.selected_ids.length)  {
                params[o.param]=params[o.param]+","+o.selected_ids.join(',')
              }
            }else{
              params[o.param]=o.selected_ids.join(',')
            }
            
         })

         return params
    }


    
    const [print_data,setPrintData]=useState({data:[],print:false})

    const [gettingItemToUpdate,setGettingItemToUpdate]=useState(false)
    
  
     useEffect(()=>{
       setSelectedTableItems([])
     },[updateTable])

    const [menuOpen, setMenuOpen] = useState(false)

    function time(){
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      const currentMinute = currentDate.getMinutes();
      const formattedTime = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
      return formattedTime
    }
    
    function today(get_this_day) {
            const currentDate = get_this_day ? get_this_day : new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            return formattedDate
    }

    const months = [
      "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
      "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
   ];

   function _c_date(date){
    if(date){
      date=new Date(date);
      date.setHours(date.getHours() + 2);
      return date.toISOString()
    }else{
      return null
    }
   }
   
   function text_l(text,max=50){
    if(text?.length > max){
       text=text.slice(0,max)+"..."
    }
    return text
  }


  function isMobile() {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  function _c_date(date){
      if(!date) return 
      if(typeof date == "string"){
        return new Date(new Date(date).getTime() + 2 * 60 * 60 * 1000).toISOString()
      }else{
        return  new Date(date.getTime() + 2 * 60 * 60 * 1000).toISOString()
      }
   }

    const dateToWords = dateStr => {
      const months = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];
      const date = new Date(dateStr);
      return `${date.getDate().toString().padStart(2, '0')} de ${months[date.getMonth()]} de ${date.getFullYear()}`;
    };

    const [printingDoc,setPrintingDoc]=useState(null)
    useEffect(()=>{
         if(printingDoc){
          setPrintingDoc(null)
          window.print()
         }
    },[printingDoc])

    const value = {
      printingDoc,setPrintingDoc,
      _microcredit_managers_and_operators,
      dateToWords,
      _c_date,
      isMobile,
      text_l,
      _closeAllPopUps,
      _c_date,
      today,
      time,
      _plan_payments,
      _plans,
      months,
      _settings,
      _contract_assets,
      _tmrp_classes,
      _transations,
      _transations_stats,
      _accounts,
      menuOpen,
      _clients_list,
      _delayed_clients,
      setMenuOpen,
      _closeAllPopUps,
      _operators,
      _setOpenPopUps,
      _openPopUps,
      setItemsToDelete,
      getParamsFromFilters,
      handleLoaded,
      _loaded,
      setIsLoading,
      isLoading,
      add_remove_table_item,
      itemsToDelete,
      _updateFilters,
      _sendFilter,
      _get,
      _search,
      _showPopUp,
      isDeleting,
      _cn,
      _cn_n,
      _managers,
      _scrollToSection,
      APP_BASE_URL,
      user,
      APP_FRONDEND,
      setIsDeleting,
      _closeThisPopUp,
      print_data,
      setPrintData,
      makeRequest,
      _managers_list,
      _microcredits,
      _microcredits_list,
      loaded:_loaded,
      updateTable,
      setUpdateTable,
      _selectedTableItems,setSelectedTableItems,
      gettingItemToUpdate,setGettingItemToUpdate,
      _operators,
      _clients,
      _interest_rates,
      _late_payment_interests,
      selectedMicrocredit,
      setSelectedMicrocredit,
      _account_categories,
      _contracts,
      _clients_on_blacklist,
      setContracts,
      setClientsList,
      setInterestRates,
      setLatePaymentInterests,
      setInventoryCategories,
      _dashboard,
      _inventory_categories,
      _account_balances,
      data:{}
    };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useData = () => {
   return useContext(DataContext);
};