
import '../../css/main.css'
import './style.css'
import Menu from '../../common/menu.js';
import TopBar from "../../common/topBar";
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import { useState ,useEffect,useRef} from 'react';
import CreatePayment from './create-payment.js'
import CreateAsset from './create-asset.js'
import toast from 'react-hot-toast';
import axios from 'axios';
import porExtenso from 'numero-por-extenso';
import { useParams , useLocation,useNavigate} from 'react-router-dom';
import MultiSelect from './select-search-input'
import BaiscTable from '../../tables/default-table.js';
import DefaultFormSkeleton from '../../Skeleton/defaultForm.js';
import CreateClient from '../../clients/create.js';
import CreateInstallment from '../settings/create-interest-rates.js';
import SelectLatePaymentInterest from '../../modals/select-late-payment-interest.jsx';
import CreateLatePaymentInterest from '../settings/create-late-payment-interest.js'
import Loader from '../../loaders/loader.jsx';
import ConfirmCreditRestruring from '../../modals/credit-restructuring.jsx';
import PrintCreditSimulator from '../../Print/printCreditSimulator.js';
import InsertReceiverEmail from '../../modals/insert-receiver-email.jsx';
import ButtonLoader from '../../loaders/button.js';
import AdvancedActions from '../../Options/basic-options.jsx';
import PrintPaymentPlan from '../../Print/printPaymentPlan.js';
import PrintWarranties from '../../Print/printWarranties.js';
import AllClientAssets from '../../modals/all-client-contract-assets.jsx';

export default function _Create() {
    const { id } = useParams()
    const {pathname} = useLocation();
    const navigate=useNavigate()
    const data = useData(); 
    const [uploadedFiles, setUploadedFiles]=useState({})
    const [message, setMessage]=useState(null)
    const [editingState,setEditingState]=useState(false);
    const [createPayment, setCreatePayment] = useState(false);
    const [createAsset, setCreateAsset] = useState(false);
    const [editAsset, setEditAsset] = useState(null);
    const [createClient, setCreateClient] = useState(false);
    const [editClient, setEditClient] = useState(false);
    const [createLatePaymentInterest, setCreateLatePaymentInterest] = useState(false);
    const [editLatePaymentInterest, setEditLatePaymentInterest] = useState(false);
    const [createInstallment, setCreateInstallment] = useState(false);
    const [editInstallment, setEditInstallment] = useState(false);
    const [loanToEdit, setLoanToEdit] = useState(data.loanToEdit);
    const [loading,setLoading]=useState(false)
    const [currentTab,setCurrentTab]=useState('details')
    const [itemToEditLoaded,setItemToEditLoaded]=useState(null)
    const [regenerateInstallments,setRegenerateInstallments]=useState(id ? false : true)
    const [showLatePaymentInterest,setShowLatePaymentInterest]=useState(false)
    const [showAllClientAssets,setShowAllClientAssets]=useState(false)
    const [printing,setPrinting]=useState(false)
    const [showRestructuringDialog,setShowRestructuringDialog]=useState(false)
    const [isRestructuring,setIsRestructuring]=useState(false)
    const [showSendLoanSummary,setShowSendLoanSummary]=useState(false)
    const [isSimulator,setIsSimulator]=useState(false)
    const [receiverEmail,setReceiverEmail]=useState('')
    const [addExpenses,setAddExpenses]=useState(false)
    const [valid,setValid]=useState(false)

    

    const [topDetails,setTopDetails]=useState({
      paid:0,
      total_to_pay:0,
      late_payment_interest:0,
      left:0,
      total_expenses:0
    })



    let initial_form={

      installments:[],
      contract_assets:[],
      LatePaymentInterests:[],
      paid:0,
      total_to_pay:0,
      number_of_days:'',
      number_of_installments:'',
      installment_id:'',
      client_id:null,
      disbursement_date:'',
      disbursement_value:'',
      notes:'',
      account_id:'',
      cost_of_preparation:'',
      insurance:'',
      taxes:'',
      total_expenses:'',
      disbursement_performed_by_id:''

    }

    const [formData,setFormData]=useState(initial_form)

    const {user,selectedMicrocredit,userRole,userInfo} = useAuth()
    let required_data=['inventory_categories','clients_list','accounts','interest_rates','late_payment_interests','account_balances','microcredit_managers_and_operators']

    useEffect(()=>{ 
        if(!user) return
        data.handleLoaded('remove',required_data)
        data._get(required_data,{
            inventory_categories:{microcredit_id:selectedMicrocredit},
            clients_list:{microcredit_id:selectedMicrocredit},
            accounts:{microcredit_id:selectedMicrocredit},
            interest_rates:{microcredit_id:selectedMicrocredit},
            late_payment_interests:{microcredit_id:selectedMicrocredit},
            microcredit_managers_and_operators:{microcredit_id:selectedMicrocredit}
        },false) 

   

    },[user])

    const [reqLoaded,setReqLoaded]=useState(false)

    useEffect(()=>{
      setReqLoaded((itemToEditLoaded || !id)  && data.loaded.includes('accounts') && data.loaded.includes('clients_list') && data.loaded.includes('interest_rates') && data.loaded.includes('late_payment_interests') && data.loaded.includes('account_balances')  && data.loaded.includes('microcredit_managers_and_operators') && data.loaded.includes('inventory_categories'))
    },[data.loaded,itemToEditLoaded])


 

  useEffect(()=>{
     setIsSimulator(pathname.includes('/credit-simulator'))
  },[pathname])


  const fileInputRef_1 = useRef(null);
  const fileInputRef_2 = useRef(null);
  const fileInputRef_3 = useRef(null);
  const [loadingFile,setLoadingFile]=useState(false)
  const [sendingFiles, setSendingFiles]=useState({})



const onFileUpload = async (event) => {

  toast.remove()
  const formData = new FormData();
  let field=event.target.className
  formData.append('file', event.target.files[0]);
  formData.append('data', JSON.stringify({file_dest:field,id:formData.client_id}))

  toast.loading('A enviar ficheiro...')
  fileInputRef_1.current.value = ''


  try {

    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

  
    if(field=="signed_contract_src"){
         setUploadedFiles({...uploadedFiles,signed_contract_src:response.data})
    }else if(field=="identity_ticket_src"){
         setUploadedFiles({...uploadedFiles,identity_ticket_src:response.data})
    }else if(field=="declaration_of_residence_src"){
         setUploadedFiles({...uploadedFiles,declaration_of_residence_src:response.data})
    }
   
    toast.remove()
    toast.success('Arquivo enviado')

  } catch (error) {
    toast.remove()
    toast.error('Ocorreu um erro:'+error)
    console.error(error);
  }
};




function calculateAge(birthDate) {

  if(!birthDate){
    return
  }
  const birth = new Date(birthDate);
  const today = new Date();
  let age = today.getFullYear() - birth.getFullYear();
  const monthDiff = today.getMonth() - birth.getMonth();
  const dayDiff = today.getDate() - birth.getDate();
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
  }
  return age;
}



function print_client_contract(type,doc){

  let micro=user.microcredits.filter(i=>i.id==selectedMicrocredit)[0]
  let client=data._clients_list.filter(i=>i.id==formData.client_id)[0]

  if(!client){
    toast.error('Selecione o cliente para continuar')
    return
  }


  //last_payday
  let paid_installments=formData.installments.filter(i=>i.payment_histories.length)
  let last_installment=paid_installments[paid_installments.length - 1]  //
  let last_payday=last_installment?.payment_histories?.[last_installment?.payment_histories?.length - 1]?.payday?.split('T')?.[0]

  let placeholders={
    nome_da_empresa:micro.name,
    localizacao_da_empresa:micro.location || '[Localização da empresa]',
    nuit_da_empresa:micro.nuit || '[Nuit da empresa]',
    nome_do_cliente:client.name || '[nome do cliente]',
    bi_do_cliente:client.identity_number || '[BI do cliente]',
    bairro_do_cliente:client.neighborhood_name || '[Bairro do cliente]',
    contacto_primario_do_cliente:client.primary_contact || '[Contacto primário do cliente]',
    carta_de_conducao_do_cliente:client.driver_license_number || '[Carta de condução do cliente]',
    avenida_do_cliente:client.avenue || '[Avenida do cliente]',
    numero_da_casa_do_cliente:client.house_number || '[Número da casa do cliente]',
    idade_do_cliente:client.date_of_birth ? calculateAge(client.date_of_birth) : '[Idade do cliente]',
    numero_de_passaporte_do_cliente:client.passport_number || '[Número de passaporte do cliente]',
    contacto_secundario_do_cliente:client.secondary_contact || '[Contacto secundário do cliente]',
    email_do_cliente:client.email || '[Email do cliente]',
    estado_civil_do_cliente:client.marital_status=="single" ? "Solteriro" :  client.marital_status == "married" ? 'Cadado(a)': client.marital_status=="widowed" ? 'Viúvo(a)' : client.marital_status=='divorced'  ? 'Divorciado(a)' : '[Estado civil do cliente]',
    data_de_emissao_do_bi_do_cliente:data._c_date(client.date_of_issuance_of_the_identity_card)?.split('T')?.[0]?.split('-')?.reverse()?.join('/') || '[Data de emissão de BI]',
    local_de_emissao_do_bi_do_cliente:client.place_of_issuance_of_the_identity_card || '[Local de emissão de BI]',
    contacto_da_empresa:micro.primary_contact || '[Contacto da empresa]',
    contacto_alternativo_da_empresa:micro.secondary_contact || '[Contacto alternativo da empresa]',
    valor_financiado:data._cn(formData.disbursement_value || 0),
    valor_financiado_por_extenso:porExtenso.porExtenso(formData.disbursement_value || 0),
    total_a_pagar:data._cn(formData.total_to_pay || 0),
    total_a_pagar_por_extenso:porExtenso.porExtenso(formData.total_to_pay || 0),
    percentagem_de_juros:formData.fees_percentage || `[Percentagem de juros]`,
    percentagem_de_juros_por_extenso:porExtenso.porExtenso(formData.fees_percentage || 0),
    periodo_em_dias:formData.number_of_days || '[Periodo em dias]',
    data:data.dateToWords(new Date().toISOString().split('T')[0]),
    valor_pago:data._cn(topDetails.paid || 0),
    valor_pago_por_extenso:porExtenso.porExtenso(topDetails.paid || 0),
    nome_do_representante:userInfo?.name || '[Nome do representante]',
    cargo_do_representante:userInfo?.job_title || '[Cargo do representante]',
    data_pagamento:last_payday ? data.dateToWords(last_payday) : '[Data de pagamento]',
    prazo_de_pagamento:formData.refund_date || '[Prazo de pagamento]',
    quantidade_de_prestacoes:formData.number_of_installments,
    data_da_primeira_prestacao:formData.disbursement_date ? data.dateToWords(formData.disbursement_date) : '[Data da primeira prestação]',
    nuit_do_cliente:client.nuit || `[Nuit do cliente]`,
    juros:formData.fees || '[Juros]',
    endereco_do_cliente:client.address || '[Endereço do cliente]',
    profissao_do_cliente:client.job_title || '[Profissão do cliente]',
    nome_do_pai_do_cliente: client.father_name || '[Nome do Pai]',
    nome_da_mae_do_cliente: client.mother_name || '[Nome da Mãe]',

    local_de_emissao_da_carta_de_conducao_do_cliente: client.driver_license_issue_location || '[Local de emissão da Carta de Condução]',
    data_de_emissao_da_carta_de_conducao_do_cliente: data._c_date(client.driver_license_issue_date)?.split('T')?.[0]?.split('-')?.reverse()?.join('/') || '[Data de emissão da Carta de Condução]',

    local_de_emissao_do_passaporte_do_cliente: client.passport_issue_location || '[Local de emissão do Passaporte]',
    data_de_emissao_do_passaporte_do_cliente: data._c_date(client.passport_issue_date)?.split('T')?.[0]?.split('-')?.reverse()?.join('/') || '[Data de emissão do Passaporte]',

  }

  Object.keys(placeholders).forEach(key => {
    placeholders[key] = String(placeholders[key]);
  });

  console.log({placeholders})
  console.log({client})
  
  
  PrintContract(placeholders,type,doc)
}




const PrintContract =async (placeholders,type,doc) => {
 
  try{

        setPrinting(doc)
        let response=await data.makeRequest({method:'post',url:`api/print-client-contract`,withToken:true,data:{
        microcredit_id:selectedMicrocredit,
        placeholders,
        document_type:type,
        doc
        }, error: ``},0);
     
        window.location.href=data.APP_BASE_URL+"/download-contract/"+response

  }catch(e){
      toast.remove()
      let msg="Acorreu um erro, tente novamente"
      if(e.response){
        if(e.response.status==500){
            msg="Erro, inesperado. Contacte seu administrador"
        }
      }else if(e.code=='ERR_NETWORK'){
            msg="Verfique sua internet e tente novamente"
      }
      toast.error(msg)
  }
  setPrinting(false)

}

let utils={

  daysBetween:function(date1, date2) {
    const milliseconds1 = date1.getTime();
    const milliseconds2 = date2.getTime();
    const diff = milliseconds2 - milliseconds1;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    return days;
  },

  calculateEndDate:function (startDate, days) {

          if (!startDate) return null;

          const startDateObject = new Date(startDate);
          startDateObject.setDate(startDateObject.getDate() + days); 

          return startDateObject.toISOString().split('T')[0];

         /* if(!startDate) return   

          if(startDate.split('-')[1]=="02") days=days-1
         
          const startDateObject = new Date(startDate); 
          const currentMonth = startDateObject.getMonth();
          const daysInCurrentMonth = new Date(startDateObject.getFullYear(), currentMonth + 1, 0).getDate(); // Get the number of days in the current month
          
          if (days <= daysInCurrentMonth) {
            startDateObject.setDate(startDateObject.getDate() + days);
          } else {
            startDateObject.setDate(startDateObject.getDate() + days - daysInCurrentMonth);
            startDateObject.setMonth(currentMonth + 1); 
          }
          return startDateObject.toISOString().split('T')[0]; */ 


  },
  get_installment_id:function() {


  },

  get_account_id:function(one){

  },
  divideDateRange: function(startDate, endDate, numberOfParts, total_to_pay) {

    const start = new Date(startDate);
    const end = new Date(endDate);
    const dateDiff = end - start;
    const interval = Math.floor(dateDiff / numberOfParts);

    let result = [];

    for (let i = 0; i < numberOfParts; i++) {
      const partStart = new Date(start.getTime() + i * interval);
      const partEnd = new Date(start.getTime() + (i + 1) * interval);
      if (i === numberOfParts - 1) {
        partEnd.setTime(end.getTime());
    }

    let item={id:Math.random(),paid:0, start: partStart.toISOString().split('T')[0], end: partEnd.toISOString().split('T')[0] , total_to_pay: total_to_pay ? (total_to_pay /  numberOfParts): 0,payment_histories:[]}
    item.initial_amount=item.total_to_pay
    result.push(item);

    }
   
    return result;

   },
    calculateLatePaymentIntervals:function(value, latePaymentArray, prioritizePercentage = false){
 
      if(!latePaymentArray.some(i=>i.from <= value)){
          return []
      }

      let latePaymentArraySortByLowerFrom=[...latePaymentArray].sort((a, b) => a.from - b.from);
      let mostClose=latePaymentArraySortByLowerFrom[0].from

      value= - (mostClose - (value + 1))

      let remainingDays = value;
      let result = [];
      let coveredDays = new Set(); 

    
      if (prioritizePercentage) {
          latePaymentArray = [...latePaymentArray].sort((a, b) => b.percentage - a.percentage);
      }

      for (let { from, to, percentage } of latePaymentArray) {
          let daysInRange = 0;

          for (let day = from; day <= to && remainingDays > 0; day++) {
              if (!coveredDays.has(day)) {  
                  coveredDays.add(day);
                  daysInRange++;
                  remainingDays--;
              }
          }

          if (daysInRange > 0) {
              result.push({ days: daysInRange, percentage });
          }

          if (remainingDays === 0) break;
      }

      return result;
    },
   _calculateLatePaymentIntervals:function(value, latePaymentArray) {
    let remainingValue = value;
    let result = [];

    for (let i = 0; i < latePaymentArray.length; i++) {
        const interval = latePaymentArray[i];
        const daysInRange = Math.min(remainingValue, interval.to - interval.from + 1);

        if (daysInRange > 0) {
            result.push({
                days: daysInRange,
                percentage: interval.percentage
            });
            remainingValue -= daysInRange;
        }

        if (remainingValue <= 0) {
            break;
        }
    }

    return result;
  },
  today:function () {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
  },
  add_contract_payments:function (result){

    
      let total_expenses=(parseFloat(formData.insurance || 0)) + (parseFloat(formData.cost_of_preparation || 0)) + (parseFloat(formData.taxes || 0))
      let divided_expenses=total_expenses / result.length

      let _late_payment_interests=formData.LatePaymentInterests

      for (let i = 0; i < result.length; i++) {


          if(formData.restructuring_to_id){
            result[i].status="paid"
          }

          if(result[i].payment_histories.length==0){

            result[i].paid=0

            let end=new Date(result[i].end)
        
            let today=utils.today()
          
            result[i].delay=utils.daysBetween(end, new Date(today))

            if(result[i].delay <= 0 || result[i].status=="paid") {

                result[i].delay=0
                result[i].late_payment_interest=0

                if(result[i].status!="paid") {
                    result[i].total_to_pay = result[i].initial_amount
                    result[i].status="pending"
                } 

            }else{
                const intervals = this.calculateLatePaymentIntervals(result[i].delay,_late_payment_interests);
                let late_payment_interest=0
                intervals.forEach(j=>{
                      late_payment_interest+=j.days * (j.percentage / 100) * (result[i].initial_amount -  divided_expenses)
                })
                result[i].late_payment_interest=late_payment_interest
                result[i].total_to_pay = result[i].initial_amount + result[i].late_payment_interest   
                if(result[i].status!="paid")  result[i].status="delayed" 
            }
        }else{
          
                let late_payment_interest=0
                let paid=0
                let total_delay=0

                if(result[i].status!="paid") result[i].status="pending"

                result[i].payment_histories.sort((a, b) => new Date(a.payday) - new Date(b.payday))

                result[i].payment_histories.forEach((f,_f)=>{

                  paid=result[i].payment_histories.map(item => parseFloat(item.amount)).reduce((acc, curr) => acc + curr, 0)

                  let delay=utils.daysBetween(new Date(result[i].end),new Date(f.payday))
          
                  if(_f!=0){

                    let previous_history_payment_delay=utils.daysBetween(new Date(result[i].end),new Date(result[i].payment_histories[_f - 1].payday))
                    if(previous_history_payment_delay > 0){
                      delay -= previous_history_payment_delay
                    }
                
                  }
                
                  total_delay+=delay
                  let initial_amount=result[i].initial_amount - divided_expenses
                  let initial_amount_to_pay=0

                  if(_f!=0) {
                      initial_amount_to_pay=initial_amount - result[i].payment_histories.filter((_,_g)=>_g < _f).map(item => parseFloat(item.amount)).reduce((acc, curr) => acc + curr, 0)
                      if(initial_amount_to_pay > initial_amount || initial_amount_to_pay < 0){
                        initial_amount_to_pay=0
                      }
                  }else{
                      initial_amount_to_pay=result[i].initial_amount - divided_expenses
                  }

                  if(delay > 0 && f.status!="paid"){
                        const intervals = this.calculateLatePaymentIntervals(delay,_late_payment_interests);
                        let count_late_payment_interest=0
                        intervals.forEach(j=>{
                            count_late_payment_interest+=(j.days) * (j.percentage / 100) * (initial_amount_to_pay)
                        })
                        late_payment_interest+=count_late_payment_interest
                        if(result[i].status!="paid") result[i].status="delayed"
                }

                 if(result[i].payment_histories.length==1 && paid < (result[i].initial_amount - divided_expenses) && (new Date(result[i].end) < new Date())){
                     let _delay=0
                     if(new Date(f.payday) < new Date(result[i].end)){
                        _delay=utils.daysBetween(new Date(result[i].end),new Date())
                      }else{
                        _delay=utils.daysBetween(new Date(f.payday),new Date())
                      }  
                      let amount=(result[i].initial_amount - divided_expenses) - paid
                      if(_delay > 0 && f.status!="paid"){
                        const intervals = this.calculateLatePaymentIntervals(_delay,_late_payment_interests);
                        let count_late_payment_interest=0
                        intervals.forEach(j=>{
                            count_late_payment_interest+=(j.days) * (j.percentage / 100) * (amount)
                        })
                        late_payment_interest+=count_late_payment_interest
                        if(result[i].status!="paid") result[i].status="delayed"
                        total_delay+=_delay
                     }
                 }
                })

                result[i].late_payment_interest=late_payment_interest
                result[i].paid=paid
                result[i].delay=total_delay < 0 ? 0 : total_delay
                result[i].total_to_pay=result[i].initial_amount + late_payment_interest

        }
      }

      return result

    },

    get_client_id:function(){
              
    },

    check_available_credit:function () {

    },

    init:function(){

    }

}



useEffect(()=>{

  let initial_amount=0;
  let refund_date;
  let installments=[]
  let number_of_days=0
  let fees_percentage=0
  let fees=0
  let total_to_pay=0


  let selected_interest_rate=data._interest_rates.filter(i=>i.id==formData.installment_id)[0]

  if (selected_interest_rate && formData.disbursement_date && formData.disbursement_value){
    let disbursement_value=parseFloat(formData.disbursement_value || 0)
    initial_amount=(disbursement_value + ((parseFloat(selected_interest_rate.percentage)/100) * disbursement_value)) + (parseFloat(formData.insurance || 0)) + (parseFloat(formData.cost_of_preparation || 0)) + (parseFloat(formData.taxes || 0))
    refund_date=utils.calculateEndDate(formData.disbursement_date, parseInt(selected_interest_rate.days))
    number_of_days=selected_interest_rate.days 
    fees_percentage=selected_interest_rate.percentage
    fees=(fees_percentage/100) * disbursement_value
  } 
 
 if(formData.disbursement_date && refund_date && initial_amount && formData.number_of_installments){
    let calculated_installments=utils.divideDateRange(formData.disbursement_date, refund_date, formData.number_of_installments, initial_amount)
    installments=regenerateInstallments ? utils.add_contract_payments(calculated_installments) : utils.add_contract_payments(formData.installments)
    total_to_pay=installments.map(item => parseInt(item.total_to_pay || 0)).reduce((acc, curr) => acc + curr, 0)
  }


 if(!id || (formData.id && data.loaded.includes('interest_rates') && data.loaded.includes('late_payment_interests'))){
   setFormData(prev=>({...prev,
    initial_amount,refund_date,installments,number_of_days,fees,fees_percentage}))
 }

 
},[
  formData.number_of_installments,
  formData.installment_id,
  formData.disbursement_value,
  formData.disbursement_date,
  formData.LatePaymentInterests,
  formData.insurance,
  formData.cost_of_preparation,
  formData.taxes,
  data.loaded
])




function getPaymentStatus(){
   return formData.installments.some(i=>i.status=="delayed") ? "delayed" : formData.installments.some(i=>i.status=="pending") ? "pending" : "paid"
}

useEffect(()=>{
   setFormData(prev=>({...prev,payment_status:getPaymentStatus()}))
},[formData.installments])


useEffect(()=>{
  let total_to_pay=formData.installments.map(item => parseFloat(item.total_to_pay || 0)).reduce((acc, curr) => acc + curr, 0)
  let paid=formData.installments.map(item => parseFloat(item.paid || 0)).reduce((acc, curr) => acc + curr, 0)
  let late_payment_interest=formData.installments.map(item => parseFloat(item.late_payment_interest || 0)).reduce((acc, curr) => acc + curr, 0)
  let left=parseFloat(total_to_pay || 0) - parseFloat(paid || 0)
  left=left < 0  ? 0 : left
  let total_expenses=(parseFloat(formData.insurance || 0)) + (parseFloat(formData.cost_of_preparation || 0)) + (parseFloat(formData.taxes || 0))

  setTopDetails({
   paid,
   late_payment_interest,
   left,
   total_to_pay,
   total_expenses
  })

  if(!addExpenses)  setAddExpenses(total_expenses ? true : false)

},[formData])









const PrintLoanSummary = async (receiver_email)=>{

      let client=data._clients_list.filter(i=>i.id==formData.client_id)[0]

      if(!client){
        toast.error('Selecione o cliente para continuar')
        return
      }

      setLoading(true)

      let installment=data._interest_rates.map(item => {
        return  { id: item.id, value: item.days +` dias (${item.percentage}%)`, percentage:item.percentage,days:item.days}
      }
     ).filter(i=>i.id==formData.installment_id)[0]
 
      const top_items=[
        {name:'Cliente',value:client.name},
        {name:'Financiamento',value:data._cn(formData.disbursement_value || 0)+"MT"},
        {name:'Juros de mora',value:data._cn(topDetails.late_payment_interest)+"MT"},
        {name:'Data de reembolso',value:formData.refund_date ? formData.refund_date?.split('-')?.reverse()?.join('/') : '-'},
        {name:'Data de desembolso',value:formData.disbursement_date ? formData.disbursement_date?.split('-')?.reverse()?.join('/') : '-'},
        {name:'Total a pagar',value:data._cn(topDetails.total_to_pay)+"MT"},
        {name:'Pago',value:data._cn(topDetails.paid)+"MT"},
        {name:'Juros',value:data._cn(formData.fees)+"MT"},
        {name:'Prestação',value:installment?.value},
        {name:'Despesas',value:data._cn(topDetails.total_expenses)+"MT"},
        {name:'Número de Parcelas',value:(formData.number_of_installments || 0)}
      ]

      console.log({formData})


      try{
  
       let response=await data.makeRequest({method:'post',url:`api/generate-loan-summary-pdf`,withToken:true,data:{
          top_items,
          receiver_email,
          notes:formData.notes,
          expenses_list:[
            ...(formData.cost_of_preparation ? [`Custo de preparo:${data._cn(formData.cost_of_preparation)}MT`] : []),
            ...(formData.insurance ? [`Seguro:${data._cn(formData.insurance)}MT`] : []),
            ...(formData.taxes ? [`Imposto:${data._cn(formData.taxes)}MT`] : [])
          ],
          late_payment_interests:formData.LatePaymentInterests.map(i=>`De ${i.from} até ${i.to} (${i.percentage})%`),
          installments:formData.installments.map((i)=>(
            {
              initial_amount:(i.initial_amount || 0).toFixed(2)+"MT",
              total_to_pay:(i.total_to_pay).toFixed(2)+"MT",
              paid:(i.paid || 0).toFixed(2)+"MT",
              left:(i.total_to_pay) - (i.paid || 0) < 0 ? '0MT': ((i.total_to_pay) - (i.paid || 0)).toFixed(2)+"MT",
              end:i.end.split('-')?.reverse()?.join('/'),
              delay:i.delay || '-',
              late_payment_interest:(i.late_payment_interest || 0).toFixed(2)+"MT" || '-'
            }
          ))
       }, error: ``},0);

       setLoading(false)

       if(receiver_email){
         toast.success('Enviado com sucesso!')
       }else{
        window.location.href=data.APP_BASE_URL+"/download-loan-summary/"+response
       }


       setShowSendLoanSummary(false)
       setReceiverEmail('')

      }catch(e){

        let msg="Acorreu um erro, tente novamente"
        console.log(e)
        setLoading(false)

      if(e.response){
      
        if(e.response.status==400){
            msg="Dados inválidos"
        }
        if(e.response.status==500){
            msg="Erro, inesperado. Contacte seu administrador"
        }

      }else if(e.code=='ERR_NETWORK'){
            msg="Verfique sua internet e tente novamente"
      }
 
      toast.error(msg)
      setLoading(false)
             
      }
}


useEffect(()=>{

     let v=true;

     if(!formData.account_id || !formData.client_id || !topDetails.total_to_pay || (!formData.disbursement_performed_by_id && !isSimulator)) {
       v=false
     }

     setValid(v)

},[formData])

async function SubmitForm(restructuring){

  if(message) {
    toast.error(message)
    return
  }

  if(!formData.account_id || !formData.client_id || !topDetails.total_to_pay || (!formData.disbursement_performed_by_id && !isSimulator)) {
      toast.error('Preencha todos os campos obrigatórios!')
      return 
  }

 

  setLoading(true)
  
 
  try{

    const new_form={
      userRole,
      ...formData,
      ...topDetails,
      ...uploadedFiles,
      payment_status:getPaymentStatus(),
      contract_assets:formData.contract_assets.map(i=>({...i,status:formData.payment_status=="delayed" ? 'confiscated' : 'retained'})),
      microcredit_id:selectedMicrocredit,
      restructuring:isRestructuring ? true : false,
    }

    if(id && !isRestructuring){
  
      await data.makeRequest({method:'post',url:`api/contract/update/`+id,withToken:true,data:{
         ...new_form
      }, error: ``},0);
      toast.success('Actualizado com sucesso!')
      setLoading(false)

      
      if(restructuring=="restructuring"){

          setRegenerateInstallments(true)
          setIsRestructuring(true)
          setShowRestructuringDialog(false)
          setFormData({...formData,installments:[],
          restructuring_amount:topDetails.left,
          contract_assets:[],
          legal_status:'normal',
          disbursement_date:formData.refund_date,
          number_of_installments:1,
          disbursement_value:topDetails.left,
          notes:'',
          signed_contract_src:'',
          declaration_of_residence_src:'',
          identity_ticket_src:'',
          cost_of_preparation:'',
          insurance:'',
          taxes:''

        })

        setAddExpenses(false)

      }

    }else{

      await data.makeRequest({method:'post',url:`api/contract/create`,withToken:true,data:{
        ...new_form,
        restructuring_from_id:isRestructuring ? formData.id : null
      }, error: ``},0)
      setIsRestructuring(false)
      toast.success('Criado com sucesso!')
      if(isRestructuring){
        navigate('/loans/create')
      }
      setLoading(false)
      setFormData(initial_form)
      
    }

  }catch(e){

      let msg="Acorreu um erro, tente novamente"
      console.log(e)
      setLoading(false)

      if(e.response){

        if(e.response.status==404){
          msg="Item não encontrado"
          navigate('/loans')
        }
      
        if(e.response.status==400){
            msg="Dados inválidos"
        }
        if(e.response.status==500){
            msg="Erro, inesperado. Contacte seu administrador"
        }

      }else if(e.code=='ERR_NETWORK'){
            msg="Verfique sua internet e tente novamente"
      }

      toast.error(msg)
      setLoading(false)

    }
    
}



useEffect(()=>{

  if(!user || !id){
      return
  }

  (async()=>{

    try{
  
      let response=await data.makeRequest({method:'get',url:`api/contract/`+(id),withToken:true, error: ``},0);
      setItemToEditLoaded(response)
      setFormData({...response})

      setUploadedFiles({...uploadedFiles,
        signed_contract_src:response.signed_contract_src,
        declaration_of_residence_src:response.declaration_of_residence_src,
        identity_ticket_src:response.identity_ticket_src
      })

    }catch(e){

      console.log({e})

      let msg="Acorreu um erro, tente novamente"

      setLoading(false)

      if(e.response){

        if(e.response.status==409){
          msg="Email já existe"
        } 
        
        if(e.response.status==404){
            msg="Item não encontrado"
            navigate('/loans')
        }
        if(e.response.status==500){
            msg="Erro, inesperado. Contacte seu administrador"
        }

      }else if(e.code=='ERR_NETWORK'){
            msg="Verfique sua internet e tente novamente"
      }

      toast.error(msg)

  }
  
})()

},[user,pathname])


const [availableAccountBalance,setAvailableAccountBalance]=useState(null)

useEffect(()=>{

  if(isSimulator || isRestructuring){
    return
  }

  const account=data._account_balances.filter(i=>i.account_id==formData.account_id)[0]
  let avaliable=0 
  if(account){
      avaliable=parseFloat(account.availableBalance)
  }

  setAvailableAccountBalance(avaliable)

  if((avaliable < formData.disbursement_value && avaliable >= 0) && account && !id){
    setMessage('Apenas ' +data._cn(avaliable)+ ' disponivel nesta conta!')
  }else if(avaliable < 0  && !id){
    setMessage('A conta tem saldo negativo!')
  }else  if(!avaliable  && account  && !id){
    setMessage('Sem saldo disponivel para o emprestimo!')
  }else{
    setMessage(null)
  }


},[formData.account_id,formData.disbursement_value,data.loaded])



function getAssetStatus(){

  let status="retained"
  if(formData.installments.some(i=>i.status=="delayed")){
    status="confiscated"
  } 
  return status

}


async function RemoveRestructuredContract(){

  data._closeAllPopUps()
  toast.remove()
  setPrinting('restructuring')
  setLoading(true)

  try{

     await data.makeRequest({method:'post',url:'api/contracts/delete',withToken:true,data:{
        ids:[formData.restructuring_to_id],
        restructuring_from_id:formData.id,
     }, error: ``},0)

     setFormData({...formData,restructuring_to_id:null})
     window.location.reload()
     setLoading(false)
     setPrinting(false)
     toast.remove()
     toast.success("Actualizado com sucesso")
     data.setUpdateTable(Math.random())

  }catch(e){
     toast.remove()
     setPrinting(false)
     let msg="Acorreu um erro, tente novamente"
     setLoading(false)
     if(e.response){
       
       if(e.response.status==404){
           msg="Item não encontrado"
       }
       if(e.response.status==500){
           msg="Erro, inesperado. Contacte seu administrador"
       }

     }else if(e.code=='ERR_NETWORK'){
           msg="Verfique sua internet e tente novamente"
     }
     toast.error(msg)

  }



}


async function handleApproval({approval_status,id}){ 

  data._closeAllPopUps()
  toast.remove()
  toast.loading('A actualizar...') 
  setLoading(true)
 
  try{
     await data.makeRequest({method:'post',url:`api/contracts/approval-status`,withToken:true,data:{
           approval_status,
           id
     }, error: ``},0);
     setFormData({...formData,approval_status})

     setLoading(false)

     toast.remove()
     toast.success("Actualizado com sucesso")
     data.setUpdateTable(Math.random())

  }catch(e){
     toast.remove()
     let msg="Acorreu um erro, tente novamente"

     setLoading(false)

     if(e.response){
       if(e.response.status==409){
         msg="Nome já existe"
       } 
       if(e.response.status==404){
           msg="Item não encontrado"
       }
       if(e.response.status==500){
           msg="Erro, inesperado. Contacte seu administrador"
       }
     }else if(e.code=='ERR_NETWORK'){
           msg="Verfique sua internet e tente novamente"
     }

     toast.error(msg)

  }
}




async function handleItems({status,id,from}){ 
  data._closeAllPopUps()
  
  toast.remove()
  toast.loading('A actualizar...') 

  setLoading(true)

  let url = from == "legal_status" ? 'api/contracts/change-legal-status' : null
 
  try{
     await data.makeRequest({method:'post',url,withToken:true,data:{
           status,
           id
     }, error: ``},0);

     toast.remove()
     toast.success("Actualizado com sucesso")
     data.setUpdateTable(Math.random())
     setLoading(false)

     if(from == "legal_status"){
      setFormData({...formData,legal_status:status})
     }

  }catch(e){
     toast.remove()
     let msg="Acorreu um erro, tente novamente"
     setLoading(false)
     if(e.response){
       
       if(e.response.status==404){
           msg="Item não encontrado"
       }
       if(e.response.status==500){
           msg="Erro, inesperado. Contacte seu administrador"
       }

     }else if(e.code=='ERR_NETWORK'){
           msg="Verfique sua internet e tente novamente"
     }
     toast.error(msg)

  }
}


async function printPDFDocs(doc){
  let micro=user.microcredits.filter(i=>i.id==selectedMicrocredit)[0]
  if(!formData.client_id){
    toast('Selecione um cliente para continuar')
    return
  }

  setPrinting(doc) 


  if(!micro.logo_filename){
    data.setPrintingDoc(doc)
    setPrinting(false)
    return
  }

  const img = new Image();
  img.src = `${process.env.REACT_APP_BASE_URL}/uploaded_files/`+micro.logo_filename;
  img.onload = () => {
       setPrinting(false)
       data.setPrintingDoc(doc)

  };
  img.onerror = () => {
      setPrinting(false)
      toast.error('Imagem não carregada')
      data.setPrintingDoc(doc)
  };
}


useEffect(()=>{
    if((userRole=="operator" && !userInfo?.permissions?.contracts?.includes('create'))){
       navigate('/dashboard')
    }
},[userInfo]) 




return (

    <>  
      <InsertReceiverEmail receiverEmail={receiverEmail} setReceiverEmail={setReceiverEmail} PrintLoanSummary={PrintLoanSummary} setShow={setShowSendLoanSummary} show={showSendLoanSummary} loading={loading}/>
      <ConfirmCreditRestruring setShow={setShowRestructuringDialog} show={showRestructuringDialog} formData={formData} topDetails={topDetails} SubmitForm={SubmitForm} loading={loading}/>
      <SelectLatePaymentInterest setForm={setFormData} form={formData} setShow={setShowLatePaymentInterest} show={showLatePaymentInterest}/>
      <AllClientAssets setForm={setFormData} form={formData} show={showAllClientAssets} setShow={setShowAllClientAssets}/>
      <main className="dashboard-container">
  
       {<CreateClient createRes={(client)=>{
            data.setClientsList([...data._clients_list,client])
            setFormData({...formData,client_id:client.id})
       }} showD={createClient ? true : false}  create={createClient} setCreate={setCreateClient} edit={editClient} setEdit={setEditClient} />}
        
       {<CreateInstallment createRes={(installment)=>{
            data.setInterestRates([...data._interest_rates,installment])
            setFormData({...formData,installment_id:installment.id})
       }} showD={createInstallment ? true : false}  create={createInstallment} setCreate={setCreateInstallment} edit={editInstallment} setEdit={setEditInstallment} />}
      
      {<CreateLatePaymentInterest createRes={(interest)=>{
            data.setLatePaymentInterests([...data._late_payment_interests,interest])
            setFormData({...formData,LatePaymentInterests:[...formData.LatePaymentInterests,interest]})
       }} showD={createLatePaymentInterest ? true : false}  create={createLatePaymentInterest} setCreate={setCreateLatePaymentInterest} edit={editLatePaymentInterest} setEdit={setEditLatePaymentInterest} />}
      
       {<CreateAsset showD={createAsset || editAsset ? true : false} contractFormData={formData} setContractFormData={setFormData}  create={createAsset} setCreate={setCreateAsset} edit={editAsset} setEdit={setEditAsset} />}
       <CreatePayment showD={createPayment ? true : false} create={createPayment} setCreate={setCreatePayment} utils={utils} formData={formData} setFormData={setFormData}/>
       <PrintCreditSimulator topDetails={topDetails} formData={formData}/>
       <PrintPaymentPlan formData={formData}/>
       <PrintWarranties formData={formData}/>

        <div className="dashboard">
            <Menu/>
            <div className="main-dashboard">
               
                  <TopBar activePage={isRestructuring ? 'Restruturação de crédito': isSimulator ? 'Simulador de crédito': pathname!="/loans/create" ? 'Editar contrato' : 'Criar contratos'} lastPage={{name:'Contratos',pathname:'/loans'}}/>

                  <div className="center pb-10">

                     {!reqLoaded && <div className="bg-white p-2 rounded-[1rem]">
                        <DefaultFormSkeleton/>
                      </div>}
                     

                       <div className={`loan-container ${!reqLoaded  ? 'hidden':''}  ${loading ? 'loading' :''}`}>
                                   <div className="top-title">
                                     
                                                         {isRestructuring ? 'Restruturar crédito' : isSimulator ? 'Simulador de crédito' : pathname!="/loans/create" ? 'Editar contrato' : 'Criar contratos'}

                                                         <div className="flex items-center gap-x-3">
                                                         {(loanToEdit?.approval_status=="pending" && pathname!="/loans/create") && <div>

                                                          <div className="status" style={{color:'#111',background:'#F9CE66',padding:'2px 5px',borderRadius:5}}><span style={{fontSize:12}}>Aprovação Pendente</span></div>

                                                          </div>}

                                                          {(formData.restructuring_to_id) && <div className="flex items-center">
                                                            <svg class="mx-auto  text-orange-400 mr-2" height={20} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path></svg>
                                                            <span onClick={()=>window.location.href=('/loan/'+formData.restructuring_to_id)} className="text-orange-400  cursor-pointer underline">Contrato restruturado</span>
                                                          </div>}

                                                          {(!isSimulator) && <div class="div_btn"><button onClick={()=>SubmitForm()} class={`save ${!valid ? '!bg-gray-400 !pointer-events-none':''}`}><span>{isRestructuring ? 'Restruturar crédito': !id ? 'Criar contrato' : 'Actualizar'}</span></button><span class="loader"></span></div>}
                                                          
                                                          <AdvancedActions hide={printing} id={'legal-action'} w={300} items={[
                                                            {name:'Rejeitar',hide:isRestructuring || !id || (userRole=="operator" && !userInfo?.permissions?.contracts?.includes('reject')) || formData.approval_status=="rejected" || (userRole!="manager" && !userInfo?.permissions?.contracts?.includes('approve')),onClick:()=>{
                                                              handleApproval({approval_status:'rejected',id:formData.id})
                                                               data._closeAllPopUps()
                                                            },icon:(<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>)},
                                                            {name:'Aprovar',hide:isRestructuring || !id || (userRole=="operator" && !userInfo?.permissions?.contracts?.includes('approve')) || formData.approval_status=="approved"  || (userRole!="manager" && !userInfo?.permissions?.contracts?.includes('approve')),onClick:()=>{
                                                              handleApproval({approval_status:'approved',id:formData.id})
                                                              data._closeAllPopUps()
                                                            },icon:(<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>)},
                                                                                                                          
                                                            {name:'Encaminhar para Jurídico',hide:(userRole=="operator" && !userInfo?.permissions?.legal_guidelines?.includes('add')) || !id || formData.legal_status=="legal" || isRestructuring,onClick:()=>{
                                                              handleItems({status:'legal',id:formData.id,from:'legal_status'})
                                                              data._closeAllPopUps()
                                                            }},

                                                            {name:'Remover do Jurídico',hide:isRestructuring || (userRole=="operator" && !userInfo?.permissions?.legal_guidelines?.includes('remove')) || !id || formData.legal_status=="normal",onClick:()=>{
                                                              handleItems({status:'normal',id:formData.id,from:'legal_status'})
                                                              data._closeAllPopUps()
                                                            }},

                                                            {name:'Restruturar crédito',hide:formData.restructuring_from_id || (userRole=="operator" && !userInfo?.permissions?.restructure_credit?.includes('emit')) ||   !(id  && topDetails.left!=0 && formData.payment_status!="status" && !isRestructuring && !formData.restructuring_to_id),onClick:()=>{
                                                              setShowRestructuringDialog(true)
                                                              data._closeAllPopUps()
                                                            }},

                                                            {name:'Imprimir certidão de quitação',hide:topDetails.left || !id || 0==0,onClick:()=>{
                                                              print_client_contract('pdf','certificate')
                                                              data._closeAllPopUps()
                                                            },icon:(
                                                               <svg height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z"></path></svg>
                                                            )},

                                                            {name:'Imprimir certidão de quitação',hide:topDetails.left || !id || 0==0,onClick:()=>{
                                                              print_client_contract('word','certificate')
                                                              data._closeAllPopUps()
                                                            },icon:(
                                                               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" height="19px"><path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.6875 C 27.941406 6.882813 27.941406 7.085938 28 7.28125 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 44 37 L 44 35 L 30 35 L 30 29 L 44 29 L 44 27 L 30 27 L 30 22 L 44 22 L 44 20 L 30 20 L 30 15 L 44 15 L 44 13 L 30 13 Z M 4.625 15.65625 L 8.4375 34.34375 L 12.1875 34.34375 L 14.65625 22.375 C 14.769531 21.824219 14.875 21.101563 14.9375 20.25 L 14.96875 20.25 C 14.996094 21.023438 15.058594 21.75 15.1875 22.375 L 17.59375 34.34375 L 21.21875 34.34375 L 25.0625 15.65625 L 21.75 15.65625 L 19.75 28.125 C 19.632813 28.828125 19.558594 29.53125 19.53125 30.21875 L 19.5 30.21875 C 19.433594 29.339844 19.367188 28.679688 19.28125 28.21875 L 16.90625 15.65625 L 13.40625 15.65625 L 10.78125 28.0625 C 10.613281 28.855469 10.496094 29.582031 10.46875 30.25 L 10.40625 30.25 C 10.367188 29.355469 10.308594 28.625 10.21875 28.09375 L 8.1875 15.65625 Z"></path></svg>
                                                            )},


                                                            {name:'Imprimir termo de confissão de dívida',hide:0==0,onClick:()=>{
                                                              print_client_contract('pdf','agreement')
                                                              data._closeAllPopUps()
                                                            },icon:(
                                                               <svg height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z"></path></svg>
                                                            )},

                                                            {name:'Imprimir termo de confissão de dívida',hide:0==0,onClick:()=>{
                                                              print_client_contract('word','agreement')
                                                              data._closeAllPopUps()
                                                            },icon:(
                                                               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" height="19px"><path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.6875 C 27.941406 6.882813 27.941406 7.085938 28 7.28125 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 44 37 L 44 35 L 30 35 L 30 29 L 44 29 L 44 27 L 30 27 L 30 22 L 44 22 L 44 20 L 30 20 L 30 15 L 44 15 L 44 13 L 30 13 Z M 4.625 15.65625 L 8.4375 34.34375 L 12.1875 34.34375 L 14.65625 22.375 C 14.769531 21.824219 14.875 21.101563 14.9375 20.25 L 14.96875 20.25 C 14.996094 21.023438 15.058594 21.75 15.1875 22.375 L 17.59375 34.34375 L 21.21875 34.34375 L 25.0625 15.65625 L 21.75 15.65625 L 19.75 28.125 C 19.632813 28.828125 19.558594 29.53125 19.53125 30.21875 L 19.5 30.21875 C 19.433594 29.339844 19.367188 28.679688 19.28125 28.21875 L 16.90625 15.65625 L 13.40625 15.65625 L 10.78125 28.0625 C 10.613281 28.855469 10.496094 29.582031 10.46875 30.25 L 10.40625 30.25 C 10.367188 29.355469 10.308594 28.625 10.21875 28.09375 L 8.1875 15.65625 Z"></path></svg>
                                                            )},
                                                              

                                                            {name:'Anular contrato gerado na restruturação de crédito',hide:!formData.restructuring_to_id,onClick:()=>{
                                                              RemoveRestructuredContract()
                                                              data._closeAllPopUps()
                                                            }},
                                                            


                                                          ]}/>
                                                          
                                                          {printing && <div>
                                                              <Loader/>
                                                          </div>}

                                                         </div>

                                   </div>

                                  {!isSimulator && <div className="top-options">
                                         <span className={currentTab == "details" ? 'active' : ''} onClick={()=>setCurrentTab('details')}>Detalhes <label className="ml-2">do emprestimo</label></span>
                                         <span onClick={()=>setCurrentTab('models')}   className={currentTab == "models" ? 'active' : ''} >Documentos <label className="ml-2"></label></span>
                                         <span onClick={()=>setCurrentTab('assets')}   className={`flex items-center ${currentTab == "assets" ? 'active' : ''}`} >
                                          Bens <label className="ml-2">associados</label>
                                          {formData.contract_assets.length!=0 && <div className="ml-2 bg-custom_blue-400 text-white rounded-full px-2 py-[px] flex items-center justify-center">
                                            <span style={{padding:0,display:'initial'}}>{formData.contract_assets.length}</span>
                                          </div>}
                                          </span>
                                   </div>}

                                   <div className="main-section">

                                           <div className="details" style={{display:currentTab == "details" ? 'block' : 'none'}} >
                                                  <div className="count-cards">
                                                     {!isSimulator && <div className="item">
                                                                <label>Saldo disponível</label>
                                                                <span className="value">{availableAccountBalance==null ? '-' :data._cn((availableAccountBalance || 0).toFixed(2))}</span>
                                                     </div>}
                                                    
                                                      <div className="item">
                                                            <label>Total a pagar</label>
                                                            <span className="value">{data._cn(topDetails.total_to_pay)}</span>
                                                     </div>
                                                     <div className="item">
                                                            <label>Financiamento</label>
                                                            <span className="value">{data._cn(formData.disbursement_value || 0)}</span>
                                                      </div>
                                                      <div className="item">
                                                            <label>Pago</label>
                                                            <span className="value">{data._cn(topDetails.paid)}</span>
                                                      </div>
                                                      <div className="item">
                                                            <label>Em falta</label>
                                                            <span className="value">{data._cn(topDetails.left)}</span>
                                                     </div>
                                                      <div className="item">
                                                            <label>Juros</label>
                                                            <span  className="value">{data._cn(formData.fees)}</span>
                                                     </div>
                                                      <div className="item">
                                                            <label>Juros de mora</label>
                                                            <span className="value">{data._cn(topDetails.late_payment_interest)}</span>
                                                      </div>
                                                      <div className="item">
                                                            <label>Despesas</label>
                                                            <span className="value">{data._cn((parseFloat(formData.insurance || 0)) + (parseFloat(formData.cost_of_preparation || 0)) + (parseFloat(formData.taxes || 0)))}</span>
                                                      </div>
                                                     
                                                     <div className="item">
                                                            <label>Data de reembolso </label>
                                                            <span className="value">{formData.refund_date ? formData.refund_date?.split('-')?.reverse()?.join('/') : '-'}</span>
                                                     </div>

                                                  </div>

                                                  <div className="input-container">
                                                     {(!isRestructuring && !isSimulator) && <div className="inputs">
                                                              <span>Conta de desembolso <label className="text-red-600 ml-1 translate-y-1">*</label></span>
                                                              <select onChange={(e)=>setFormData({...formData,account_id:e.target.value})} value={formData.account_id}>
                                                                   <option disabled selected value="">Selecione uma opção</option>
                                                                   {data._accounts.filter(i=>i.role!="loss" && i.role!="pledged").map((i,_i)=>(
                                                                        <option value={i.id} key={'_account-'+_i}>{i.name}</option>
                                                                   ))}
                                                              </select> 
                                                       </div>}

                                                      <div className="inputs search-select">
                                                              <span>Cliente <label className="text-red-600 ml-1 translate-y-1">*</label></span>
                                                              <MultiSelect options={data._clients_list.filter(i=>i.deleted_at==null || i.id==itemToEditLoaded?.client_id)} setSelectedOptions={(item)=>{
                                                                setFormData({...formData,client_id:item.id})
                                                              }} selectedOptions={data._clients_list.filter(i=>i.id==formData.client_id)[0] || []} placeholder={'Adicione cliente'} noOptionsMessage={'Sem opções'}/>
                                                             {((userRole=="manager" || userInfo?.permissions?.clients?.includes('create')) && !formData.restructuring_to_id) && <span className="btn-add" onClick={()=>{
                                                                 setCreateClient(true)
                                                              }}><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="24"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg></span> }
                                                    
                                                     </div>

                                                      <div className="inputs">
                                                         <span>Montante por desembolsar<label className="text-red-600 ml-1 translate-y-1">*</label></span>
                                                         <input className={`${formData.restructuring_to_id ? 'opacity-40':''}`} disabled={(loading ||formData.restructuring_to_id) ? true : false} value={formData.disbursement_value} onChange={e=>{
                                                          setFormData({...formData,disbursement_value:parseFloat(e.target.value)})
                                                          setRegenerateInstallments(true)
                                                        }} type="number" placeholder="valor"/>
                                                      </div>
                                                      

                                                       <div className="inputs">
                                                            <span>Prestação<label className="text-red-600 ml-1 translate-y-1">*</label></span>
                                                              <select className={`${formData.restructuring_to_id ? 'opacity-40':''}`} disabled={loading || formData.restructuring_to_id ? true : false} value={formData.installment_id}  onChange={e=>{
                                                                  setFormData({...formData,installment_id:e.target.value})
                                                                  setRegenerateInstallments(true)
                                                              }}>
                                                                <option disabled selected value="">Selecione uma opção</option>
                                                                {data._interest_rates.map(item => {
                                                                        return  { id: item.id, value: item.days +` dias (${item.percentage}%)`, percentage:item.percentage,days:item.days}
                                                                    }
                                                                ).map((i,_i)=>(
                                                                  <option value={i.id} key={'_account-'+_i}>{i.value}</option>
                                                                ))}
                                                            </select>
                                                            {((userRole=="manager" || userInfo?.permissions?.contract_settings?.includes('create')) && !formData.restructuring_to_id) && <span className="btn-add" onClick={()=>setCreateInstallment(true)}><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="24"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg></span>}
                                                        </div>

                                                       <div className="inputs">
                                                          <span>Parcelas<label className="text-red-600 ml-1 translate-y-1">*</label></span>
                                                          <input className={`${formData.restructuring_to_id ? 'opacity-40':''}`} disabled={loading || formData.restructuring_to_id ? true : false} value={formData.number_of_installments ?  formData.number_of_installments : ''} onChange={e=>{
                                                            if(parseInt(e.target.value || 0) > 50){
                                                               toast.remove()
                                                               toast.error('Não pode adicionar mais que 50 parcelas')
                                                            }
                                                            setFormData({...formData,number_of_installments:parseInt(e.target.value || 0) > 50 ? parseInt(1) : parseInt(e.target.value)})
                                                            setRegenerateInstallments(true)
                                                          }} type="number" placeholder="Número de parcelas"/>
                                                      </div>


                                                      

                                                       <div className="inputs">
                                                          <span>Data de desembolso <label className="text-red-600 ml-1 translate-y-1">*</label></span>
                                                          <input className={`${formData.restructuring_to_id ? 'opacity-40':''}`} type="date" disabled={loading || formData.restructuring_to_id ? true : false} value={formData.disbursement_date} onChange={e=>{
                                                            setFormData({...formData,disbursement_date:e.target.value})
                                                            setRegenerateInstallments(true)
                                                          }}/>
                                                      </div>

                                                      {!isSimulator && <div className="inputs">
                                                              <span>Responsável pelo desembolso<label className="text-red-600 ml-1 translate-y-1">*</label></span>
                                                              <select onChange={(e)=>setFormData({...formData,disbursement_performed_by_id:e.target.value})} value={formData.disbursement_performed_by_id}>
                                                                   <option disabled selected value="">Selecione uma opção</option>
                                                                   {data._microcredit_managers_and_operators.map((i,_i)=>(
                                                                        <option value={i.id} key={'_account-'+_i}>{i.name}</option>
                                                                   ))}
                                                              </select> 
                                                       </div>}
                                                      
                                                      <div className="inputs _full">
                                                          <span>Nota <label className="ml-1 opacity-60">(opcional)</label></span>
                                                          <textarea disabled={loading ? true : false} style={{height:'80px'}} value={formData.notes} onChange={e=>setFormData({...formData,notes:e.target.value})} placeholder="Nota..."></textarea>
                                                      </div>
                                                  </div>

                                                  <div className="mx-2 mb-3">

                                                    <div className="flex items-center mb-1">
                                                      <span class="flex items-center mb-2 text-[17px]  text-gray-900">Taxas de juros de mora <label className="ml-1 opacity-60">(opcional)</label></span>
                                                      {!formData.restructuring_to_id && <span onClick={()=>{
                                                           setShowLatePaymentInterest(true)
                                                      }} className="bg-custom_blue-400 flex items-center  cursor-pointer ml-10 text-white px-2 py-[2px] rounded-full text-[0.9rem]">
                                                          Taxas
                                                          <svg className="fill-white rotate-90" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"></path></svg>
                                                      </span>}
                                                      {(userRole=="manager" && !formData.restructuring_to_id) && <span className="__btn-add" onClick={()=>setCreateLatePaymentInterest(true)}><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="24"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg></span>}
                                                    </div>
                                                    <div className="flex items-center">

                                                        <div class={`bg-gray border flex  flex-wrap border-gray-300 text-gray-900 text-sm  rounded-[0.3rem]  w-[400px] max-md:w-auto max-md:flex-1 px-1.5 py-1`}>
                                                            {!formData.LatePaymentInterests.length && <span className="py-1">Nenhuma taxa adicionada</span>}
                                                            {formData.LatePaymentInterests.map(i=>(
                                                                <div className="bg-gray-200 rounded-[0.3rem] my-[1px] px-2 py-1 inline-flex items-center mr-1">
                                                                <span className="text-[14px] flex items-center">De {i.from} até {i.to}
                                                                  
                                                                  <svg className="mx-2" xmlns="http://www.w3.org/2000/svg" height="19px" viewBox="0 -960 960 960"  fill="#5f6368"><path d="m600-200-57-56 184-184H80v-80h647L544-704l56-56 280 280-280 280Z"/></svg>
                                                                   {i.percentage}%</span>
                                                                
                                                                  {!formData.restructuring_to_id && <div  onClick={() => {
                                                                      setFormData({...formData,LatePaymentInterests:formData.LatePaymentInterests.filter(g=>g.id!=i.id)})
                                                                  }}
                                                                  
                                                                  className={` ml-1 cursor-pointer rounded-[0.3rem] hover:opacity-40  flex items-center justify-center`}
                                                                  >
                                                                      <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" fill="#5f6368">
                                                                      <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                                                                      </svg>
                                                                  </div>}
                                                              </div>
                                                            ))}
                                                        </div>
                                                      </div>

                                                  </div>

 
                                                  <div className="mb-3 mt-7">
                                                   
                                                   <div className="flex justify-start">
                                                      <label  className={`mx-2 flex ${!(loading || formData.restructuring_to_id) ? 'cursor-pointer':''} items-center gap-x-1`}>
                                                       <input disabled={loading || formData.restructuring_to_id ? true : false}  onClick={()=>{
                                                        setAddExpenses(!addExpenses)
                                                        setFormData({...formData,
                                                          insurance:'',
                                                          cost_of_preparation:'',
                                                          taxes:'',
                                                          expense_account_id:''
                                                        })
                                                      }}  checked={addExpenses} id="checkbox-table-3" type="checkbox" class={`${formData.restructuring_to_id ? 'opacity-40':''} w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 dark:focus:ring-blue-600  focus:ring-2`}/>
                                                         
                                                          <span>Incluir Despesas</span>
                                                      </label>
                                                    </div>
                                                   
                                                    <div className={`${!addExpenses ? '!hidden':''}`}>
                                                      <div className={`input-container !mt-3`}>
                                                          <div className="inputs">
                                                                <span>Custo de preparo</span>
                                                                <input className={`${formData.restructuring_to_id ? 'opacity-40':''}`} type="number" disabled={loading || formData.restructuring_to_id ? true : false} value={formData.cost_of_preparation} onChange={e=>{
                                                                  setFormData({...formData,cost_of_preparation:e.target.value})
                                                                  setRegenerateInstallments(true)
                                                                }}/>
                                                        </div>

                                                        <div className="inputs">
                                                                <span>Seguro</span>
                                                                <input className={`${formData.restructuring_to_id ? 'opacity-40':''}`}  type="number" disabled={loading || formData.restructuring_to_id ? true : false} value={formData.insurance} onChange={e=>{
                                                                  setFormData({...formData,insurance:e.target.value})
                                                                  setRegenerateInstallments(true)
                                                                }}/>
                                                        </div>

                                                        <div className="inputs">
                                                                <span>Imposto</span>
                                                                <input className={`${formData.restructuring_to_id ? 'opacity-40':''}`} type="number" disabled={loading || formData.restructuring_to_id ? true : false} value={formData.taxes} onChange={e=>{
                                                                  setFormData({...formData,taxes:e.target.value})
                                                                  setRegenerateInstallments(true)
                                                                }}/>
                                                        </div>
                                                        
                                                        {/**<div className="inputs">
                                                              <span>Conta a creditar despesas</span>
                                                              <select onChange={(e)=>setFormData({...formData,expense_account_id:e.target.value})} value={formData.expense_account_id}>
                                                                   <option disabled selected value="">Selecione uma opção</option>
                                                                   {data._accounts.filter(i=>i.role!="loss" && i.role!="pledged").map((i,_i)=>(
                                                                        <option value={i.id} key={'_account-'+_i}>{i.name}</option>
                                                                   ))}
                                                              </select>
                                                         </div> */}

                                                      </div>
                                                    </div>

                                                  </div>

                                                  <div className="_table">

                                                  <span  class="flex items-center mb-2 text-[17px]  text-gray-900">Parcelas </span>
                                                     
                                                      <div className="btn-add-installment">
                                                       </div>
                                                              <BaiscTable  canAdd={false}  loaded={true} header={[
                                                                    'Valor inicial',
                                                                    'Valor a pagar',
                                                                    'Valor pago',
                                                                    'Em falta',
                                                                    'Prazo de pagamento',
                                                                    'Dias em atraso',
                                                                    'Juros de mora',
                                                                    'Estado'
                                                                    ]
                                                              }
                                                              body={formData.installments.map((i,_i)=>(
                                                                   <BaiscTable.Tr>
                                                                          <BaiscTable.Td  onClick={()=>setCreatePayment(i)}>{data._cn((i.initial_amount || 0).toFixed(2))}</BaiscTable.Td>
                                                                          <BaiscTable.Td onClick={()=>setCreatePayment(i)}>{data._cn((i.total_to_pay).toFixed(2))}</BaiscTable.Td>
                                                                          <BaiscTable.Td onClick={()=>setCreatePayment(i)}>{i.paid ? data._cn((i.paid || 0).toFixed(2)) : '-'}</BaiscTable.Td>
                                                                          <BaiscTable.Td onClick={()=>setCreatePayment(i)}>{(i.total_to_pay) - (i.paid || 0) < 0 ? 0 : data._cn(((i.total_to_pay) - (i.paid || 0)).toFixed(2))}</BaiscTable.Td>
                                                                          <BaiscTable.Td onClick={()=>setCreatePayment(i)}>{i.end.split('-')?.reverse()?.join('/')}</BaiscTable.Td>
                                                                          <BaiscTable.Td onClick={()=>setCreatePayment(i)}>{i.delay || '-'}</BaiscTable.Td>
                                                                          <BaiscTable.Td onClick={()=>setCreatePayment(i)}>{data._cn((i.late_payment_interest || 0).toFixed(2))}</BaiscTable.Td>
                                                                          <BaiscTable.Td onClick={()=>setCreatePayment(i)}>
                                                                              <button style={{border:0}} type="button"  class={`text-gray-900 cursor-default ${i.status=="paid" ? 'bg-green-500':i.status=="pending" ? 'bg-yellow-500':'bg-[#f82f54bf]'} focus:outline-none  font-medium rounded-[0.3rem] text-sm px-2 py-1 text-center inline-flex items-center`}>
                                                                                    {i.status=='pending' ? 'Pendente':  i.status=="paid" ? 'Pago' : 'Atrasado'}
                                                                              </button>
                                                                          </BaiscTable.Td>
                                                                     </BaiscTable.Tr>
                                                                    ))}
                                                              />
                                                     </div>
                                                    
                                           </div>


                                           <div className="contract_model" style={{display:currentTab == "models" ? 'block' : 'none'}}>
                                                   <div className="max-w-[500px]">

                                                   {(userRole=="manager" || userInfo?.permissions?.contract_template?.includes('print')) && <div className="section-div download-model ">
                                                             <span className="flex-1">Imprimir modelo de contrato</span>
                                                             {printing=="contract" ? <>
                                                                <div className="ml-3">
                                                                   <Loader/>
                                                                </div>
                                                             </> : <div className={`${printing && printing!="contract" ? 'opacity-40 pointer-events-none':''}`}>
                                                             <button onClick={()=>print_client_contract('pdf','contract')}>
                                                               <svg className="fill-custom_blue-400" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z"></path></svg>
                                                             </button>
                                                             <button onClick={()=>print_client_contract('word','contract')}>
                                                                <svg className="fill-custom_blue-400" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50"  height="20px"><path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.6875 C 27.941406 6.882813 27.941406 7.085938 28 7.28125 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 44 37 L 44 35 L 30 35 L 30 29 L 44 29 L 44 27 L 30 27 L 30 22 L 44 22 L 44 20 L 30 20 L 30 15 L 44 15 L 44 13 L 30 13 Z M 4.625 15.65625 L 8.4375 34.34375 L 12.1875 34.34375 L 14.65625 22.375 C 14.769531 21.824219 14.875 21.101563 14.9375 20.25 L 14.96875 20.25 C 14.996094 21.023438 15.058594 21.75 15.1875 22.375 L 17.59375 34.34375 L 21.21875 34.34375 L 25.0625 15.65625 L 21.75 15.65625 L 19.75 28.125 C 19.632813 28.828125 19.558594 29.53125 19.53125 30.21875 L 19.5 30.21875 C 19.433594 29.339844 19.367188 28.679688 19.28125 28.21875 L 16.90625 15.65625 L 13.40625 15.65625 L 10.78125 28.0625 C 10.613281 28.855469 10.496094 29.582031 10.46875 30.25 L 10.40625 30.25 C 10.367188 29.355469 10.308594 28.625 10.21875 28.09375 L 8.1875 15.65625 Z"/></svg>
                                                             </button>
                                                            
                                                             </div>}
                                                              
                                                   </div>}

                                                   {(userRole=="manager" || userInfo?.permissions?.debt_confession_agreement_template?.includes('print')) && <div className="section-div download-model">
                                                             <span className="flex-1">Imprimir termo de confissão de dívida</span>
                                                             {printing=="agreement" ? <>
                                                                <div className="ml-3">
                                                                   <Loader/>
                                                                </div>
                                                             </> : <div className={`${printing && printing!="agreement" ? 'opacity-40 pointer-events-none':''}`}>
                                                             <button onClick={()=>print_client_contract('pdf','agreement')}>
                                                               <svg className="fill-custom_blue-400" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z"></path></svg>
                                                             </button>
                                                             <button onClick={()=>print_client_contract('word','agreement')}>
                                                                <svg className="fill-custom_blue-400" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50"  height="20px"><path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.6875 C 27.941406 6.882813 27.941406 7.085938 28 7.28125 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 44 37 L 44 35 L 30 35 L 30 29 L 44 29 L 44 27 L 30 27 L 30 22 L 44 22 L 44 20 L 30 20 L 30 15 L 44 15 L 44 13 L 30 13 Z M 4.625 15.65625 L 8.4375 34.34375 L 12.1875 34.34375 L 14.65625 22.375 C 14.769531 21.824219 14.875 21.101563 14.9375 20.25 L 14.96875 20.25 C 14.996094 21.023438 15.058594 21.75 15.1875 22.375 L 17.59375 34.34375 L 21.21875 34.34375 L 25.0625 15.65625 L 21.75 15.65625 L 19.75 28.125 C 19.632813 28.828125 19.558594 29.53125 19.53125 30.21875 L 19.5 30.21875 C 19.433594 29.339844 19.367188 28.679688 19.28125 28.21875 L 16.90625 15.65625 L 13.40625 15.65625 L 10.78125 28.0625 C 10.613281 28.855469 10.496094 29.582031 10.46875 30.25 L 10.40625 30.25 C 10.367188 29.355469 10.308594 28.625 10.21875 28.09375 L 8.1875 15.65625 Z"/></svg>
                                                             </button>
                                                            
                                                             </div>}
                                                             
                                                   </div>}
                                                   {(userRole=="manager" || userInfo?.permissions?.clearance_certificate_template?.includes('print')) && <div className="section-div download-model">
                                                             <span className="flex-1">Imprimir certidão de quitação</span>
                                                             {printing=="certificate" ? <>
                                                                <div className="ml-3">
                                                                   <Loader/>
                                                                </div>
                                                             </> :  <div className={`${printing && printing!="certificate" ? 'opacity-40 pointer-events-none':''}`}>
                                                             <button onClick={()=>print_client_contract('pdf','certificate')}>
                                                               <svg className="fill-custom_blue-400" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z"></path></svg>
                                                             </button>
                                                             <button onClick={()=>print_client_contract('word','certificate')}>
                                                                <svg className="fill-custom_blue-400" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50"  height="20px"><path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.6875 C 27.941406 6.882813 27.941406 7.085938 28 7.28125 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 44 37 L 44 35 L 30 35 L 30 29 L 44 29 L 44 27 L 30 27 L 30 22 L 44 22 L 44 20 L 30 20 L 30 15 L 44 15 L 44 13 L 30 13 Z M 4.625 15.65625 L 8.4375 34.34375 L 12.1875 34.34375 L 14.65625 22.375 C 14.769531 21.824219 14.875 21.101563 14.9375 20.25 L 14.96875 20.25 C 14.996094 21.023438 15.058594 21.75 15.1875 22.375 L 17.59375 34.34375 L 21.21875 34.34375 L 25.0625 15.65625 L 21.75 15.65625 L 19.75 28.125 C 19.632813 28.828125 19.558594 29.53125 19.53125 30.21875 L 19.5 30.21875 C 19.433594 29.339844 19.367188 28.679688 19.28125 28.21875 L 16.90625 15.65625 L 13.40625 15.65625 L 10.78125 28.0625 C 10.613281 28.855469 10.496094 29.582031 10.46875 30.25 L 10.40625 30.25 C 10.367188 29.355469 10.308594 28.625 10.21875 28.09375 L 8.1875 15.65625 Z"/></svg>
                                                             </button>
                                                             </div>}
                                                   </div>}
                                                  {(userRole=="manager" || userInfo?.permissions?.payment_plan?.includes('print')) &&  <div className="section-div download-model">
                                                             <span className="flex-1">Imprimir plano de pagamento</span>
                                                             {printing=="plan" ? <>
                                                                <div className="ml-3">
                                                                   <Loader/>
                                                                </div>
                                                             </> : <div className={`${printing && printing!="plan" ? 'opacity-40 pointer-events-none':''}`}>
                                                             <button onClick={()=>{
                                                                 printPDFDocs('plan')
                                                             }}>
                                                               <svg className="fill-custom_blue-400" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z"></path></svg>
                                                             </button>
                                                             </div>}
                                                   </div>}

                                                   {(userRole=="manager" || userInfo?.permissions?.asset_and_guarantee_sheet?.includes('print')) && <div className="section-div download-model">
                                                             <span className="flex-1">Imprimir ficha de bens e garantias</span>
                                                             {printing=="warranties" ? <>
                                                                <div className="ml-3">
                                                                   <Loader/>
                                                                </div>
                                                             </> : <div className={`${printing && printing!="warranties" ? 'opacity-40 pointer-events-none':''}`}>
                                                             <button onClick={()=>{
                                                               printPDFDocs('warranties')
                                                             }}>
                                                               <svg className="fill-custom_blue-400" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z"></path></svg>
                                                             </button>
                                                             </div>}
                                                   </div>}


                                                   </div>
                                                   <div className="sending-inputs">

                                                            <span>Carregar modelo de contrato assinado</span>
                                                            <div className="inputs-c">
                                                                <input type="file" ref={fileInputRef_1} onChange={onFileUpload} className="signed_contract_src"/>
                                                                <button>Selecionar arquivo</button>
                                                                <span>
                                                                      {uploadedFiles.signed_contract_src && (<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path fill="green" d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"></path></svg>)}
                                                                </span>
                                                            </div>
                                                            {uploadedFiles.signed_contract_src && <div className="see-file" onClick={()=>setUploadedFiles({...uploadedFiles,signed_contract_src:null})}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                                                            </div>}
                                                            {uploadedFiles.signed_contract_src && <div className="see-file" >
                                                                      <a target="_blank" href={`${process.env.REACT_APP_BASE_URL}/uploaded_files/${uploadedFiles.signed_contract_src}`}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m216-160-56-56 464-464H360v-80h400v400h-80v-264L216-160Z"/></svg></a>
                                                            </div>}

                                                </div>
                                                 <div className="sending-inputs">
                                                            <span>Carregar bilhete de identidade</span>
                                                            <div className="inputs-c">
                                                                <input type="file" ref={fileInputRef_1} onChange={onFileUpload} className="identity_ticket_src"/>
                                                                <button>Selecionar arquivo</button>
                                                                <span>
                                                                      {uploadedFiles.identity_ticket_src && (<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path fill="green" d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"></path></svg>)}
                                                                </span>
                                                            </div>
                                                            {uploadedFiles.identity_ticket_src && <div className="see-file" onClick={()=>setUploadedFiles({...uploadedFiles,identity_ticket_src:null})}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                                                            </div>}

                                                            {uploadedFiles.identity_ticket_src && <div className="see-file">
                                                                      <a target="_blank" href={`${process.env.REACT_APP_BASE_URL}/uploaded_files/${uploadedFiles.identity_ticket_src}`}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m216-160-56-56 464-464H360v-80h400v400h-80v-264L216-160Z"/></svg></a>
                                                            </div>}

                                                            </div> <div className="sending-inputs">
                                                            <span>Carregar declaração de residência</span>
                                                            <div className="inputs-c">
                                                                <input type="file" ref={fileInputRef_1} onChange={onFileUpload} className="declaration_of_residence_src"/>
                                                                <button>Selecionar arquivo</button>
                                                                <span>
                                                                      {uploadedFiles.declaration_of_residence_src && (<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path fill="green" d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"></path></svg>)}
                                                                </span>
                                                            </div>
                                                            {uploadedFiles.declaration_of_residence_src && <div className="see-file" onClick={()=>setUploadedFiles({...uploadedFiles,declaration_of_residence_src:null})}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                                                            </div>}
                                                            {uploadedFiles.declaration_of_residence_src && <div className="see-file" >
                                                                      <a target="_blank" href={`${process.env.REACT_APP_BASE_URL}/uploaded_files/${uploadedFiles.declaration_of_residence_src}`}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m216-160-56-56 464-464H360v-80h400v400h-80v-264L216-160Z"/></svg></a>
                                                            </div>}
                                                </div>
                                           </div>



                                           <div className="contract_model" style={{display:currentTab == "assets" ? 'block' : 'none'}}>

                                              <div className="flex items-center flex-wrap max-lg:flex-col">

                                               <div class="sub-title">
                                                     Bens
                                                     {(userRole=="manager" || userInfo?.permissions?.inventory?.includes('create')) && <button onClick={()=>setCreateAsset(true)}>Adicionar</button>}
                                               </div>

                                               <button className="flex items-center border-none rounded-[0.2rem] py-[0.2rem] px-2 bg-custom_blue-400 text-white" onClick={()=>{
                                                      if(formData.client_id){
                                                        setShowAllClientAssets(true)
                                                      }else{
                                                        toast('Selecione um cliente para continuar')
                                                      }
                                               }}>
                                                   <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"></path></svg>
                                                   Procurar outros bens do cliente
                                                </button>
                                               </div>
                                             

                                               <div className="_table">
                                                            <BaiscTable canAdd={user?.role=="admin"}  loaded={true} header={[
                                                                    'Descrição',
                                                                    'Valor',
                                                                    'Estado',
                                                                    'Categoria',
                                                                    'Data de aquisição',
                                                                    'Número de registro',
                                                                    'Observações',
                                                                    'Localização',
                                                                    'Fotos ou documentos'
                                                                    ]
                                                              } 
                                                              body={formData.contract_assets.map((i,_i)=>(
                                                                   <BaiscTable.Tr>
                                                                          <BaiscTable.Td onClick={()=>setEditAsset(i)}>{i.description || '-'}</BaiscTable.Td>
                                                                          <BaiscTable.Td onClick={()=>setEditAsset(i)}>{(parseFloat(i.amount || 0)).toFixed(2)}</BaiscTable.Td>
                                                                          <BaiscTable.Td onClick={()=>setEditAsset(i)}>
                                                                              <button style={{border:0}} type="button"  class={`text-gray-900 cursor-default ${getAssetStatus()=="retained" ? 'bg-[#F9CE66]':'bg-[#FF6B6B]'} focus:outline-none font-medium rounded-[0.3rem] text-sm px-2 py-1 text-center inline-flex items-center`}>
                                                                                    {getAssetStatus()=='retained' ? 'Retido': 'Confiscado'}
                                                                              </button>
                                                                          </BaiscTable.Td>
                                                                          <BaiscTable.Td onClick={()=>setEditAsset(i)}>{data._inventory_categories.filter(f=>f.id==i.category_id)[0]?.name}</BaiscTable.Td>
                                                                          <BaiscTable.Td onClick={()=>setEditAsset(i)}>{i.date_of_acquisition || '-'}</BaiscTable.Td>
                                                                          <BaiscTable.Td onClick={()=>setEditAsset(i)}>{i.registration_number || '-'}</BaiscTable.Td>
                                                                          <BaiscTable.Td onClick={()=>setEditAsset(i)}>{i.notes || '-'}</BaiscTable.Td>
                                                                          <BaiscTable.Td onClick={()=>setEditAsset(i)}>{i.location || '-'}</BaiscTable.Td>
                                                                          <BaiscTable.Td onClick={()=>setEditAsset(i)}>
                                                                          
                                                                          <div class="media-items" style={{display:'flex',maxWidth:'100px',overflow:'auto'}}>
                                                                                  {(i.media).map(f=>(
                                                                                    <a key={f.name} target="_blank" href={`${process.env.REACT_APP_BASE_URL}/uploaded_files/${f.name}`}>
                                                                                        <div className="item" style={{backgroundPosition:'center',backgroundSize:'cover',backgroundImage:`url('${process.env.REACT_APP_BASE_URL}/uploaded_files/${f.name.replace(' ','%20')}')`}}>
                                                                                        </div>
                                                                                      </a>
                                                                                  ))}
                                                                          </div>

                                                                          </BaiscTable.Td>

                                                                           <BaiscTable.AdvancedActions data={data} w={200} id={i.id} items={[
                                                                                {name:'Remover',onClick:()=>{
                                                                                      setFormData({...formData,contract_assets:formData.contract_assets.filter(f=>f.id!=i.id)})
                                                                                },icon:(<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"></path></svg>)}
                                                                         ]}/>
                                                                          
                                                                    </BaiscTable.Tr>
                                                                    ))}
                                                              />


                                               </div>  
                                           </div>

                                   </div>
                                   

                                   {/*((!isSimulator)) && <div className="btn-send" style={{display:currentTab == "details" ? 'flex' : 'none'}}>
                                        {<div className="div_btn"><button onClick={()=>SubmitForm()} className="save"><span>{pathname=="/loans/create" ? 'Enviar' : 'Enviar'}</span></button><span className="loader"></span></div>}
                                   </div>*/}

                                  {isSimulator && <div className="w-full flex items-center justify-end px-5 gap-x-2">
                                       
                                        {!loading && <button onClick={()=>{
                                             setShowSendLoanSummary(true)
                                        }} id="confirm-button" type="button" class={`py-2 flex items-center px-4 w-full text-sm font-medium text-center rounded-[0.3rem] text-white ${topDetails.total_to_pay ? 'bg-custom_blue-400':'pointer-events-none bg-gray-400'}  sm:w-auto bg-honolulu_blue-500 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300`}>
                                          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/></svg>
                                          <span className="ml-2 !text-white">Enviar PDF</span>
                                        </button>}  

                                         <button onClick={()=>{
                                              PrintLoanSummary()
                                        }} id="confirm-button" type="button" class={`py-2 flex items-center px-4 w-full text-sm font-medium text-center rounded-[0.3rem] text-white ${topDetails.total_to_pay ? 'bg-custom_blue-400':'pointer-events-none bg-gray-400'}  sm:w-auto bg-honolulu_blue-500 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300`}>
                                          {loading && <ButtonLoader/>}
                                          {loading ? 'A carregar...' : <>
                                             <svg width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#fff"><path d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z"></path></svg>
                                             <span className="ml-2">Imprimir</span>
                                          
                                          </>}
                                        </button>     
                                   </div>}

                                   <div className={`message ${message ? 'show' :''}`}>
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="25"><path fill="#FFA500" d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                          <span>{message}</span>
                                   </div>
                       </div>

                  </div>

                  
            </div>
        </div>
    </main>
    </>
  );
}
