
import { useEffect, useState} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import DefaultFormSkeleton from '../Skeleton/defaultForm';

const Create = ({create,setCreate, edit , setEdit,showD }) => {

const data = useData();

const navigate=useNavigate()

let initial_form={type:'',reference:'',amount:'',category:'',description:'',account:'',account_id:'',category_id:'', in_account:'',out_account:'',in_account_id:'',out_account_id:'',account_in_role:'',account_out_role:'',reference_id:'',transition_performed_by_id:''} 
const transation_types=[{id:'out',label:'Saida'},{id:'in',label:'Entrada'},{id:'transfer',label:'Transferência'}]
const [transationTypes,setTransationTypes]=useState(transation_types)

const [itemToEditLoaded,setItemToEditLoaded]=useState(null)
const {pathname} = useLocation()
const [message,setMessage]=useState(null)
const [coutingWithPreviousAmount,setCoutingWithPreviousAmount]=useState(null)
const [formData,setFormData]=useState(initial_form)

const [loading,setLoading]=useState(false)
const {user,selectedMicrocredit,userRole,userInfo} = useAuth()
let required_data=['accounts','clients_list','account_categories','account_balances','microcredit_managers_and_operators']

useEffect(()=>{   
      if(!user) return
      data.handleLoaded('remove',required_data)
      data._get(required_data,{
        accounts:{microcredit_id:selectedMicrocredit},
        clients_list:{microcredit_id:selectedMicrocredit},
        account_categories:{microcredit_id:selectedMicrocredit},
        account_balances:{microcredit_id:selectedMicrocredit},
        microcredit_managers_and_operators:{microcredit_id:selectedMicrocredit}
      },false) 
},[user,pathname,edit,create])




useEffect(()=>{

    if(edit){
       setCreate(false)
       setTransationTypes(transation_types.filter(i=>i.id!="transfer"))
    }else{
       setFormData({...initial_form,microcredit_id:selectedMicrocredit || null})
    }

},[edit])


useEffect(()=>{
    if(create){
       setEdit(false)
    }
    setFormData({...initial_form,microcredit_id:selectedMicrocredit || null})
},[create])


useEffect(()=>{
   console.log(formData)
},[formData])


async function handle_client(){

    if(message){
        toast.error(message)
        return
   }else if(
       !formData.transition_performed_by_id ||
       !formData.type  ||
       !formData.amount ||
        (!formData.account_id && (formData.type=="in" || formData.type=="out"))  ||
         !formData.category_id ||
           !formData.description ||
            ((!formData.in_account_id ||
               !formData.out_account_id) &&
                formData.type=="transfer")) {
       toast.error('Preencha todos os campos obrigatorios!')
       console.log(formData)
       return 
   }

   if(formData.type=="transfer" && formData.in_account_id==formData.out_account_id){
       toast.error('Não é permitido transferir para mesma conta.')
       return 
   }

   setLoading(true)
  
   try{


    let form={
      ...formData,
      category_id:formData.category_id || null
    }

     if(edit){

       await data.makeRequest({method:'post',url:`api/transation/update/`+edit.id,withToken:true,data:{
         ...form,
       }, error: ``},0);
       
       toast.success('Actualizado com sucesso!')
       setLoading(false)

     }else{

       await data.makeRequest({method:'post',url:`api/transation/create`,withToken:true,data:{
         ...form
       }, error: ``},0)

       toast.success('Criado com sucesso!')
       setLoading(false)

     }

     setEdit(null)
     setCreate(null)
     data.setUpdateTable(Math.random())

   }catch(e){

       let msg="Acorreu um erro, tente novamente"
       console.log(e)
       setLoading(false)

       if(e.response){
         if(e.response.status==409){
             msg="Email já existe na microcrédito"
         }   
         if(e.response.status==400){
             msg="Dados inválidos"
         }
         if(e.response.status==500){
             msg="Erro, inesperado. Contacte seu administrador"
         }

       }else if(e.code=='ERR_NETWORK'){
             msg="Verfique sua internet e tente novamente"
       }

       toast.error(msg)
       setLoading(false)

     }
     
}


useEffect(()=>{
  if(!edit){
      setItemToEditLoaded(null)
  }
},[edit])


useEffect(()=>{

    if(!user || !edit?.id){
        return
    }

    (async()=>{
      try{

        let response=await data.makeRequest({method:'get',url:`api/transation/`+edit.id,withToken:true, error: ``},100);
        setItemToEditLoaded(response)
        setFormData({...response})

      }catch(e){

        console.log({e})

        let msg="Acorreu um erro, tente novamente"

        setLoading(false)

        if(e.response){

          if(e.response.status==409){
            msg="Email já existe"
          } 
          
          if(e.response.status==404){
              msg="Item não encontrado"
              setEdit(null)
          }
          if(e.response.status==500){
              msg="Erro, inesperado. Contacte seu administrador"
          }

        }else if(e.code=='ERR_NETWORK'){
              msg="Verfique sua internet e tente novamente"
        }

        toast.error(msg)
    }
    
  })()

},[user,pathname,edit])



function getAvailable(account_id){
  const account=data._account_balances.filter(i=>i.account_id==account_id)[0]
  let avaliable=0
  if(account){
            avaliable=parseFloat(account.availableBalance)
  }
  return  avaliable

}






useEffect(()=>{

  let account_id=formData.type=="out" ? formData.account_id : formData.type=="transfer" ? formData.out_account_id : null
  const account=data._account_balances.filter(i=>i.account_id==account_id)[0]
  let avaliable=0
  
  if(account){
     avaliable=parseFloat(account.availableBalance)
     if(edit && itemToEditLoaded?.account_id == formData.account_id){
          if(itemToEditLoaded.type=="in"){
             avaliable-=itemToEditLoaded.amount
             setCoutingWithPreviousAmount(`(-${data._cn(itemToEditLoaded.amount)})`)
          }else{
             avaliable+=itemToEditLoaded.amount
             setCoutingWithPreviousAmount(`(+${data._cn(itemToEditLoaded.amount)})`)
          }
     }else{
      setCoutingWithPreviousAmount(null)
     }
    
  }else{
    setCoutingWithPreviousAmount(null)
  }

  if(formData.contract_id){
    setMessage('Não pode editar transação gerada de um contrato')
  }else if(account && (avaliable < formData.amount)){
    setMessage('Saldo insuficiente para transação de saida!')
  }else{
    setMessage(null)
  }

 
},[formData,create])





const [reqLoaded,setReqLoaded]=useState(false)

useEffect(()=>{
  setReqLoaded((itemToEditLoaded || create)  && data.loaded.includes('accounts') && data.loaded.includes('clients_list') && data.loaded.includes('account_categories') && data.loaded.includes('account_balances') && data.loaded.includes('microcredit_managers_and_operators'))
},[data.loaded,itemToEditLoaded,create,edit])

return (


       <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
        <div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
        <div className={`create-microcredit ${loading && 'loading'} ${(!reqLoaded) ? 'loading-editing-item':''}`}>
                       
                {(!reqLoaded) && <DefaultFormSkeleton/>}
                <div className="top-title">{edit ? 'Editar' :' Adicionar'} transação</div>  
                {formData.contract_id && <div>
                         <div onClick={()=>navigate('/loan/'+formData.contract_id)} className="status" style={{transform:'translateY(0.3rem)',color:'#fff',background:'#2360E4',padding:'2px 5px',borderRadius:5,display:'flex',alignItems:'center'}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21" fill="#fff"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"></path></svg><span style={{fontSize:12,color:'#fff',marginLeft:4}}>Ver contrato</span></div> 
                  </div> }
                <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                </div>
      
                 
                   <div className="input-container">
                      <div className="inputs">
                             <span>Tipo de transação <label class="mandatory">*</label></span>
                             <select disabled={formData.contract_id ? true : false}  value={formData.type} onChange={e=>setFormData({...formData, type:e.target.value})}>
                                  <option selected value="">Selecione transação</option>
                                  {transationTypes.map(i=>(
                                        <option key={i.id} selected={formData.type == i.id} value={i.id}>{i.label}</option>
                                  ))}
                             </select>
                     </div>

                    {(formData.type=="in" || formData.type=="out") && <div className="inputs">
                                                 <span>Conta de {formData.type=="in" ? "Entrada" : "Saida"} <label class="mandatory">*</label></span>
                                                 <select disabled={formData.contract_id ? true : false} value={formData.account_id} onChange={e=>setFormData({...formData, account_id:e.target.value,account:data._accounts.filter(i=>i.id==e.target.value)[0].description})}>
                                                      <option disabled selected value="">Selecione conta</option>
                                                      {data._accounts.map(i=>(
                                                            <option key={i.id} value={i.id}>{i.name} {i.description ? `(${i.description})`:''}</option>
                                                      ))}
                                                 </select>
                                                 {(formData.account_id) && <span style={{paddingRight:'0.2rem',justifyContent:'flex-end',display:'flex',height:0,opacity:.7,fontSize:'0.8rem'}}>Saldo: {data._cn(getAvailable(formData.account_id).toFixed(2))} <span style={{fontSize:'0.8rem'}} className="ml-1">{coutingWithPreviousAmount}</span></span>}
                                               
                      </div>}


                      {formData.type=="transfer" && (<div className="inputs">
                                                    <span>Conta de saida<label class="mandatory">*</label></span>
                                                    <select disabled={formData.contract_id ? true : false} value={formData.out_account_id} onChange={e=>setFormData({...formData, out_account_id:e.target.value,out_account:data._accounts.filter(i=>i.id==e.target.value)[0].description})}>
                                                        <option disabled selected value="">Selecione conta</option>
                                                        {data._accounts.map(i=>(
                                                                <option key={i.id} value={i.id}>{i.name} {i.description ? `(${i.description})`:''}</option>
                                                        ))}
                                                    </select>
                                                    {(formData.out_account_id) && <span style={{paddingRight:'0.2rem',justifyContent:'flex-end',display:'flex',height:0,opacity:.7,fontSize:'0.8rem'}}>Saldo: {data._cn(getAvailable(formData.out_account_id).toFixed(2))}</span>}
                     
                        </div>
                        )}
                      <div className="inputs">
                            <span>Montante <label class="mandatory">*</label></span>
                           <input disabled={formData.contract_id ? true : false} type="number"   value={formData.amount} onChange={e=>setFormData({...formData,amount:parseFloat(e.target.value)})}  placeholder="Valor"/>
                     </div>
                      {formData.type=="transfer" && (<div className="inputs">
                                                        <span>Conta entrada <label class="mandatory">*</label></span>
                                                        <select disabled={formData.contract_id ? true : false} value={formData.in_account_id} onChange={e=>setFormData({...formData, in_account_id:e.target.value,in_account:data._accounts.filter(i=>i.id==e.target.value)[0].description})}>
                                                            <option disabled selected value="">Selecione conta</option>
                                                            {data._accounts.map(i=>(
                                                                    <option key={i.id} value={i.id}>{i.name} {i.description ? `(${i.description})`:''}</option>
                                                            ))}
                                                        </select>
                                                        {(formData.in_account_id) && <span style={{paddingRight:'0.2rem',justifyContent:'flex-end',display:'flex',height:0,opacity:.7,fontSize:'0.8rem'}}>Saldo: {data._cn(getAvailable(formData.in_account_id).toFixed(2))}</span>}
                        </div>
                        )}

                       <div className="inputs">
                            <span>Referência (Cliente)</span>
                            <select disabled={formData.contract_id ? true : false} onChange={(e)=>setFormData({...formData,reference_id:e.target.value})} value={formData.reference_id}>                             
                               <option selected value="">(Nunhuma)</option>
                                {data._clients_list.filter(i=>i.deleted_at==null || i.id==itemToEditLoaded?.reference_id).map((i,_i)=>(
                                    <option value={i.id} key={'_client-'+_i}>{i.name}</option>
                                ))}
                            </select>
                     </div>
                      <div className="inputs">
                             <span>Categoria <label class="mandatory">*</label></span>
                             <select disabled={formData.contract_id ? true : false} value={formData.category_id} onChange={e=>setFormData({...formData, category_id:e.target.value, category:data._account_categories.filter(i=>i.id==e.target.value)[0].name})}>
                                  <option disabled selected value="">Selecione categoria</option>
                                  {data._account_categories.map(i=>(
                                        <option key={i.id}  value={i.id}>{i.name}</option>
                                  ))}
                             </select>
                     </div>

                    <div className="inputs">
                          <span>Responsável pela transação<label class="mandatory">*</label></span>
                          <select disabled={formData.contract_id ? true : false} onChange={(e)=>setFormData({...formData,transition_performed_by_id:e.target.value})} value={formData.transition_performed_by_id}>
                                <option disabled selected value="">Selecione uma opção</option>
                                {data._microcredit_managers_and_operators.map((i,_i)=>(
                                    <option value={i.id} key={'_account-'+_i}>{i.name}</option>
                                ))}
                          </select> 
                    </div>

                      <div className={`inputs ${formData.type=="transfer"  ||  !formData.type ? "full" : ""}`}>
                            <span>Descrição <label class="mandatory">*</label></span>
                            <textarea disabled={formData.contract_id ? true : false}  value={formData.description} onChange={e=>setFormData({...formData, description:e.target.value})} style={{height:'70px'}} placeholder="Descrição"></textarea>
                     </div>  
                  </div>

                  <div className="res">
                        {message && <>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="20"><path fill="#FFA500" d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                          <span className="message" style={{_color:'#FFA500',opacity:.6}}>{message}</span>
                          </>
                        }
                  </div>

      
                 {!formData.contract_id && <div className="options">

                        <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>

                        <div className="div_btn">
                           {(userRole!="operator" || !(!userInfo?.permissions?.cash_management_transactions?.includes('update') && edit)) && <button className="save" onClick={handle_client}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>}
                           <span className="loader"></span>
                        </div>

                </div>}

      
              </div>
               </div>
      
      
      )





};

export default Create;
