import React from 'react'
import { useData } from '../../contexts/DataContext'
export default function SelectLatePaymentInterest({show,setShow,form,setForm}) {
  const data=useData()
  return (
        <div style={{zIndex:999}} id="crud-modal" tabindex="-1" aria-hidden="true" class={`overflow-y-auto  bg-[rgba(0,0,0,0.3)] flex ease-in delay-100 transition-all ${!show ? 'opacity-0 pointer-events-none translate-y-[50px]':''} overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100vh] max-h-full`}>
            <div class="relative p-4 w-full max-w-[370px] max-h-full ">
                
                <div class="relative bg-white rounded-lg shadow pb-[50px]">
                    
                    <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 class="text-lg font-semibold text-gray-900">
                           Selecione as taxas de juros de mora 
                        </h3>
                    </div>

                    <div class="p-4 md:p-5 flex flex-wrap max-h-[200px] overflow-y-auto">
                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
                                <tr>
                                    <th scope="col" class="px-6 py-3">
                                       <span className="ml-5">De</span>
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                       Até
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                       Percentagem
                                    </th>
                                    
                                </tr>
                            </thead>

                            <tbody>
                                 {data._late_payment_interests.map((i,_i)=>(
                                    <tr key={_i} class="bg-white hover:bg-gray-50">
                                        <th scope="row" class="px-6 py-4 flex items-center whitespace-nowrap">
                                            <div class="flex items-center mr-2">
                                                <input onClick={()=>{
                                                     if(form.LatePaymentInterests.some(g=>g.id==i.id)){
                                                        setForm({...form,LatePaymentInterests:form.LatePaymentInterests.filter(g=>g.id!=i.id)})
                                                     }else{
                                                        setForm({...form,LatePaymentInterests:[...form.LatePaymentInterests,i]})
                                                     }
                                                }} checked={form.LatePaymentInterests.some(f=>f.id==i.id)} id="checkbox-table-3" type="checkbox" class="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 dark:focus:ring-blue-600  focus:ring-2"/>
                                             </div>
                                            {i.from}
                                        </th>
                                        <td class="px-6 py-4">{i.to}</td>
                                        <td class="px-6 py-4 font-medium ">{i.percentage}%</td>
                                   </tr>
                                 ))}
                            </tbody>
                        </table>

                        {data._late_payment_interests.length==0 && <span className="p-5 flex">Nenhuma taxa adicionada</span>}
                    </div>
                  </div>

                </div>

               

                <div onClick={()=>{

                  setShow(false)

                 }} className="w-[30px] cursor-pointer h-[30px] absolute right-5 top-5 rounded-full bg-gray-300 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" fill="#5f6368"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                </div>


            </div>
        </div> 

  )
}
