
import { useEffect, useState} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import DefaultFormSkeleton from '../Skeleton/defaultForm';

const CreateClient = ({create,setCreate, edit , setEdit,showD, createRes }) => {

const data = useData();

const initial_form={expiration_date:'',type:'free',period:'monthly',activation_date:'',quantity:1,status:'paid',microcredit_id:null}
const [itemToEditLoaded,setItemToEditLoaded]=useState(false)
const {pathname} = useLocation()
const {user} = useAuth()
const [formData,setFormData]=useState(initial_form)
const [loading,setLoading]=useState(false)
const periods=['monthly','yearly']
const [planTotalAmount,setPlanTotalAmount]=useState(0)
const [selectedMicro,setSelectedMicro]=useState(null)
const [minActivationDate,setMinActivationDate]=useState(null)
 


let required_data=['plans']
useEffect(()=>{   
      if(!user) return
      data.handleLoaded('remove',required_data)
      data._get(required_data) 
},[user,pathname])


useEffect(()=>{

    if(edit){
       setCreate(false)
       setFormData({...edit,expiration_date:edit.expiration_date.split('T')[0],activation_date:edit.activation_date.split('T')[0]})
    }else{
       setFormData({...initial_form})
    }


},[edit])


useEffect(()=>{
    if(create){
       setEdit(false)
       setFormData({...initial_form})
    }
},[create])

useEffect(()=>{

  if(formData.is_custom_payment){
    setPlanTotalAmount(parseFloat(formData.amount || 0))
  }else if(formData.type && data.loaded.includes('plans')){
    let q=parseInt(formData.quantity || 1)
    if(formData.period=="yearly"){
      q=q*12
    }
    let price=data._plans.filter(i=>i.name.toLowerCase()==formData.type)[0]?.price || 0
    setPlanTotalAmount(parseFloat(price) * q)
  }else{
    setPlanTotalAmount(0)
  }
},[formData,data.loaded])


function calculateExpirationDate(planType="monthly", duration, starting_date) {
  
  const currentDate = starting_date  ? new Date(starting_date) : new Date();
  let expirationDate;
  if (planType === 'monthly') {
      expirationDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + parseInt(duration), currentDate.getDate());
  } else if (planType === 'yearly') {
      expirationDate = new Date(currentDate.getFullYear() + parseInt(duration), currentDate.getMonth(), currentDate.getDate());
  } else {
      throw new Error('Invalid plan type specified.');
  }
  return data.today(expirationDate);
}

useEffect(()=>{
  if(formData.activation_date){
      setFormData({...formData,expiration_date:calculateExpirationDate(formData.period, formData.quantity || 1,formData.activation_date)})
  }
},[formData.activation_date,formData.period,formData.quantity,formData.plans])



console.log({formData})

async function handle_client(){

  if((!formData.activation_date || !formData.period || !formData.type || !formData.expiration_date || !formData.microcredit_id)){
    toast.error('Preencha todos os campos!')
    return 
  }

   setLoading(true)
  
   try{

     if(edit){

       await data.makeRequest({method:'post',url:`api/plan-payment/update/`+edit.id,withToken:true,data:{
         ...formData,
       }, error: ``},0);
       toast.success('Actualizado com sucesso!')
       setLoading(false)

     }else{

        await data.makeRequest({method:'post',url:`api/plan-payment/create`,withToken:true,data:{
         ...formData
       }, error: ``},0)
       toast.success('Criado com sucesso!')
       setLoading(false)

     }

     setEdit(null)
     setCreate(null)
     data.setUpdateTable(Math.random())

   }catch(e){

       let msg="Acorreu um erro, tente novamente"
       console.log(e)
       setLoading(false)

       if(e.response){
         if(e.response.status==409){
             msg="Nome já existe"
         }   
         if(e.response.status==400){
             msg="Dados inválidos"
         }
         if(e.response.status==500){
             msg="Erro, inesperado. Contacte seu administrador"
         }

       }else if(e.code=='ERR_NETWORK'){
             msg="Verfique sua internet e tente novamente"
       }

       toast.error(msg)
       setLoading(false)

     }
     
}


useEffect(()=>{
  if(!edit){
      setItemToEditLoaded(false)
  }
},[edit])




useEffect(()=>{

  if(!user || edit?.id || !formData.microcredit_id){
      return
  }

  (async()=>{
      
    try{
  
    let response=await data.makeRequest({method:'get',url:`api/microcredit/`+formData.microcredit_id,withToken:true, error: ``},100);
    setSelectedMicro(response)
    setFormData({...formData,type:response.plan})

    }catch(e){

      console.log({e})

      let msg="Acorreu um erro, tente novamente"

      setLoading(false)

      if(e.response){

        if(e.response.status==404){
            msg="Microcrédito não encontrado"
            setEdit(null)
        }
        if(e.response.status==500){
            msg="Erro, inesperado. Contacte seu administrador"
        }

      }else if(e.code=='ERR_NETWORK'){
            msg="Verfique sua internet e tente novamente"
      }

      toast.error(msg)

  }
})()

},[formData.microcredit_id])


useEffect(()=>{

  if(create && selectedMicro){
    if(selectedMicro.plans.length){
      setMinActivationDate(new Date(Math.max(...selectedMicro.plans.map(i=>i.expiration_date).map(date => new Date(date))))?.toISOString()?.split('T')?.[0])    
    }else{
      setMinActivationDate(new Date().toISOString().split('T')[0])
    }
  }else{
    setMinActivationDate(null)
  }  

},[edit,itemToEditLoaded,selectedMicro])



return (
       <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
        <div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
        <div className={`create-microcredit  ${loading && 'loading'} ${(!edit?.id && formData.microcredit_id && !selectedMicro) ? 'loading-editing-item':''}`}>
                         {(!edit?.id && formData.microcredit_id && !selectedMicro) && <DefaultFormSkeleton/>}
                         <div className="top-title">{edit ? 'Editar' :' Adicionar'} plano</div>  
                         <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                               <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                         </div>
                         
                         <div className="input-container mb-5">
                         <div className="w-full mt-3 mb-3">
                            <span className="opacity-55">Total a pagar:</span> <span>{data._cn(planTotalAmount)}</span>
                        </div>
                              <div className="inputs">
                                  <span>Microcrédito</span>
                                  <select disabled={edit} className={`${edit ? 'opacity-45':''}`} value={formData.microcredit_id} onChange={e=>{

                                    setFormData({...formData, 
                                      microcredit_id:e.target.value,
                                      activation_date:''
                                    })

                                    setSelectedMicro(null)

                                  }}>
                                            <option selected value="">Selecionar microcrédito</option>
                                            {data._microcredits_list.map(i=>(
                                                <option  key={i.id} selected={formData.microcredit_id === i.id} value={i.id}>{i.name}</option>
                                            ))}
                                  </select>
                               </div>

                               <div className="inputs">
                                    <span>Plano</span>
                                    <select  value={formData.type} onChange={e=>setFormData({...formData, type: e.target.value})}>
                                        {data._plans.map(i=>(
                                              <option key={i.id} selected={formData.plan === i.name.toLowerCase()} value={i.name.toLowerCase()}>{i.name} ({i.price})</option>
                                        ))}
                                   </select>
                               </div>
                               <div className="inputs">
                                      <span>Quantidade</span>
                                      <input type="number" value={formData.quantity ? formData.quantity : ''} onChange={e=>setFormData({...formData,quantity:e.target.value.replace(/[^0-9]/g, '')})}  placeholder="1"/>
                               </div>
                               <div className="inputs">
                                      <span>Periodo de subscrição</span>
                                      <select  value={formData.period} onChange={e=>setFormData({...formData, period:e.target.value})}>
                                              {periods.map(i=>(
                                                    <option key={i.id} selected={formData.period === i} value={i}>{i}</option>
                                              ))}
                                      </select>
                               </div>

                               <div className="inputs">
                                      <span>Data de activação</span>
                                      <input __min={minActivationDate} type="date" value={formData.activation_date ? formData.activation_date : ''} onChange={e=>setFormData({...formData,activation_date:e.target.value})}  placeholder=""/>
                               </div>

                               <div className="inputs">
                                      <span>Data de vencimento</span>
                                      <input type="date" value={formData.expiration_date} onChange={e=>setFormData({...formData,expiration_date:e.target.value})}  placeholder=""/>
                               </div>

                              
                               <div className="inputs">
                                      <span>Estado</span>
                                      <select  value={formData.status} onChange={e=>setFormData({...formData, status:e.target.value})}>
                                          <option  selected value="">Selecionar estado</option>
                                          <option  value={'paid'}>Pago</option>
                                          <option  value={'pending'}>Pendente</option>
                                          <option  value={'delayed'}>Atrasado</option>
                                      </select>
                               </div>

                              {formData.is_custom_payment && <div className="inputs">
                                      <span>Valor a pagar</span>
                                      <input type="number" value={formData.amount} onChange={e=>setFormData({...formData,amount:e.target.value.replace(/[^0-9]/g, '')})}  placeholder=""/>
                               </div>}

                               

                               <div>
                                  <label className="cursor-pointer items-center flex">
                                      <input onClick={()=>setFormData({...formData,is_custom_payment:Boolean(!formData.is_custom_payment)})} className="mr-1" type="checkbox" checked={Boolean(formData.is_custom_payment)}  class="w-4 mr-1 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 dark:focus:ring-blue-600  focus:ring-2" /> 
                                      <span>Definir pagamento customizado</span>
                                  </label>
                               </div>
                        </div>
      
      
                    <div className="options">
                        <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>
                        <div className="div_btn">
                           <button className="save" onClick={handle_client}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>
                           <span className="loader"></span>
                        </div>
                    </div>
      
              </div>
               </div>
      
      
      )


};

export default CreateClient;
