import React from 'react'
import OpenLoader from '../loaders/openLoader'
import { useData } from '../../contexts/DataContext'
import Delete from '../modals/delete'
import { useAuth } from '../../contexts/AuthContext'
import PreLoader from '../loaders/preloader'

export default function DefaultLayout({children}) {
  const data=useData()
  const {loading,isLoading} = useAuth() 

  return (
    <>
      
       {(loading || isLoading) && <PreLoader/>}
       <Delete show={data._openPopUps.delete}/>
       {data.gettingItemToUpdate && <OpenLoader/>}
       {children}
    </>
  )
}
