
import '../../css/main.css'
import './style.css'
import Menu from '../../common/menu.js';
import TopBar from "../../common/topBar";
import { useEffect, useState } from 'react';
import CreateInventoryCategory from './create-inventory-category.js'
import DeleteDialog2 from './delete-dialog-1.js'
import BaiscTable from '../../tables/default-table.js';
import { useAuth } from '../../../contexts/AuthContext.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useData } from '../../../contexts/DataContext.js';
import DefaultLayout from '../../layouts/DefaultLayout.js';


export default function Create() {
const [createInterestRates, setCreateInterestRates] = useState(false);
const [createPaymentInterest, setCreatePaymentInterest] = useState(false);

const [edit1, setEdit1] = useState(null);
const [del1, setDel1] = useState(null);
const [edit2, setEdit2] = useState(null);
const [del2, setDel2] = useState(null);

const {pathname} = useLocation()
const {user,selectedMicrocredit,userInfo,userRole} = useAuth()
const data=useData()
const navigate=useNavigate()

let required_data=['accounts','inventory_categories']
const [updateFilters,setUpdateFilters]=useState(null)

useEffect(()=>{ 
   if(!user) return
   data.handleLoaded('remove',required_data)
   data._get(required_data,{
      accounts:{microcredit_id:selectedMicrocredit},
      inventory_categories:{microcredit_id:selectedMicrocredit}
   }) 
 },[user,pathname,updateFilters])


 useEffect(()=>{
   data.handleLoaded('remove',required_data)
 },[updateFilters])

 useEffect(()=>{
   if(data.updateTable){
        data.setUpdateTable(null)
        data.handleLoaded('remove',required_data)
        data._get(required_data,{
            accounts:{microcredit_id:selectedMicrocredit},
            inventory_categories:{microcredit_id:selectedMicrocredit}
        }) 
   }
},[data.updateTable])

useEffect(()=>{
     if((userRole=="operator" && !userInfo?.permissions?.inventory_settings?.includes('read'))){
          navigate('/dashboard')
     }
},[userInfo])

return (
    <>
      <DefaultLayout>
      <main className="dashboard-container">
      {<CreateInventoryCategory showD={(createPaymentInterest || edit2) ? true : false}  create={createPaymentInterest} setCreate={setCreatePaymentInterest} setEdit={setEdit2} edit={edit2} del={del2}/>}

      {<DeleteDialog2 showD={del2 ? true : false} del={del2}  setDel={setDel2}/>}

      <div className="dashboard">
      <Menu/>
      <div className="main-dashboard">
            <TopBar activePage={'Definições de inventário'} lastPage={{name:'Contratos',pathname:'/loans'}}/>
            <div className="center">
                  <div className="loan-container">
                              <div className="top-title">Definições</div>
                         
                              <div className="section">
                                    
                                    <div className="sub-title">Categorias {(userRole!="operator" || userInfo?.permissions?.inventory_settings?.includes('create')) && <button onClick={()=>setCreatePaymentInterest(true)}>Adicionar categoria</button>}</div>
                                    <div className="_table max-w-[700px]">
                                    <BaiscTable canAdd={user?.role=="admin"} addRes={()=>{
                                          
                                    }} loaded={data._loaded.includes('inventory_categories')} header={[

                                          <BaiscTable.MainActions sigleSelection={true} data={data} options={{
                                                deleteFunction:'default',
                                                deleteUrl:'api/invetory-categories/delete'}
                                          }
                                          items={data._inventory_categories || []}/>,
                                                'Nome',
                                          ]

                                    }

                                    body={(data._inventory_categories || []).map((i,_i)=>(
                                                <BaiscTable.Tr first={_i==0}>
                                                      <BaiscTable.Td w={30}>
                                                            <BaiscTable.Actions hide={true} sigleSelection={true}  data={data}  options={{
                                                                  deleteFunction:'default',
                                                                  deleteUrl:'api/invetory-categories/delete',
                                                                  id:i.id}
                                                            }/>
                                                      </BaiscTable.Td>
                                                <BaiscTable.Td onClick={()=>setEdit2(i)}>{i.name}</BaiscTable.Td>
                                          </BaiscTable.Tr>
                                          ))}
                                    />
                                 </div>

                              </div>
                  </div>
            </div>
      </div>
      </div>
      </main>
      </DefaultLayout>
    </>
  );
}
