
import { useEffect, useState} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import DefaultFormSkeleton from '../Skeleton/defaultForm';

const CreateClient = ({create,setCreate, edit , setEdit,showD, createRes }) => {

const data = useData();

const initial_form = {name:'',status:'active',nuit:'', address:'',type: '', id: '', user_id: '', microcredit_id: '', role: '', email: '', primary_contact: '',job_title:'', secondary_contact: '', job_title: '', neighborhood_name: '', block_number: '', identity_number: '', passport_number: '', driver_license_number: '', date_of_birth: '', house_number: '', place_of_issuance_of_the_identity_card: '', date_of_issuance_of_the_identity_card: '', marital_status: '', avenue: '',mother_name:'',father_name:'', driver_license_issue_date:'',driver_license_issue_location:'', passport_issue_date:'',passport_issue_location:''};

const [itemToEditLoaded,setItemToEditLoaded]=useState(false)
const {pathname} = useLocation()

const [formData,setFormData]=useState(initial_form)
const [loading,setLoading]=useState(false)
const {user,selectedMicrocredit,userInfo,userRole} = useAuth()

let required_data=['microcredits_list']

useEffect(()=>{   
      if(!user) return
      data.handleLoaded('remove',required_data)
      data._get(required_data) 
},[user,pathname])


useEffect(()=>{

    if(edit){
       setCreate(false)
    }else{
       setFormData({...initial_form,microcredit_id:selectedMicrocredit || null})
    }

},[edit])


useEffect(()=>{
    if(create){
       setEdit(false)
    }
    setFormData({...initial_form,microcredit_id:selectedMicrocredit || null})
},[create])


async function handle_client(){

   if(!formData.name || !formData.primary_contact || !formData.microcredit_id) {
       toast.error('Preencha todos os campos!')
       return 
   }

   if(!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email?.trim())) &&formData.email){
        toast.error('Email inválido')
        return
   }

   setLoading(true)
  
   try{

     if(edit){

       await data.makeRequest({method:'post',url:`api/client/update/`+edit.id,withToken:true,data:{
         ...formData,
       }, error: ``},0);
       
       toast.success('Actualizado com sucesso!')
       setLoading(false)

     }else{

        let res=await data.makeRequest({method:'post',url:`api/client/create`,withToken:true,data:{
         ...formData
       }, error: ``},0)

       if(createRes){
          createRes(res)
       }

       toast.success('Criado com sucesso!')
       setLoading(false)

     }

     setEdit(null)
     setCreate(null)
     data.setUpdateTable(Math.random())

   }catch(e){

       let msg="Acorreu um erro, tente novamente"
       console.log(e)
       setLoading(false)

       if(e.response){
         if(e.response.status==409){
             msg="Email já existe na microcrédito"
         }   
         if(e.response.status==400){
             msg="Dados inválidos"
         }
         if(e.response.status==500){
             msg="Erro, inesperado. Contacte seu administrador"
         }

       }else if(e.code=='ERR_NETWORK'){
             msg="Verfique sua internet e tente novamente"
       }

       toast.error(msg)
       setLoading(false)

     }
     
}


useEffect(()=>{
  if(!edit){
      setItemToEditLoaded(false)
  }
},[edit])


useEffect(()=>{

    if(!user || !edit?.id){
        return
    }

    (async()=>{
      
      try{
    
      let response=await data.makeRequest({method:'get',url:`api/client/`+edit.id,withToken:true, error: ``},100);
      setItemToEditLoaded(true)
      setFormData({...response,microcredit_id:response.user_microcredits.id})
  
      }catch(e){

        console.log({e})

        let msg="Acorreu um erro, tente novamente"

        setLoading(false)

        if(e.response){

          if(e.response.status==409){
            msg="Email já existe"
          } 
          
          if(e.response.status==404){
              msg="Item não encontrado"
              setEdit(null)
          }
          if(e.response.status==500){
              msg="Erro, inesperado. Contacte seu administrador"
          }

        }else if(e.code=='ERR_NETWORK'){
              msg="Verfique sua internet e tente novamente"
        }

        toast.error(msg)

    }
    
  })()

},[user,pathname,edit])






return (
       <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
        <div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
        <div className={`create-microcredit client ${loading && 'loading'} ${(edit && !itemToEditLoaded) ? 'loading-editing-item':''}`}>
                        {(edit && !itemToEditLoaded) && <DefaultFormSkeleton/>}
                        <div className="top-title">{edit ? 'Editar' :' Adicionar'} cliente</div>  
                        <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                               <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                        </div>
      
                        <div className="input-container">
                     <div className="inputs">
                           <span>Nome <label class="mandatory">*</label></span>
                           <span class="set-empty" style={{display:'none'}}>
                           <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="15"  viewBox="0 0 350.000000 346.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"><path d="M1615 3274 c-394 -38 -691 -175 -963 -443 -325 -322 -474 -691 -459 -1136 11 -320 80 -524 282 -832 l73 -111 -160 -158 -161 -158 99 -92 99 -93 84 82 c112 110 128 124 186 179 l51 47 119 -80 c303 -203 524 -273 860 -273 233 0 368 23 560 96 430 162 802 572 926 1020 49 178 64 449 34 653 -33 234 -125 448 -286 666 -27 37 -49 70 -49 75 0 5 70 79 155 164 l156 156 -98 97 -98 97 -153 -155 c-84 -85 -155 -155 -158 -155 -3 0 -59 36 -124 79 -225 149 -383 216 -585 251 -110 18 -315 31 -390 24z m356 -295 c114 -24 258 -84 401 -166 64 -37 124 -73 134 -80 16 -11 -79 -109 -871 -903 -489 -490 -894 -889 -899 -887 -16 5 -155 244 -192 329 -47 110 -74 221 -84 352 -32 393 91 729 370 1006 273 272 574 390 950 373 58 -3 144 -13 191 -24z m837 -589 c72 -121 114 -219 149 -345 27 -96 28 -110 28 -315 -1 -201 -3 -221 -27 -313 -57 -209 -155 -381 -311 -543 -191 -201 -393 -318 -645 -375 -126 -29 -395 -32 -511 -5 -170 38 -338 108 -486 203 -38 25 -72 47 -74 49 -2 1 399 403 890 893 l894 890 19 -22 c10 -12 43 -65 74 -117z"/></g></svg>
                           </span>
                           <input type="text" value={formData.name} onChange={e=>setFormData({...formData,name:e.target.value})}  placeholder="Nome do cliente"/>
                     </div>
                    
                      <div className="inputs">
                           <span>Contacto primário <label class="mandatory">*</label></span>
                           <input value={formData.primary_contact} onChange={e=>setFormData({...formData,primary_contact:e.target.value})}  type="number" placeholder="Contacto primário"/>
                     </div>

                      <div className="inputs">
                            <span>Contacto secudário</span>
                            <input type="number" value={formData.secondary_contact} onChange={e=>setFormData({...formData,secondary_contact:e.target.value})}  placeholder="Contacto secunário"/>
                     </div>

                     <div className="inputs">
                           <span>Profissão</span>
                           <input value={formData.job_title} onChange={e=>setFormData({...formData,job_title:e.target.value})} type="text" placeholder="Profissão"/>
                     </div>

                     <div className="inputs">
                           <span>Email</span>
                           <input type="text" value={formData.email} onChange={e=>setFormData({...formData,email:e.target.value})}  placeholder="Email do cliente"/>
                     </div>

                     <div className="inputs">
                           <span>Nuit</span>
                           <input value={formData.nuit} onChange={e=>setFormData({...formData,nuit:e.target.value})}  type="number" placeholder="Nuit"/>
                     </div>

                     <div className="inputs">
                            <span>Endereço</span>
                            <input type="text" value={formData.address} onChange={e=>setFormData({...formData,address:e.target.value})}  placeholder="Endereço"/>
                     </div>
              
                    
                     <div className="inputs">
                            <span>Bairro</span>
                            <input value={formData.neighborhood_name} onChange={e=>setFormData({...formData,neighborhood_name:e.target.value})}  type="text" placeholder="Nome do bairro de residência"/>
                     </div>

                     <div className="inputs">
                            <span>Quarteirão</span>
                            <input type="text" value={formData.block_number} onChange={e=>setFormData({...formData,block_number:e.target.value})}  placeholder="Número do quarteirão do cliente"/>
                     </div>

                     <div className="inputs">
                            <span>Número da casa</span>
                            <input value={formData.house_number} onChange={e=>setFormData({...formData,house_number:e.target.value})}  type="number" placeholder="Número da casa"/>
                     </div>

                     <div className="inputs">
                            <span>Avenida</span>
                            <input value={formData.avenue} onChange={e=>setFormData({...formData,avenue:e.target.value})}  type="text" placeholder="Avenida do cliente"/>
                     </div>
                     

                     <div className="inputs">
                            <span>Data de nascimento</span>
                            <input value={formData.date_of_birth} onChange={e=>setFormData({...formData,date_of_birth:e.target.value})}  type="date"/>
                     </div>

                     <div className="inputs">
                          <span>Nome do Pai</span>
                          <input 
                              value={formData.father_name} 
                              onChange={e => setFormData({ ...formData, father_name: e.target.value })}  
                              type="text" 
                              placeholder="Nome do Pai"
                          />
                      </div>

                      <div className="inputs">
                          <span>Nome da Mãe</span>
                          <input 
                              value={formData.mother_name} 
                              onChange={e => setFormData({ ...formData, mother_name: e.target.value })}  
                              type="text" 
                              placeholder="Nome da Mãe"
                          />
                      </div>
                       <div className="inputs">
                            <span>Estado civil</span>
                            <select value={formData.marital_status} onChange={e=>setFormData({...formData, marital_status:e.target.value})}>
                                 <option disabled selected value="">Selecione</option>
                                 <option value={'single'}>Solteiro(a)</option>
                                 <option value={'married'}>Casado(a)</option>
                                 <option value={'widowed'}>Viúvo(a)</option>
                                 <option value={'divorced'}>Divorciado(a)</option>
                             </select>
                      </div> 
                      <div className="inputs">
                            <span >Número de B.I</span>
                            <input value={formData.identity_number} onChange={e=>setFormData({...formData,identity_number:e.target.value})}  type="text" placeholder="Número de B.I"/>
                      </div>
                      <div className="inputs">
                            <span>Local de emissão de BI</span>
                            <input value={formData.place_of_issuance_of_the_identity_card} onChange={e=>setFormData({...formData,place_of_issuance_of_the_identity_card:e.target.value})}  type="text" placeholder="Local de emissão de BI"/>
                      </div>
                      <div className="inputs">
                            <span>Data de emissao de BI</span>
                            <input type="date" value={formData.date_of_issuance_of_the_identity_card} onChange={e=>setFormData({...formData,date_of_issuance_of_the_identity_card:e.target.value})}   placeholder="Data de emissao de BI"/>
                      </div>


                      {/**<div className="inputs">
                            <span>Tipo de documento de identificação</span>
                            <select value={formData.marital_status} onChange={e=>setFormData({...formData, marital_status:e.target.value})}>
                                 <option disabled selected value="">Selecione</option>
                                 <option value={'identity_number'}>Bilhete de identidade</option>
                                 <option value={'driver_license'}>Carta de condução</option>
                                 <option value={'widowed'}>Viúvo(a)</option>
                                 <option value={'divorced'}>Divorciado(a)</option>
                             </select>
                       </div>*/} 

                      <div className="inputs">
                            <span>Carta de condução</span>
                            <input value={formData.driver_license_number} onChange={e=>setFormData({...formData,driver_license_number:e.target.value})}  type="text" placeholder="Número da carta de condução"/>
                      </div>

                      <div className="inputs">
                          <span>Local de Emissão da Carta de Condução</span>
                          <input 
                              value={formData.driver_license_issue_location} 
                              onChange={e => setFormData({ ...formData, driver_license_issue_location: e.target.value })}  
                              type="text" 
                              placeholder="Local de Emissão da Carta de Condução"
                          />
                      </div>

                      <div className="inputs">
                          <span>Data de Emissão da Carta de Condução</span>
                          <input 
                              type="date" 
                              value={formData.driver_license_issue_date} 
                              onChange={e => setFormData({ ...formData, driver_license_issue_date: e.target.value })}  
                              placeholder="Data de Emissão da Carta de Condução"
                          />
                      </div>

                       <div className="inputs">
                            <span>Número de passaporte</span>
                            <input value={formData.passport_number} onChange={e=>setFormData({...formData,passport_number:e.target.value})}  type="text" placeholder="Número de passaporte"/>
                      </div>

                   

                      <div className="inputs">
                          <span>Local de Emissão do Passaporte</span>
                          <input 
                              value={formData.passport_issue_location} 
                              onChange={e => setFormData({ ...formData, passport_issue_location: e.target.value })}  
                              type="text" 
                              placeholder="Local de Emissão do Passaporte"
                          />
                      </div>

                      <div className="inputs">
                          <span>Data de Emissão do Passaporte</span>
                          <input 
                              type="date" 
                              value={formData.passport_issue_date} 
                              onChange={e => setFormData({ ...formData, passport_issue_date: e.target.value })}  
                              placeholder="Data de Emissão do Passaporte"
                          />
                      </div>
                      <div className="inputs">
                         
                      </div>

                      {(user?.role=="admin") &&  <div className="inputs">
                             <span>Microcredito <label class="mandatory">*</label></span>
                             <select value={formData.microcredit_id} onChange={e=>setFormData({...formData, microcredit_id:e.target.value})}>
                                 <option disabled selected value="">Selecione uma microcredito</option>
                                  {data._microcredits_list.map(i=>(
                                        <option key={i.id} selected={formData.microcredit_id === i.id} value={i.id}>{i.name}</option>
                                  ))}
                             </select>
                      </div>}


                  </div>
      
      
                    <div className="options">
                        <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>
                        <div className="div_btn">
                           {(userRole!="operator" || !(!userInfo?.permissions?.clients?.includes('update') && edit)) && <button className="save" onClick={handle_client}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>}
                           <span className="loader"></span>
                        </div>
                    </div>
      
               </div>
               </div>
      
      
      )





};

export default CreateClient;
