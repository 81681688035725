
import { useEffect, useState} from 'react';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import toast from 'react-hot-toast';
import DefaultFormSkeleton from '../../Skeleton/defaultForm';
import { useLocation } from 'react-router-dom';

const Create = ({create,setCreate, edit , setEdit, del ,showD,setRes,createRes}) => {

const data = useData();

const initial_form={name:''}
const [itemToEditLoaded,setItemToEditLoaded]=useState(false)
const {pathname} = useLocation()

const [formData,setFormData]=useState(initial_form)

const [loading,setLoading]=useState(false)

const {user,selectedMicrocredit,userInfo,userRole} = useAuth()

useEffect(()=>{
    if(edit){
       setCreate(false)
    }else{
       setFormData({...initial_form,microcredit_id:selectedMicrocredit || null})
    }
},[edit])


useEffect(()=>{
    if(create){
       setEdit(false)
    }
    setFormData({...initial_form,microcredit_id:selectedMicrocredit || null})
},[create])


useEffect(()=>{
   console.log(formData)
},[formData])

useEffect(()=>{

    if(formData.percentage < 0){
       setFormData({...formData,percentage:0})
    }else if(formData.percentage > 100){
       setFormData({...formData,percentage:100})
    }

},[formData])


async function SubmitForm(){

   if(!formData.name) {
       toast.error('Preencha todos os campos!')
       return 
   }
   setLoading(true)
   try{

     if(edit){

       await data.makeRequest({method:'post',url:`api/inventory-category/update/`+edit.id,withToken:true,data:{
         ...formData,
       }, error: ``},0);
       
       toast.success('Actualizado com sucesso!')
       setLoading(false)

     }else{

       let res=await data.makeRequest({method:'post',url:`api/inventory-category/create`,withToken:true,data:{
         ...formData
       }, error: ``},0)

       if(createRes){
          createRes(res)
       }

       toast.success('Criado com sucesso!')
       setLoading(false)
     }

     setEdit(null)
     setCreate(null)
     data.setUpdateTable(Math.random())

   }catch(e){

       let msg="Acorreu um erro, tente novamente"
       console.log(e)
       setLoading(false)

       if(e.response){
         if(e.response.status==409){
             msg="Nome já existe"
         }   
         if(e.response.status==400){
             msg="Dados inválidos"
         }
         if(e.response.status==500){
             msg="Erro, inesperado. Contacte seu administrador"
         }

       }else if(e.code=='ERR_NETWORK'){
             msg="Verfique sua internet e tente novamente"
       }

       toast.error(msg)
       setLoading(false)

     }
     
}


useEffect(()=>{
  if(!edit){
      setItemToEditLoaded(false)
  }
},[edit])


useEffect(()=>{

    if(!user || !edit?.id){
        return
    }

    (async()=>{
      
      try{
    
      let response=await data.makeRequest({method:'get',url:`api/inventory-category/`+edit.id,withToken:true, error: ``},100);
      setItemToEditLoaded(true)
      console.log({response})
      setFormData({...response})
  
      }catch(e){

        console.log({e})

        let msg="Acorreu um erro, tente novamente"

        setLoading(false)

        if(e.response){

          if(e.response.status==409){
            msg="Email já existe"
          } 
          
          if(e.response.status==404){
              msg="Item não encontrado"
              setEdit(null)
          }
          if(e.response.status==500){
              msg="Erro, inesperado. Contacte seu administrador"
          }

        }else if(e.code=='ERR_NETWORK'){
              msg="Verfique sua internet e tente novamente"
        }

    }
    
  })()

},[user,pathname,edit])


console.log({create})

   
return (
    <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
     <div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
    <div  className={`create-microcredit ${loading && 'loading'} ${(edit && !itemToEditLoaded) ? 'loading-editing-item':''}`}>
                   {(edit && !itemToEditLoaded) && <DefaultFormSkeleton/>}
                  <div className="top-title">{edit ? 'Editar' :' Adicionar'} categoria</div>  
                  <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                  <div className="input-container">
                     
                      <div className="inputs">
                           <span>Nome</span>
                           <input value={formData.name} max={100} onChange={e=>setFormData({...formData,name:e.target.value})}  placeholder="Nome"/>
                      </div>

                    
                  </div>


              <div className="options">
                  {(userRole!="operator" || !(!userInfo?.permissions?.inventory_settings?.includes('update') && edit)) && <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>}
                  <div className="div_btn">
                     <button className="save" onClick={SubmitForm}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>
                     <span className="loader"></span>
                  </div>
              </div>

        </div>
         </div>


)
};

export default Create;
