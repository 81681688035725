import '../css/main.css'
import './style.css'
import Menu from '../common/menu.js';
import TopBar from "../common/topBar";
import { useEffect, useState } from 'react';
import BaiscTable from '../tables/default-table.js';
import BasicPagination from '../pagination/basic.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.js';
import { useData } from '../../contexts/DataContext.js';
import DefaultLayout from '../layouts/DefaultLayout.js';
import toast from 'react-hot-toast';
import TopCardSkeleton from '../Skeleton/topCards.js';
import CreateAsset from '../loans/create/create-asset.js';
import Loader from '../loaders/loader.jsx';
import * as XLSX from 'xlsx';


export default function Clients() {

const [create, setCreate] = useState(false);
const [edit, setEdit] = useState(null);
const [del, setDel] = useState(null);
const [tableSearch,setTableSeach]=useState('');
const {pathname} = useLocation()
const {user,selectedMicrocredit,userRole,userInfo} = useAuth()
const data=useData()

const [loading,setLoading]=useState(false)
const navigate=useNavigate()
const [printing,setPrinting]=useState(false)

let required_data=['contract_assets','clients_list','inventory_categories']

const [currentPage,setCurrentPage]=useState(1)
const [updateFilters,setUpdateFilters]=useState(null)
const [search,setSearch]=useState('')
const [filterOptions,setFilterOptions]=useState([])
let initial_filters={start_date:'',end_date:'',approval_status:'',payment_status:'',client_id:''}
const [filters,setFilters]=useState(initial_filters);
const [hideFilters,setHideFilters]=useState(true)


useEffect(()=>{ 
   if(!user || data.updateTable) return
   data.handleLoaded('remove',required_data)
   data._get(required_data,{
     contract_assets:{microcredit_id:selectedMicrocredit,search:search,page:currentPage,...filters,...data.getParamsFromFilters(filterOptions)},
     clients_list:{microcredit_id:selectedMicrocredit},
   }) 
 },[user,pathname,search,currentPage,updateFilters])


 useEffect(()=>{
   data.handleLoaded('remove',required_data)
 },[updateFilters])

 useEffect(()=>{
   if(data.updateTable){
        data.setUpdateTable(null)
        data.handleLoaded('remove',required_data)
        setCurrentPage(1)
        data._get(required_data,{
          contract_assets:{microcredit_id:selectedMicrocredit,search:search,page:1,...filters,...data.getParamsFromFilters(filterOptions)},
          clients_list:{microcredit_id:selectedMicrocredit},
         }) 
   }
},[data.updateTable])

useEffect(()=>{data.setUpdateTable(Math.random())},[filters])




const Print = async (type,_data) => {
     let name=(user?.microcredits?.filter(i=>i.id==selectedMicrocredit)[0]?.name || '') +` - Inventário ${data.today()} ${data.time()}`

     const mappedData = _data.map(item => ({
          'ID':item.id,
          'Cliente':item.client.name,
          'Estado':item.type=="confiscated" ? 'Confiscado' :'Retido',
          'Valor':(item.type=="out" && item.amount!=0 ?'-' :'')+""+data._cn(item.amount),
          'Categoria':item.category?.name || '-',
          'Descrição':item.description,
          'Data de aquisição':item.date_of_acquisition.split('-')?.reverse().join('-'),
          'Número de registro':item.registration_number,
          'Data de criação':item.created_at.split('T')[0].split('-')?.reverse().join('-'),
     }));

     if(type=="excel"){
          const workbook = XLSX.utils.book_new();
          const sheetData = XLSX.utils.json_to_sheet(mappedData);
          XLSX.utils.book_append_sheet(workbook, sheetData, 'Sheet1');
          XLSX.writeFile(workbook, `${name}.xlsx`);
     }else{
          data.setPrintData({print:true,data:[mappedData],names:[name]})
     }
}

const Export =async (type) => {
     
      try{
          setPrinting(true)
          let response=await data.makeRequest({method:'get', url:`api/contract-assets`,params:{
              all:true,microcredit_id:selectedMicrocredit || '',search:search,page:currentPage,...filters,...data.getParamsFromFilters(filterOptions)
          }, error: ``,withToken:true},0);
         Print(type,response.data)
      }catch(e){
          toast.remove()
          let msg="Acorreu um erro, tente novamente"
          if(e.response){
            if(e.response.status==500){
                msg="Erro, inesperado. Contacte seu administrador"
            }
          }else if(e.code=='ERR_NETWORK'){
                msg="Verfique sua internet e tente novamente"
          }
          toast.error(msg)
      }
      setPrinting(false)
}


useEffect(()=>{
     if((userRole=="operator" && !userInfo?.permissions?.inventory?.includes('read'))){
          navigate('/dashboard')
     }
},[userInfo])


return (
    <>
     <DefaultLayout>

     <main className="dashboard-container">

     {<CreateAsset showD={(create || edit) ? true : false}  create={create} setCreate={setCreate} setEdit={setEdit} edit={edit} del={del}/>}
   
     <div className="dashboard">
     <Menu/>
     <div className="main-dashboard">
          
          <TopBar activePage={'Inventário'}/>

          {!data.loaded.includes('contract_assets') && <TopCardSkeleton/>}

          <div className="center">
          <div style={{display:!data.loaded.includes('contract_assets') ? 'none':'flex'}} className={`stat_page_2`}>
                             <div className="items">

                                  <div className="box">
                                    <div className="icon blue-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M620-163 450-333l56-56 114 114 226-226 56 56-282 282Zm220-397h-80v-200h-80v120H280v-120h-80v560h240v80H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h167q11-35 43-57.5t70-22.5q40 0 71.5 22.5T594-840h166q33 0 56.5 23.5T840-760v200ZM480-760q17 0 28.5-11.5T520-800q0-17-11.5-28.5T480-840q-17 0-28.5 11.5T440-800q0 17 11.5 28.5T480-760Z"/></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Bens retidos</div>
                                       <div className="total">{(data._contract_assets?.filteredTotals?.retained || 0)}</div>
                                    </div>
                                 </div>
                                   <div className="box">
                                    <div className="icon green-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z"/></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Confiscados</div>
                                       <div className="total">{(data._contract_assets?.filteredTotals?.confiscated || 0)}</div>
                                    </div>
                                 </div>


                                   <div className="box">
                                    <div className="icon red-icon">
                                         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="27"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Valor total de bens</div>
                                       <div className="total">{data._cn((data._contract_assets?.filteredTotals?.amount || 0).toFixed(2))}</div>
                                    </div>
                                 </div>

                                
                             </div>
               </div>

               <div className="center_search">
                    <div className="search_container">
                         <div className="search-div">
                              <input type="text" placeholder="Pesquisar..." value={search} onChange={(e)=>{
                                   setSearch(e.target.value)
                                   data.setUpdateTable(Math.random())
                              }}/>
                                   <div className="search-btn">
                                   <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg>
                              </div>
                         </div>
                    </div>

                    <div className="flex items-center">
                         <div onClick={()=>
                             data.setUpdateTable(Math.random())
                          } className="mr-2 cursor-pointer hover:opacity-65">
                              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z"/></svg>
                         </div>
                        
                    </div>
               </div>


                  <div className={`extra-filters ${hideFilters ? '_hide':''}`}>

                   <label onClick={()=>setHideFilters(!hideFilters)} className="underline sm:hidden text-[15px]  text-custom_blue-400 absolute top-4 cursor-pointer">{hideFilters ? 'Mostrar filtros':'Esconder filtros'}</label>

                    <div className="input-container">
                         <div className="inputs">
                              <span>Data de inicio</span>
                              <input type="date" value={filters.start_date} onChange={(e)=>setFilters({...filters,start_date:e.target.value})} />
                         </div>

                         <div className="inputs">
                              <span>Data de fim</span>
                              <input type="date" value={filters.end_date} onChange={(e)=>setFilters({...filters,end_date:e.target.value})} />
                         </div>

                        
                         <div className="inputs">
                              <span>Cliente</span>
                              <select value={filters.client_id} onChange={e=>setFilters({...filters, client_id:e.target.value})}>
                                        <option selected value="">Selecionar cliente</option>
                                        {data._clients_list.sort((a, b) => (a.deleted_at === null ? -1 : 1) - (b.deleted_at === null ? -1 : 1)).map(i=>(
                                             <option key={i.id} selected={filters.client_id === i.id} value={i.id}>{i.name} {i.deleted_at ? '(Arquivado)':''}</option>
                                        ))}
                              </select>
                         </div>
                    </div>

                    <div className="options">
                    <span onClick={()=>setFilters(initial_filters)}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-122q-121-15-200.5-105.5T160-440q0-66 26-126.5T260-672l57 57q-38 34-57.5 79T240-440q0 88 56 155.5T440-202v80Zm80 0v-80q87-16 143.5-83T720-440q0-100-70-170t-170-70h-3l44 44-56 56-140-140 140-140 56 56-44 44h3q134 0 227 93t93 227q0 121-79.5 211.5T520-122Z"/></svg> </span>
                    </div>

             </div>

               <div className="dasboard-item-list-container">
                    <div className="top-section">
                         <div className="left-side">
                                   <div className="show">
                                             <span>Resultados:</span>
                                             <label>{data._contract_assets?.total}</label>
                                   </div>
                              
                         </div>
                         <div className="right-side">
                                        {printing ? <>
                                                  <Loader/>
                                        </> : <>
                                             <span onClick={()=>{
                                                  Export('pdf')
                                             }} className="export export-excel page" style={{background:'rgb(74, 205, 53)'}}>
                                                  <svg width="21" viewBox="0 0 24 24" style={{fill:'#fff'}} xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z" />
                                                  </svg>
                                             </span>
                                             
                                             <span onClick={()=>{
                                                  Export('excel')
                                             }} className="export export-excel page" style={{background:'rgb(74, 205, 53)'}}>
                                             <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24"><path fill="#fff" d="M18 22a2 2 0 0 0 2-2v-5l-5 4v-3H8v-2h7v-3l5 4V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12zM13 4l5 5h-5V4z"></path></svg>
                                             </span>
                                        </>}
                         </div>
                    </div>


                     <BaiscTable  loaded={data._loaded.includes('contract_assets')}  header={
                           [
                              <BaiscTable.MainActions hide={userRole=="operator" && !userInfo?.permissions?.inventory?.includes('delete')} data={data} options={{
                                deleteFunction:'default',
                                deleteUrl:'api/contract-assets/delete'}
                              } items={data._contracts?.data || []}/>,
                              'ID',
                              'Descrição',
                              'Valor',
                              'Cliente',
                              'Estado',
                              'Categoria',
                              'Data de aquisição',
                              'Número de registro',
                              'Observações',
                              'Localização',
                              'Fotos ou documentos',
                              'Criado em'
                           ]
                     } 

                     body={(data._contract_assets?.data || []).map((i,_i)=>(
                           <BaiscTable.Tr>
                                 <BaiscTable.Td>
                                       <BaiscTable.Actions hide={userRole=="operator" && !userInfo?.permissions?.inventory?.includes('delete')} data={data}  options={{
                                             deleteFunction:'default',
                                             deleteUrl:'api/contract-assets/delete',
                                             id:i.id}
                                       }/>
                                 </BaiscTable.Td>
                                 <BaiscTable.Td onClick={()=>setEdit(i)}>{i.id}</BaiscTable.Td>
                                 <BaiscTable.Td onClick={()=>setEdit(i)}>{i.description || '-'}</BaiscTable.Td>
                                 <BaiscTable.Td onClick={()=>setEdit(i)}>{data._cn((parseFloat(i.amount)).toFixed(2))}</BaiscTable.Td>
                                 <BaiscTable.Td onClick={()=>setEdit(i)}>{i.client.name}</BaiscTable.Td>
                                 <BaiscTable.Td onClick={()=>setEdit(i)}>
                                    <button style={{border:0}} type="button"  class={`text-gray-900 cursor-default ${i.status=="retained" ? 'bg-[#F9CE66]':'bg-[#FF6B6B]'} focus:outline-none font-medium rounded-[0.3rem] text-sm px-2 py-1 text-center inline-flex items-center`}>
                                          {i.status=='retained' ? 'Retido': 'Confiscado'}
                                    </button>
                                 </BaiscTable.Td>
                                 <BaiscTable.Td onClick={()=>setEdit(i)}>{data._inventory_categories.filter(f=>f.id==i.category_id)[0]?.name}</BaiscTable.Td>
                                 <BaiscTable.Td onClick={()=>setEdit(i)}>{i.date_of_acquisition?.split('-')?.reverse()?.join('/') || '-'}</BaiscTable.Td>
                                 <BaiscTable.Td onClick={()=>setEdit(i)}>{i.registration_number || '-'}</BaiscTable.Td>
                                 <BaiscTable.Td onClick={()=>setEdit(i)}>{i.notes || '-'}</BaiscTable.Td>
                                 <BaiscTable.Td onClick={()=>setEdit(i)}>{i.location || '-'}</BaiscTable.Td>
                                 <BaiscTable.Td onClick={()=>setEdit(i)}>
                               
                                 <div class="media-items" style={{display:'flex',maxWidth:'100px',overflow:'auto'}}>
                                          {(i.media).map(f=>(
                                          <a key={f.name} target="_blank" href={`${process.env.REACT_APP_BASE_URL}/uploaded_files/${f.name}`}>
                                                <div className="item" style={{backgroundPosition:'center',backgroundSize:'cover',backgroundImage:`url('${process.env.REACT_APP_BASE_URL}/uploaded_files/${f.name.replace(' ','%20')}')`}}>
                                                </div>
                                             </a>
                                          ))}
                                 </div>

                                 </BaiscTable.Td>
                                 <BaiscTable.Td onClick={()=>setEdit(i)}>
                                                  {i.created_at?.split('T')[0]?.split('-')?.reverse()?.join('/')}
                                 </BaiscTable.Td>

                                   <BaiscTable.AdvancedActions data={data} w={200} id={i.id} items={[
                                        {name:'Eliminar',hide:(userRole!="manager" && !userInfo?.permissions?.inventory?.includes('delete')),onClick:()=>{
                                             setTimeout(()=>{
                                                  data.setItemsToDelete({...data.itemsToDelete,url:'api/contract-assets/delete',items:[i.id]})
                                                  data._showPopUp('delete')
                                             },100)
                                        },icon:(<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="#5f6368"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"></path></svg>)},
                                   ]}/>

                                   
                           </BaiscTable.Tr>
                           ))}
                     />

               <BasicPagination show={data._loaded.includes('contract_assets')} from={'contract_assets'} setCurrentPage={setCurrentPage} total={data._contract_assets?.total}  current={data._contract_assets?.page} last={data._contract_assets?.totalPages}/>


               </div>

          </div>

          
     </div>
     </div>
     </main>

     </DefaultLayout>
    </>
  );
}
