import React, { useEffect } from 'react'
import { useData } from '../../contexts/DataContext';

function BasicTable() {

  const data=useData()



  useEffect(()=>{
    if(data.print_data.print){
        window.print()
        data.setPrintData({...data.print_data,print:false})
    }
  },[data.print_data.print])

  

  return (

    <div
      print="true"
      className={`print_table ${data.print_data.print ? '_print_div':''}`}
    >
    

      {data.print_data.data.map((t,_t)=>(

         <div>
             <h2>{data.print_data.names[_t]}</h2>
         <br/>

        <table style={{ width: '100%', fontSize: '0.875rem', textAlign: 'left'}}>
        <thead style={{ fontSize: '0.75rem', textTransform: 'uppercase', backgroundColor: '#f9fafb', color: '#4a5568' }}>
          <tr>
            {Object.keys((t[0] || {})).map((i,_i)=>(
              <th key={_i} style={{ padding: '0.5rem 0.5rem' }}>{i}</th>
            ))}
          </tr>
        </thead>
        <tbody>
            {t.map((i,_i)=>(
                    <tr style={{ backgroundColor: '#ffffff', borderBottom: '1px solid #e5e7eb' }}>
                          {Object.keys(i).map((f,_f)=>(
                            <td style={{ padding: '0.5rem 0.5rem' }}>{t[_i][f]}</td>
                          ))}
                    </tr>
            ))}
        </tbody>
        </table>
        </div>

      ))}

     
    </div>
  );
}

export default BasicTable;
