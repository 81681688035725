
import '../../css/main.css'
import './style.css'
import Menu from '../../common/menu.js';
import TopBar from "../../common/topBar";
import { useEffect, useState } from 'react';
import CreateInterestRates from './create-interest-rates.js'
import CreateLatePaymentInterest from './create-late-payment-interest.js'
import DeleteDialog1 from './delete-dialog-1.js'
import DeleteDialog2 from './delete-dialog-1.js'
import BaiscTable from '../../tables/default-table.js';
import { useAuth } from '../../../contexts/AuthContext.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useData } from '../../../contexts/DataContext.js';
import DefaultLayout from '../../layouts/DefaultLayout.js';

export default function Create() {

const [createInterestRates, setCreateInterestRates] = useState(false);
const [createPaymentInterest, setCreatePaymentInterest] = useState(false);


const [edit1, setEdit1] = useState(null);
const [del1, setDel1] = useState(null);
const [edit2, setEdit2] = useState(null);
const [del2, setDel2] = useState(null);

const {pathname} = useLocation()
const {user,selectedMicrocredit,userInfo,userRole} = useAuth()
const data=useData()
const navigate=useNavigate()

let required_data=['interest_rates','late_payment_interests']
const [updateFilters,setUpdateFilters]=useState(null)

useEffect(()=>{ 
   if(!user) return
   data.handleLoaded('remove',required_data)
   data._get(required_data,{
      interest_rates:{microcredit_id:selectedMicrocredit},
      late_payment_interests:{microcredit_id:selectedMicrocredit}
   }) 
 },[user,pathname,updateFilters])


 useEffect(()=>{
   data.handleLoaded('remove',required_data,)
 },[updateFilters])

 useEffect(()=>{
   if(data.updateTable){
        data.setUpdateTable(null)
        data.handleLoaded('remove',required_data)
        data._get(required_data,{
            interest_rates:{microcredit_id:selectedMicrocredit},
            late_payment_interests:{microcredit_id:selectedMicrocredit}
        }) 
   }
},[data.updateTable])



useEffect(()=>{
    if((userRole=="operator" && !userInfo?.permissions?.contract_settings?.includes('create'))){
        navigate('/dashboard')
    }
},[userInfo]) 



return (
    <>
      <DefaultLayout>
      <main className="dashboard-container">

      {<CreateInterestRates showD={(createInterestRates || edit1) ? true : false}  create={createInterestRates} setCreate={setCreateInterestRates} setEdit={setEdit1} edit={edit1} del={del1}/>}
      {<CreateLatePaymentInterest showD={(createPaymentInterest || edit2) ? true : false}  create={createPaymentInterest} setCreate={setCreatePaymentInterest} setEdit={setEdit2} edit={edit2} del={del2}/>}

      {<DeleteDialog1 showD={del1 ? true : false} del={del1}  setDel={setDel1}/>}
      {<DeleteDialog2 showD={del2 ? true : false} del={del2}  setDel={setDel2}/>}

      <div className="dashboard">
      <Menu/>
      <div className="main-dashboard">
            <TopBar activePage={'Definições de contrato'} lastPage={{name:'Contratos',pathname:'/loans'}}/>
            <div className="center">
                  <div className="loan-container">
                              <div className="top-title">Taxas</div>
                              <div className="section">
                                    <div className="sub-title">Taxas de juros {(userRole!="operator" || userInfo?.permissions?.contracts_settings?.includes('create')) && <button onClick={()=>setCreateInterestRates(true)}>Adicionar taxa</button>}</div>
                                    <div className="_table max-w-[700px]">

                                    <BaiscTable canAdd={user?.role=="admin"}  loaded={data._loaded.includes('interest_rates')} header={[
                                          <BaiscTable.MainActions  sigleSelection={true} data={data} options={{
                                          deleteFunction:'default',
                                          deleteUrl:'api/interest-rates/delete'}
                                          } items={data._interest_rates || []}/>,
                                          'Dias',
                                          'Percentagem (%)',
                                          'Contratos associados'
                                          ]
                                    }
                                    body={(data._interest_rates || []).map((i,_i)=>(
                                                <BaiscTable.Tr disabled={i.contracts.length}>
                                                <BaiscTable.Td w={30}>
                                                      <BaiscTable.Actions  hide={true} sigleSelection={true}  data={data}  options={{
                                                            deleteFunction:'default',
                                                            deleteUrl:'api/interest-rates/delete',
                                                            id:i.id}
                                                      }/>
                                                </BaiscTable.Td>
                                                <BaiscTable.Td onClick={()=>setEdit1(i)}>{i.days}</BaiscTable.Td>
                                                <BaiscTable.Td onClick={()=>setEdit1(i)}>{i.percentage}</BaiscTable.Td>
                                                <BaiscTable.Td onClick={()=>setEdit2(i)}>{i.contracts.length || '-'}</BaiscTable.Td>
                                          </BaiscTable.Tr>
                                          ))}
                                    />


                                 </div>
                              </div>







                              <div className="section">
                                    <div className="sub-title">Taxas de juros de mora {(userRole!="operator" || userInfo?.permissions?.contracts_settings?.includes('create')) && <button onClick={()=>setCreatePaymentInterest(true)}>Adicionar taxa</button>}</div>
                                    <div className="_table max-w-[700px]">
                                    <BaiscTable canAdd={user?.role=="admin"} addRes={()=>{
                                          
                                    }}  loaded={data._loaded.includes('late_payment_interests')} header={[
                                          <BaiscTable.MainActions  sigleSelection={true} data={data} options={{
                                          deleteFunction:'default',
                                          deleteUrl:'api/late-payment-interests/delete'}
                                          } items={data._late_payment_interests || []}/>,
                                          'De',
                                          'Até',
                                          'Percentagem (%)',
                                          'Contratos associados'
                                          ]
                                    }

                                    body={(data._late_payment_interests || []).map((i,_i)=>(
                                          
                                                <BaiscTable.Tr disabled={i.Contracts.length}>
                                                <BaiscTable.Td w={30}>
                                                      <BaiscTable.Actions hide={true}  sigleSelection={true}  data={data}  options={{
                                                            deleteFunction:'default',
                                                            deleteUrl:'api/late-payment-interests/delete',
                                                            id:i.id}
                                                      }/>
                                                </BaiscTable.Td>
                                                <BaiscTable.Td onClick={()=>setEdit2(i)}>{i.from}</BaiscTable.Td>
                                                <BaiscTable.Td onClick={()=>setEdit2(i)}>{i.to}</BaiscTable.Td>
                                                <BaiscTable.Td onClick={()=>setEdit2(i)}>{i.percentage}</BaiscTable.Td>
                                                <BaiscTable.Td onClick={()=>setEdit2(i)}>{i.Contracts.length || '-'}</BaiscTable.Td>
                                                
                                          </BaiscTable.Tr>
                                          ))}
                                    />
                                 </div>

                              </div>
                  </div>
            </div>
      </div>
      </div>
      </main>
      </DefaultLayout>
    </>
  );
}
