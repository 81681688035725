
import { useEffect, useState,useRef} from 'react';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CreateInvetoryCategory from '../../inventory/settings/create-inventory-category'
const Create = ({create,setCreate, edit , setEdit, showD,contractFormData,setContractFormData}) => {

const status_types=[{id:'retained',label:'Retido'},{id:'confiscated',label:'Confiscado'}]
let initial_form={id:Math.random(),description:'',notes:'',amount:'',category_id:null,media:[],location:'', registration_number:'',date_of_acquisition:'',status:'retained',category_id:'',model:''}
let [formData,setFormData]=useState(initial_form)
const [createCategory, setCreateCategory] = useState(false);
const [editCategory, setEditCategory] = useState(null);
const [loading,setLoading]=useState(false)
const [loadingFile,setLoadingFile]=useState(false)
const fileInputRef = useRef(null);
const data = useData();
const {selectedMicrocredit, user,userRole,userInfo} = useAuth();

useEffect(()=>{
    if(edit){
       setFormData({...edit,category_id:edit.category_id || ''})
       setCreate(false)
    }
},[edit])

useEffect(()=>{
    if(create){
       setFormData({...initial_form,id:Math.random().toString(),status:formData.status}) 
       if(setEdit) setEdit(false)
    }
},[create])


function getAssetStatus(){

     let status="retained"
     if(contractFormData){
        if(contractFormData.payment_status=="delayed"){
          status="confiscated"
        }
     }else{
        if(formData.contract?.payment_status=="delayed"){
             status="confiscated"
        }
     }
     return status
     
}




useEffect(()=>{
    if(formData.percentage < 0 ){
       setFormData({...formData,percentage:0})
    }else if(formData.percentage > 100){
       setFormData({...formData,percentage:100})
    }
},[formData])



async function SubmitForm(){

  if(!formData.amount || !formData.description || !formData.date_of_acquisition || !formData.registration_number || !formData.date_of_acquisition) {
      toast.error('Preencha todos os campos!')
      return 
  }

  setLoading(true)
 
  try{

    if(edit){

      await data.makeRequest({method:'post',url:`api/contract-asset/update/`+edit.id,withToken:true,data:{
        ...formData,
      }, error: ``},0);
      
      toast.success('Actualizado com sucesso!')
      setLoading(false)

    }else{

      await data.makeRequest({method:'post',url:`api/contract-asset/create`,withToken:true,data:{
        ...formData
      }, error: ``},0)

      toast.success('Criado com sucesso!')
      setLoading(false)

    }

    setEdit(null)
    setCreate(null)
    data.setUpdateTable(Math.random())

  }catch(e){
      let msg="Acorreu um erro, tente novamente"
      console.log(e)
      setLoading(false)

      if(e.response){
        if(e.response.status==409){
            msg="Email já existe na microcrédito"
        }  
        if(e.response.status==404){
          msg="Item não encotrado"
        }   
        if(e.response.status==400){
            msg="Dados inválidos"
        }
        if(e.response.status==500){
            msg="Erro, inesperado. Contacte seu administrador"
        }

      }else if(e.code=='ERR_NETWORK'){
            msg="Verfique sua internet e tente novamente"
      }
      toast.error(msg)
      setLoading(false)
    }
    
}



function removeMediaItem(name){
    setFormData({...formData,media:(typeof formData.media == "string"  ? JSON.parse(formData.media) : formData.media).filter(i=>i.name!=name)})
}

async function  getFile(file){

    resetInputValue();
    setLoadingFile(true)

    const _formData = new FormData();
    _formData.append('data', JSON.stringify({file_dest:'contract_assets'}))
    _formData.append('file', file);

    try {

      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/upload`, _formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setFormData({...formData,media:[{name:response.data},...(typeof formData.media == "string"  ? JSON.parse(formData.media) : formData.media)]})
      setLoadingFile(false)
      toast.remove()
      toast.success('Arquivo enviado')
    } catch (error) {
      toast.remove()
      toast.error('Ocorreu um erro:'+error)
      console.error(error);
      setLoadingFile(false)

    }
   
}

const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    getFile(file)
};
  
const resetInputValue = () => {
    fileInputRef.current.value = '';
};
  
const handleDragOver = (e) => {
    e.preventDefault();
};

return (
  <>
  <CreateInvetoryCategory createRes={(category)=>{
     data.setInventoryCategories([...data._inventory_categories,category])
     setFormData({...formData,category_id:category.id})
     setCreateCategory(false)
  }} showD={createCategory} create={createCategory} edit={editCategory} setCreate={setCreateCategory} setEdit={setEditCategory}/>
  <div className={`dialog-container-bg ${showD && !createCategory ? 'show' :''}`}>
  <div className="bg-close" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}></div>
  <div  className={`create-microcredit ${loading && 'loading'}`}>
                  <div className="top-title">{edit ? 'Editar' :' Adicionar'} Bem</div>  
                  
                  <div className="btn-close" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                  <div className="status" style={{color:'#111',background:getAssetStatus()=='retained' ? '#F9CE66' : '#FF6B6B'}}>{getAssetStatus()=='retained' ? 'Retido'  : 'Confiscado'}</div>

                  <div className="input-container">
                    
                  <div className="inputs">
                           <span>Descrição <label class="mandatory">*</label></span>
                           <input value={formData.description}   onChange={e=>setFormData({...formData,description:e.target.value})} type="text" placeholder="Descrição"/>
                     </div>

                     <div className="inputs">
                           <span>Valor</span>
                           <input value={formData.amount ? formData.amount : ""}  onChange={e=>setFormData({...formData,amount:parseInt(e.target.value)})} type="number" placeholder="Valor"/>
                     </div>


                     <div className="inputs">
                           <span>Data de aquisição</span>
                           <input value={formData.date_of_acquisition}   onChange={e=>setFormData({...formData,date_of_acquisition:e.target.value})} type="date" placeholder="Data de aquisição"/>
                     </div>


                     <div className="inputs">
                           <span>Número de registro</span>
                           <input value={formData.registration_number}   onChange={e=>setFormData({...formData,registration_number:e.target.value})} type="text" placeholder="Número de registro"/>
                     </div>


                     <div className="inputs">
                           <span>Localização</span>
                           <input value={formData.location}   onChange={e=>setFormData({...formData,location:e.target.value})} type="text" placeholder="Localização"/>
                     </div> 

                     

                     <div className="inputs" style={{display:'none'}}>
                             <span>Estado</span>
                             <select style={{opacity:0.6}} disabled value={formData.status} onChange={e=>setFormData({...formData,status:e.target.value})}>
                                  <option disabled selected value="">Selecione estado</option>
                                  {status_types.map(i=>(
                                        <option key={i.id} selected={formData.status == i.id} value={i.id}>{i.label}</option>
                                  ))}
                             </select>
                     </div>
  
                     <div className="inputs">
                           <span>Modelo</span>
                           <input value={formData.model}   onChange={e=>setFormData({...formData,model:e.target.value})} type="text" placeholder="Modelo"/>
                     </div> 

                     <div className="inputs">
                             <span>Categoria</span>
                             <select  value={formData.category_id} onChange={e=>setFormData({...formData, category_id:e.target.value})}>
                                  <option disabled selected value="">Selecione categoria</option>
                                  {data._inventory_categories.map(i=>(
                                        <option key={i.id}  value={i.id}>{i.name}</option>
                                  ))}
                             </select>

                             {(userRole!="operator" || userInfo?.permissions?.inventory_settings?.includes('create')) && <span className="btn-add" onClick={()=>{
                                        setCreateCategory(true)
                              }}><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="24"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg></span>}
                                                    
                     </div>

                     <div className={`inputs`}>
                            <span>Observações</span>
                            <textarea  value={formData.notes} onChange={e=>setFormData({...formData, notes:e.target.value})} style={{height:'70px'}} placeholder="Observações"></textarea>
                     </div> 
                     <div className={`inputs media`}>
                             <span>Fotos ou documentos</span>
                             <div className={`media-items ${loadingFile && 'loading'}`}
                                          onDrop={handleDrop}
                                          onDragOver={handleDragOver}>
                                   <label>
                                    <div className={`item add-file`}>
                                         <div className="loader"></div> <div className="add-icon"><svg xmlns="http://www.w3.org/2000/svg" height="23" viewBox="0 -960 960 960" width="24"><path d="M640-520v-200h80v200h-80ZM440-244q-35-10-57.5-39T360-350v-370h80v476Zm30 164q-104 0-177-73t-73-177v-370q0-75 52.5-127.5T400-880q75 0 127.5 52.5T580-700v300h-80v-300q-1-42-29.5-71T400-800q-42 0-71 29t-29 71v370q-1 71 49 120.5T470-160q25 0 47.5-6.5T560-186v89q-21 8-43.5 12.5T470-80Zm170-40v-120H520v-80h120v-120h80v120h120v80H720v120h-80Z"/></svg></div>
                                    </div>
                                        <input
                                            type="file"
                                            onChange={(e) => getFile(e.target.files[0])}
                                            ref={fileInputRef}
                                            style={{opacity:0,position:'absolute',pointerEvents:'none'}}
                                        />
                                   </label>


                                   {(formData.media).map(i=>(
                                         <div className="item" style={{backgroundPosition:'center',backgroundSize:'cover',backgroundImage:`url('${process.env.REACT_APP_BASE_URL}/uploaded_files/${i.name.replace(' ','%20')}')`}}>
                                            <div className="close-icon" onClick={()=>removeMediaItem(i.name)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="14"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"></path></svg>
                                            </div>
                                            <a target="_blank" href={`${process.env.REACT_APP_BASE_URL}/uploaded_files/${i.name}`}>
                                           
                                            <div className="see-icon">
                                                <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="24"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
                                            </div>

                                            <div style={{display:'none'}} className="file-icon">
                                                 <svg fill="#161A1D" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#161A1D" d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path></svg>
                                            </div>
                                            </a>
                                        </div>
                                        
                                   ))}
                                  

                                  
                             </div>
                     </div>
              </div>

              <div className="options">
                  <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>
                  <div className="div_btn">
                     {(userRole!="operator" || !((!userInfo?.permissions?.inventory?.includes('update') && !contractFormData && edit)))  && <button className="save" onClick={()=>{
                         if(contractFormData){
                            setContractFormData({...contractFormData,contract_assets:[...contractFormData.contract_assets.filter(i=>i.id!=formData.id),formData]})
                            setEdit(null)
                            setCreate(null)
                         }else{
                            SubmitForm()
                         }
                     }}><span>{contractFormData ? 'Continuar' : edit ? 'Actualizar' : 'Adicionar'}</span></button>}
                     <span className="loader"></span>
                  </div>
              </div>

        </div>
         </div>

  </>
 

)
};

export default Create;
