import { useState,useRef,useEffect } from 'react'
import Menu from '../common/menu'
import Faq from './faq'
import Join from './join'
import Footer from '../common/footer'
import '../css/main.css'

import { useData} from '../../../contexts/DataContext';


import mainImage from '../../../assets/images/home/main-image.png'
import shortAboutImage from '../../../assets/images/home/short-about.png'
import dashboardFeatureImage  from '../../../assets/images/home/dashboard-feature.png'
import cashFeatureImage  from '../../../assets/images/home/cash-feature.png'
import contractsFeatureImage  from '../../../assets/images/home/contracts-feature.png'
import { useNavigate, useLocation , Link} from 'react-router-dom'; 

function Home() {
  const [count, setCount] = useState(0)
  const navigate = useNavigate();
 
    const scrollToSection = (to) => {
            const Section = document.getElementById(to);
            console.log(Section)
            if (Section) {
              Section.scrollIntoView({ behavior: 'smooth' });
            }
   };



  return (
     <div className="_container landing-page">
             <Menu/>
             <div className="_home " id="home">
                      <div className="_home-content">
                        <div className="_left-home">
                        <h3>
                            Sistema Inteligente de Gestão de Microcrédito VLMS
                        </h3>
                        <span className="color-white">Otimize a gestão de seus clientes, contratos, transações de caixa, pagamentos atrasados, relatórios, operadores e mais com o sistema integrado de gestão de microcrédito VLMS de forma eficiente, segura e acessível.</span>
                        <div className="_btns">
                              <a href="#join"><button className="_demo">Cadastrar</button></a>
                              <Link to={'/test'}><button className="_test" __s="btn-test-free">Testar grátis</button></Link>
                        </div>
                        </div> 
	                     <div className="_right-home"> 
	                       { <img src={mainImage}/>}
	                     </div>
                      </div>
                    
             </div>

             <div className="about-short">
                 <div className="left">
	                  <h3 className="title">
	                          <label> Otimize</label> as operações da sua microcrédito
                             { /* <label> Transforme</label> a sua microcrédito*/}
	                  </h3>
                     { /*<h2>VLMS foi projetada para atender às necessidades de gestão de microcrédito em empresas de todos os tamanhos</h2>*/}
	                  <h2>Leve seu negócio para o próximo nível</h2>
                      
                      <span className="text">
	                   A plataforma VLMS automatiza tarefas rotineiras e repetitivas, como análise de crédito, emissão de relatórios e acompanhamento de pagamentos, permitindo que gestores economizem tempo e foquem em atividades estratégicas.</span>

	                  <div className="input-container">
	                    {/* <input type="text" placeholder="Email da sua empresa"/>*/}
	                     <button onClick={()=>scrollToSection('join')}>Cadastrar</button>
	                  </div>
                 </div>
                 <div className="right">
                         <img src={shortAboutImage}/>
                 </div>
             </div>


             <div className="top-features" id="features">
                 <div className="section-title">
                      <h3>Recursos</h3>
                      <span>Como elevamos o <label>seu negócio?</label></span>
                    
                 </div>

                 <div className="items">
						<div className="item">
								<div className="icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M80-160v-120h80v-440q0-33 23.5-56.5T240-800h600v80H240v440h240v120H80Zm520 0q-17 0-28.5-11.5T560-200v-400q0-17 11.5-28.5T600-640h240q17 0 28.5 11.5T880-600v400q0 17-11.5 28.5T840-160H600Zm40-120h160v-280H640v280Zm0 0h160-160Z"/></svg> </div>
								<span className="title">Compatibilidade</span>
		                        {/*<span className="text">
		                        		                          Gestão de Empréstimos, Acompanhamento de pagamentos, Análise de riscos, Relátorios e Notificações 
		                        		                        </span>*/}
		                      <span className="text">
		                        	Plataforma responsiva para todos os tamanhos de dispositivos.
		                       </span>



						</div>
						<div className="item">
							    <div className="icon">
							    	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="30"><path d="M570-104q-23 23-57 23t-57-23L104-456q-11-11-17.5-26T80-514v-286q0-33 23.5-56.5T160-880h286q17 0 32 6.5t26 17.5l352 353q23 23 23 56.5T856-390L570-104Zm-57-56 286-286-353-354H160v286l353 354ZM260-640q25 0 42.5-17.5T320-700q0-25-17.5-42.5T260-760q-25 0-42.5 17.5T200-700q0 25 17.5 42.5T260-640ZM160-800Z"/></svg>
							    </div>
							    <span className="title">Plano</span>
		                        <span className="text">
		                       Oferecemos planos que se ajustam às necessidades do seu negócio, com possibilidade de personalização.
		                        </span>
						</div>
						<div className="item">	
							<div className="icon">
								<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960" width="30"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80ZM364-182l48-110q-42-15-72.5-46.5T292-412l-110 46q23 64 71 112t111 72Zm-72-366q17-42 47.5-73.5T412-668l-46-110q-64 24-112 72t-72 112l110 46Zm188 188q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm116 178q63-24 110.5-71.5T778-364l-110-48q-15 42-46 72.5T550-292l46 110Zm72-368 110-46q-24-63-71.5-110.5T596-778l-46 112q41 15 71 45.5t47 70.5Z"/></svg>
							</div>
							<span className="title">Suporte</span>
		                        <span className="text">
		                         Temos uma equipe dedicada para fornecer suporte personalizado à sua empresa.
		                        </span>
						</div>
                 </div>
             </div>







            <div className="features-items">
                     <div className="item dashboard">
                                <div className="text-container">
                                     <label>Dashboard</label>
                                     <span className="title">Tenha uma visão micro do seu negócio</span>
                                      <div className="image-container mobile-view">
                                        <img src={dashboardFeatureImage}/>
                                      </div>
                                     <span className="text">
                                     	Tenha uma visão abrangente e em tempo real dos dados mais relevantes do seu negócio como o estado de contratos, pagamentos por vencer, em atraso, assim como o desempenho das suas receitas.
                                     </span>
                                     <div><button>Testar grátis</button></div>
                                </div>
                                <div className="image-container desktop-view">
                                     <img src={dashboardFeatureImage}/>
                                </div>
                     </div>


                     <div className="item contracts">
                                <div className="image-container desktop-view">
                                     <img src={contractsFeatureImage}/>
                                </div>
                                 <div className="text-container">
                                     <label>Gestão de Contratos</label>
                                     <span className="title">Acompanhamento completo</span>
                                      <div className="image-container mobile-view">
                                     <img src={contractsFeatureImage}/>
                                     </div>
                                     <span className="text">
                                     	Acompanhe o status de cada contrato, desde a solicitação até a liquidação final. Crie e gerencie contratos com facilidade, automatize a cobrança de pagamentos e receba notificações sobre vencimentos próximos. Melhore o atendimento ao cliente e economize tempo valioso.
                                     </span>
                                     <div><button>Testar grátis</button></div>
                                </div>
                     </div>



                     <div className="item cash">
                                 <div className="text-container">
                                     <label>Gestão de Caixa</label>
                                     <span className="title">Controlo facilitado</span>
                                      <div className="image-container mobile-view">
                                     <img src={cashFeatureImage}/>
                                     </div>
                                     <span className="text">
                                    A ferramenta de gestão de caixa da nossa plataforma facilita o controlo de todas as suas transações, incluindo despesas e receitas, permitindo realizar transferências, transações automáticas no desembolso e pagamento de empréstimos.</span>
                                     <div><button>Testar grátis</button></div>
                                </div>

                                <div className="image-container desktop-view">
                                     <img src={cashFeatureImage}/>
                                </div>
                     </div>
            </div>



            <div className="plans" id="plans">
                <div className="section-title">
                     <h3>Planos</h3>
                     <span>Escolha  o melhor plano para o seu negócio</span>
                 </div>

                 <div className="items">
                     <div className="item free">
                         <span className="name">GRÁTIS</span>
                         <span className="price"><label className="value">0MT</label></span>
                           <div className="discount">Disponível apenas por 30 dias</div>
                         
                         <div className="features">

                        

                            <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                            		<span>Contratos: Ilimitados</span>
                            </div>
                           

                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                            		<span>Clientes: Ilimitados</span>
                             </div>
                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                            		<span>Gestor: 1</span>
                             </div>
                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                            		<span>Operadores: 2</span>
                            </div>

                           
                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Upload de documentos</span>
                             </div>

                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Contratos digitais</span>
                             </div>

                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Notificações por Email</span>
                             </div>

                           
                         </div>
                         <div className="btn-div">
                            <button onClick={()=>scrollToSection('join')}>Requsitar</button>
                         </div>
                     </div>


                      <div className="item standard">
                         <span className="name">STANDARD</span>
                         <span className="price"><label className="value">1.500MT</label><label className="per">/mês</label></span>
                          <div className="discount">Desconto de 1 mês no pagamento anual</div>
                          <div className="features">
                           
                            <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Contratos: Ilimitados</span>
                            </div>
                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Clientes: Ilimitados</span>
                             </div>
                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Gestores:2</span>
                             </div>
                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Operadores: 4</span>
                            </div>
                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Upload de documentos</span>
                             </div>

                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Contratos digitais</span>
                             </div>
                             
                             <div> 
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Notificações por Email</span>
                             </div>

                         </div>
                        <div className="btn-div">
                           <button onClick={()=>scrollToSection('join')}>Requisitar</button>
                         </div>
                     </div>

                      <div className="item premium">
                         <span className="name">PREMIUM</span>
                         <span className="price"><label className="value">2.000MT</label><label className="per">/mês</label></span>
                         
                        <div className="discount">Desconto de 1 mês no pagamento anual</div>
                         <div className="features">
                            <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Contratos: Ilimitados</span>
                            </div>
                            
                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Clientes: Ilimitados</span>
                             </div>
                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Gestores: Ilimitados</span>
                             </div>
                           

                            <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Operadores: Ilimitados</span>
                            </div>
                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Upload de documentos</span>
                             </div>


                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Contratos digitais</span>
                             </div>

                              <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Módulo de gestão de risco (Clientes na Blacklist)</span>
                             </div>

                             <div> 
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Módulo de gestão de inventário</span>
                             </div>

                             


                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Notificações por Email</span>
                            </div>

                           
                         </div>
                         <div className="btn-div">
                            <button onClick={()=>scrollToSection('join')}>Requisitar</button>
                         </div>
                     </div>



                     <div className="item gold">
                         <span className="name">GOLD</span>
                         <span className="price"><label className="value">4.000MT</label><label className="per">/mês</label></span>
                         
                        <div className="discount">Desconto de 1 mês no pagamento anual</div>
                         <div className="features">
                            <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Contratos: Ilimitados</span>
                            </div>
                            
                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Clientes: Ilimitados</span>
                             </div>
                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Gestores: Ilimitados</span>
                             </div>
                           

                            <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Operadores: Ilimitados</span>
                            </div>
                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Upload de documentos</span>
                             </div>


                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Contratos digitais</span>
                             </div>

                              <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Módulo de gestão de risco (Clientes na Blacklist)</span>
                             </div>

                             <div> 
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Módulo de gestão de inventário</span>
                             </div>



                             <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Notificações por Email</span>
                            </div>

                            <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Notificações por SMS</span>
                            </div>
                            <div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                                    <span>Sistema personalizado</span>
                            </div>

                          
                           
                         </div>
                         <div className="btn-div">
                            <button onClick={()=>scrollToSection('join')}>Requisitar</button>
                         </div>
                     </div>


                    
                 </div>

            </div>



            <Join/>


            <Faq/>



            <Footer/>



     </div>
  )
}

export default Home