import React from 'react'
import BaiscTable from '../tables/default-table'
import { useData } from '../../contexts/DataContext'
import { useAuth } from '../../contexts/AuthContext'

export default function PrintWarranties({formData}) {

  const data=useData()
  const {selectedMicrocredit,user}=useAuth()
  let client=data._clients_list.filter(i=>i.id==formData.client_id)[0] || {}
  let micro=user.microcredits.filter(i=>i.id==selectedMicrocredit)[0]

  const addFiveYearsBR = dateString => {
    let date = new Date(dateString);
    date.setFullYear(date.getFullYear() + 5);
    return date.toLocaleDateString('pt-BR');
};


  return (
    <div  print="true" className={`${data.printingDoc=="warranties" ? '_print_div' :''} _warranties_print  !text-[12px] w-full left-0 top-0 justify-center`} style={{zIndex:999}}>
    
        <style>
            {data.printingDoc=="warranties" && `

                ._warranties_print *{
                font-size: 12px !important;
                }
                table.paging,  table.paging thead td {
                    height: 140px !important;
                    border:0 !important;
                }

            @page {
                size: letter;
                margin: .2in;
            }
            `}
        </style>

        <div class="_header">
         {micro.logo_filename && <div className="flex w-full justify-center mb-1">
             <img className="h-[90px]  object-cover" src={`${process.env.REACT_APP_BASE_URL}/uploaded_files/`+micro.logo_filename}/>
        </div>}
        <h2 className="text-center text-[18px] font-semibold py-4 !border-t mx-auto">FORMULÁRIO DE BENS DE GARANTIA</h2>

     
        </div>

        <table className="paging"><thead><tr><td>&nbsp;</td></tr></thead><tbody><tr><td>
        <div className=" bg-white  mt-2">

            

        <div className="mb-4">
            <p className="font-bold">
            Nome Completo: <span className="font-normal">{client.name}</span>
            </p>
            <p className="font-bold">
             Tipo de Identificação: <span className="font-normal">Bilhete de Identidade</span>, 
            <span className="font-bold"> Nº Identificação: </span><span className="font-normal">{client.identity_number || '-'}</span>
            </p>
            <p className="font-bold">
            Data de Emissão: <span className="font-normal">{client.date_of_issuance_of_the_identity_card && client.date_of_issuance_of_the_identity_card!="0000-00-00" ? client.date_of_issuance_of_the_identity_card.split('T')[0].split('-')?.reverse()?.join('/') : '-'}</span>, 
            <span className="font-bold"> Data de Validade: </span> <span className="font-normal">{client.date_of_issuance_of_the_identity_card && client.date_of_issuance_of_the_identity_card!="0000-00-00" ? addFiveYearsBR(client.date_of_issuance_of_the_identity_card) : '-' }</span>.
            </p>
            <p className="font-bold">
            Bairro: <span className="font-normal">{client.neighborhood_name || '-'}</span>, 
            <span className="font-bold"> Rua/Avenida: </span><span className="font-normal">{client.avenue || '-'}</span>.
            </p>
            <p className="font-bold">
            {/** Prédio nº: <span className="font-normal">.</span>,  */}
            {/**Andar: <span className="font-normal">.</span>,  */}
            Quarteirão: <span className="font-normal">{client.block_number || '-'}</span>, 
            Casa Nº: <span className="font-normal">{client.house_number || '-'}</span>, 
            </p>
            <p className="font-bold">
            Contacto: <span className="font-normal">{client.primary_contact || '-'}</span>, 
            <span className="font-bold"> Contacto Alternativo: </span>
            <span className="font-normal">{client.secondary_contact || '-'}</span>
            </p>
        </div>


        <div className="w-full my-6 bg-white text-black">
            {/* Declaration */}
            <p className="mt-4">
                Declaram que são os únicos possuidores e legítimos proprietários dos bens elencados na tabela abaixo, 
                que constituem como garantia patrimonial para assegurar o pontual e integral cumprimento de todas as 
                obrigações pecuniárias, assumidas nos termos do contrato de mútuo celebrado com a {micro.name}, 
                em , e bem assim, todas as despesas ou encargos administrativos, judiciais ou extrajudiciais, incluindo 
                honorários de advogados, em que a {micro.name}, haja de incorrer para cobrança do seu crédito.
            </p>
        </div>

        
               
            <div className="overflow-x-auto">
                <table className="w-full border border-black">
                    <thead>
                    <tr className="bg-[#95d4d4] border border-black">
                        <th className="border border-black px-2 py-1 text-left font-bold">Qty</th>
                        <th className="border border-black px-2 py-1 text-left font-bold">Designação</th>
                        <th className="border border-black px-2 py-1 text-left font-bold">Modelo</th>
                        <th className="border border-black px-2 py-1 text-left font-bold">Observações</th>
                        <th className="border border-black px-2 py-1 text-left font-bold">Valor</th>
                    </tr>
                    </thead>
                    <tbody>
                    
                    {formData.contract_assets.map((i,_i)=>(
                        <tr className="border border-black">
                        <td className="border border-black px-2 py-1">{_i+1}</td>
                        <td className="border border-black px-2 py-1">{i.description}</td>
                        <td className="border border-black px-2 py-1">{i.model}</td>
                        <td className="border border-black px-2 py-1">{i.notes || '-'}</td>
                        <td className="border border-black px-2 py-1">{i.amount ? data._cn(i.amount) : '-'}</td>
                        </tr>
                    ))}
                    
                    <tr className="border border-black">
                        <td className="border border-black px-2 py-1" colSpan="4">N° total de bens</td>
                        <td className="border border-black px-2 py-1">{formData.contract_assets.length}</td>
                    </tr>  
                    <tr className="border border-black">
                        <td className="border border-black px-2 py-1" colSpan="4">Valor total de bens</td>
                        <td className="border border-black px-2 py-1">{data._cn(formData.contract_assets.map(i=>parseFloat(i.amount || 0)).reduce((acc, curr) => acc + curr, 0))}</td>
                    </tr>
                    </tbody>
                </table>
                </div>
 

                <p className="my-4">
                E por ser verdade, certifico que todas as informacões relativas aos bens elecandos na tabela acima prestadas a {micro.name},
                correspondem a verdade, e que os mesmos bens se encontram localizados no <span className="font-bold">Bairro:</span>{client.neighborhood_name || '-'}, <span className="font-bold">Avenida:</span> {client.avenue || '-'}, <span className="font-bold">Quarteirão:</span> {client.block_number || '-'}, <span className="font-bold">Casa Nº:</span> {client.house_number || '-'}. 
                </p>


                <div className="signatures">

                <span className="flex justify-center w-full pt-4 mb-6">{new Date().toISOString().split('T')[0].split('-')?.reverse()?.join('/')}</span>

                
                <div className="overflow-x-auto">
                <table className="w-full border border-black">
                    <tbody>
                    {/* Mutuário */}
                    <tr className="bg-[#95d4d4] border border-black font-bold">
                        <td className="border border-black px-4 py-1 w-1/2">Mutuário</td>
                        <td className="border border-black px-4 py-1 w-1/2">Co-Assinante (Mutuário)</td>
                    </tr>
                    <tr>
                        <td className="border border-black px-4 py-5"></td>
                        <td className="border border-black px-4 py-5"></td>
                    </tr>

                    {/* Avalista */}
                    <tr className="bg-[#95d4d4] border border-black font-bold">
                        <td className="border border-black px-4 py-1">Avalista</td>
                        <td className="border border-black px-4 py-1">Co-Assinante (Avalista)</td>
                    </tr>
                    <tr>
                        <td className="border border-black px-4 py-5"></td>
                        <td className="border border-black px-4 py-5"></td>
                    </tr>

                    {/* Gestor de Crédito */}
                    <tr className="bg-[#95d4d4] border border-black font-bold">
                        <td className="border border-black px-4 py-1" colSpan="2">Gestor de Crédito</td>
                    </tr>
                    <tr>
                        <td className="border border-black px-4 py-5" colSpan="2"></td>
                    </tr>
                    </tbody>
                </table>
                </div>

                </div>

            

             


              </div>

              </td></tr></tbody><tfoot><tr><td>&nbsp;</td></tr></tfoot></table>
                  {/* Footer */}
                  <div className="_footer">
                  <div className="p-4 border-t-2">
                    <p><span>Endereço:</span><label className="text-gray-500">{micro.location}</label></p>
                    <p><span>Contacto:</span><label className="text-gray-500"> {micro.primary_contact || '-'}</label></p>
                    <p><span>NUIT:</span><label className="text-gray-500"> {micro.nuit}</label></p>
                    {micro.website_link && <p><span>Website:</span> <a href={`${micro.website_link}`} className="text-gray-500 underline">{micro.website_link}</a></p> }
                    {micro.email && <p><span>e-Mail:</span> <a href={`mailto:${micro.email}`} className="text-gray-500 underline">{micro.email}</a></p>}
                 </div>
               </div>

    </div>
  )
}
