import React from 'react'
import ButtonLoader from '../loaders/button'
import toast from 'react-hot-toast'

export default function InsertReceiverEmail({PrintLoanSummary,show,setShow,loading,receiverEmail,setReceiverEmail}) {
  
 return (
 <div style={{zIndex:999}} id="authentication-modal" tabindex="-1" aria-hidden="true" class={`${!show ? 'opacity-0 pointer-events-none translate-y-[10px]':''} transition-all ease-in delay-75 overflow-y-auto overflow-x-hidden flex  fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100vh] bg-[rgba(0,0,0,0.3)] max-h-full`}>
    <div class="relative p-4 w-full max-w-md max-h-full">
        <div class="relative bg-white rounded-lg shadow-sm">
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t border-gray-200">
                <h3 class="text-xl font-semibold text-gray-900">
                    Insira o email para o envio dos resultados em documento PDF
                </h3>
                <button onClick={()=>setShow(false)} type="button" class={`end-2.5 ${loading ? 'opacity-0 pointer-events-none':''} shrink-0 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center`} data-modal-hide="authentication-modal">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <div class="p-4 md:p-5">
                <div class="space-y-4" action="#">
                    <div>
                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Email</label>
                        <input value={receiverEmail} onChange={(e)=>setReceiverEmail(e.target.value)} type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Email do destinatário" required />
                    </div>
                 
                    <button onClick={()=>{
                        
                        if(!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(receiverEmail.trim()))){
                            toast.error('Email inválido')
                            return
                        }

                        PrintLoanSummary(receiverEmail)
                    }} type="submit" class={`text-white ${loading ? 'pointer-events-none':''} bg-custom_blue-400 flex items-center justify-center focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center`}>
                            {loading && <ButtonLoader/>}
                            <span>{loading ? 'A carregar...' : 'Enviar'}</span>
                    </button>

                    
                   
                </div>
            </div>
        </div>
    </div>
</div> 

  )
}
