
import '../../css/main.css'
import './style.css'
import Menu from '../../common/menu.js';
import TopBar from "../../common/topBar";
import { useEffect, useState } from 'react';
import CreateAccount from './create-accounts.js'
import CreateAccountCategory from './create-account-category.js'
import DeleteDialog1 from './delete-dialog-1.js'
import DeleteDialog2 from './delete-dialog-1.js'
import BaiscTable from '../../tables/default-table.js';
import { useAuth } from '../../../contexts/AuthContext.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useData } from '../../../contexts/DataContext.js';
import DefaultLayout from '../../layouts/DefaultLayout.js';


export default function Create() {
const [createInterestRates, setCreateInterestRates] = useState(false);
const [createPaymentInterest, setCreatePaymentInterest] = useState(false);

const [edit1, setEdit1] = useState(null);
const [del1, setDel1] = useState(null);
const [edit2, setEdit2] = useState(null);
const [del2, setDel2] = useState(null);

const {pathname} = useLocation()
const {user,selectedMicrocredit,userInfo,userRole} = useAuth()
const data=useData()
const navigate=useNavigate()

let required_data=['accounts','account_categories']
const [updateFilters,setUpdateFilters]=useState(null)

useEffect(()=>{ 
   if(!user) return
   data.handleLoaded('remove',required_data)
   data._get(required_data,{
      accounts:{microcredit_id:selectedMicrocredit},
      account_categories:{microcredit_id:selectedMicrocredit}
   }) 
 },[user,pathname,updateFilters])


 useEffect(()=>{
   data.handleLoaded('remove',required_data)
 },[updateFilters])

 useEffect(()=>{
   if(data.updateTable){
        data.setUpdateTable(null)
        data.handleLoaded('remove',required_data)
        data._get(required_data,{
            accounts:{microcredit_id:selectedMicrocredit},
            account_categories:{microcredit_id:selectedMicrocredit}
        }) 
   }
},[data.updateTable])


useEffect(()=>{
             if((userRole=="operator" && !userInfo?.permissions?.cash_management_settings?.includes('read'))){
               navigate('/dashboard')
             }
 },[userInfo])




return (
    <>
      <DefaultLayout>
      <main className="dashboard-container">
      {<CreateAccount showD={(createInterestRates || edit1) ? true : false}  create={createInterestRates} setCreate={setCreateInterestRates} setEdit={setEdit1} edit={edit1} del={del1}/>}
      {<CreateAccountCategory showD={(createPaymentInterest || edit2) ? true : false}  create={createPaymentInterest} setCreate={setCreatePaymentInterest} setEdit={setEdit2} edit={edit2} del={del2}/>}

      {<DeleteDialog1 showD={del1 ? true : false} del={del1}  setDel={setDel1}/>}
      {<DeleteDialog2 showD={del2 ? true : false} del={del2}  setDel={setDel2}/>}

      <div className="dashboard">
      <Menu/>
      <div className="main-dashboard">
            <TopBar activePage={'Definições de contrato'} lastPage={{name:'Contratos',pathname:'/loans'}}/>
            <div className="center">
                  <div className="loan-container">
                              <div className="top-title">Definições de caixa</div>
                              <div className="section">
                                    <div className="sub-title">Contas {(userRole!="operator" || userInfo?.permissions?.cash_management_settings?.includes('create')) && <button onClick={()=>setCreateInterestRates(true)}>Adicionar conta</button>}</div>
                                    <div className="_table max-w-[700px]">
                                    <BaiscTable canAdd={user?.role=="admin"} addRes={()=>{
                                          
                                    }}  loaded={data._loaded.includes('accounts')} header={[
                                          <BaiscTable.MainActions sigleSelection={true} data={data} options={{
                                          deleteFunction:'default',
                                          deleteUrl:'api/accounts/delete'}
                                          } items={data._accounts || []}/>,
                                          'Nome',
                                          'Descrição',
                                          'Tipo',
                                          ]
                                    }

                                    body={(data._accounts || []).map((i,_i)=>(
                                          
                                                <BaiscTable.Tr>
                                                <BaiscTable.Td w={30}>
                                                      <BaiscTable.Actions hide={true} sigleSelection={true}  data={data}  options={{
                                                            deleteFunction:'default',
                                                            deleteUrl:'api/accounts/delete',
                                                            id:i.id}
                                                      }/>
                                                </BaiscTable.Td>
                                                <BaiscTable.Td onClick={()=>setEdit1(i)}>{i.name}</BaiscTable.Td>
                                                <BaiscTable.Td onClick={()=>setEdit1(i)}>{i.description || '-'}</BaiscTable.Td>
                                                <BaiscTable.Td onClick={()=>setEdit1(i)}>{i.type=='bank-account' ? 'Bancária':  i.type =="mobile-account" ? 'Movél'  : 'Outro'}</BaiscTable.Td>

                                          </BaiscTable.Tr>
                                          ))}
                                    />
                                 </div>
                              </div>


                              <div className="section">
                                    <div className="sub-title">Categorias de conta{(userRole!="operator" || userInfo?.permissions?.cash_management_settings?.includes('create')) && <button onClick={()=>setCreatePaymentInterest(true)}>Adicionar categoria</button>}</div>
                                    <div className="_table max-w-[700px]">
                                    <BaiscTable canAdd={user?.role=="admin"} addRes={()=>{
                                          
                                    }} loaded={data._loaded.includes('account_categories')} header={[

                                          <BaiscTable.MainActions sigleSelection={true} data={data} options={{
                                                deleteFunction:'default',
                                                deleteUrl:'api/account-categories/delete'}
                                          }
                                          
                                          items={data._account_categories || []}/>,
                                                'Nome',
                                          ]

                                    }

                                    body={(data._account_categories || []).map((i,_i)=>(
                                                <BaiscTable.Tr first={_i==0}>
                                                      <BaiscTable.Td w={30}>
                                                            <BaiscTable.Actions hide={true} sigleSelection={true}  data={data}  options={{
                                                                  deleteFunction:'default',
                                                                  deleteUrl:'api/account-categories/delete',
                                                                  id:i.id}
                                                            }/>
                                                      </BaiscTable.Td>
                                                <BaiscTable.Td onClick={()=>setEdit2(i)}>{i.name}</BaiscTable.Td>
                                          </BaiscTable.Tr>
                                          ))}
                                    />
                                 </div>

                              </div>
                  </div>
            </div>
      </div>
      </div>
      </main>
      </DefaultLayout>
    </>
  );
}
