
import {useLocation,useNavigate} from 'react-router-dom'
import { useState,useEffect } from 'react';
import { useAuth  } from '../../contexts/AuthContext';
import { useData } from '../../contexts/DataContext';


 const Menu = () => {

  const {user,selectedMicrocredit,setSelectedMicrocredit,userRole,userInfo} = useAuth()
  const {menuOpen, setMenuOpen} = useData()
  const data=useData()
  const [openDropDown, setOpenDropDown] = useState(''); 
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname

  function getIcon(icon,active){
    const images={
        dashboard:(
            <svg  className={`${active ? 'fill-custom_blue-400':''} `} xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24"><path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z"></path></svg>
        ),
        users:(
            <svg className={`${active ? 'fill-custom_blue-400':''} `} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"></path></svg>
        ),
        more:(
           <svg className={`${active ? 'fill-custom_blue-400':''} `} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M240-400q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm240 0q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm240 0q-33 0-56.5-23.5T640-480q0-33 23.5-56.5T720-560q33 0 56.5 23.5T800-480q0 33-23.5 56.5T720-400Z"/></svg>
        ),
        loans:(
          <svg className={`${active ? 'fill-custom_blue-400':''} `} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-200h80v-40h40q17 0 28.5-11.5T600-280v-120q0-17-11.5-28.5T560-440H440v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T360-520v120q0 17 11.5 28.5T400-360h120v40H360v80h80v40ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-560v-160H240v640h480v-480H520ZM240-800v160-160 640-640Z"></path></svg>
        ),
        cash_management:(
          <svg className={`${active ? 'fill-custom_blue-400':''} `} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
        ),
        microcredits:(
          <svg className={`${active ? 'fill-custom_blue-400':''} `} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960"><path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z"></path></svg>
        )
    }
    return images[icon]
  }
 const menuItems = [

     {name:'Dashboard',path:'/dashboard',paths:['dashboard'],field:'dashboard',icon:'dashboard',show:true},
     
     {name:'Microcreditos',path:'/microcredits',paths:['/microcredits'],field:'microcredits',icon:'microcredits',show:userRole=="admin"},
     {name:'Utilizadores',path:'/clients',paths:['/clients','/operators'],field:'users',icon:'users',sub_menus:[
        {name:'Clientes',path:'/clients',paths:['/clients'],show:true},
        {name:'Operadores',path:'/operators',paths:['/operators'],show:userRole!="operator"},
        {name:'Gestores',path:'/mangers',paths:['/managers'],show:userRole=="admin"},
     ],show:userRole!="client"},


     {name:'Contratos',path:'/loans',paths:['/loans','/loans/create','loan/:id','loans/template','/loans/settings'],field:'loans',icon:'loans',sub_menus:[
        {name:'Contratos',path:'/loans',paths:['/loans'],show:true},
        {name:'Criar',path:'/loans/create',paths:['/loans/create'],show:true},
        {name:'Definições',path:'/loans/settings',paths:['/loans/settings'],show:userRole!="operator"},
        {name:'Modelo',path:'/loans/template',paths:['/loans/template'],show:userRole!="client"},
     ],show:userRole!="client" && userRole!="admin"},  


     {name:'Caixa',path:'/cash-management',paths:['/cash-management','/cash-management/settings','/cash-management/stats'],field:'cash_management',icon:'cash_management',sub_menus:[
        {name:'Caixa',path:'/cash-management',paths:['/cash-management'],show:true}, 
        {name:'Relatórios',path:'/cash-management/stats',paths:['/cash-management/stats'],show:true},
        {name:'Definições',path:'/cash-management/settings',paths:['/cash-management/settings'],show:userRole!="operator"},
     ],show:userRole!="client" && userRole!="admin"},  

     {name:'Mais',path:'/more',paths:['/more'],field:'more',icon:'more',show:true},

  ]




  function checkActive(item,isSub){
    if(!item) return false
    let macth=false
    if(isSub || !item.sub_menus){
      item.paths.forEach(p=>{
           let _pathname=p.includes(':') ? pathname.split('/').filter((_,_i)=>_i!=pathname.split('/').length - 1).join('') : pathname.split('/').join('')
           let path=p.split('/').join('').split(':')[0]

           if(path == _pathname) {
             macth=true
           }
      })
      return macth
    }else{
      item.sub_menus.forEach(sub=>{
           sub.paths.forEach(p=>{
             let _pathname=p.includes(':') ? pathname.split('/').filter((_,_i)=>_i!=pathname.split('/').length - 1).join('') : pathname.split('/').join('')
             let path=p.split('/').join('').split(':')[0]

             if(path == _pathname) {
                 macth=true
             }
           })
      })
      return macth
    }
}

  function changeOpenDropdown(link,to){
        if(link == openDropDown){
              setOpenDropDown('')
          }else{
              setOpenDropDown(link)
              go_to(to,'hasSubmenus')
        } 
  }

   useEffect(()=>{
       setOpenDropDown(pathname)
   },[pathname])

   function go_to(path,hasSubmenus) {
       data.currentPage=path
       navigate(path)
       if(!hasSubmenus)  setMenuOpen(false)
   }


   const [mobileMenusOpen,setMobileMenusOpen]=useState([])

   function closeAndOpen(path){
        if(mobileMenusOpen.includes(path)){
            setMobileMenusOpen(mobileMenusOpen.filter(i=>i!=path))
        }else{
            data._showPopUp('mobile_menu')
            setMobileMenusOpen([path])
        }
   }


   const operator_first_links={

        users:userInfo?.permissions?.clients?.includes('read') ? '/clients' : userInfo?.permissions?.operators?.includes('read') ? '/operators' : null,
        contracts:userInfo?.permissions?.contracts?.includes('read') ? '/loans' : userInfo?.permissions?.contracts?.includes('create') ? '/loans/create' : userInfo?.permissions?.contract_settings?.includes('read') ? '/loans/settings' : userInfo?.permissions?.contract_template?.includes('update') ? '/loans/template/contract' : userInfo?.permissions?.clearance_certificate_template?.includes('update') ? '/loans/template/certificate' : userInfo?.permissions?.debt_confession_agreement_template?.includes('update') ? '/loans/template/agreement' : null,
        cash_management:userInfo?.permissions?.cash_management_transactions?.includes('read') ? '/cash-management' : userInfo?.permissions?.cash_management_reports?.includes('read') ? '/cash-management/stats' : userInfo?.permissions?.cash_management_settings?.includes('read') ? '/cash-management/settings' :  null,
        inventory:userInfo?.permissions?.inventory?.includes('read') ? '/inventory' : userInfo?.permissions?.inventory_settings?.includes('read') ? '/inventory/settings' : null,
        risk_management:userInfo?.permissions?.risk_management?.includes('read') ? '/risk-management/clients' : userInfo?.permissions?.blacklist?.includes('read') ? '/risk-management/blacklist' : userInfo?.permissions?.risk_management_settings?.includes('read')  ? '/risk-management/settings' : null,
        legal_guidelines:userInfo?.permissions?.legal_guidelines?.includes('read') ? '/legal/contracts' : null,
        payments:userInfo?.permissions?.payments?.includes('read') ? '/payments' : null

   }
 


  return (
    <>
<div className={` -bottom-2 md:hidden _mobile_menu fixed  left-0 w-full bg-white flex items-center border-t border-t-gray-200 rounded-t-[15px] cursor-pointer shadow-sm`} style={{zIndex:999}}>
             <div className="w-full px-2 py-4 flex items-center justify-around relative">

                {menuItems.filter(i=>i.show).map((item, index)=>(
                   <div>
                        {item.sub_menus && <div className={`absolute shadow bottom-[100%] border-t-gray-200 rounded-t-[15px] w-full left-0 ${item.path}  ${mobileMenusOpen.includes(item.path) ? '':'hidden'}`}>
                                <div className={`rounded-[0.3rem]  bg-gray-300 p-[10px] flex flex-col`}>
                                    {item.sub_menus.filter(i=>i.show).map(i=>(
                                      <span onClick={()=>{
                                        setMenuOpen(false)
                                        setMobileMenusOpen([])
                                        navigate(i.path)
                                      }}
                                          className={`text-[1rem]  py-2 flex  ${checkActive(i,true) ? 'text-honolulu_blue-500 font-medium':' opacity-80'} hover:text-honolulu_blue-500`}
                                      >
                                          {i.name} 
                                      </span>
                                    ))}
                                </div>
                       </div>}

                    <div  onClick={()=>{

                            data._closeAllPopUps()
                            setMobileMenusOpen([])
                            if(item.field=="more"){
                              setMenuOpen(true)
                              return
                            }

                            setMenuOpen(false)
                            if(item.sub_menus){
                              closeAndOpen(item.path)
                            }else{
                              navigate(item.path)
                            }
                            
                    }} key={index} className={`flex-col flex items-center`}  >
                        {getIcon(item.icon,((checkActive(item) && !menuOpen) || (menuOpen && item.field=="more")))}
                        <span className={`${((checkActive(item) && !menuOpen) || (menuOpen && item.field=="more")) ? 'text-custom_blue-400 font-medium':''} text-[0.8rem]`}> {item.name}</span>
                    </div>
                    </div>
                ))}
             </div>

    </div>


    <div className={`left-menu ${menuOpen ? 'open' :''}`}>
    <div className="_c">
    <div className="top" style={{marginBottom:0}}>
            
            <div>

            {user.microcredits?.filter(i=>i.id==selectedMicrocredit)?.[0]?.logo_filename && <div className="flex justify-center mb-2">
                <img className="w-[50px] h-[50px] rounded-full border" src={`${process.env.REACT_APP_BASE_URL}/uploaded_files/`+user.microcredits?.filter(i=>i.id==selectedMicrocredit)?.[0]?.logo_filename}/>
            </div>}

            {(user?.role!="admin" && user?.microcredits.length==1) && <h1>{user?.microcredits[0]?.name}</h1>}

            {(user?.role!="admin" && user?.microcredits.length!=1) &&  <select  onChange={(e)=>{
                    navigate('/dashboard')
                    setSelectedMicrocredit(e.target.value)
                    setMenuOpen(false)

            }} value={selectedMicrocredit} id="countries" class="bg-gray-50 border text-[0.8rem] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            {user?.microcredits?.map(i=>(
                <option value={i.id}>{i.name}</option>
            ))}
            </select>}

            {(user?.role=="admin") && <h1>{"VLMS"}</h1>}

            </div>

            <div className="w-full">
                <span className="font-normal text-[0.8rem] text-gray-500">{userRole=="admin" ? 'Admin' : userRole=="manager" ? 'Gestor' : userRole=="operator" ? "Operador":'Cliente'}</span>
           </div>
    </div>

   

    <div className="btn-close-menu" onClick={()=>setMenuOpen(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"></path></svg>
    </div>

   

    <div className="center">

       <div  onClick={()=>go_to('/dashboard')}  className={`_nav-link ${pathname=='/dashboard' || pathname=='/'? 'active' : ''}`} link_page="dashboard">
                <div className="main-link">
                <svg xmlns="http://www.w3.org/2000/svg" width="24"  viewBox="0 0 24 24"><path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z"></path></svg>
                <span>Dashboard</span>
                </div>
        </div>

        {user && userRole == "admin" && <div onClick={()=>go_to('/microcredits')}   className={`_nav-link ${pathname=='/microcredits' ? 'active' : ''}`}  link_page="microcredits">
                      <div className="main-link">
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960"><path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z"/></svg>
                          <span>Microcreditos</span>
                      </div>
         </div>}

   

        {user && (userRole=="manager" || userRole=="admin" || operator_first_links?.users) && (<div  className={`_nav-link  ${openDropDown=='users' ? 'open' : ''}  ${pathname=='/clients' || pathname=='/operators' || pathname=='/managers'  ? 'open active' : ''} `} link_page="users" >
                    <div className="main-link" onClick={() => changeOpenDropdown('users',userRole=="operator" ? operator_first_links?.users : '/clients')}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"/></svg>
                        <span>Utilizadores</span>
                        <label className="expand"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg></label>
                    </div>
      
                    <div className="dropdown-options">
                           {(userRole != "operator" || userInfo?.permissions?.clients?.includes('read')) &&  <span onClick={()=>go_to('/clients')}  className={`o ${pathname=='/clients' ? 'active' : ''}`}>
                                Clientes
                           </span>}
                           {(userRole != "operator" || userInfo?.permissions?.operators?.includes('read')) && <span onClick={()=>go_to('/operators')} className={`o ${pathname=='/operators' ? 'active' : ''}`}>
                                Operadores
                           </span>}
                           {userRole == "admin" && <span onClick={()=>go_to('/managers')} className={`o ${pathname=='/managers' ? 'active' : ''}`}>
                                Gestores
                           </span>}
                    </div>
        </div>)}


        {user && (userRole=="manager"  || userRole=="client" || operator_first_links.contracts) && (<div  className={`_nav-link  ${openDropDown=='loans' ? 'open' : ''}  ${pathname=='/loans/create' || pathname.startsWith('/loans/') || pathname=='/loans/settings' || pathname=='/loans' || pathname=="/loans/template"  ? 'open active' : ''} `} link_page="loans" >
                           
                            <div className="main-link" onClick={() => changeOpenDropdown('loans',userRole=="operator" ? operator_first_links.contracts : '/loans')}> 
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-200h80v-40h40q17 0 28.5-11.5T600-280v-120q0-17-11.5-28.5T560-440H440v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T360-520v120q0 17 11.5 28.5T400-360h120v40H360v80h80v40ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-560v-160H240v640h480v-480H520ZM240-800v160-160 640-640Z"/></svg>
                                <span>Gestão de contratos</span>
                                <label className="expand"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg></label>
                            </div>
              
                            <div className="dropdown-options">
                                   {(userRole != "operator" || userInfo?.permissions?.contracts?.includes('read')) && <span  onClick={()=>go_to('/loans')} className={`o ${pathname=='/loans' ? 'active' : ''}`}>
                                       Contratos
                                   </span>}
                                   {user && (userRole=="manager" || (userRole=="operator" && userInfo?.permissions?.contracts?.includes('create'))) && <span onClick={()=>go_to('/loans/create')}  className={`o ${pathname=='/loans/create' ? 'active' : ''}`}>
                                       Criar
                                   </span>}
                                  {user && (userRole=="manager" || (userRole=="operator" && userInfo?.permissions?.contract_settings?.includes('read'))) && <span onClick={()=>go_to('/loans/settings')} className={`o ${pathname=='/loans/settings' ? 'active' : ''}`}>
                                       Definições
                                  </span>}
                                  {user && userRole == "client" && <span className={`o ${pathname=='/loans/settings' ? 'active' : ''}`}>
                                       Solicitar
                                  </span>}

                                  {user && (userRole=="manager" ||  (userRole == "operator"  && userInfo?.permissions?.contract_template?.includes('update'))) && <span onClick={()=>go_to('/loans/template/contract')}  className={`o ${pathname=='/loans/template/contract' ? 'active' : ''}`}>
                                       Modelo de contrato
                                  </span>}
                                  {user && (userRole=="manager" ||  (userRole == "operator"  && userInfo?.permissions?.clearance_certificate_template?.includes('update'))) && <span onClick={()=>go_to('/loans/template/certificate')}  className={`o ${pathname=='/loans/template/certificate' ? 'active' : ''}`}>
                                       Modelo de certidão de quitação
                                  </span>}
                                
                                  {user && (userRole=="manager" ||  (userRole == "operator"  && userInfo?.permissions?.debt_confession_agreement_template?.includes('update'))) && <span onClick={()=>go_to('/loans/template/agreement')}  className={`o ${pathname=='/loans/template/agreement' ? 'active' : ''}`}>
                                       Modelo de termo de confissão de dívida
                                  </span>}
                           </div>
         </div>)}


         {user && (userRole=="manager" || (operator_first_links.cash_management)) && (<div  className={`_nav-link  ${openDropDown=='cash-management' ? 'open' : ''}  ${pathname=='/cash-management/stats' || pathname=='/cash-management/settings' || pathname=='/cash-management'  ? 'open active' : ''} `} link_page="cash-management" >
                            <div className="main-link" onClick={() => changeOpenDropdown('cash-management',userRole=="operator" ? operator_first_links.cash_management : '/cash-management')}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                                <span>Gestão de caixa</span>
                                <label className="expand"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg></label>
                            </div>
              
                            <div className="dropdown-options" d={'/cash-management'}>
                                   {(userRole != "operator" || userInfo?.permissions?.cash_management_transactions?.includes('read')) && <span  onClick={()=>go_to('/cash-management')} className={`o ${pathname=='/cash-management' ? 'active' : ''}`}>
                                       Caixa
                                   </span>}
                                   {(userRole != "operator" || userInfo?.permissions?.cash_management_reports?.includes('read')) && <span  onClick={()=>go_to('/cash-management/stats')} className={`o ${pathname=='/cash-management/stats' ? 'active' : ''}`}>
                                       Relatórios
                                   </span>}
                                   {(userRole != "operator" || userInfo?.permissions?.cash_management_settings?.includes('read')) && <span  onClick={()=>go_to('/cash-management/settings')} className={`o ${pathname=='/cash-management/settings' ? 'active' : ''}`}>
                                       Definições
                                   </span>}
                           </div>
         </div>)}



         {user && ((userRole=="manager" || operator_first_links.inventory) && (user.microcredits?.filter(i=>i.id==selectedMicrocredit)[0]?.plan=="gold" || user.microcredits?.filter(i=>i.id==selectedMicrocredit)[0]?.plan=="premium")) && (<div  className={`_nav-link  ${openDropDown=='inventory' ? 'open' : ''}  ${pathname=='/inventory' || pathname=='/inventory/settings' || pathname=='/inventory'  ? 'open active' : ''} `} link_page="inventory" >
                            <div className="main-link" onClick={() => changeOpenDropdown('inventory',userRole=="operator" ? operator_first_links.inventory :  '/inventory')}>
                               <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z"/></svg>
                                <span>Gestão de inventário</span>
                                <label className="expand"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg></label>
                            </div>
              
                            <div className="dropdown-options" d={'/inventory'}>
                                   {(userRole != "operator" || userInfo?.permissions?.inventory?.includes('read')) && <span  onClick={()=>go_to('/inventory')} className={`o ${pathname=='/inventory' ? 'active' : ''}`}>
                                      Bens
                                   </span>}

                                   {(userRole != "operator" || userInfo?.permissions?.inventory_settings?.includes('read')) && <span  onClick={()=>go_to('/inventory/settings')} className={`o ${pathname=='/inventory/settings' ? 'active' : ''}`}>
                                      Definições
                                   </span>}

                            </div>
         </div>)}


         {user && (((userRole=="manager" || operator_first_links.risk_management) && (user.microcredits?.filter(i=>i.id==selectedMicrocredit)[0]?.plan=="gold" || user.microcredits?.filter(i=>i.id==selectedMicrocredit)[0]?.plan=="premium")) || userRole=="admin") && (<div  className={`_nav-link  ${openDropDown=='risk-management' ? 'open' : ''}  ${pathname=='/risk-management/blacklist' || pathname=='/risk-management/clients'  || pathname=="/risk-management/settings" ? 'open active' : ''} `} link_page="risk-management">
                            
                            <div className="main-link" onClick={() => changeOpenDropdown('risk-management',userRole=="operator" ? operator_first_links.risk_management : '/risk-management/clients')}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h168q13-36 43.5-58t68.5-22q38 0 68.5 22t43.5 58h168q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm280-590q13 0 21.5-8.5T510-820q0-13-8.5-21.5T480-850q-13 0-21.5 8.5T450-820q0 13 8.5 21.5T480-790ZM200-200v-560 560Z"/></svg>
                                <span>Gestão de risco</span>
                                <label className="expand"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg></label>
                            </div>
              
                            <div className="dropdown-options" d={'/cash-management'}>
                                   {(userRole != "operator" || userInfo?.permissions?.risk_management?.includes('read')) && <span  onClick={()=>go_to('/risk-management/clients')} className={`o ${pathname=='/risk-management/clients' ? 'active' : ''}`}>
                                       {userRole=="admin" ? 'Clientes' :'Meus clientes'}
                                   </span>}
                                   {(userRole != "operator" || userInfo?.permissions?.blacklist?.includes('read')) && <span  onClick={()=>go_to('/risk-management/blacklist')} className={`o ${pathname=='/risk-management/blacklist' ? 'active' : ''}`}>
                                       Blacklist
                                   </span>}
                                   {user && (userRole=="manager" || (userRole=="operator" && userInfo?.permissions?.risk_management_settings?.includes('read'))) &&  <span onClick={()=>go_to('/risk-management/settings')} className={`o ${pathname=='/risk-management/settings' ? 'active' : ''}`}>
                                      Definições
                                   </span>}
                           </div>

         </div>)}

         {user && (userRole=="manager" || operator_first_links.legal_guidelines) && (<div  className={`_nav-link  ${openDropDown=='payments' ? 'open' : ''}  ${pathname=='/legal/contracts' || pathname=='/legal/clients'  ? 'open active' : ''}`} link_page="plans">
                    
                     <div className="main-link" onClick={() => changeOpenDropdown('legal','/legal/contracts')}>    
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000"><path d="M80-120v-80h360v-447q-26-9-45-28t-28-45H240l120 280q0 50-41 85t-99 35q-58 0-99-35t-41-85l120-280h-80v-80h247q12-35 43-57.5t70-22.5q39 0 70 22.5t43 57.5h247v80h-80l120 280q0 50-41 85t-99 35q-58 0-99-35t-41-85l120-280H593q-9 26-28 45t-45 28v447h360v80H80Zm585-320h150l-75-174-75 174Zm-520 0h150l-75-174-75 174Zm335-280q17 0 28.5-11.5T520-760q0-17-11.5-28.5T480-800q-17 0-28.5 11.5T440-760q0 17 11.5 28.5T480-720Z"/></svg>
                        <span>Jurídico</span>
                        <label className="expand shrink-0"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg></label>
                     </div>

                     <div className="dropdown-options">
                           <span onClick={()=>go_to('/legal/contracts')}  className={`o ${pathname=='/legal/contracts' ? 'active' : ''}`}>
                              Contratos
                           </span>
                           <span onClick={()=>go_to('/legal/clients')} className={`o ${pathname=='/legal/clients' ? 'active' : ''}`}>
                              Clientes
                           </span>
                    </div>
          </div>)}


           {user?.email!="test@visum.co.mz" && user && (userRole=="admin" || userRole=="manager" || operator_first_links.payments) && (<div  className={`_nav-link  ${openDropDown=='payments' ? 'open' : ''}  ${pathname=='/payments' || pathname=='/plans'  ? 'open active' : ''}`} link_page="plans">
                    <div className="main-link" onClick={() => changeOpenDropdown('payments','/payments')}>  
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                        <span>Pagamentos {userRole=="admin" ? "e planos":""}</span>
                        <label className="expand"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg></label>
                    </div>
                    <div className="dropdown-options">
                           <span onClick={()=>go_to('/payments')}  className={`o ${pathname=='/payments' ? 'active' : ''}`}>
                              Pagamentos
                           </span>
                          {userRole=="admin" && <span onClick={()=>go_to('/plans')} className={`o ${pathname=='/plans' ? 'active' : ''}`}>
                              Planos
                           </span>}
                    </div>

          </div>)}





         <div onClick={()=>go_to('/settings')}  className={`_nav-link ${pathname=='/settings' ? 'active' : ''}`} link_page="settings">
           <div className="main-link">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z"/></svg>
              <span>Configurações</span>
           </div>
          </div>

          <div onClick={()=>go_to('/profile')}  className={`_nav-link ${pathname=='/profile' ? 'active' : ''}`} link_page="profile">
           <div className="main-link">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M200-246q54-53 125.5-83.5T480-360q83 0 154.5 30.5T760-246v-514H200v514Zm280-194q58 0 99-41t41-99q0-58-41-99t-99-41q-58 0-99 41t-41 99q0 58 41 99t99 41ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm69-80h422q-44-39-99.5-59.5T480-280q-56 0-112.5 20.5T269-200Zm211-320q-25 0-42.5-17.5T420-580q0-25 17.5-42.5T480-640q25 0 42.5 17.5T540-580q0 25-17.5 42.5T480-520Zm0 17Z"/></svg>
                <span>Perfil de usuário</span>
           </div>
          </div>

     </div>


    <div className="bottom">

     
        
    </div>
    <div className="right-side"></div>
    </div>
</div> 
</>
  )
};

export default Menu;
