import * as React from 'react';
import './style.css'
import { useData } from '../../../contexts/DataContext';

export default function Compontent({items}) {

  const data=useData()
  
  return (
      <div class="table-container last_transations">
    <table>
      <thead>
        <tr>
          <th>Hora</th>
          <th>Descrição</th>
          <th>Tipo de Transação</th>
          <th>Montante</th>
          <th>Referência</th>
        </tr>
      </thead>
      <tbody> 
       
          {items.map(i=>(
              <tr className="tr">
                <td>{data._c_date(i.created_at).split('T')[1].slice(0,5)}</td>
                <td>{i.description}</td>
                 <td>
                    <div style={{ backgroundColor:i.type=='in' ? '#CEDBF7':  '#F9CE65', color: '#111' , padding:'0.5rem',borderRadius:'2rem'}}>
                       <span style={{display:'flex',minWidth:'60px',justifyContent:'center'}}>  {i.type=='in' ? 'Entrada':  'Saida'}</span>
                   </div>
                  </td>
                  <td>{(i.type=="out" ? "-" : "") +""+(data._cn(i.amount.toFixed(2)))}</td>
                  <td>{i.reference?.name}</td>
                  
              </tr>
           ))}
      </tbody>
    </table>

     {!items.length && <span style={{fontSize:'0.9rem',display:'flex',justifyContent:"center",padding:'2rem',opacity:0.4}}>Sem registros</span>}
  </div>
  );


}
