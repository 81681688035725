import React from 'react'
import { useData } from '../../contexts/DataContext'

export default function AdvancedActions({items=[],id=null,w=undefined,hide}) {
    const data=useData()
    return (
        <div className={`relative cursor-pointer ${hide ? 'hidden':''}  ${(items || []).filter(i=>!i.hide).length == 0 ? 'hidden':''} flex items-center`}>
          <span className="_table_options" onClick={()=>{
               if(!data._openPopUps.table_options){
                  data._showPopUp('table_options',id)
               }else{
                  data._closeAllPopUps()
               }
          }}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#111"><path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z"/></svg></span>
          {data._openPopUps.table_options==id && <div id="dropdown" class={`z-10 _table_options bg-white  absolute right-[20px] divide-y divide-gray-100 rounded-lg shadow w-[${w ? w :'130'}px]`}>
                <ul class="py-2 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
                    {(items || []).filter(i=>!i.hide).map((i,_i)=>(
                        <li onClick={i.onClick} className="flex items-center px-4 hover:bg-gray-100">
                        <span className="mr-2">{i.icon}</span>
                        <a href="#" class="block py-2 text-[14px]">{i.name}</a>
                        </li>
                    ))}
                </ul>
          </div>}
       </div>
       )
}

