

import { useEffect, useState} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import DefaultFormSkeleton from '../Skeleton/defaultForm.js';

const Create = ({create,setCreate, edit, setEdit,showD}) => {

const {user} = useAuth();
const  data=useData()
let initial_plan_info={expiration_date:'',type:'free',period:'monthly',activation_date:'',quantity:1,status:'paid'}
let initial_form={name:'',location:'',nuit:'',plans:[],description:'',email:'',primary_contact:''}
const [formData,setFormData]=useState(initial_form)
const [showDetails,setShowDetails]=useState(false)
const [loading,setLoading]=useState(false)
const [addNewPlan,setAddNewPlan]=useState(false)
const [newPlan,setNewPlan]=useState(initial_plan_info)
const periods=['monthly','yearly']
const {pathname} = useLocation()
const [itemToEditLoaded,setItemToEditLoaded]=useState(false)
const [planTotalAmount,setPlanTotalAmount]=useState(0)
const [minActivationDate,setMinActivationDate]=useState(new Date().toISOString()?.split('T')?.[0])
 

let required_data=['plans']
useEffect(()=>{   
      if(!user) return
      data.handleLoaded('remove',required_data)
      data._get(required_data) 
},[user,pathname])


useEffect(()=>{

  if(edit && itemToEditLoaded){
    if(formData.plans.length){
      setMinActivationDate(new Date(Math.max(...formData.plans.map(i=>i.expiration_date).map(date => new Date(date))))?.toISOString()?.split('T')?.[0])    
    }else{
      setMinActivationDate(new Date().toISOString().split('T')[0])
    }
  }else{
    setMinActivationDate(new Date().toISOString()?.split('T')?.[0])
  }  

},[edit,create,itemToEditLoaded,formData.plans])

 function today(get_this_day) {

        const currentDate = get_this_day ? get_this_day : new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate

}


function calculateExpirationDate(planType="monthly", duration, starting_date) {
  
    const currentDate = starting_date  ? new Date(starting_date) : new Date();
    let expirationDate;
    if (planType === 'monthly') {
        expirationDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + parseInt(duration), currentDate.getDate());
    } else if (planType === 'yearly') {
        expirationDate = new Date(currentDate.getFullYear() + parseInt(duration), currentDate.getMonth(), currentDate.getDate());
    } else {
        throw new Error('Invalid plan type specified.');
    }
    return today(expirationDate);
}

useEffect(()=>{


       if(newPlan.is_custom_payment){
         setPlanTotalAmount(parseFloat(newPlan.amount || 0))
       }else if(newPlan.type && data.loaded.includes('plans')){
         let q=parseInt(newPlan.quantity || 1)
         if(newPlan.period=="yearly"){
           q=q*12
         }
         let price=data._plans.filter(i=>i.name.toLowerCase()==newPlan.type)[0]?.price || 0
         setPlanTotalAmount(parseFloat(price) * q)
       }else{
         setPlanTotalAmount(0)
       }
},[newPlan,data.loaded])



useEffect(()=>{
    if(edit){
       setCreate(false)
    }
    setShowDetails(false)
    setAddNewPlan(edit ? false : true)
},[edit])

useEffect(()=>{
    if(create) {
      setEdit(false)
      setFormData({...initial_form})
      setNewPlan(initial_plan_info)
    }
    setShowDetails(false)

},[create])

useEffect(()=>{
      if(newPlan.activation_date){
          setNewPlan({...newPlan,expiration_date:calculateExpirationDate(newPlan.period, newPlan.quantity || 1,newPlan.activation_date)})
      }
},[newPlan.activation_date,newPlan.period,newPlan.quantity,formData.plans])


async function handle_action(){
   
    if(!formData.name || !formData.location || !formData.nuit) {
        toast.error('Preencha todos os campos!')
        return 
    }
    
    if((addNewPlan || formData.plans.length==0) && (!newPlan.activation_date || !newPlan.period || !newPlan.type || !newPlan.expiration_date)){
        toast.error('Preencha os detalhes do plano!')
        return 
    }

    if(newPlan.is_custom_payment && !newPlan.amount){
      toast.error('Defina o valor a pagar!')
      return 
    }
    setLoading(true)
   
    try{

      if(edit){
        await data.makeRequest({method:'post',url:`api/microcredit/update/`+edit.id,withToken:true,data:{
          ...formData,
          newPlan:addNewPlan ? newPlan : null 
        }, error: ``},0);
        toast.success('Actualizado com sucesso!')
        setLoading(false)

      }else{

        await data.makeRequest({method:'post',url:`api/microcredit/create`,withToken:true,data:{
          ...formData,
          newPlan:newPlan
        }, error: ``},0)

        toast.success('Criado com sucesso!')
        setLoading(false)
      }

      setEdit(null)
      setCreate(null)
      setNewPlan(false)
      data.setUpdateTable(Math.random())

    }catch(e){

        let msg="Acorreu um erro, tente novamente"
        console.log(e)
        setLoading(false)

        if(e.response){
          if(e.response.status==409){
            msg="Nome já existe"
          }
          if(e.response.status==400){
              msg="Dados inválidos"
          }
          if(e.response.status==500){
              msg="Erro, inesperado. Contacte seu administrador"
          }

        }else if(e.code=='ERR_NETWORK'){
              msg="Verfique sua internet e tente novamente"
        }

        toast.error(msg)
        setLoading(false)
      }
}
  

useEffect(()=>{
     if(!edit){
         setItemToEditLoaded(false)
     }
},[edit])

 
 useEffect(()=>{
  if(!user || !edit?.id){
      return
  }

  (async()=>{

    try{

     let response=await data.makeRequest({method:'get',url:`api/microcredit/`+edit.id,withToken:true, error: ``},100);
     setItemToEditLoaded(true)
     setFormData(response)
   
    }catch(e){

      let msg="Acorreu um erro, tente novamente"

      setLoading(false)

      if(e.response){
        if(e.response.status==409){
          msg="Nome já existe"
        } 
        if(e.response.status==404){
            msg="Item não encontrado"
            setEdit(null)
        }
        if(e.response.status==500){
            msg="Erro, inesperado. Contacte seu administrador"
        }

      }else if(e.code=='ERR_NETWORK'){
            msg="Verfique sua internet e tente novamente"
      }

  }
  
})()
},[user,pathname,edit])


useEffect(()=>{
   if(addNewPlan && formData.plans.length==0){
      setNewPlan({...newPlan,plan:formData.plan})
   }
},[addNewPlan])


return (

  <>
  <div className={`dialog-container-bg hidden ${showD && !data.gettingItemToUpdate ? 'show' :''}`}>
  <div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
  <div  className={`create-microcredit ${loading && 'loading'} ${(edit && !itemToEditLoaded) ? 'loading-editing-item':''}`}>
                   {(edit && !itemToEditLoaded) && <DefaultFormSkeleton/>}

                  <div className={`details ${showDetails ? 'show' :'hide'}`}>
                       <div className={`top-title`}>Detalhes do plano</div>
                       <div className="btn-close" onClick={()=>setShowDetails(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/></svg>
                       </div>

                      <div className="flex items-center justify-between mt-2">
                         <div className="w-full">
                           {addNewPlan &&  <>
                            <span className="opacity-55">Total a pagar:</span> <span>{data._cn(planTotalAmount)}</span>
                           </>
                           }
                        </div>
                        {(edit) && <div onClick={()=>{
                            setAddNewPlan(!addNewPlan)
                            setNewPlan(initial_plan_info)
                        }} className="bg-custom_blue-500  rounded-[2rem] cursor-pointer hover:bg-custom_blue-600 px-2 py-[0.1rem] flex items-center">
                            <svg className={`${addNewPlan ? 'rotate-45':''}`} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg>
                            <span style={{color:'#fff'}} className="text-[12px]">{addNewPlan ? 'cancelar' : 'Adicionar subscrição'}</span>
                        </div>}
                      </div>

                        {addNewPlan && <div className="input-container mb-5">
                               <div className="inputs">
                                    <span>Plano</span>
                                    <select  value={newPlan.type} onChange={e=>setNewPlan({...newPlan, type: e.target.value})}>
                                        {data._plans.map(i=>(
                                              <option key={i.id} selected={newPlan.type === i.name.toLowerCase()} value={i.name.toLowerCase()}>{i.name} ({i.price})</option>
                                        ))}
                                   </select>
                               </div>
                               <div className="inputs">
                                      <span>Quantidade</span>
                                      <input type="number" value={newPlan.quantity ? newPlan.quantity : ''} onChange={e=>setNewPlan({...newPlan,quantity:e.target.value.replace(/[^0-9]/g, '')})}  placeholder="1"/>
                               </div>
                               <div className="inputs">
                                      <span>Periodo de subscrição</span>
                                      <select  value={newPlan.period} onChange={e=>setNewPlan({...newPlan, period:e.target.value})}>
                                              {periods.map(i=>(
                                                    <option key={i.id} selected={newPlan.period === i} value={i}>{i}</option>
                                              ))}
                                      </select>
                               </div>

                               <div className="inputs">
                                      <span>Data de activação</span>
                                      <input ___min={minActivationDate} type="date" value={newPlan.activation_date ? newPlan.activation_date : ''} onChange={e=>setNewPlan({...newPlan,activation_date:e.target.value})}  placeholder=""/>
                               </div>

                               <div className="inputs">
                                      <span>Data de vencimento</span>
                                      <input type="date" value={newPlan.expiration_date} onChange={e=>setNewPlan({...newPlan,expiration_date:e.target.value})}  placeholder=""/>
                               </div>

                              {newPlan.is_custom_payment && <div className="inputs">
                                      <span>Valor a pagar</span>
                                      <input type="number" value={newPlan.amount} onChange={e=>setNewPlan({...newPlan,amount:e.target.value.replace(/[^0-9]/g, '')})}  placeholder=""/>
                               </div>}

                               <div>
                                  <label className="cursor-pointer items-center flex">
                                      <input onClick={()=>setNewPlan({...newPlan,is_custom_payment:Boolean(!newPlan.is_custom_payment)})} className="mr-1" type="checkbox" checked={Boolean(newPlan.is_custom_payment)}  class="w-4 mr-1 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 dark:focus:ring-blue-600  focus:ring-2" /> 
                                      <span>Definir pagamento customizado</span>
                                  </label>
                               </div>

                               
                        </div>}

                         {/**  {(addNewPlan && (newPlan.activation_date && newPlan.period && newPlan.type && newPlan.expiration_date)) && <div className="flex mb-4">
                          {<div onClick={()=>{
                                 setFormData({...formData,plans:[...formData.plans,{
                                  
                                 }]})
                            }} className="bg-custom_blue-500  rounded-[2rem] cursor-pointer hover:bg-custom_blue-600 px-2 py-[0.1rem] flex items-center">
                                <span style={{color:'#fff'}} className="text-[12px]">{'Adicionar'}</span>
                          </div>}
                        </div>} */}

                        <div className={`${!edit ? 'hidden':''}`}>

                        <div className="flex flex-wrap">
                          {/**  <span>Histórico de planos</span> <label className="mx-2">-</label><span className="text-[0.8rem]">Vencimento: {new Date(Math.max(...formData.plans.map(i=>i.expiration_date).map(date => new Date(date))))?.toISOString()?.split('T')?.[0]}</span>**/}
                          <span>Histórico de subscrição</span>
                        </div>

                        <div class="relative overflow-x-auto">
                          <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                                <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            Plano
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Pago
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Estado
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Periodo de subscrição
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Data de activação
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Data de vencimento
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.plans.map(i=>(
                                       <tr class="bg-white border-b">
                                          <th scope="row" class="px-6 relative py-4 flex items-center font-medium text-gray-900 whitespace-nowrap">
                                           <div onClick={()=>{
                                               setFormData({...formData,plans:formData.plans.filter(f=>f.id!=i.id)})
                                           }} className="absolute -translate-x-[30px] opacity-50 hover:opacity-100 cursor-pointer">
                                            <svg class="rotate-45" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path  d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg>
                                           </div>
                                           <button  style={{border:0,color:'#fff'}} type="button"  class={`text-white cursor-default ${i.plan=="free" ? 'bg-green-500':'bg-custom_blue-400'}   focus:outline-none  font-medium rounded-[0.3rem] text-sm px-2 py-1 text-center inline-flex items-center`}>
                                                {i.type}
                                           </button>
                                          </th>
                                          <td class="px-6 py-4">
                                              {data._cn(i.amount)}
                                          </td>
                                          <td class="px-6 py-4">
                                              <button style={{ backgroundColor:!i.status || i.status=='pending' || i.status=='parcial' ? '#eab308': i.status=="paid" ? '#4ACD35' : '#f82f54bf', border:0}} type="button"  class={`text-white cursor-default  focus:outline-none  font-medium rounded-[0.3rem] text-sm px-2 py-1 text-center inline-flex items-center`}>
                                                  {i.status=='pending' || !i.status ? 'Pendente':  i.status=="paid" ? 'Pago' :  i.status=="parcial" ? 'Parcialmente' : i.status=="delayed" ? 'Atrasado' : 'Atrasado'}
                                              </button>
                                          </td>
                                          
                                          <td class="px-6 py-4">
                                              {i.period=="monthly" ? 'Mensal':'Anual'}
                                          </td>
                                          <td class="px-6 py-4" >
                                             {i.activation_date?.split('T')[0] ? i.activation_date?.split('T')[0]?.split('-')?.reverse()?.join('/') :"-"}
                                          </td>
                                          <td class="px-6 py-4">
                                             {i.expiration_date?.split('T')[0] ? i.expiration_date?.split('T')[0]?.split('-')?.reverse()?.join('/') :"-"}   
                                          </td>
                                        </tr>
                                    ))}
                                  
                                </tbody>
                            </table>
                        </div>





                        </div>

                  </div>

                  <div className="top-title">{edit ? 'Editar' :' Adicionar'} microcredito</div> 
                  
                  <div className="btn-close" onClick={()=>(edit ? setEdit(null) : setCreate(false))}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                  <div  onClick={()=>setShowDetails(true)} className={`status`}>Detalhes do plano</div>

                  <div className="input-container">
                     <div className="inputs">
                           <span>Nome <label class="mandatory">*</label></span>
                           <input type="text" value={formData.name} onChange={e=>setFormData({...formData,name:e.target.value})} placeholder="Nome da empresa"/>
                     </div>

                     <div className="inputs">
                            <span>Localização <label class="mandatory">*</label></span>
                           <input type="text"  value={formData.location} onChange={e=>setFormData({...formData,location:e.target.value})} placeholder="Localização da empresa"/>
                     </div>
                  

                     <div className="inputs">
                            <span>Nuit  <label class="mandatory">*</label></span>
                           <input type="number" value={formData.nuit} onChange={e=>setFormData({...formData,nuit:e.target.value.replace(/[^0-9]/g, '')})}  placeholder="Nuit da empresa"/>
                     </div>
                     <div className="inputs">
                           <span>Contacto </span>
                           <input value={formData.primary_contact} onChange={e=>setFormData({...formData,primary_contact:e.target.value})}  type="number" placeholder="Contacto"/>
                     </div>
                     
                     <div className="inputs">
                           <span>Email</span>
                           <input type="text" value={formData.email} onChange={e=>setFormData({...formData,email:e.target.value})}  placeholder="Email da empresa"/>
                     </div>

                     <div className="inputs _full !hidden">
                        <span>Descrição</span>
                        <textarea disabled={loading ? true : false} style={{height:'80px'}} value={formData.description} onChange={e=>setFormData({...formData,description:e.target.value})} placeholder="Descrição..."></textarea>
                     </div>
                  </div>

              <div className="options">
                  <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>
                  <div className="div_btn">
                     <button className="save" onClick={handle_action}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>
                     <span className="loader"></span>
                  </div>
              </div>

        </div>

 </div>
  
  </>


)
};

export default Create;
