import '../css/main.css'
import './style.css'
import Menu from '../common/menu.js';
import TopBar from "../common/topBar";
import { useEffect, useState } from 'react';
import BaiscTable from '../tables/default-table.js';
import BasicPagination from '../pagination/basic.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.js';
import { useData } from '../../contexts/DataContext.js';
import DefaultLayout from '../layouts/DefaultLayout.js';
import toast from 'react-hot-toast';
import TopCardSkeleton from '../Skeleton/topCards.js';
import Loader from '../loaders/loader.jsx';
import * as XLSX from 'xlsx';

export default function Clients() {
const {pathname} = useLocation()
const {user,selectedMicrocredit,userRole,userInfo} = useAuth()
const data=useData()
const [loading,setLoading]=useState(false)
const [printing,setPrinting]=useState(false)

let required_data=['delayed_clients']
const [currentPage,setCurrentPage]=useState(1)
const [updateFilters,setUpdateFilters]=useState(null)
const [search,setSearch]=useState('')
const [filterOptions,setFilterOptions]=useState([])
let initial_filters={start_date:'',end_date:'',on_blacklist:'',classe:'',delayed_payments:''}
const [filters,setFilters]=useState(initial_filters);
const [hideFilters,setHideFilters]=useState(true)
const navigate=useNavigate()

useEffect(()=>{ 
   if(!user || data.updateTable) return
   data.handleLoaded('remove',required_data)
   data._get(required_data,{delayed_clients:{microcredit_id:selectedMicrocredit || '',search:search,page:currentPage,...filters,...data.getParamsFromFilters(filterOptions)}}) 
  
},[user,pathname,search,currentPage,updateFilters])


 useEffect(()=>{
   data.handleLoaded('remove',required_data)
 },[updateFilters])

 useEffect(()=>{
   if(data.updateTable){
        data.setUpdateTable(null)
        data.handleLoaded('remove',required_data)
        setCurrentPage(1)
        data._get(required_data,{delayed_clients:{microcredit_id:selectedMicrocredit || '',search:search,page:1,...filters,...data.getParamsFromFilters(filterOptions)}}) 
   }
},[data.updateTable])


useEffect(()=>{data.setUpdateTable(Math.random())},[filters])


async function handleItems({on_blacklist,id}){ 

     data._closeAllPopUps()
     toast.remove()
     toast.loading('A actualizar...') 

     setLoading(true)
    
     try{
        await data.makeRequest({method:'post',url:`api/microcredit-user/change-blacklist-state`,withToken:true,data:{
              on_blacklist,
              id
        }, error: ``},0);
  
        toast.remove()
        toast.success("Actualizado com sucesso")
        data.setUpdateTable(Math.random())
  
     }catch(e){
        toast.remove()
  
        let msg="Acorreu um erro, tente novamente"
  
        setLoading(false)
  
        if(e.response){
          if(e.response.status==409){
            msg="Nome já existe"
          } 
          if(e.response.status==404){
              msg="Item não encontrado"
          }
          if(e.response.status==500){
              msg="Erro, inesperado. Contacte seu administrador"
          }
  
        }else if(e.code=='ERR_NETWORK'){
              msg="Verfique sua internet e tente novamente"
        }

        toast.error(msg)
  
     }
  }



  const Print = async (type,_data) => {
     let name=(user?.microcredits?.filter(i=>i.id==selectedMicrocredit)[0]?.name || '') +` - Clientes ${data.today()} ${data.time()}`

     const mappedData = _data.map(item => ({
          'ID':item.id, 
          'Cliente':item.name,
          'Contacto':item.primary_contact,
          'Na lista negra':item.on_blacklist ? 'Sim' : 'Não',
          'Contratos atrasados':item.delayed_contracts,
          'Parcelas em atraso':item.delayed_payments,
          'Contrados pagos com atraso':item.currently_delayed_contracts,
          'Parcelas pagas com atraso':item.currently_delayed_payments,
          'TPR':item.tpr,
          'tmrp':parseFloat(item.tmrp || 0).toFixed(1),
          'Classe':item.classe,
          'Total de contratos':item.total_contracts
     }));

     if(type=="excel"){
          const workbook = XLSX.utils.book_new();
          const sheetData = XLSX.utils.json_to_sheet(mappedData);
          XLSX.utils.book_append_sheet(workbook, sheetData, 'Sheet1');
          XLSX.writeFile(workbook, `${name}.xlsx`);
     }else{
          data.setPrintData({print:true,data:[mappedData],names:[name]})
     }
}

const Export =async (type) => {
      try{
          setPrinting(true)
          let response=await data.makeRequest({method:'get', url:`api/delayed-clients`,params:{
              all:true,microcredit_id:selectedMicrocredit || '',search:search,page:currentPage,...filters,...data.getParamsFromFilters(filterOptions)
          }, error: ``,withToken:true},0);
          Print(type,response.data)
      }catch(e){
          toast.remove()
          let msg="Acorreu um erro, tente novamente"
          if(e.response){
            if(e.response.status==500){
                msg="Erro, inesperado. Contacte seu administrador"
            }
          }else if(e.code=='ERR_NETWORK'){
                msg="Verfique sua internet e tente novamente"
          }
          toast.error(msg)
      }
      setPrinting(false)
}

  


useEffect(()=>{
     if((userRole=="operator" && !userInfo?.permissions?.risk_management?.includes('read'))){
          navigate('/dashboard')
     }
},[userInfo])


return (
    <>
     <DefaultLayout>

     <main className="dashboard-container">
     
     <div className="dashboard">
     <Menu/>
     <div className="main-dashboard">

          <TopBar activePage={'Análise de risco'}/>
               {!data.loaded.includes('delayed_clients') && <TopCardSkeleton/>}
               <div className="center">
             


                   {/**
                    * <div style={{display:!data.loaded.includes('delayed_clients') ? 'none':'flex'}} className={`stat_page_2`}>
    
                         <div className="items">

                              <div className="box">
                                   <div className="icon orange-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h168q13-36 43.5-58t68.5-22q38 0 68.5 22t43.5 58h168q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm280-590q13 0 21.5-8.5T510-820q0-13-8.5-21.5T480-850q-13 0-21.5 8.5T450-820q0 13 8.5 21.5T480-790ZM200-200v-560 560Z"></path></svg>
                                   </div>
                                   <div className="center-content">
                                   <div className="name">Contratos em atraso</div>
                                   <div className="total">{(data._delayed_clients?.global_totals?.currently_delayed_contracts || 0)}</div>
                                   </div>
                              </div>

                              <div className="box">
                                   <div className="icon orange-icon">
                                       <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm440 240H120q-33 0-56.5-23.5T40-240v-440h80v440h680v80ZM280-400v-320 320Z"/></svg>
                                   </div>
                                   <div className="center-content">
                                   <div className="name">Parcelas em atraso</div>
                                   <div className="total">{(data._delayed_clients?.global_totals?.currently_delayed_payments || 0)}</div>
                                   </div>
                              </div>

                              <div className="box">
                                   <div className="icon orange-icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h168q13-36 43.5-58t68.5-22q38 0 68.5 22t43.5 58h168q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm280-590q13 0 21.5-8.5T510-820q0-13-8.5-21.5T480-850q-13 0-21.5 8.5T450-820q0 13 8.5 21.5T480-790ZM200-200v-560 560Z"></path></svg>
                                   </div>
                                   <div className="center-content">
                                   <div className="name">Contrados pagos com atraso</div>
                                   <div className="total">{(data._delayed_clients?.global_totals?.delayed_payments || 0)}</div>
                                   </div>
                              </div>

                              <div className="box">
                                   <div className="icon orange-icon">
                                   <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm440 240H120q-33 0-56.5-23.5T40-240v-440h80v440h680v80ZM280-400v-320 320Z"/></svg>
                                   </div>
                                   <div className="center-content">
                                   <div className="name">Parcelas pagas com atraso</div>
                                   <div className="total">{(data._delayed_clients?.global_totals?.delayed_payments || 0)}</div>
                                   </div>
                              </div>


                              
                         </div>
                    </div>
                    */}


                    <div style={{display:!data.loaded.includes('delayed_clients') ? 'none':'flex'}} className={`stat_page_2`}>
                         <div className="items">
                              <div className="box">
                                   <div className="icon red-icon">
                                   <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"></path></svg>
                                   </div>
                                   <div className="center-content">
                                   <div className="name">Clientes na lista negra</div>
                                   <div className="total">{(data._delayed_clients?.global_totals?.on_blacklist || 0)}</div>
                                   </div>
                              </div>

                              <div className="box">
                                   <div className="icon green-icon">
                                   <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"></path></svg>
                                   </div>
                                   <div className="center-content">
                                   <div className="name">Clientes fora da lista negra</div>
                                   <div className="total">{(data._delayed_clients?.global_totals?.not_on_blacklist || 0)}</div>
                                   </div>
                              </div>

                         </div>
                 </div>


                 <div style={{display:!data.loaded.includes('delayed_clients') || user?.role=="admin" ? 'none':'flex'}} className={`stat_page_2`}>
                         <div className="items">
                               {(data._delayed_clients?.global_totals?.classes || []).map((i,_i)=>(
                                   <div className="box">
                                        <div className={`icon ${_i==0 ? 'green-icon': _i==1 ? 'orange-icon':'red-icon'}`}>
                                            {i.className}
                                        </div>
                                        <div className="center-content">
                                             <div className="name">TMRP - Classe {i.className}</div>
                                             <div className="total">{i.totalClients}</div>
                                             <span style={{opacity:.6,fontSize:'0.9rem'}} className="flex items-center"><label>{i?.condition=='less_than' ? "<=" : ">="}</label>   {i?.number}</span>
                                        </div>
                                   </div>
                               ))}
                         </div>
                 </div>

               <div className="center_search">

                    <div className="search_container">
                         <div className="search-div">
                              <input type="text" placeholder="Pesquisar..." value={search} onChange={(e)=>{
                                   setSearch(e.target.value)
                                   data.setUpdateTable(Math.random())
                              }}/>
                                   <div className="search-btn">
                                   <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg>
                              </div>
                         </div>
                    </div>

                    <div className="flex items-center">
                         <div onClick={()=>
                           data.setUpdateTable(Math.random())
                         } className="mr-2 cursor-pointer hover:opacity-65">
                              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z"/></svg>
                         </div>
                        
                    </div>
               </div>


               <div className={`extra-filters ${hideFilters ? '_hide':''}`}>

               <label onClick={()=>setHideFilters(!hideFilters)} className="underline sm:hidden text-[15px]  text-custom_blue-400 absolute top-4 cursor-pointer">{hideFilters ? 'Mostrar filtros':'Esconder filtros'}</label>

               <div className="input-container">

                    <div className="inputs">
                         <span>Data de inicio</span>
                         <input type="date" value={filters.start_date} onChange={(e)=>setFilters({...filters,start_date:e.target.value})} />
                    </div>

                         <div className="inputs">
                         <span>Data de fim</span>
                         <input type="date" value={filters.end_date} onChange={(e)=>setFilters({...filters,end_date:e.target.value})} />
                    </div>

                    <div className="inputs">
                                    <span>Classificação</span>
                                    <select value={filters.classe} onChange={e=>setFilters({...filters,classe:e.target.value})}>
                                        <option  selected value="">Selecionar opção</option>
                                        <option value="A">Classe A</option>
                                        <option value="B">Classe B</option>
                                        <option value="C">Classe C</option>
                                    </select>
                    </div>

                    <div className="inputs">
                                    <span>Status na Blacklist</span>
                                    <select value={filters.on_blacklist} onChange={e=>setFilters({...filters,on_blacklist:e.target.value})}>
                                        <option  selected value="">Selecionar opção</option>
                                        <option value={true}>Adicionado</option>
                                        <option value={false}>Não adicionado</option>
                                    </select>
                    </div>

                    <div className="inputs">
                                    <span>Status de pagamentos</span>
                                    <select value={filters.delayed_payments} onChange={e=>setFilters({...filters,delayed_payments:e.target.value})}>
                                        <option  selected value="">Selecionar opção</option>
                                        <option value={true}>Com atrasos</option>
                                        <option value={false}>Sem atrasos</option>
                                    </select>
                    </div>

                    

               </div>

               <div className="options">
               <span onClick={()=>setFilters(initial_filters)}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-122q-121-15-200.5-105.5T160-440q0-66 26-126.5T260-672l57 57q-38 34-57.5 79T240-440q0 88 56 155.5T440-202v80Zm80 0v-80q87-16 143.5-83T720-440q0-100-70-170t-170-70h-3l44 44-56 56-140-140 140-140 56 56-44 44h3q134 0 227 93t93 227q0 121-79.5 211.5T520-122Z"/></svg> </span>
               </div>

          </div>

               <div className="dasboard-item-list-container">
               <div className="top-section">
                         <div className="left-side">
                                   <div className="show">
                                             <span>Resultados:</span>
                                             <label>{data._delayed_clients?.total || 0}</label>
                                   </div>
                              
                         </div>
                         <div className="right-side">
                                        {printing ? <>
                                                  <Loader/>
                                        </> : <>
                                             <span onClick={()=>{
                                                  Export('pdf')
                                             }} className="export export-excel page" style={{background:'rgb(74, 205, 53)'}}>
                                                  <svg width="21" viewBox="0 0 24 24" style={{fill:'#fff'}} xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z" />
                                                  </svg>
                                             </span>
                                             
                                             <span onClick={()=>{
                                                  Export('excel')
                                             }} className="export export-excel page" style={{background:'rgb(74, 205, 53)'}}>
                                             <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24"><path fill="#fff" d="M18 22a2 2 0 0 0 2-2v-5l-5 4v-3H8v-2h7v-3l5 4V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12zM13 4l5 5h-5V4z"></path></svg>
                                             </span>
                                        </>}
                         </div>
                    </div>

               <BaiscTable   loaded={data._loaded.includes('delayed_clients')} header={[
                    'ID',
                    'Nome',
                    'Status na Blacklis',
                    'Contratos em atraso',
                    'Parcelas em atraso',
                    'Contratos pagos com atraso',
                    'Parcelas pagas com atraso',
                    'Dias de atraso',
                    'Contacto',
                    'Email',
                    'Estado'
                    ]
               }

               body={(data._delayed_clients?.data || []).map((i,_i)=>(
                         <BaiscTable.Tr>
                         <BaiscTable.Td>{i.id}</BaiscTable.Td>
                         <BaiscTable.Td>{i.name}</BaiscTable.Td>
                         <BaiscTable.Td>
                            <button style={{border:0}} type="button"  class={`text-gray-900 cursor-default ${i.on_blacklist ? 'bg-[#f82f54bf]':'bg-gray-400'}   focus:outline-none  font-medium rounded-[0.3rem] text-sm px-2 py-1 text-center inline-flex items-center`}>
                                 {i.on_blacklist ? 'Adicionado' :'Não adicionado'}
                            </button>
                         </BaiscTable.Td>
                         <BaiscTable.Td>{i.currently_delayed_contracts}</BaiscTable.Td>
                         <BaiscTable.Td>{i.currently_delayed_payments}</BaiscTable.Td>
                         <BaiscTable.Td>{i.delayed_contracts}</BaiscTable.Td>
                         <BaiscTable.Td>{i.delayed_payments}</BaiscTable.Td>
                         <BaiscTable.Td>{i.delayed_days}</BaiscTable.Td>
                         <BaiscTable.Td>{i.primary_contact}</BaiscTable.Td>
                         <BaiscTable.Td>{i.email || '-'}</BaiscTable.Td>
                        
                         <BaiscTable.Td>
                            <button style={{border:0}} type="button"  class={`text-gray-800 cursor-default ${i.status=="active" ? 'bg-green-500':'bg-gray-400'}   focus:outline-none  font-medium rounded-[0.3rem] text-sm px-2 py-1 text-center inline-flex items-center`}>
                                 {i.status}
                            </button>
                         </BaiscTable.Td>
                        
                         <BaiscTable.AdvancedActions data={data} w={300} id={i.id} items={[
                              {name:'Remover da lista negra',hide:!i.on_blacklist || (userRole!="manager" && !userInfo?.permissions?.blacklist?.includes('remove')),onClick:()=>{handleItems({on_blacklist:false,id:i.id})},icon:(<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M200-440v-80h560v80H200Z"/></svg>)},
                              {name:'Adicionar na lista negra',hide:i.on_blacklist || i.delayed_payments <= 0 || (userRole!="manager" && !userInfo?.permissions?.blacklist?.includes('add')),onClick:()=>{handleItems({on_blacklist:true,id:i.id})},icon:(<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M440-280h80v-160h160v-80H520v-160h-80v160H280v80h160v160Zm40 200q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>)},
                          ]}/>
                        </BaiscTable.Tr>
                    ))}
          />

            
               </div>

          </div>

          
     </div>
     </div>
     </main>

     </DefaultLayout>
    </>
  );
}
