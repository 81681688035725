"use client"

import '../css/main.css'
import Menu from '../common/menu.js';
import TopBar from "../common/topBar";
import { useState ,useEffect,useRef} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import Loader from '../loaders/loader.jsx';
import { useLocation } from 'react-router-dom';
import ChangePasswordModal from '../modals/change-password.jsx';


export default function Settings() {
  
  const data = useData();
  const {user,selectedMicrocredit,userRole,isLoading} = useAuth(); 
  const [loading,setLoading]=useState(false)
  const {pathname} = useLocation()

  const [settings,setSettings]=useState([])
  const [selectedMicro,setSelectedMicro]=useState(null)
  const [userInfo,setUserInfo]=useState({})
  const [showPasswordDialog,setShowPasswordDialog]=useState(false)

  const required_data=['settings']
  useEffect(()=>{ 
    if(!user) return
    data.handleLoaded('remove',required_data)
    data._get(required_data) 
  },[user,pathname])




    useEffect(()=>{
        if(selectedMicrocredit){
           setSelectedMicro(selectedMicrocredit)
        }
    },[selectedMicrocredit])


    useEffect(()=>{

        if(user?.role=="admin"){
            setUserInfo(user)
        }else if(selectedMicro){
            setUserInfo(user.microcredits.filter(i=>i.id==selectedMicro)[0]?.user_microcredits?.[0] || {})
        }

    },[selectedMicro,user])



   return (

    <>
    
    <ChangePasswordModal show={showPasswordDialog} setShow={setShowPasswordDialog}/>

    <main className="dashboard-container">

        <div className="dashboard">
            <Menu/>
            <div className="main-dashboard">
               
            <TopBar activePage={'Configurações'}/>

                  <div className="center">

                    {(loading) && <div className="w-full flex justify-center items-center h-[200px]">
                            <Loader/><span>A carregar...</span>
                    </div>}
                     
                    <div className={`${loading ? 'hidden':''}`}>

                    <div className="bg-white overflow-hidden rounded-[0.4rem] relative">
                             <button onClick={()=>{
                                  setShowPasswordDialog(true)
                             }} id="confirm-button" type="button" class="ml-2 py-1 px-2  text-sm font-medium text-center rounded-full absolute right-3 top-3 text-white bg-custom_blue-400 sm:w-auto bg-honolulu_blue-500 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300">Alterar senha</button>

                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Perfil de usuário
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                    Informação da conta e microcréditos associados
                                </p>

                                {user?.role!="admin" && <div className="flex items-center my-2">
                                   <span className="mr-2">Microcrédito</span>
                                   <select  onChange={(e)=>{
                                              setSelectedMicro(e.target.value)
                                    }} value={selectedMicro} id="countries" class="bg-gray-50 border text-[0.8rem] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5">
                                    {user?.microcredits?.map(i=>(
                                        <option value={i.id}>{i.name}</option>
                                    ))}
                                  </select>

                                </div>}
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                <dl className="sm:divide-y sm:divide-gray-200">
                                   <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Função
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {user?.role=="admin" ? "Admin" : userInfo?.role=="manager" ? "Gestor" : userInfo?.role=="manager" ? "Operador" : ""}
                                        </dd>
                                    </div>
                                    <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Nome
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {userInfo?.name}
                                        </dd>
                                    </div>
                                    <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Email
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {userInfo?.email}
                                        </dd>
                                    </div>
                                    {userRole!="admin" && <>
                                        <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">
                                                Contacto
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {userInfo?.primary_contact || '-'}
                                            </dd>
                                        </div>
                                        <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">
                                                Endereço
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {userInfo?.address || '-'}
                                            </dd>
                                        </div>
                                        <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">
                                                Cargo
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {userInfo?.job_title || '-'}
                                            </dd>
                                        </div>
                                    </>}
                                </dl>
                            </div>
                      </div>



                    </div>


                  </div>

                  
            </div>
        </div>
    </main>
    </>
  );
}
