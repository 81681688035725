import '../css/main.css'
import './style.css'
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import RecoverPasswordModal from './recover-password.js';
import { useData } from '../../contexts/DataContext.js';
import BasicPopUp from '../PopUp/basic-popup.js';


const Login=function() {

     const location = useLocation();
     const currentPath = location.pathname;
     const data=useData()

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const [form,setForm]=useState({})
    const [message,setMessage]=useState('')
    const [showRecoverPasswordDialog,setShowRecoverPasswordDialog]=useState(false)
    const [success,setSuccess] = useState(false)
    const [recoverPasswordStatus,setRecoverPasswordStatus]=useState('code_not_sent')
    const [showPasswordRecoveredOk,setShowPasswordRecoveredOk]=useState(false)
    const navigate=useNavigate()

    const handleKeyPress = (event) => {

        if (event.key == 'Enter') {
          handleLogin();
        }

    };

    useEffect(()=>{
        if(currentPath.includes('/test')){
            setPassword('secret')
            setEmail('test@visum.co.mz')
        }
    },[])


    const handleLogin = async () => {
         toast.remove()

         if(!email || !password){
            toast.remove()
            toast.error('Preencha todos os campos!')
            return
         }

         if(!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim()))){
          toast.error('Email inválido')
          setLoading(false)
          return
        }

         setLoading(true)

          try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/login`, {
              email,
              password
            });

            localStorage.setItem('token',response.data.token);
            localStorage.setItem('refresh-token',response.data.refreshToken);

            setLoading(false)
            toast.remove()
            toast.success('Autenticado com sucesso!')
            window.location.href="/";

        } catch (e) {
          let msg="Acorreu um erro, tente novamente"
          console.log(e)
          setLoading(false)

          if(e.response){
            if(e.response.status==404){
                msg="Usuário não encotrado"
            }
            if(e.response.status==401){
              msg="Senha incorreta"
            }
            if(e.response.status==423){
              msg="Usuário não associado a microcrédito activa"
          }
            if(e.response.status==400){
                msg="Dados inválidos"
            }
            if(e.response.status==500){
                msg="Erro, inesperado. Contacte seu administrador"
            }

          }else if(e.code=='ERR_NETWORK'){
                msg="Verfique sua internet e tente novamente"
          }

          toast.error(msg)
          setLoading(false)
        }
    };



    function recoverPasswordSubmit(){
        if(recoverPasswordStatus=="code_not_sent"){
                    SendCode()
        }
        if(recoverPasswordStatus=="code_sent"){
                  VerifyCode()
        }
    }


    async function VerifyCode(){

        setMessage('')
        setLoading(true)

        try{

            let response=await data.makeRequest({method:'post',url:`api/verify-code`,data:{...form,action:'recover_password'}, error: ``},0);
            localStorage.setItem('token',response.token)
            localStorage.setItem('refresh-token',response.refreshToken)
            setShowRecoverPasswordDialog(false)
            setShowPasswordRecoveredOk(true)

        }catch(e){
            setLoading(false)
            let msg="Erro, inesperado. Contacte seu administrador"

            if(e.response){
              if(e.response.status==400){
                  msg='Código inválido ou usado'
              }
              if(e.response.status==500){
                  msg="Erro, inesperado. Contacte seu administrador"
              }

            }else if(e.code=='ERR_NETWORK'){
                  msg="Verfique sua internet e tente novamente"
            }
            setMessage(msg)

        }

    }
    


   async function SendCode(){

    setMessage('')

    if(!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email.trim()))){
       setMessage('E-mail inválido')
       return
    }

    setLoading(true)
    setForm({...form,code:''})

    try{
      await data.makeRequest({method:'post',url:`api/send-verification-code`,data:{email:form.email,action:'recover_password'}, error: ``},0);
      setLoading(false)
      if(showRecoverPasswordDialog){
         setRecoverPasswordStatus('code_sent')
      }
    }catch(e){

       setLoading(false)
       let msg="Erro, inesperado. Contacte seu administrador"

       if(e.response){
        if(e.response.status==404){
          msg='Usuário não encontrado!'
        }
        if(e.response.status==400){
            msg='Código inválido ou usado'
        }
        if(e.response.status==500){
            msg="Erro, inesperado. Contacte seu administrador"
        }

      }else if(e.code=='ERR_NETWORK'){
            msg="Verfique sua internet e tente novamente"
      }
      setMessage(msg)
    }
  }


  useEffect(()=>{

    if(new URLSearchParams(window.location.search).get('recover-password')){
      setShowRecoverPasswordDialog(true)
      return
    }

  },[])

  return (
    <>
    
 <BasicPopUp  
    show={showPasswordRecoveredOk} title={'Senha alterada'}
    btnConfirm={{text:'Entrar',onClick:()=>{
          setShowPasswordRecoveredOk(false)
          window.location.href="/"
    }}}
    link={{text:'',onClick:()=>{}}}
    message={'Sua senha foi actualizada com sucesso. A nova senha foi envida para seu email'}         
  />
     
    <RecoverPasswordModal status={recoverPasswordStatus} setStatus={setRecoverPasswordStatus} success={success} resendCode={SendCode}  message={message} setMessage={setMessage} setForm={setForm} loading={loading} SubmitForm={recoverPasswordSubmit} form={form} setShow={setShowRecoverPasswordDialog} show={showRecoverPasswordDialog}/>

    <main  className={`login-container ${loading && 'loading'}`}>
      
      <div className="msg-error"></div>

      <div className="center-div">
             
                <div className="login-form">
                  
                  <div className="logo-c">
                        <h1 className="underline cursor-pointer" onClick={()=>{
                             window.location.href="/"
                        }}>VLMS</h1>
                  </div>
                  
                  <h2>Login</h2> 

                  <div className="div-input">
                          <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" ><path fill="#D9D9D9" d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/></svg>
                          <input  onKeyPress={handleKeyPress} value={email} onChange={(e) => setEmail(e.target.value)} className="username" type="text" placeholder="Email"/>
                  </div>

                  <div className="div-input">
                          <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960"><path fill="#D9D9D9" d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z"/></svg>
                          <input  onKeyPress={handleKeyPress} value={password} onChange={(e) => setPassword(e.target.value)} className="password" type="password" placeholder="Senha"/>
                  </div>

                  <div onClick={()=>{
                       setShowRecoverPasswordDialog(true)
                  }} className="forgot-password text-gray-400 underline text-[0.9rem]">Recuperar senha</div>

                  <div className="div_btn">
                     <button onClick={handleLogin}>Entrar</button>
                     <span className="loader"></span>
                  </div>
                </div>

            <div className="text-container">
                <div className="top-content">
                  <span className="welcome-text">Bem vindo!</span> 
                  <div className="copy">VLMS &copy; 2025</div>
                </div>
            </div>

      </div>
       <div className="footer"><span><Link to={"/terms"}>Termos</Link></span> | <span><Link to={"/privacy-politics"}>Politicas</Link></span></div>
    </main>
    </>
  );
}

export default  Login
