import * as React from 'react';
import './style.css'
import { useNavigate } from 'react-router-dom';
import { useData } from '../../../contexts/DataContext';


export default function Compontent({items}) {

  const navigate = useNavigate();

  function handleEdit(id) {
    navigate('/loan/'+id)
  }

  const data=useData()
  
  return (
      <div class="table-container skeleton-bg">
    <table>
      <thead>
        <tr>
          <th>Nome</th>
          <th>Gestores</th>
          <th>Saldo disponível</th>
          <th>T. Contratos</th>
          <th>T. Clientes</th>
          <th>T. Gestores</th>
          <th>T. Operadores</th>
          <th>Plano</th>
        </tr>
      </thead>
      <tbody>
       
          {items.map(i=>(
              <tr className="tr"> 
                     <td>{i.name}</td>
                     <td>{i.managers.join(', ') || '-'}</td>
                     <td>{data._cn(i.availableBalance.toFixed(2))}</td>
                     <td>{i.totalContracts || '-'}</td>
                     <td>{i.roleCounts.client || '-'}</td>
                     <td>{i.roleCounts.manager || '-'}</td>
                     <td>{i.roleCounts.operator || '-'}</td>
                     <td>{i.plan?.toUpperCase()}</td>
              </tr>

           ))}
       
      </tbody>
    </table>

     {!items.length && <span style={{fontSize:'0.9rem',display:'flex',justifyContent:"center",padding:'2rem',opacity:0.4}}>Sem registros</span>}
  </div>
  );


}
