import React from 'react'
export default function SetUserPermissions({showPermissions,setShowPermissions,formData,setFormData,permissions,permissions_description,permissions_item_description}) {
 
  return (
        <div style={{zIndex:99999}} id="crud-modal" tabindex="-1" aria-hidden="true" class={`overflow-y-auto  bg-[rgba(0,0,0,0.3)] flex ease-in delay-100 transition-all ${!showPermissions ? 'opacity-0 pointer-events-none translate-y-[50px]':''} overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100vh] max-h-full`}>
            <div class="relative p-4 w-full max-w-[800px] max-h-full ">
                
                <div class={`relative bg-white overflow-hidden ${!showPermissions ? 'hidden':''} rounded-lg shadow pb-[50px]`}>
              

                <div className="w-full col-span-2">
                    <div className="w-full cursor-pointer flex items-center py-5 px-10">
                       <span>Permissões</span>
                    </div>
                   <div className="w-full overflow-hidden">
                   <div class={`relative ${!showPermissions ? 'hidden':''} overflow-x-auto p-5`}>
          
                   <table className="w-full text-sm text-left rtl:text-right text-gray-500">
    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
        <tr>
            <th scope="col" className="px-8 py-3">Recurso</th>
            <th scope="col" className="px-8 py-3">Permissão</th>
        </tr>
    </thead>

    <tbody>
        {Object.keys(permissions).map((i, _i) => (
            <tr className="bg-white">
                <th scope="row" className="sm:px-8 py-4 font-medium text-gray-900">
                    <div className="flex items-center cursor-pointer">
                        <input
                            onChange={() => {
                                if (!formData.permissions[i]?.length) {
                                    setFormData({ ...formData, permissions: { ...formData.permissions, [i]: permissions[i] } });
                                } else if (permissions[i].length == formData.permissions[i]?.length) {
                                    setFormData({ ...formData, permissions: { ...formData.permissions, [i]: [] } });
                                } else {
                                    setFormData({ ...formData, permissions: { ...formData.permissions, [i]: permissions[i] } });
                                }
                            }}
                            id={i + "item"}
                            checked={permissions[i].length == formData.permissions[i]?.length}
                            type="checkbox"
                            className="w-4 h-4 !cursor-pointer bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 focus:ring-2"
                        />
                        <label htmlFor={i + "item"} className="ml-2 cursor-pointer">
                            {permissions_description[i]}
                        </label>
                    </div>
                </th>

                <td className="sm:px-8 py-4">
                    <div className="flex flex-wrap gap-4">
                        {permissions[i].map((f) => (
                            <div key={f} className="flex items-center cursor-pointer">
                                <input
                                    onChange={() => {
                                        if (formData.permissions[i]?.includes(f)) {
                                            setFormData({ ...formData, permissions: { ...formData.permissions, [i]: formData.permissions[i].filter((z) => z != f) } });
                                        } else {
                                            if (formData.permissions[i]) {
                                                setFormData({ ...formData, permissions: { ...formData.permissions, [i]: [...formData.permissions[i], f] } });
                                            } else {
                                                setFormData({ ...formData, permissions: { ...formData.permissions, [i]: [f] } });
                                            }
                                        }
                                    }}
                                    id={i + f}
                                    checked={formData.permissions[i]?.includes(f)}
                                    type="checkbox"
                                    className="w-4 h-4 !cursor-pointer bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 focus:ring-2"
                                />
                                <label htmlFor={i + f} className="ml-2 cursor-pointer">
                                    {permissions_item_description[f]}
                                </label>
                            </div>
                        ))}
                    </div>
                </td>
            </tr>
        ))}
    </tbody>
</table>

                        </div>
                   </div>

               </div>



                </div>

                {showPermissions && <div onClick={()=>{

                  setShowPermissions(false)

                 }} className="cursor-pointer px-2 h-[30px] absolute right-5 top-5 rounded-full bg-gray-300 flex items-center justify-center">
                       <span>Fechar</span>
                </div>}


            </div>
        </div> 

  )
}
