import React from 'react'
import BaiscTable from '../tables/default-table'
import { useData } from '../../contexts/DataContext'
import { useAuth } from '../../contexts/AuthContext'

export default function PrintPaymentPlan({formData}) {

  const data=useData()
  const {selectedMicrocredit,user}=useAuth()
  let client=data._clients_list.filter(i=>i.id==formData.client_id)[0] || {}
  let micro=user.microcredits.filter(i=>i.id==selectedMicrocredit)[0]

  return (
    <div  print="true" className={`${data.printingDoc=="plan" ? '_print_div' :''} !text-[13px] w-full left-0 top-0 justify-center`} style={{zIndex:999}}>

              <div class="_header">
              {micro.logo_filename && <div className="flex w-full justify-center mb-4">
                 <img className="w-[70px]  object-cover" src={`${process.env.REACT_APP_BASE_URL}/uploaded_files/`+micro.logo_filename}/>
              </div>}
              <h2 className="text-center text-[22px] font-semibold py-4 !border-t  mx-auto">Plano de pagamento</h2>
              
              </div>
              <table className="paging"><thead><tr><td>&nbsp;</td></tr></thead><tbody><tr><td>

              <div className="_print_content  bg-white pt-3">
                  
                 <div className="w-full flex justify-between mt-5">
                      <div className="flex gap-x-2">
                             <div className="gap-y-1 flex-col flex items-end &>_span !text-[12px] !text-gray-500">
                                 <span className="flex h-[20px] items-center">Nome:</span>
                                 {client.address && <span className="flex h-[20px] items-center">Endereço:</span>}
                                 {client.email && <span className="flex h-[20px] items-center">E-mail:</span>}
                                 <span className="flex h-[20px] items-center">Emissão</span>
                             </div>
                             <div className="gap-y-1 flex-col flex">
                                 <span className="flex h-[20px] items-center">{client.name}</span>
                                 {client.address && <span className="flex h-[20px] items-center">{client.address}</span>}
                                 {client.email && <span className="flex h-[20px] items-center">{client.email}</span>}
                                 <span className="flex h-[20px] items-center">{new Date().toISOString().split('T')[0]?.split('-')?.reverse()?.join('/')}</span>
                             </div>
                      </div>
                      <div className="gap-y-1 flex-col flex">
                                 <span className="flex h-[20px] items-center">{micro.name}</span>
                                 {micro.location && <span className="flex h-[20px] items-center">{micro.location}</span>}
                                 {micro.primary_contact && <span className="flex h-[20px] items-center">{micro.primary_contact}</span>}
                                 {micro.email && <span className="flex h-[20px] items-center">{micro.email}</span>}
                      </div>

               </div>


                <div className="mt-10">
                    <BaiscTable  loaded={true} header={[
                        'Valor inicial',
                        'Juros de mora',
                        'Valor a pagar',
                        'Pago',
                        'Prazo de pagamento',
                        'Divida',
                        ]
                    }
                    body={(formData.installments || []).map((i,_i)=>(
                        <BaiscTable.Tr>
                                <BaiscTable.Td >{data._cn((i.initial_amount || 0).toFixed(2))}</BaiscTable.Td>
                                <BaiscTable.Td >{data._cn((i.late_payment_interest || 0).toFixed(2))}</BaiscTable.Td>
                                <BaiscTable.Td >{data._cn((i.total_to_pay).toFixed(2))}</BaiscTable.Td>
                                <BaiscTable.Td >{data._cn((i.paid || 0).toFixed(2))}</BaiscTable.Td>
                                <BaiscTable.Td >{i.end.split('-')?.reverse()?.join('/')}</BaiscTable.Td>
                                <BaiscTable.Td >{(i.total_to_pay) - (i.paid || 0) < 0 ? 0 : data._cn(((i.total_to_pay) - (i.paid || 0)).toFixed(2))}</BaiscTable.Td>
                            </BaiscTable.Tr>
                        ))}
                    />
                </div>

                {(formData.insurance + formData.cost_of_preparation + formData.taxes) > 0 && <div className="mt-10">
                       
                      <h2 className="mb-2 font-semibold text-[18px]">Despesas</h2>
                      <div className="flex flex-col gap-y-2">
                        {formData.cost_of_preparation > 0 && <div>
                            <span className="font-medium mr-2">Custo de Preparo:</span>
                            <label className="!text-gray-500">{formData.cost_of_preparation}MT</label>
                        </div>}
                        {formData.taxes > 0 && <div>
                            <span className="font-medium mr-2">Imposto de Selo:</span>
                            <label className="!text-gray-500">{formData.taxes}MT</label>
                        </div>}
                        {formData.insurance > 0 && <div>
                            <span className="font-medium mr-2">Seguro:</span>
                            <label className="!text-gray-500">{formData.insurance}MT</label>
                        </div>}
                      </div>
                </div>}


                <div className="w-full mt-20 text-[15px]">
                    E por corresponder à verdade, de livre e espontânea vontade assino o presente plano de pagamento, feito em duplicado,
                    com idêntico valor e teor, ambos com força de original.
                </div>

                

              </div>
              </td></tr></tbody><tfoot><tr><td>&nbsp;</td></tr></tfoot></table>
                
              <div class="_footer">
                   <div className="w-full flex flex-col items-center mt-[100px]">
                                    <span>{new Date().toISOString().split('T')[0]?.split('-')?.reverse()?.join('/')}</span>
                                    <span className="font-semibold">O Devedor</span>

                                    <span className="flex border-b-2 border-black mt-20 w-[200px]"></span>
                                    <span className="text-[15px] mt-2">{client.name}</span>
                    </div>
              </div>
              
              

    </div>
  )
}
