"use client"

import '../css/main.css'
import './style.css'
import Menu from '../common/menu.js';
import TopBar from "../common/topBar";
import { useState ,useEffect,useRef} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import Loader from '../loaders/loader.jsx';
import { useLocation } from 'react-router-dom';
import LogoFile from '../Inputs/logo.jsx';


export default function Settings() {
  const data = useData();
  const {user,selectedMicrocredit,userRole,setUser,userInfo} = useAuth(); 
  const [loading,setLoading]=useState(false)
  const {pathname} = useLocation()
  const [selectedPage,setSelectedPage]=useState('micro')
  const [settings,setSettings]=useState([])
  const [form,setForm]=useState({})

  const required_data=['settings']
  useEffect(()=>{ 
    if(!user) return
    data.handleLoaded('remove',required_data)
    data._get(required_data) 
  },[user,pathname])


  async function updateSettings(){ 

    toast.remove()
    toast.loading('A actualizar...') 

    setLoading(true)
   
    try{
       await data.makeRequest({method:'post',url:`api/settings/update`,withToken:true,data:{
            settings
       }, error: ``},0);
 
       toast.remove()
       toast.success("Actualizado com sucesso")
       setLoading(false)
 
    }catch(e){
       toast.remove()
 
       let msg="Acorreu um erro, tente novamente"
 
       setLoading(false)
 
       if(e.response){
         if(e.response.status==409){
           msg="Nome já existe"
         } 
         if(e.response.status==404){
             msg="Item não encontrado"
         }
         if(e.response.status==500){
             msg="Erro, inesperado. Contacte seu administrador"
         }
 
       }else if(e.code=='ERR_NETWORK'){
             msg="Verfique sua internet e tente novamente"
       }

       toast.error(msg)
 
    }
 }



 async function updateMicro(){ 

  toast.remove()
  toast.loading('A actualizar...') 

  setLoading(true)
 
  try{
     await data.makeRequest({method:'post',url:`api/microcredit/profile/update/${form.id}`,withToken:true,data:{
          ...form
     }, error: ``},0);

     toast.remove()
     toast.success("Actualizado com sucesso")
     setLoading(false)

     setUser({...user,microcredits:user.microcredits.map((i,_i)=>{
        if(i.id==selectedMicrocredit){
           return {...i,...form,logo_filename:form.logo_filename,name:form.name}
        }else{
           return i
        }
     })})

  }catch(e){
     toast.remove()

     let msg="Acorreu um erro, tente novamente"

     setLoading(false)

     if(e.response){
       if(e.response.status==409){
         msg="Nome já existe"
       } 
       if(e.response.status==404){
           msg="Item não encontrado"
       }
       if(e.response.status==500){
           msg="Erro, inesperado. Contacte seu administrador"
       }

     }else if(e.code=='ERR_NETWORK'){
           msg="Verfique sua internet e tente novamente"
     }

     toast.error(msg)

  }
}

 useEffect(()=>{
    setSettings(data._settings)
 },[data._settings])

 
 function handleUploadedFiles(upload){
   setForm({...form,[upload.key]:upload.filename})
 }


 useEffect(()=>{
  if(!user) return

  if(user?.role!="admin"){
      setForm(user.microcredits.filter(i=>i.id==selectedMicrocredit)[0] || {})
  }

  if(userRole=="manager"){
      setSelectedPage('micro')
  }else{
      setSelectedPage('nots')
  }

},[user,userRole])

 const pages=[
  {name:'Perfil da microcrédito',page:'micro'},
  {name:'Notificações',page:'nots'}
 ]


 

  return (
    <>
    <main className="dashboard-container">

        <div className="dashboard">

            <Menu/>

            <div className="main-dashboard">
               
            <TopBar activePage={'Configurações'}/>

                  <div className="center mb-10">
                    {(data._loaded.includes('settings') && !loading) && <div className={`inline-flex ${userRole!="manager" ? 'hidden':''} bg-white overflow-hidden rounded-[0.3rem] mb-10 shadow justify-between gap-x-3 px-2`}>
                         {pages.map((i,_i)=>(
                          <span  onClick={()=>setSelectedPage(i.page)} className={`py-2 text-[15px] relative cursor-pointer flex ${selectedPage==i.page ? '':'opacity-50'}`}>
                               {i.name}
                               {selectedPage==i.page && <label className="w-full absolute bottom-0 flex h-[3px] bg-custom_blue-300 rounde-t-[0.3rem]"></label>}
                          </span>
                         ))}
                    </div>}

                    {(!data._loaded.includes('settings') || loading) && <div className="w-full flex justify-center items-center h-[200px]">
                            <Loader/><span>A carregar...</span>
                    </div>}


                    {(data._loaded.includes('settings') && !loading && selectedPage=="micro") && <div className="w-full bg-white rounded-[0.4rem] px-3 py-2 pb-5">
                      <div className="mb-10 w-full">
                        <LogoFile res={handleUploadedFiles} _upload={{key:'logo_filename',filename:form.logo_filename}} label={'Logotipo'}/>
                      </div>

                      <div class="max-w-sm">
                        <div class="mb-5">
                          <label class="block mb-2 text-sm font-medium text-gray-900">Nome da empresa</label>
                          <input value={form.name} onChange={(e)=>setForm({...form,name:e.target.value})} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5"  required />
                        </div>
                        <div class="mb-5">
                          <label class="block mb-2 text-sm font-medium text-gray-900">Localização</label>
                          <input value={form.location} onChange={(e)=>setForm({...form,location:e.target.value})} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5"  required />
                        </div>
                        <div class="mb-5">
                          <label class="block mb-2 text-sm font-medium text-gray-900">Email</label>
                          <input value={form.email} onChange={(e)=>setForm({...form,email:e.target.value})} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5"  required />
                        </div>
                        <div class="mb-5">
                          <label class="block mb-2 text-sm font-medium text-gray-900">Contacto</label>
                          <input value={form.primary_contact} onChange={(e)=>setForm({...form,primary_contact:e.target.value})} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5"  required />
                        </div>
                        <div class="mb-5">
                          <label class="block mb-2 text-sm font-medium text-gray-900">Contacto alternativo</label>
                          <input value={form.secondary_contact} onChange={(e)=>setForm({...form,secondary_contact:e.target.value})} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5"  required />
                        </div>       
                        
                        <div class="mb-5">
                          <label class="block mb-2 text-sm font-medium text-gray-900">Nuit</label>
                          <input value={form.nuit} onChange={(e)=>setForm({...form,nuit:e.target.value})} type="number" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5" required />
                        </div>
                        <div class="mb-5">
                          <label class="block mb-2 text-sm font-medium text-gray-900">Link de website</label>
                          <input value={form.website_link} onChange={(e)=>setForm({...form,website_link:e.target.value})} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5"  required />
                        </div>
                        <div class="mb-5">
                           <label for="message" class="block mb-2 text-sm font-medium text-gray-900">Descrição</label>
                           <textarea value={form.description} onChange={(e)=>setForm({...form,description:e.target.value})} id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"></textarea>
                         </div>
                        <button onClick={()=>{
                             updateMicro()
                         }} id="confirm-button" type="button" class="py-2 px-4 w-full text-sm font-medium text-center rounded-[0.3rem] text-white bg-custom_blue-400 sm:w-auto bg-honolulu_blue-500 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300">Actualizar empresa</button>
                                                       
                      </div>
                    </div>}
                     
                    {(data._loaded.includes('settings') && !loading && selectedPage=="nots") && <div  className={`settings ${loading ? 'loading' :''}`}>                         
                         <div className="settings-options">
                                                     <div className="set option-c">
                                                              <div className="top-title">Preferências de Usuário</div>
                                                              <div className="option pop-ups">
                                                                       <div className="title">Receber Notificações por email</div>
                                                                       <div className={` ${loading.email_notifications ? 'loading' :''}`}>
                                                                             <div onClick={()=>{
                                                                                  setSettings([...settings.filter(i=>i.key!="email_notifications"),{
                                                                                      ...settings.filter(i=>i.key=="email_notifications")[0],
                                                                                      value:settings.filter(i=>i.key=="email_notifications")[0].value=="true" ? "false" : "true"
                                                                                  }])
                                                                             }} className={`switch ${settings.filter(i=>i.key=="email_notifications")[0]?.value=="true" ? 'active' :''}`}></div>
                                                                       </div>
                                                              </div>
                    
                                                              {/**user.microcredits?.filter(i=>i.id==selectedMicrocredit)[0]?.plan!="gold" && <div className="option pop-ups">
                                                                        <div className="title">Receber Notificações por SMS</div>
                                                                        <div className={` ${loading.sms_notifications ? 'loading' :''}`}>
                                                                              <div onClick={()=>{
                                                                                setSettings([...settings.filter(i=>i.key!="sms_notifications"),{
                                                                                  ...settings.filter(i=>i.key=="sms_notifications")[0],
                                                                                  value:settings.filter(i=>i.key=="sms_notifications")[0].value=="true" ? "false" : "true"
                                                                                 }])
                                                                                }} className={`switch ${settings.filter(i=>i.key=="sms_notifications")[0]?.value=="true" ? 'active' :''}`}></div>
                                                                        </div>
                                                              </div> */}
                                                            
                                                        </div>
                                                        <div className="py-3">
                                                          <button id="confirm-button" type="button" class="py-2 hidden px-4 w-full text-sm font-medium text-center rounded-[0.3rem] text-gray-500 border sm:w-auto bg-honolulu_blue-500 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300">Cancelar</button>
                                                          <button onClick={()=>{
                                                             updateSettings()
                                                          }} id="confirm-button" type="button" class="ml-2 py-2 px-4 w-full text-sm font-medium text-center rounded-[0.3rem] text-white bg-custom_blue-400 sm:w-auto bg-honolulu_blue-500 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300">Actualizar notificações</button>
                                                       
                                                        </div>
          
                                                  </div>
                       </div>}


                  </div>

                  
            </div>
        </div>
    </main>
    </>
  );
}
