import '../css/main.css'
import './style.css'
import Menu from '../common/menu.js';
import TopBar from "../common/topBar";
import Create from './create.js'
import DeleteDialog from './delete-dialog.js'
import { useState,useEffect } from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import BaiscTable from '../tables/default-table'
import BasicPagination from '../pagination/basic.js';
import DefaultLayout from '../layouts/DefaultLayout.js';
import toast from 'react-hot-toast';
import TopCardSkeleton from '../Skeleton/topCards.js';

export default function Microcredits() {
const {token,user} = useAuth();
const data = useData();  

const [create, setCreate] = useState(false);
const [edit, setEdit] = useState(null);
const [del, setDel] = useState(null);
const [tableSearch,setTableSeach]=useState('');
const {pathname} = useLocation()
const [loading,setLoading]=useState(false)
let initial_filters={start_date:'',end_date:'',status:''}
const [filters,setFilters]=useState(initial_filters);
const [hideFilters,setHideFilters]=useState(true)


let required_data=['microcredits']
const [currentPage,setCurrentPage]=useState(1)
const [updateFilters,setUpdateFilters]=useState(null)
const [search,setSearch]=useState('')
const [filterOptions,setFilterOptions]=useState([])

useEffect(()=>{ 
   if(!user ||  data.updateTable) return
   data.handleLoaded('remove',required_data)
   data._get(required_data,{microcredits:{search:search,page:currentPage,...filters,...data.getParamsFromFilters(filterOptions)}}) 
 },[user,pathname,search,currentPage,updateFilters])


 useEffect(()=>{
   data.handleLoaded('remove',required_data)
 },[updateFilters])

 

 useEffect(()=>{
   if(data.updateTable){
        data.setUpdateTable(null)
        data.handleLoaded('remove',required_data)
        setCurrentPage(1)
        data._get(required_data,{microcredits:{search:search,page:1,...filters,...data.getParamsFromFilters(filterOptions)}}) 
   }
},[data.updateTable])

useEffect(()=>{data.setUpdateTable(Math.random())},[filters])



async function handleItems({status,id}){ 
   data._closeAllPopUps()
   toast.remove()

   toast.loading('A actualizar...') 
   setLoading(true)
  
   try{

      await data.makeRequest({method:'post',url:`api/microcredit/change-status`,withToken:true,data:{
            status,
            id
      }, error: ``},0);

      toast.remove()
      toast.success("Actualizado com sucesso")
      data.setUpdateTable(Math.random())

   }catch(e){

      toast.remove()

      let msg="Acorreu um erro, tente novamente"

      setLoading(false)

      if(e.response){
        if(e.response.status==409){
          msg="Nome já existe"
        } 
        if(e.response.status==404){
            msg="Item não encontrado"
            setEdit(null)
        }
        if(e.response.status==500){
            msg="Erro, inesperado. Contacte seu administrador"
        }

      }else if(e.code=='ERR_NETWORK'){
            msg="Verfique sua internet e tente novamente"
      }

      toast.error(msg)

   }
}



return (

     <DefaultLayout>
           <>
    <main className="dashboard-container">

       {<Create showD={(create || edit) ? true : false}  create={create} setCreate={setCreate} setEdit={setEdit} edit={edit} del={del}/>}
       {<DeleteDialog showD={del ? true : false} del={del}  setDel={setDel}/>}



        <div className="dashboard">
            <Menu/>
            <div className="main-dashboard">
                  <TopBar activePage={'Microcreditos'}/>
                  {!data.loaded.includes('microcredits') && <TopCardSkeleton/>}
                  <div className="center">
                  <div style={{display:!data.loaded.includes('microcredits') ? 'none':'flex'}} className={`stat_page_2`}>
                             <div className="items">

                                  <div className="box">
                                    <div className="icon blue-icon">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="27"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Financiado</div>
                                       <div className="total">{data._cn((data._microcredits?.globalContractTotals?.disbursementValue || 0).toFixed(2))}</div>
                                    </div>
                                 </div>
                                   <div className="box">
                                    <div className="icon green-icon">
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="27"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Recebido</div>
                                       <div className="total">{data._cn((data._microcredits?.globalContractTotals?.paid || 0).toFixed(2))}</div>
                                    </div>
                                 </div>


                                   <div className="box">
                                    <div className="icon red-icon">
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="27"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Em falta</div>
                                       <div className="total">{data._cn((data._microcredits?.globalContractTotals?.left || 0).toFixed(2))}</div>
                                    </div>
                                 </div>

                                 <div className="box">
                                    <div className="icon orange-icon">
                                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="27"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Esperado</div>
                                       <div className="total">{data._cn((data._microcredits?.globalContractTotals?.expected || 0).toFixed(2))}</div>
                                    </div>
                                 </div>
                             </div>
               </div>



                       <div className="center_search">

                           <div className="search_container">
                                 <div className="search-div">
                                      <input type="text" placeholder="Pesquisar..." value={search} onChange={(e)=>{
                                          setSearch(e.target.value)
                                          data.setUpdateTable(Math.random())
                                       }}/>
                                       <div className="search-btn">
                                       <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg>
                                    </div>
                                 </div>
                           </div>

                          <div className="flex items-center">
                              <div onClick={()=>
                                 data.setUpdateTable(Math.random())
                              } className="mr-2 cursor-pointer hover:opacity-65">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z"/></svg>
                              </div>
                              <div className="btn-add-item" onClick={()=>setCreate(true)}>
                                       <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg>
                                       <span>Adicionar</span>
                              </div>
                           </div>


                       </div>


  
                        <div className={`extra-filters ${hideFilters ? '_hide':''}`}>

                        <label onClick={()=>setHideFilters(!hideFilters)} className="underline sm:hidden text-[15px]  text-custom_blue-400 absolute top-4 cursor-pointer">{hideFilters ? 'Mostrar filtros':'Esconder filtros'}</label>

                        <div className="input-container">
                           <div className="inputs">
                                 <span>Data de inicio</span>
                                 <input type="date" value={filters.start_date} onChange={(e)=>setFilters({...filters,start_date:e.target.value})} />
                           </div>

                           <div className="inputs">
                                 <span>Data de fim</span>
                                 <input type="date" value={filters.end_date} onChange={(e)=>setFilters({...filters,end_date:e.target.value})} />
                        </div>

                           <div className="inputs">
                              <span>Estado</span>
                              <select value={filters.status} onChange={e=>setFilters({...filters,status:e.target.value})}>
                                   <option  selected value="">Selecionar estado</option>
                                   <option  value={'pending'}>Pedente</option>
                                   <option  value={'active'}>Active</option>
                                   <option  value={'inactive'}>Inactivo</option>
                              </select>
                           </div>


                        </div>

                        <div className="options">
                        <span onClick={()=>setFilters(initial_filters)}>
                           <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-122q-121-15-200.5-105.5T160-440q0-66 26-126.5T260-672l57 57q-38 34-57.5 79T240-440q0 88 56 155.5T440-202v80Zm80 0v-80q87-16 143.5-83T720-440q0-100-70-170t-170-70h-3l44 44-56 56-140-140 140-140 56 56-44 44h3q134 0 227 93t93 227q0 121-79.5 211.5T520-122Z"/></svg> </span>
                        </div>

                        </div>

                        <div className="dasboard-item-list-container">
                        <div className="top-section">
                           <div className="left-side">
                                       <div className="show">
                                                <span>Resultados:</span>
                                                <label>{data._microcredits?.total}</label>
                                       </div>
                                 
                           </div>
                           <div className="right-side">
                                       
                           </div>
                        </div>
                     <BaiscTable canAdd={user?.role=="admin"} addRes={()=>{
                        setCreate(true)
                     }} loaded={data._loaded.includes('microcredits')} header={[
                         <BaiscTable.MainActions data={data} options={{
                          deleteFunction:'default',
                          deleteUrl:'api/microcredits/delete'}
                         } items={data._microcredits?.data || []}/>,
                         'ID',
                         'Nome',
                         'Localização',
                         'Email',
                         'Contacto',
                         'Nuit',
                         'Estado',
                         'Plano',
                         'Periodo de subscrição',
                         'Data de activação do plano',
                         'Data de vencimento do plano',
                         'Gestores',
                         'Modelo de contracto',
                         'Criado em'
                        ]
                      }

                       body={(data._microcredits?.data || []).map((i,_i)=>(
                              <BaiscTable.Tr>
                                <BaiscTable.Td>
                                  <BaiscTable.Actions data={data}  options={{
                                       deleteFunction:'default',
                                       deleteUrl:'api/microcredits/delete',
                                       id:i.id}
                                  }/>
                                </BaiscTable.Td>
                                <BaiscTable.Td onClick={()=>setEdit(i)}>{i.id}</BaiscTable.Td>
                                <BaiscTable.Td onClick={()=>setEdit(i)}>{i.name}</BaiscTable.Td>
                                <BaiscTable.Td onClick={()=>setEdit(i)}>{i.location}</BaiscTable.Td>
                                <BaiscTable.Td onClick={()=>setEdit(i)}>{i.email}</BaiscTable.Td>
                                <BaiscTable.Td onClick={()=>setEdit(i)}>{i.primary_contact}</BaiscTable.Td>
                                <BaiscTable.Td onClick={()=>setEdit(i)}>{i.nuit}</BaiscTable.Td>
                                <BaiscTable.Td onClick={()=>setEdit(i)}>
                                <button style={{border:0}} type="button"  class={`text-gray-900 cursor-default ${i.status=="pending" ? "bg-[#eab308]" : i.status=="active" ? 'bg-green-500':'bg-gray-400'}   focus:outline-none  font-medium rounded-[0.3rem] text-sm px-2 py-1 text-center inline-flex items-center`}>
                                        {i.status}
                                </button>
                                
                                </BaiscTable.Td>
                                <BaiscTable.Td onClick={()=>setEdit(i)}>
                                  <button style={{border:0}} type="button"  class={`text-gray-900 cursor-default ${i.plan=="free" ? 'bg-green-500':'bg-custom_blue-400'}   focus:outline-none  font-medium rounded-[0.3rem] text-sm px-2 py-1 text-center inline-flex items-center`}>
                                         {i.plan}
                                  </button>
                                </BaiscTable.Td>
                                <BaiscTable.Td onClick={()=>setEdit(i)}>{i.active_plan?.period=="monthly" ? 'Mensal': i.active_plan?.period =="yearly" ? 'Anual' : '-'}</BaiscTable.Td>

                                <BaiscTable.Td onClick={()=>setEdit(i)}>{i.active_plan ? i.active_plan?.activation_date?.split('T')[0]?.split('-')?.reverse()?.join('/') :"-"}</BaiscTable.Td>
                                <BaiscTable.Td onClick={()=>setEdit(i)}>{i.active_plan ? i.active_plan?.expiration_date?.split('T')[0]?.split('-')?.reverse()?.join('/') :"-"}</BaiscTable.Td>
                                <BaiscTable.Td onClick={()=>setEdit(i)}>{i.user_microcredits.map(i=>i.name).join(', ')}</BaiscTable.Td>
                                <BaiscTable.Td>
                                      {i.google_docs_document_id ? (
                                       <div onClick={()=>window.open(`https://docs.google.com/document/d/${i.google_docs_document_id}/edit?tab=t.0`)}>
                                       <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z"/></svg>
                                       </div>
                                      ) : '-'}
                                </BaiscTable.Td>
                                 <BaiscTable.Td onClick={()=>setEdit(i)}>
                                                {i.created_at.split('T')[0]?.split('-')?.reverse()?.join('/')}
                                 </BaiscTable.Td>
                                <BaiscTable.AdvancedActions data={data} w={200} id={i.id} items={[
                                    {name:'Desactivar',hide:i.status=="inactive",onClick:()=>{handleItems({status:'inactive',id:i.id})},icon:(<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>)},
                                    {name:'Activar',hide:i.status=="active",onClick:()=>{handleItems({status:'active',id:i.id})},icon:(<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>)},
                                ]}/>
                            </BaiscTable.Tr>
                        ))}

                      
              />

                   <BasicPagination show={data._loaded.includes('microcredits')} from={'microcredits'} setCurrentPage={setCurrentPage} total={data._microcredits?.total}  current={data._microcredits?.currentPage} last={data._microcredits?.totalPages}/>

                       </div>

                  </div>

                  
            </div>
        </div>
    </main>
    </>
     </DefaultLayout>
  );
}
