
import { useEffect, useState} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import DefaultFormSkeleton from '../Skeleton/defaultForm.js';

const Create = ({create,setCreate, edit , setEdit, del,showD }) => {

const data = useData();

const initial_form={id:'',name:'',email:'',primary_contact:'',microcredit_id:'',job_title:''} 
const [itemToEditLoaded,setItemToEditLoaded]=useState(false)
const {pathname} = useLocation()

const [formData,setFormData]=useState(initial_form)

const [loading,setLoading]=useState(false)

const {user} = useAuth()

let required_data=['microcredits_list']
useEffect(()=>{   
      if(!user) return
      data.handleLoaded('remove',required_data)
      data._get(required_data) 
},[user,pathname])


useEffect(()=>{
    if(edit){
       setCreate(false)
    }
},[edit])


useEffect(()=>{
    if(create){
       setEdit(false)
    }

    setFormData(initial_form)
},[create])


useEffect(()=>{
   console.log(formData)
},[formData])





async function handle_manager(){

   if(!formData.name || !formData.email || !formData.primary_contact || !formData.microcredit_id) {
       toast.error('Preencha todos os campos!')
       return 
   }

   if(!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email.trim()))){
        toast.error('Email inválido')
        return
   }

   setLoading(true)
  
   try{

     if(edit){

       await data.makeRequest({method:'post',url:`api/manager/update/`+edit.id,withToken:true,data:{
         ...formData
       }, error: ``},0);
       
       toast.success('Actualizado com sucesso!')
       setLoading(false)

     }else{

       await data.makeRequest({method:'post',url:`api/manager/create`,withToken:true,data:{
         ...formData
       }, error: ``},0)

       toast.success('Criado com sucesso!')
       setLoading(false)

     }

     setEdit(null)
     setCreate(null)
     data.setUpdateTable(Math.random())

   }catch(e){

       let msg="Acorreu um erro, tente novamente"
       console.log(e)
       setLoading(false)

       if(e.response){
        if(e.response.status==409){
          msg="Email já existe na microcrédito"
        }  
         if(e.response.status==400){
             msg="Dados inválidos"
         }
         if(e.response.status==500){
             msg="Erro, inesperado. Contacte seu administrador"
         }

       }else if(e.code=='ERR_NETWORK'){
             msg="Verfique sua internet e tente novamente"
       }

       toast.error(msg)
       setLoading(false)

     }
     
}


useEffect(()=>{
  if(!edit){
      setItemToEditLoaded(false)
  }
},[edit])

useEffect(()=>{

    if(!user || !edit?.id){
        return
    }

    (async()=>{
      
      try{
      let response=await data.makeRequest({method:'get',url:`api/manager/`+edit.id,withToken:true, error: ``},100);
     
     
      setItemToEditLoaded(true)
      setFormData({...response,microcredit_id:response.user_microcredits.id})
  
      }catch(e){

        let msg="Acorreu um erro, tente novamente"

        setLoading(false)

        if(e.response){

          if(e.response.status==409){
            msg="Email já existe"
          } 
          
          if(e.response.status==404){
              msg="Item não encontrado"
              setEdit(null)
          }
          if(e.response.status==500){
              msg="Erro, inesperado. Contacte seu administrador"
          }

        }else if(e.code=='ERR_NETWORK'){
              msg="Verfique sua internet e tente novamente"
        }

    }
    
  })()

},[user,pathname,edit])






return (
 <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
  <div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
  <div className={`create-microcredit ${loading && 'loading'} ${(edit && !itemToEditLoaded) ? 'loading-editing-item':''}`}>
                  {(edit && !itemToEditLoaded) && <DefaultFormSkeleton/>}
                  <div className="top-title">{edit ? 'Editar' :' Adicionar'} gestor</div>  
                  <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                  <div className="input-container">
                     <div className="inputs">
                           <span>Nome <label class="mandatory">*</label></span>
                           <input value={formData.name} onChange={e=>setFormData({...formData,name:e.target.value})} type="text" placeholder="Nome do gestor"/>
                     </div>

                     <div className="inputs">
                           <span>Email <label class="mandatory">*</label></span>
                           <input value={formData.email} onChange={e=>setFormData({...formData,email:e.target.value})} type="text" placeholder="Email do gestor"/>
                     </div>
                   

                     <div className="inputs">
                            <span>Contacto <label class="mandatory">*</label></span>
                            <input value={formData.primary_contact} onChange={e=>setFormData({...formData,primary_contact:e.target.value.replace(/[^0-9]/g, '')})} type="text" placeholder="Contacto do gestor"/>
                     </div>

                     <div className="inputs">
                           <span>Cargo </span>
                           <input value={formData.job_title} onChange={e=>setFormData({...formData,job_title:e.target.value})} type="text" placeholder="Cargo"/>
                     </div>
                   

                    {/**<div className="inputs full search-select">
                                          <span>Microcréditos</span>
                                          <MultiSelect options={data._microcredits_list} setSelectedOptions={(selected)=>{
                                               setFormData({...formData,microcredits:selected})
                                          }} selectedOptions={formData.microcredits} placeholder={'Adicione microcrédito'} noOptionsMessage={'Sem opções'}/>
                    </div> */}

                   {(user?.role=="admin") &&  <div className="inputs">
                             <span>Microcredito <label class="mandatory">*</label></span>
                             <select value={formData.microcredit_id} onChange={e=>setFormData({...formData, microcredit_id:e.target.value})}>
                                 <option disabled selected value="">Selecione uma microcredito</option>
                                  {data._microcredits_list.map(i=>(
                                        <option key={i.id} selected={formData.microcredit_id === i.id} value={i.id}>{i.name}</option>
                                  ))}
                             </select>
                   </div>}

                  
                  </div>


              <div className="options">
                  <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>
                  <div className="div_btn">
                     <button className="save" onClick={handle_manager}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>
                     <span className="loader"></span>
                  </div>
              </div>

        </div>
         </div>


)
};

export default Create;
