import React, { useEffect, useState } from 'react'
import { useData } from '../../contexts/DataContext'
import Loader from '../loaders/loader'
export default function AllClientAssets({show,setShow,form,setForm}) {
  const data=useData()
  const [search,setSeach]=useState('')
  const [loadedAssets,setLoadedAssets]=useState([])
  const [loading,setLoading]=useState(true)
 

  async function getAssets() {

    try{

        let res=await data.makeRequest({method:'post',url:'api/all-client-assets',withToken:true,data:{
            client_id:form.client_id
        }, error: ``},1000)
        setLoading(false)
        setLoadedAssets(res)
   
     }catch(e){

     }
   
    
  }

  useEffect(()=>{

    if(!show || !form.client_id){
        setLoading(true)
        setLoadedAssets([])
        return
    }

    getAssets()

  },[show])

  return (
        <div style={{zIndex:999}} id="crud-modal" tabindex="-1" aria-hidden="true" class={`overflow-y-auto  bg-[rgba(0,0,0,0.3)] flex ease-in delay-100 transition-all ${!show ? 'opacity-0 pointer-events-none translate-y-[50px]':''} overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100vh] max-h-full`}>
            <div class="relative p-4 w-full max-w-[550px] max-h-full">

               
                
                <div class={`relative ${!show ? 'hidden':''} bg-white rounded-lg shadow pb-[50px] transition-none `}>
                    
                    {loading && <div className="flex flex-col w-full pt-16 justify-center items-center">
                        <Loader/>
                        <span>A carregar...</span>
                    </div>}


                    <div className={`${loading ? 'hidden':''} `}>
                    <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 class="text-lg font-semibold text-gray-900">
                            Selecione para adicionar
                        </h3>
                    </div>

                    <div class="p-4 md:p-5 flex flex-wrap max-h-[200px] overflow-y-auto">
                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500 min-w-[500px]">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
                                <tr>
                                    <th scope="col" class="px-6 py-3">
                                       <span className="ml-5">Descrição</span>
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                       Valor
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                       Imagens/Documentos
                                    </th>
                                    
                                </tr>
                            </thead>

                            <tbody>
                                 {loadedAssets.map((i,_i)=>(
                                    <tr key={_i} class="bg-white hover:bg-gray-50">
                                        <th scope="row" class="px-6 py-4 flex items-center whitespace-nowrap">
                                            <div class="flex items-center mr-2">
                                                <input onClick={()=>{
                                                      if(form.contract_assets.some(f=>f.id==i.id)){
                                                        setForm({...form,contract_assets:form.contract_assets.filter(f=>f.id!=i.id)})
                                                      }else{
                                                         setForm({...form,contract_assets:[i,...form.contract_assets]})
                                                      }
                                                }} checked={form.contract_assets.some(f=>f.id==i.id)} id="checkbox-table-3" type="checkbox" class="w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 dark:focus:ring-blue-600  focus:ring-2"/>
                                             </div>
                                             {i.description}
                                        </th>
                                        <td class="px-6 py-4">
                                            {i.amount ? data._cn(i.amount) : '-'}
                                        </td>
                                        <td class="px-6 py-4 font-medium ">
                                        <div class="media-items" style={{display:'flex',maxWidth:'100px',overflow:'auto'}}>
                                          {(i.media).map(f=>(
                                                <a key={f.name} target="_blank" href={`${process.env.REACT_APP_BASE_URL}/uploaded_files/${f.name}`}>
                                                        <div className="item" style={{backgroundPosition:'center',backgroundSize:'cover',backgroundImage:`url('${process.env.REACT_APP_BASE_URL}/uploaded_files/${f.name.replace(' ','%20')}')`}}>
                                                        </div>
                                                    </a>
                                                ))}
                                           </div>
                                        </td>
                                   </tr>
                                 ))}
                            </tbody>
                        </table>
                        {(loadedAssets.length==0 && !loading) && <span className="p-5 flex w-full justify-center">Nenhum bem associado</span>}
                    </div>
                  </div>
                    </div>

                </div>

               

                {show && <div onClick={()=>{
                  setShow(false)

                 }} className="w-[30px] cursor-pointer h-[30px] absolute right-5 top-5 rounded-full bg-gray-300 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" fill="#5f6368"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                </div>}


            </div>
        </div> 

  )
}
