import React from 'react'
import { useData } from '../../contexts/DataContext'
import { useLocation } from 'react-router-dom'
export default function ContractVariables({show,setShow}) {
  const data=useData()
  const {pathname}=useLocation()

  return (
        <div style={{zIndex:999}} id="crud-modal" tabindex="-1" aria-hidden="true" class={`overflow-y-auto  bg-[rgba(0,0,0,0.3)] flex ease-in delay-100 transition-all ${!show ? 'opacity-0 pointer-events-none translate-y-[50px]':''} overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100vh] max-h-full`}>
            <div class="relative p-4 w-full max-w-[1024px] max-h-full">
                
                <div class="relative bg-white rounded-lg shadow pb-[50px]">
                    
                    <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 class="text-lg font-semibold text-gray-900">
                           Lista de variáveis do contracto
                        </h3>
                    </div>

                    <div class="p-4 md:p-5 flex flex-wrap  overflow-y-auto">
                            <div className="contract-template-placeholders">
                                 <span className="title">Parametros</span>
                                  
                                   <div className="params">
                                      <div><span className="name">Nome da empresa:</span><span className="p">{`{nome_da_empresa}`}</span></div>
                                      <div><span className="name">Localização da empresa:</span><span className="p">{`{localizacao_da_empresa}`}</span></div>
                                      <div><span className="name">Nuit da empresa:</span><span className="p">{`{nuit_da_empresa}`}</span></div>
                                      <div><span className="name">Nome do cliente:</span><span className="p">{`{nome_do_cliente}`}</span></div>
                                      <div><span className="name">BI do cliente:</span><span className="p">{`{bi_do_cliente}`}</span></div>
                                      <div><span className="name">Endereço do cliente:</span><span className="p">{`{endereco_do_cliente}`}</span></div>
                                      <div><span className="name">Bairro do cliente:</span><span className="p">{`{bairro_do_cliente}`}</span></div>
                                      <div><span className="name">Contacto primário do cliente:</span><span className="p">{`{contacto_primario_do_cliente}`}</span></div>
                                      <div><span className="name">Carta de condução do cliente:</span><span className="p">{`{carta_de_conducao_do_cliente}`}</span></div>
                                      <div><span className="name">Avenida do cliente:</span><span className="p">{`{avenida_do_cliente}`}</span></div>
                                      <div><span className="name">Número da casa do cliente:</span><span className="p">{`{numero_da_casa_do_cliente}`}</span></div>
                                      <div><span className="name">Idade do cliente:</span><span className="p">{`{idade_do_cliente}`}</span></div>
                                      <div><span className="name">Nome do Pai:</span><span className="p">{`{nome_do_pai_do_cliente}`}</span></div>
                                      <div><span className="name">Nome da Mãe:</span><span className="p">{`{nome_da_mae_do_cliente}`}</span></div>
                                      <div><span className="name">Número de passaporte do cliente:</span><span className="p">{`{numero_de_passaporte_do_cliente}`}</span></div>
                                      <div><span className="name">Contacto secudário do cliente:</span><span className="p">{`{contacto_secundario_do_cliente}`}</span></div>
                                      <div><span className="name">Email do cliente:</span><span className="p">{`{email_do_cliente}`}</span></div>
                                      <div><span className="name">Valor financiado:</span><span className="p">{`{valor_finaciado}`}</span></div>
                                      <div><span className="name">Valor financiado por extenso:</span><span className="p">{`{valor_finaciado_por_extenso}`}</span></div>
                                      <div><span className="name">Valor a pagar:</span><span className="p">{`{valor_a_pagar}`}</span></div>
                                      <div><span className="name">Valor a pagar por extenso:</span><span className="p">{`{valor_a_pagar_extenso}`}</span></div>
                                      <div><span className="name">Período em dias:</span><span className="p">{`{periodo_em_dias}`}</span></div>
                                      <div><span className="name">Profissão do cliente:</span><span className="p">{`{profissao_do_cliente}`}</span></div>
                                      <div><span className="name">Data:</span><span className="p">{`{data}`}</span></div>
                                      <div><span className="name">Valor pago:</span><span className="p">{`{valor_pago}`}</span></div>
                                      <div><span className="name">Valor pago por extenso:</span><span className="p">{`{valor_pago_por_extenso}`}</span></div>
                                      <div><span className="name">Nome do representante:</span><span className="p">{`{nome_do_representante}`}</span></div>
                                      <div><span className="name">Cargo do representante:</span><span className="p">{`{cargo_do_representante}`}</span></div>
                                      <div><span className="name">Data de pagamento:</span><span className="p">{`{data_de_pagamento}`}</span></div>
                                      <div><span className="name">Estado civil do cliente:</span><span className="p">{`{estado_civil_do_cliente}`}</span></div>
                                      <div><span className="name">Data de emissão do BI do cliente:</span><span className="p">{`{data_de_essisao_do_bi_do_cliente}`}</span></div>
                                      <div><span className="name">Local de emissão do BI do cliente:</span><span className="p">{`{local_de_emissao_do_bi_do_cliente}`}</span></div>
                                      <div><span className="name">Local de emissão da Carta de Condução:</span><span className="p">{`{local_de_emissao_da_carta_de_conducao_do_cliente}`}</span></div>
                                      <div><span className="name">Data de emissão da Carta de Condução:</span><span className="p">{`{data_de_emissao_da_carta_de_conducao_do_cliente}`}</span></div>
                                      <div><span className="name">Local de emissão do Passaporte:</span><span className="p">{`{local_de_emissao_do_passaporte_do_cliente}`}</span></div>
                                      <div><span className="name">Data de emissão do Passaporte:</span><span className="p">{`{data_de_emissao_do_passaporte_do_cliente}`}</span></div>
                                      <div><span className="name">Prazo de pagamento:</span><span className="p">{`{prazo_de_pagamento}`}</span></div>
                                      <div><span className="name">Quantidade de prestações:</span><span className="p">{`{quantidade_de_prestacoes}`}</span></div>
                                      <div><span className="name">NUIT do cliente:</span><span className="p">{`{nuit_do_cliente}`}</span></div>
                                      <div><span className="name">Data da primeira prestação:</span><span className="p">{`{data_da_primeira_prestacao}`}</span></div>
                                      <div><span className="name">Juros:</span><span className="p">{`{juros}`}</span></div>
                                      <div><span className="name">Percentagem de juros:</span><span className="p">{`{percentagem_de_juros}`}</span></div>
                                      <div><span className="name">Percentagem de juros por extenso:</span><span className="p">{`{percentagem_de_juros_por_extenso}`}</span></div>
                                   </div>

                             </div> 

                    </div>

                </div>

               

                <div onClick={()=>{

                  setShow(false)

                 }} className="w-[30px] cursor-pointer h-[30px] absolute right-5 top-5 rounded-full bg-gray-300 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" fill="#5f6368"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                </div>


            </div>
        </div> 

  )
}
