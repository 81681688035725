
import { useEffect, useState,useRef} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx';
const Create = ({create,setCreate, edit , setEdit,showD,export_details}) => {

 
const [excelData, setExcelData]=useState([])
const [contractsExelData, setContractsExelData]=useState([])
const [loading,setLoading]=useState(false)
const fileInputRef = useRef(null);
const data = useData();
const {selectedMicrocredit, user} = useAuth();
const [tableHeader, setTableHeader]=useState([])
const [PostRes, setPostRes]=useState(false)


const handleDrop = (e) => {
  e.preventDefault();
  const file = e.dataTransfer.files[0];
  let ext=file.name.split('.')[file.name.split('.').length - 1]

  if(ext != "xlsx" && ext != "xls"){
    toast.error('Apenas arquivos excel são permitidos')
    resetInputValue();
    return
  }

  getExcelFile(file)
};

const resetInputValue = () => {
  fileInputRef.current.value = '';
};

const handleDragOver = (e) => {
  e.preventDefault();
};



const exportToExcel = () => {
  let _data
  if(export_details.name=="clientes"){
    _data = [
      {
        'Nome': 'Ana Tivane',
        'Email': 'anativane@gmail.com',
        'Contacto primário': '840003454',
        'Contacto secudário': '843445434',
        'Bairro': 'Matola-Rio',
        'Quarteirão': 'B1',
        'Número da casa': '434',
        'Avenida': 'Marcos',
        'Data de nascimento': '15/03/1990',
        'Número de B.I': '2834238464234D',
        'Local de emissão de BI': 'Maputo',
        'Data de emissão de BI': '07/04/2018',
        'Estado civil': 'Solteira',
        'Carta de condução': 'Número da carta',
        'Número de passaporte': 'Número de passaporte',
        'Nuit': '123456789',
        'Endereço': 'Rua das Flores, Casa 12',
      },
      {
        'Nome': 'Carlos Mendez',
        'Email': '',
        'Contacto primário': '820112233',
        'Contacto secudário': '',
        'Bairro': 'Polana',
        'Quarteirão': 'A3',
        'Número da casa': '56',
        'Avenida': 'Samora Machel',
        'Data de nascimento': '22/05/1985',
        'Número de B.I': '1234567890123X',
        'Local de emissão de BI': 'Beira',
        'Data de emissão de BI': '15/03/2018',
        'Estado civil': 'Casado',
        'Carta de condução': 'XYZ12345',
        'Número de passaporte': 'AB1234567',
        'Nuit': '987654321',
        'Endereço': 'Av. 25 de Setembro, Edifício 20',
      }
    ];
    
  }else if(export_details.name=="contratos"){
  _data=[{'Nome do cliente':'Ana Marcos','Apelido do cliente':'Sitoe','Contacto do cliente':'842564620','Email do cliente':'anasitoe@gmail.com','Valor de desembolso':1000,'Data de desembolso':'24/03/2024','Percentagem de juros':30,'Duração em dias':30,'Número de parcelas':2,'Data de pagamento de parcelas':'30/03/2024, 10/04/2024','Valor por pagar':2300,'Valor pago':1300,'Número da conta de desembolso':'840003454','Nome da conta de desembolso':'M-pesa','Nomes das conta de reembolso':'BCI, M-pesa','Números das contas de reembolso':'86353453454, 8435345345','Notas':''}] 
 }
  const workbook = XLSX.utils.book_new();
  const sheetData = XLSX.utils.json_to_sheet(_data);
  XLSX.utils.book_append_sheet(workbook, sheetData, 'Sheet1');
  XLSX.writeFile(workbook, `Modelo importação de arquivos excel.xlsx`);
};



function handleExelData(data){

  let datafields

  if(export_details.name=="clientes"){

    datafields = [
      { name: 'Nome', field: 'name' },
      { name: 'Email', field: 'email' },
      { name: 'Contacto primário', field: 'primary_contact' },
      { name: 'Contacto secudário', field: 'secondary_contact' },
      { name: 'Bairro', field: 'neighborhood_name' },
      { name: 'Quarteirão', field: 'block_number' },
      { name: 'Número da casa', field: 'house_number' },
      { name: 'Avenida', field: 'avenue' },
      { name: 'Data de nascimento', field: 'date_of_birth' },
      { name: 'Número de B.I', field: 'identity_number' },
      { name: 'Local de emissão de BI', field: 'place_of_issuance_of_the_identity_card' },
      { name: 'Data de emissão de BI', field: 'date_of_issuance_of_the_identity_card' },
      { name: 'Estado civil', field: 'marital_status' },
      { name: 'Carta de condução', field: 'driver_license_number' },
      { name: 'Número de passaporte', field: 'passport_number' },
      { name: 'Nuit', field: 'nuit' },
      { name: 'Endereço', field: 'address' }
  ];
    
  }else if(export_details.name=="contratos"){
    datafields=[{name:'Nome do cliente',field:'client_name'},{name:'Apelido do cliente',field:'client_last_name'},{name:'Contacto do cliente',field:'client_number'},{name:'Data de desembolso',field:'disbursement_date'},{name:'Email do cliente',field:'client_email'},{name:'Número de parcelas',field:'periods'},{name:'Data de pagamento de parcelas',field:'installments_payday'},{name:'Valor de desembolso',field:'disbursement_value'},{name:'Percentagem de juros',field:'fees_percentage'},{name:'Duração em dias',field:'days'},{name:'Valor pago',field:'paid'},{name:'Número da conta de desembolso',field:'out_account_number'},{name:'Nome da conta de desembolso',field:'out_account_name'},{name:'Números das contas de reembolso',field:'in_account_numbers'},{name:'Nomes das conta de reembolso',field:'in_account_names'},{name:'Notas',field:'notes'}]   
  }
   
  setTableHeader(datafields)

  let new_data=[]

  data.forEach(item=>{
      let new_item={}
      datafields.forEach(f=>{
            if(item[f.name] || item[f.name]==0) {
               new_item[f.field]=item[f.name]
            } 
      })
      
      if(Object.keys(new_item).length){
        new_item={...new_item,microcredit_id:selectedMicrocredit,created_by_id:user.id}
        new_data.push({...new_item,id:Math.random()})
      } 
  })
 
  
  if(!new_data.length){
    toast.error('Nenhum dado foi capturado, Verifique os campos')
  }else{
     setExcelData(new_data)
  }

  moveTable()

}


function moveTable(){
  setTimeout(()=>{
    document.querySelector("#root > main > div.dialog-container-bg.show > div.create-microcredit.import-excel.false > div.table-container").scrollLeft=0
  },200)
 
}

async function send_data(){

   setLoading(true)
    
   try{
    let response = await data.makeRequest({method:'post',url:`api/${export_details.api}/import`,data:{
      data:excelData,
      microcredit_id:selectedMicrocredit
    },withToken:true, error: ``},0);
    setExcelData(response)
    setLoading(false)
    setPostRes(true)
    data.setUpdateTable(Math.random())
    if(response.some(i=>i.invalid)){
        toast.error('Alguns dados não foram importados!')
    }else{
         toast.success('Todos os dados foram importados com sucesso')
    }
   
    moveTable()

    console.log({response})

  }catch(e){
       console.log({e})
       setLoading(false)  
       toast.error('Ocorreu um erro ao importar. Verifique os campos!')
       moveTable()
   }

}


function  getExcelFile(file){
  const reader = new FileReader();
  reader.onload = (e) => {
    const workbook = XLSX.read(e.target.result, { type: 'binary' });
    const sheetName = workbook.SheetNames[0]; // Assuming data is in the first sheet
    const sheet = workbook.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_json(sheet);
    handleExelData(data);
    resetInputValue();
  };
  reader.readAsBinaryString(file);
}

useEffect(()=>{
    if(edit){
       setCreate(false)
       setPostRes(false)
    }
},[edit])

useEffect(()=>{
    if(create){
       setEdit(false)
       setExcelData([])
       setPostRes(false)
    }
},[create])


function get_file(){
  setExcelData([])
  setPostRes(false)
  
}


function removeItem(id){
   setExcelData(excelData.filter(i=>i.id!=id))
}


console.log({excelData,tableHeader})


return (
  <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
  <div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
<div  className={`create-microcredit !max-w-[90%] client import-excel ${loading && 'loading'} `}>
                  <div className="top-title">Importar {export_details.name}</div>  
                  <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                  <div className="note" style={{display:!excelData.length ? 'block' :'none'}}>
                     Precisa de ajuda para importar? {/*** Veja este  
                    <Link to={export_details.api=="loans"  ? '/helpcenter/how-to-import-contracts-from-excel-file' : '/helpcenter/how-to-import-clients-from-excel-file'}>Artigo</Link> ou  */}
                     <label onClick={exportToExcel}>Baixe o modelo</label>.
                  </div>

                  <div className="excel-details" style={{display:excelData.length ? 'flex' :'none'}}>
                      <div className="_title" >
                        {!PostRes ? `Dados encontrados: ${excelData.length}` : `Dados adicionados:${excelData.filter(i=>!i.invalid).length}/ ${excelData.length}`}  
                      </div>
                      <button onClick={()=>get_file()}>Subistituir por outro arquivo</button>
                  </div>


                  <label style={{display:!excelData.length ? 'flex' :'none'}}><div className='box-content'
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}>
                          
                          <span>Arraste ou clique para selecionar arquivos</span>
                        
                          <span>(.xlsx, .xls)</span>
                         
                          <input
                                  type="file"
                                  accept=".xlsx, .xls"
                                  onChange={(e) => getExcelFile(e.target.files[0])}
                                  ref={fileInputRef}
                          />
                            <button>
                                Carregar arquivo
                            </button>
                           

                           
                  </div></label>

                  <div className="table-container" style={{display:excelData.length ? 'block' :'none'}}>
                   
                  <table>
                      <thead>
                      <tr>
                      <th></th>

                        {PostRes ? <th>Resultado</th> :''} 
                        {tableHeader.map((i)=>(
                           <th key={i.field}>
                               {i.name}
                           </th>
                        ))}

                      </tr>
                    </thead>
                <tbody>

                  
       
              {excelData.map((i,_i)=>(
                  <tr className="tr" key={Math.random()}> 
                      <td>
                         <div class="btn-remove" onClick={()=>removeItem(i.id)}><svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 -960 960 960"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"></path></svg></div>
                      </td>
                      {PostRes ? <td>{i.good  ? <label> Tente novamente </label> : i.invalid ? <label style={{color:'red'}}> ({i.message}) </label> : <label style={{color:'green'}}> Adicionado </label> }</td> :''}
                     
                      {tableHeader.map(j=>{
                          if(i[j.field] || i[j.field]==0) {
                             return (<td  key={Math.random()}>
                                 {/**<input  onChange={(e)=>{

                                     setExcelData(excelData.map((f,_f)=>{
                                         if(_f==_i){
                                          return {...f,[j.field]:e.target.value}
                                         }else{
                                           return f
                                         }
                                     }))

                                  }}
                                 
                                  value={i[j.field]}
                                  className={`rounded-[0.3rem] w-auto`} 
                                  /> */}

                              { i[j.field] }
                              </td>)
                          }else{
                            return <td  key={Math.random()}>-</td>
                          }
                      })}

                  </tr>
              ))}
       
      </tbody>
    </table>

                  </div>


                 <div className="options" style={{display:excelData.length ? 'flex' :'none'}}>
                    <button className="cancel"  onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>{PostRes && !excelData.filter(i=>i.invalid).length ? 'Fechar' :'Cancelar'}</button>
                    <div className="div_btn" style={{display:PostRes && !excelData.filter(i=>i.invalid).length ? 'none' :'initial'}}>
                      <button className="save" onClick={send_data}><span>{PostRes ? 'Tentar novamente' : 'Enviar'}</span></button>
                      <span className="loader"></span>
                    </div>
                </div>

        </div>
         </div>


)
};

export default Create;
