import * as React from 'react';
import './style.css'
import { useNavigate } from 'react-router-dom';
import { useData } from '../../../contexts/DataContext';


export default function Compontent({items}) {

  const navigate = useNavigate();

  function handleEdit(id) {
    navigate('/loan/'+id)
  }

  const data=useData()
  
  return (
      <div class="table-container skeleton-bg">
    <table>
      <thead>
        <tr>
          <th>Cliente</th>
          <th>Montante</th>
          <th>Dias em falta</th>
          <th>Vencimento</th>
          <th>Estado</th>
        </tr>
      </thead>
      <tbody>
       
          {items.map(i=>(
              <tr className="tr" onClick={()=>handleEdit(i.contract_id)}> 
                  <td>{i.client.name}</td>
                  <td>{data._cn((i.total_to_pay - i.paid).toFixed(2))}</td>
                  <td>{i.remaining_days == 0 ? "Hoje" : i.remaining_days < 0 ? - i.remaining_days+" dias atrás" : i.remaining_days}</td>
                  <td>{i.end?.split('-')?.reverse()?.join('/')}</td>
                  <td>
                  <div style={{padding:'0 0.3rem', backgroundColor:!i.status || i.status=='pending' || i.status=='parcial' ? '#F9CE66': i.status=="paid" ? '#4ACD35' : '#ed143d85', color: '#111' , padding:'0.2rem 0',borderRadius:'2rem',fontSize:'.9rem'}}>
                    <span style={{padding:'0 0.3rem', display:'flex',minWidth:'30px',justifyContent:'center'}}>  {i.status=='pending' || !i.status ? 'Pendente':  i.status=="paid" ? 'Pago' :  i.status=="parcial" ? 'Parcialmente' : 'Atrasado'}</span>
                  </div>
                  </td>
              </tr>

           ))}
       
      </tbody>
    </table>

     {!items.length && <span style={{fontSize:'0.9rem',display:'flex',justifyContent:"center",padding:'2rem',opacity:0.4}}>Sem registros</span>}
  </div>
  );


}
