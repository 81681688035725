import { useData } from '../../../../contexts/DataContext';
import './style.css'
import React from 'react';

export default function Compontent({content,first_column_name}) {
  const data=useData()
  return (
      <>
      <div>
      <div class="relative overflow-x-auto  sm:rounded-lg">
                                 <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                                    <thead class="text-xs text-gray-700 bg-gray-50">
                                          <tr>
                                              <th scope="col" class="px-4 py-3">{first_column_name}</th>
                                              <th scope="col" class="px-4 py-3">Domingo</th>
                                              <th scope="col" class="px-4 py-3">Segunda</th>
                                              <th scope="col" class="px-4 py-3">Terça</th>
                                              <th scope="col" class="px-4 py-3">Quarta</th>
                                              <th scope="col" class="px-4 py-3">Quinta</th>
                                              <th scope="col" class="px-4 py-3">Sexta</th>
                                              <th scope="col" class="px-4 py-3">Sabado</th>
                                          </tr>
                                    </thead>
                                    <tbody>
                                    {content.map(i=>(
                                             <tr class="bg-white border-b">

                                                <th scope="row" class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                   {i.name}
                                                </th>

                                                {i.weeks.map(f=>(
                                                    <td class="px-4 py-4">
                                                       {data._cn(f.availableBalance.toFixed(2))}
                                                    </td>
                                                ))}
                                             </tr>
                                          ))}
                                          
                                    </tbody>
                                 </table>
                                </div>

      </div>
  </>
  );


}
