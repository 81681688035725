import '../css/main.css'
import './style.css'
import Menu from '../common/menu.js';
import TopBar from "../common/topBar";
import { useEffect, useState } from 'react';
import BaiscTable from '../tables/default-table.js';
import BasicPagination from '../pagination/basic.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.js';
import { useData } from '../../contexts/DataContext.js';
import DefaultLayout from '../layouts/DefaultLayout.js';
import toast from 'react-hot-toast';

export default function Clients() {
const {pathname} = useLocation()
const {user,selectedMicrocredit,userRole,userInfo} = useAuth()
const data=useData()
const [loading,setLoading]=useState(false)
const navigate=useNavigate()

let required_data=['clients_on_blacklist']
const [currentPage,setCurrentPage]=useState(1)
const [updateFilters,setUpdateFilters]=useState(null)
const [search,setSearch]=useState('')
const [filterOptions,setFilterOptions]=useState([])
let initial_filters={start_date:'',end_date:''}
const [filters,setFilters]=useState(initial_filters);

useEffect(()=>{ 
   if(!user || data.updateTable) return
   data.handleLoaded('remove',required_data)
   data._get(required_data,{clients_on_blacklist:{microcredit_id:selectedMicrocredit,search:search,page:currentPage,...filters,...data.getParamsFromFilters(filterOptions)}}) 
 },[user,pathname,search,currentPage,updateFilters])


 useEffect(()=>{
   data.handleLoaded('remove',required_data)
 },[updateFilters])

 useEffect(()=>{
   if(data.updateTable){
        data.setUpdateTable(null)
        data.handleLoaded('remove',required_data)
        setCurrentPage(1)
        data._get(required_data,{clients_on_blacklist:{microcredit_id:selectedMicrocredit,search:search,page:1,...filters,...data.getParamsFromFilters(filterOptions)}}) 
   }
},[data.updateTable])


useEffect(()=>{data.setUpdateTable(Math.random())},[filters])

async function handleItems({on_blacklist,id}){ 
     data._closeAllPopUps()
     toast.remove()
     toast.loading('A actualizar...') 

     setLoading(true)
    
     try{
        await data.makeRequest({method:'post',url:`api/microcredit-user/change-blacklist-state`,withToken:true,data:{
              on_blacklist,
              id
        }, error: ``},0);
  
        toast.remove()
        toast.success("Actualizado com sucesso")
        data.setUpdateTable(Math.random())
  
     }catch(e){
        toast.remove()
  
        let msg="Acorreu um erro, tente novamente"
  
        setLoading(false)
  
        if(e.response){
          if(e.response.status==409){
            msg="Nome já existe"
          } 
          if(e.response.status==404){
              msg="Item não encontrado"
          }
          if(e.response.status==500){
              msg="Erro, inesperado. Contacte seu administrador"
          }
  
        }else if(e.code=='ERR_NETWORK'){
              msg="Verfique sua internet e tente novamente"
        }

        toast.error(msg)
  
     }
  }
  

useEffect(()=>{
     if((userRole=="operator" && !userInfo?.permissions?.blacklist?.includes('read'))){
          navigate('/dashboard')
     }
},[userInfo])


return (
    <>
     <DefaultLayout>

     <main className="dashboard-container">
     
     <div className="dashboard">
     <Menu/>
     <div className="main-dashboard">

          <TopBar activePage={'Cliente na lista negra'}/>

            
               <div className="center">

               <div className="center_search">

                    <div className="search_container">
                         <div className="search-div">
                              <input type="text" placeholder="Pesquisar..." value={search} onChange={(e)=>{
                                   setSearch(e.target.value)
                                   data.setUpdateTable(Math.random())
                              }}/>
                                   <div className="search-btn">
                                   <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg>
                              </div>
                         </div>
                    </div>

                    <div className="flex items-center">
                         <div onClick={()=>
                           data.setUpdateTable(Math.random())
                         } className="mr-2 cursor-pointer hover:opacity-65">
                              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z"/></svg>
                         </div>
                        
                    </div>
               </div>

               <div className="dasboard-item-list-container">
               <div className="top-section">
                         <div className="left-side">
                                   <div className="show">
                                             <span>Resultados:</span>
                                             <label>{data._clients_on_blacklist?.total || 0}</label>
                                   </div>
                              
                         </div>
                         <div className="right-side">
                                      
                         </div>
                    </div>

               <BaiscTable  loaded={data._loaded.includes('clients_on_blacklist')} header={[
                    'ID',
                    'Nome',
                    'Microcrédito',
                    ]
               }

               body={(data._clients_on_blacklist?.data || []).map((i,_i)=>(
                         <BaiscTable.Tr>
                         <BaiscTable.Td>{i.id}</BaiscTable.Td>
                         <BaiscTable.Td>{i.name}</BaiscTable.Td>
                         <BaiscTable.Td>{i.user_microcredits.name}</BaiscTable.Td>
                         <BaiscTable.AdvancedActions data={data} w={300} id={i.id} items={[
                              {name:'Remover da lista negra',hide:i.user_microcredits.id != selectedMicrocredit || (userRole!="manager" && !userInfo?.permissions?.blacklist?.includes('remove')),onClick:()=>{handleItems({on_blacklist:false,id:i.id})},icon:(<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M200-440v-80h560v80H200Z"/></svg>)},
                         ]}/>
                        </BaiscTable.Tr>
             ))}
          />

            
               </div>

          </div>

          
     </div>
     </div>
     </main>

     </DefaultLayout>
    </>
  );
}
