
import '../../css/main.css'
import './style.css'
import Menu from '../../common/menu.js';
import TopBar from "../../common/topBar";
import { useEffect, useState } from 'react';
import CreateTmrp from './create-tmrp.js'
import BaiscTable from '../../tables/default-table.js';
import { useAuth } from '../../../contexts/AuthContext.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useData } from '../../../contexts/DataContext.js';
import DefaultLayout from '../../layouts/DefaultLayout.js';

export default function Create() {

const [createTmrp, setCreateTmrp] = useState(false);


const [edit1, setEdit1] = useState(null);
const [del1, setDel1] = useState(null);

const {pathname} = useLocation()
const {user,selectedMicrocredit,userRole,userInfo} = useAuth()
const data=useData()
const navigate=useNavigate()


let required_data=['tmrp_classes','late_payment_interests']
const [updateFilters,setUpdateFilters]=useState(null)

useEffect(()=>{ 
   if(!user) return
   data.handleLoaded('remove',required_data)
   data._get(required_data,{
      tmrp_classes:{microcredit_id:selectedMicrocredit},
   }) 
 },[user,pathname,updateFilters])


 useEffect(()=>{
   data.handleLoaded('remove',required_data,)
 },[updateFilters])

 useEffect(()=>{
   if(data.updateTable){
        data.setUpdateTable(null)
        data.handleLoaded('remove',required_data)
        data._get(required_data,{
            tmrp_classes:{microcredit_id:selectedMicrocredit},
        }) 
   }
},[data.updateTable])



useEffect(()=>{
  if((userRole=="operator" && !userInfo?.permissions?.risk_management_settings?.includes('read'))){
       navigate('/dashboard')
  }
},[userInfo])


return (
    <>
      <DefaultLayout>
      <main className="dashboard-container">

      {<CreateTmrp showD={(createTmrp || edit1) ? true : false}  create={createTmrp} setCreate={setCreateTmrp} setEdit={setEdit1} edit={edit1} del={del1}/>}
   
      <div className="dashboard">
      <Menu/>
      <div className="main-dashboard">
            <TopBar activePage={'Definições de Análise de risco'} lastPage={{name:'Contratos',pathname:'/loans'}}/>
            <div className="center">
                  <div className="loan-container">
                              <div className="top-title">Classes de tempo médio de resposta de pagamento de contratos</div>
                              <div className="section">
                                     <div className="_table max-w-[700px]">

                                    <BaiscTable canAdd={user?.role=="admin"}  loaded={data._loaded.includes('tmrp_classes')} header={[
                                          'Classe',
                                          'Condição',
                                          ]
                                    }
                                    body={(data._tmrp_classes || []).map((i,_i)=>(
                                                <BaiscTable.Tr>
                                                <BaiscTable.Td onClick={()=>setEdit1(i)}>{i.name}</BaiscTable.Td>
                                                <BaiscTable.Td onClick={()=>setEdit1(i)}>{i.condition=="less_than" ? "Quando <=  "+i.number : "Quando >=  "+i.number}</BaiscTable.Td>
                                          </BaiscTable.Tr>
                                          ))}
                                    />


                                 </div>
                              </div>

                  </div>
            </div>
      </div>
      </div>
      </main>
      </DefaultLayout>
    </>
  );
}
