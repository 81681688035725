import React from 'react'
import BaiscTable from '../tables/default-table'
import { useData } from '../../contexts/DataContext'

export default function PrintCreditSimulator({formData={},topDetails={}}) {

  const data=useData()

  let installment=data._interest_rates.map(item => {
    return  { id: item.id, value: item.days +` dias (${item.percentage}%)`, percentage:item.percentage,days:item.days}
  }
 ).filter(i=>i.id==formData.installment_id)[0]

  const top_items=[
    {name:'Montante por desembolsar',value:'300'},
    {name:'Prestação',value:installment?.value},
    {name:'Data de desembolso',value:formData.disbursement_date ? formData.disbursement_date?.split('-')?.reverse()?.join('/') : '-'},
    {name:'Juros de mora',value:data._cn(topDetails.late_payment_interest)},
    {name:'Data de reembolso',value:formData.refund_date ? formData.refund_date?.split('-')?.reverse()?.join('/') : '-'},
    {name:'Total a pagar',value:data._cn(topDetails.total_to_pay)},
    {name:'Financiamento',value:data._cn(formData.disbursement_value || 0)},
    {name:'Pago',value:data._cn(topDetails.paid)},
    {name:'Valor em falta',value:data._cn(topDetails.left)},
    {name:'Juros',value:data._cn(formData.fees)},
    {name:'Número de Parcelas',value:(formData.number_of_installments || 0)}
  ]


  return <></>
 

  return (
    <div  print="true" className="w-full left-0 top-0 justify-center" style={{zIndex:999}}>
             
              <div className="max-w-[1024px] mx-auto bg-white min-h-[900px]">
                  <h2 className="text-center text-[22px] mt-5 font-semibold">Resultado de calculo de empréstimo</h2>
                  <div className="flex flex-wrap gap-y-4 mt-10 p-5 border rounded-[10px]">
                        {top_items.map((i,_i)=>(
                            <div className="w-[28%] mr-[2%] flex flex-col">
                                    <span className="text-[17px] mb-1 font-semibold">{i.name}</span>
                                    <span className="text-[15px] text-gray-500">{i.value}</span>
                            </div>
                        ))}
                  </div>

                  <div className="w-full mt-5">
                     <h2 className="text-[px] mb-5 px-2">Parcelas</h2>
                     <div>
                        <BaiscTable  loaded={true} header={[
                            'Valor inicial',
                            'Valor a pagar',
                            'Em falta',
                            'Prazo de pagamento',
                            'Dias em atraso',
                            'Juros de mora',
                            ]
                        }
                        body={(formData.installments || []).map((i,_i)=>(
                            <BaiscTable.Tr>
                                    <BaiscTable.Td >{(i.initial_amount || 0).toFixed(2)}</BaiscTable.Td>
                                    <BaiscTable.Td >{(i.total_to_pay).toFixed(2)}</BaiscTable.Td>
                                    <BaiscTable.Td >{(i.paid || 0).toFixed(2) || '-'}</BaiscTable.Td>
                                    <BaiscTable.Td >{i.end.split('-')?.reverse()?.join('/')}</BaiscTable.Td>
                                    <BaiscTable.Td >{i.delay || '-'}</BaiscTable.Td>
                                    <BaiscTable.Td >{(i.late_payment_interest || 0).toFixed(2) || '-'}</BaiscTable.Td>
                                 </BaiscTable.Tr>
                            ))}
                        />
                     </div>
                  </div>

                 {formData.notes && <div className="px-2">
                    <h2 className="text-[px] mt-10">Nota</h2>
                    <p>{formData.notes}</p>
                </div>}

              </div>

    </div>
  )
}
