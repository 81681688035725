import Admin from './admin.js';
import Operator from './operator.js';
import Manager from './manager.js';
import Client from './client.js';
import { useAuth } from '../../contexts/AuthContext';

export default function Dashboard() {
   const {user,userRole} = useAuth();

   if(!userRole){
      return <></>
   }

   if(userRole=="admin"){
      return <Admin/>
   }else if(userRole=="manager"){

      return <Manager/>

   }else if(userRole=="operator"){
    
      return <Operator/> 

   }else{
      return <Client/>
   }
  
}
