import React from 'react';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import Dashboard from './components/dashboard/dashboard';
import Managers from './components/managers/managers';
import Microcredits from './components/microcredits/microcredits';
import Operators from './components/operators/operators';
import Clients from './components/clients/clients';
import Plans from './components/plans/plans';
import PlanPayments from './components/plan-payments/plan-payments';
import Loans from './components/loans/loans';
import Settings from './components/settings/settings';
import LoanSettings from './components/loans/settings/settings'
import CreateLoan  from './components/loans/create/create'
import CashManagementSettings from './components/cash-management/settings/settings';
import CashManagement from './components/cash-management/cash-management';
import CashManagementStats from './components/cash-management/stats/stats';
import InventorySettings from './components/inventory/settings/settings';
import Inventory from './components/inventory/inventory';
import RiskManagementMyCLients from './components/risk-management/my-clients';
import RiskManagementBlackList from './components/risk-management/all-clients-on-blacklist';
import RiskManagementSettings from './components/risk-management/settings/settings';

import ContractTemplate from './components/loans/template/template';

import Login from './components/login/login';
import NotFound from './components/404/404';
import ProtectedRoute from './components/ProtectedRoute';
import TermsAndConditions from './components/landing-page/terms-and-conditions/terms-and-conditions';
import PrivacyPolitics from './components/landing-page/privacy-politics/privacy-politics';
import Helpcenter from './components/landing-page/helpcenter/helpcenter';
import Profile from './components/profile/profile';


function App() {

   function send_stat (e){
         console.log(e)
   }
   
   React.useEffect(() => {

      document.querySelectorAll('[__s]').forEach(e=>{
         e.addEventListener('click', send_stat);
      })

      return () => {
        document.querySelectorAll('[__s]').forEach(e=>{
          e.removeEventListener('click', send_stat);
        })
      };

  }, []);

  return (
    <Router>
      <Routes>

         <Route path="/login" element={<Login/>} />
         <Route path="/test" element={<Login/>} />
         <Route path="/" element={<ProtectedRoute redirectTo="/login"><Dashboard /></ProtectedRoute>}/>
         <Route path="/dashboard" element={<ProtectedRoute redirectTo="/login"><Dashboard /></ProtectedRoute>}/>
         <Route path="*" element={<NotFound />} />
         <Route path="/terms" element={<TermsAndConditions />} />
         <Route path="/privacy-politics"  element={<PrivacyPolitics/>} />
         <Route path="/helpcenter/:title"  element={<Helpcenter/>} />
         <Route path="/managers" element={ <ProtectedRoute redirectTo="/login"> <Managers /> </ProtectedRoute>}/>
         <Route path="/microcredits" element={<ProtectedRoute redirectTo="/login"> <Microcredits /></ProtectedRoute>}/>
         <Route path="/operators" element={ <ProtectedRoute redirectTo="/login"> <Operators /> </ProtectedRoute>}/>
         <Route path="/clients" element={ <ProtectedRoute redirectTo="/login"> <Clients /> </ProtectedRoute>}/>
         <Route path="/legal/clients" element={ <ProtectedRoute redirectTo="/login"> <Clients /> </ProtectedRoute>}/>
         <Route path="/plans" element={ <ProtectedRoute redirectTo="/login"> <Plans /> </ProtectedRoute>}/>
         <Route path="/payments" element={ <ProtectedRoute redirectTo="/login"> <PlanPayments /> </ProtectedRoute>}/>
         <Route path="/loans" element={<ProtectedRoute redirectTo="/login"> <Loans /> </ProtectedRoute>}/>
         <Route path="/legal/contracts" element={<ProtectedRoute redirectTo="/login"> <Loans /> </ProtectedRoute>}/>
         <Route path="/settings" element={<ProtectedRoute redirectTo="/login"> <Settings /> </ProtectedRoute>}/>
         <Route path="/loans/settings" element={<ProtectedRoute redirectTo="/login"> <LoanSettings /> </ProtectedRoute>}/>
         <Route path="/loans/create" element={<ProtectedRoute redirectTo="/login"> <CreateLoan /> </ProtectedRoute>}/>
         <Route path="/credit-simulator" element={<ProtectedRoute redirectTo="/login"> <CreateLoan /> </ProtectedRoute>}/>
         <Route path="/loans/restructuring/:restructuring_from" element={<ProtectedRoute redirectTo="/login"> <CreateLoan /> </ProtectedRoute>}/>
         <Route path="/loan/:id" element={<ProtectedRoute redirectTo="/login"> <CreateLoan /> </ProtectedRoute>}/>
         <Route path="/cash-management/settings" element={<ProtectedRoute redirectTo="/login"> <CashManagementSettings /> </ProtectedRoute>}/>
         <Route path="/cash-management" element={<ProtectedRoute redirectTo="/login"> <CashManagement /> </ProtectedRoute>}/>
         <Route path="/cash-management/stats" element={<ProtectedRoute redirectTo="/login"> <CashManagementStats /> </ProtectedRoute>}/>
         <Route path="/inventory/settings" element={<ProtectedRoute redirectTo="/login"> <InventorySettings /> </ProtectedRoute>}/>
         <Route path="/inventory" element={<ProtectedRoute redirectTo="/login"> <Inventory /> </ProtectedRoute>}/>
         <Route path="/risk-management/clients" element={<ProtectedRoute redirectTo="/login"> <RiskManagementMyCLients /> </ProtectedRoute>}/>
         <Route path="/risk-management/blacklist" element={<ProtectedRoute redirectTo="/login"> <RiskManagementBlackList /> </ProtectedRoute>}/>
         <Route path="/risk-management/settings" element={<ProtectedRoute redirectTo="/login"> <RiskManagementSettings /> </ProtectedRoute>}/>
         <Route path="/profile" element={<ProtectedRoute redirectTo="/login"> <Profile /> </ProtectedRoute>}/>
         <Route path="/loans/template/contract" element={<ProtectedRoute redirectTo="/login"> <ContractTemplate /> </ProtectedRoute>}/>
         <Route path="/loans/template/certificate" element={<ProtectedRoute redirectTo="/login"> <ContractTemplate /> </ProtectedRoute>}/>
         <Route path="/loans/template/payment-plan" element={<ProtectedRoute redirectTo="/login"> <ContractTemplate /> </ProtectedRoute>}/>
         <Route path="/loans/template/agreement" element={<ProtectedRoute redirectTo="/login"> <ContractTemplate /> </ProtectedRoute>}/>
      

      </Routes>
      
    </Router>
  );


}

function Logout({ onLogout }) {
  React.useEffect(() => {
    onLogout(); 
  }, [onLogout]);

  return null; 
}

export default App;
