

import '../../css/main.css'
import './style.css'
import ChartMoreClients from './charts/moreClients.js';
import Menu from '../../common/menu.js';
import TopBar from "../../common/topBar";
import { useState,useEffect } from 'react';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import WeeklyTable from './tables/weekly-data.js'
import MonthlyTable from './tables/monthly-data.js'
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardSkeleton from '../../Skeleton/dashboad.js';
import PieChart from '../../dashboard/charts/pieChart.js';
import * as XLSX from 'xlsx';
import Loader from '../../loaders/loader.jsx';
import { saveAs } from 'file-saver';


export default function Dashboard() {

   const {selectedMicrocredit,user,userInfo,userRole} = useAuth();
   const data=useData()
   const {pathname} = useLocation()
   let initial_filters={start_date:'',end_date:'',category_id:'',account_id:'',reference_id:''}
   const [filters,setFilters]=useState(initial_filters);
   const transation_types=[{id:'out',label:'Saida'},{id:'in',label:'Entrada'}]
   const [hideFilters,setHideFilters]=useState(true)

    let required_data=['transations_stats','accounts','clients_list','account_categories']
    const [updateFilters,setUpdateFilters]=useState(null)
    const [search,setSearch]=useState('')
    const [filterOptions,setFilterOptions]=useState([])
    const [printing,setPrinting]=useState(false)
    const navigate=useNavigate()

    

   useEffect(()=>{ 
    if(!user) return
    data.handleLoaded('remove',required_data)
    data._get(required_data,{
      transations_stats:{microcredit_id:selectedMicrocredit || '',search:search,...filters,...data.getParamsFromFilters(filterOptions)},
      accounts:{microcredit_id:selectedMicrocredit || ''},
      clients_list:{microcredit_id:selectedMicrocredit || ''},
      account_categories:{microcredit_id:selectedMicrocredit || ''},
     }) 
  },[user,pathname,search,updateFilters,filters,data.updateTable])
 
 
  useEffect(()=>{
    data.handleLoaded('remove',required_data)
  },[updateFilters])
  

   const [reqLoaded,setReqLoaded]=useState(false)
   useEffect(()=>{
      setReqLoaded(data.loaded.includes('transations_stats') && data.loaded.includes('clients_list') && data.loaded.includes('account_categories') && data.loaded.includes('accounts'))
   },[data.loaded])

   const Export =async (type) => {

      console.log(data._transations_stats)

      Print(type,data._transations_stats)
   }


   function exportToExcelArray(data,fileName){
      const wb = XLSX.utils.book_new();
      const wsData = data;
      const ws = XLSX.utils.aoa_to_sheet(wsData);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      saveAs(blob, `${fileName}.xlsx`);
    }


    const months = [
      "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
      "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];

    const weeks = [
      "Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"
    ];

   const Print =async (type,_data) => {
       
        let name=(user?.microcredits?.filter(i=>i.id==selectedMicrocredit)[0]?.name || '') +` - relatório de caixa ${data.today()} ${data.time()}`

        let _d=[[name],[]]
        _d.push([ 'Total de Entradas', 'Total de Saidas', 'Total Disponível'])
        _d.push([
         data._cn((data._transations_stats?.total?.totalIn || 0).toFixed(2)),
         `${(data._transations_stats?.total?.totalOut || 0) > 0 ? '-':''}`+ data._cn((data._transations_stats?.total?.totalOut || 0).toFixed(2)),
         data._cn((data._transations_stats?.total?.availableBalance || 0).toFixed(2))
        ])
        
        /******/
        _d.push([])
        _d.push([])

        _d.push(['Contas'])
        _d.push(['Conta','Entradas','Saidas','Disponível'])
        _d=[..._d,...(data._transations_stats?.accountTotals || []).map(i=>[
           i.name,
           data._cn(i.totalIn.toFixed(2)),
           `${i.totalOut ? '-':''} ${data._cn(i.totalOut.toFixed(2))}`,
           data._cn(i.availableBalance.toFixed(2))
        ])]

        _d.push([])
        _d.push([])
        
        _d.push(['Categorias'])
        _d.push(['Conta','Entradas','Saidas','Disponível'])
        _d=[..._d,...(data._transations_stats?.categoryTotals || []).map(i=>[
           i.name,
           data._cn(i.totalIn.toFixed(2)),
           `${i.totalOut ? '-':''} ${data._cn(i.totalOut.toFixed(2))}`,
           data._cn(i.availableBalance.toFixed(2))
        ])]

        _d.push([])
        _d.push([])
        _d.push(['Lucro previsto e obtido'])
        _d.push(['Lucro',...months])
        _d.push(['Previsto',...(_data?.payments_profit?.expected || []).map(i=>data._cn(i.total))])
        _d.push(['Obtido',...(_data?.payments_profit?.achieved || []).map(i=>data._cn(i.total))])

        _d.push([])
        _d.push([])
        _d.push(['Movimentação semanal por conta'])
        _d.push(['Conta',...weeks])
        _d=[..._d,...(_data?.accountStats?.weeklyData || []).map(i=>[i.name,...i.weeks.map(f=>data._cn(f.availableBalance.toFixed(2)))] )]
        
        _d.push([])
        _d.push([])
        _d.push(['Movimentação semanal por categoria'])
        _d.push(['Categoria',...weeks])
        _d=[..._d,...(_data?.categoryStats?.weeklyData || []).map(i=>[i.name,...i.weeks.map(f=>data._cn(f.availableBalance.toFixed(2)))] )]
       
        _d.push([])
        _d.push([])
        _d.push(['Movimentação mensal por conta'])
        _d.push(['Conta',...months])
        _d=[..._d,...(_data?.accountStats?.monthlyData || []).map(i=>[i.name,...i.months.map(f=>data._cn(f.availableBalance.toFixed(2)))] )]
        

         _d.push([])
         _d.push([])
         _d.push(['Movimentação mensal por categoria'])
         _d.push(['Categoria',...months])
         _d=[..._d,...(_data?.categoryStats?.monthlyData || []).map(i=>[i.name,...i.months.map(f=>data._cn(f.availableBalance.toFixed(2)))] )]


          
        
        exportToExcelArray(_d,name)
       
   
   }
   


   
   useEffect(()=>{
         if((userRole=="operator" && !userInfo?.permissions?.cash_management_reports?.includes('read'))){
            navigate('/dashboard')
         }
   },[userInfo])
   

   return (
    <>
    <main className="dashboard-container">


        <div className="dashboard">

            <Menu/>

            <div className="main-dashboard">
               
            <TopBar activePage={'Relatórios de caixa'}/>
            
            {!reqLoaded && <DashboardSkeleton/>}

                  <div className={`center ${!reqLoaded ? 'hidden':''}`}>


                         <div className="stat_page_2">

                             <div className="items">

                                  <div className="box">
                                    <div className="icon blue-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="m216-160-56-56 464-464H360v-80h400v400h-80v-264L216-160Z"/></svg>
                                 
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Entradas</div>
                                       <div className="total">{data._cn((data._transations_stats?.total?.totalIn || 0).toFixed(2))}</div>
                                    </div>
                                 </div>


                                   <div className="box">
                                    <div className="icon orange-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M360-200v-80h264L160-744l56-56 464 464v-264h80v400H360Z"/></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Saidas</div>
                                       <div className="total">{(data._transations_stats?.total?.totalOut || 0) > 0 ? '-':''} {data._cn((data._transations_stats?.total?.totalOut || 0).toFixed(2))}</div>
                                    </div>
                                    
                                 </div>


                                   <div className="box">
                                    <div className="icon green-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" className="opacity-[0.8]"><path d="M200-200v-560 560Zm0 80q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v100h-80v-100H200v560h560v-100h80v100q0 33-23.5 56.5T760-120H200Zm320-160q-33 0-56.5-23.5T440-360v-240q0-33 23.5-56.5T520-680h280q33 0 56.5 23.5T880-600v240q0 33-23.5 56.5T800-280H520Zm280-80v-240H520v240h280Zm-160-60q25 0 42.5-17.5T700-480q0-25-17.5-42.5T640-540q-25 0-42.5 17.5T580-480q0 25 17.5 42.5T640-420Z"></path></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Disponível</div>
                                       <div className="total">{data._cn((data._transations_stats?.total?.availableBalance || 0).toFixed(2))}</div>
                                    </div>
                                 </div>
                             </div>

                       </div>


                       <div className="filter-res mt-2">

                       <div className={`extra-filters ${hideFilters ? '_hide':''}`}>

                        <label onClick={()=>setHideFilters(!hideFilters)} className="underline sm:hidden text-[15px]  text-custom_blue-400 absolute top-4 cursor-pointer">{hideFilters ? 'Mostrar filtros':'Esconder filtros'}</label>


                           <div className="input-container">

                                    <div className="inputs">
                                    <span>Data de inicio</span>
                                    <input type="date" value={filters.start_date} onChange={(e)=>setFilters({...filters,start_date:e.target.value})} />
                              </div>

                                    <div className="inputs">
                                    <span>Data de fim</span>
                                    <input type="date" value={filters.end_date} onChange={(e)=>setFilters({...filters,end_date:e.target.value})} />
                              </div>


                              <div className="inputs">
                                    <span>Tipo de transação</span>
                                    <select  value={filters.type} onChange={e=>setFilters({...filters, type:e.target.value})}>
                                          <option selected value="">Selecionar Transação</option>
                                          {transation_types.map(i=>(
                                             <option key={i.id} selected={filters.type === i.id} value={i.id}>{i.label}</option>
                                          ))}
                                    </select>
                              </div>

                              <div className="inputs">
                                    <span>Categoria</span>
                                    <select value={filters.category_id} onChange={e=>setFilters({...filters, category_id:e.target.value})}>
                                          <option  selected value="">Selecionar Categoria</option>
                                          {data._account_categories.map(i=>(
                                             <option key={i.id} selected={filters.category_id === i.id} value={i.id}>{i.name}</option>
                                          ))}
                                    </select>
                              </div>
                              <div className="inputs">
                                    <span>Conta</span>
                                    <select value={filters.account_id} onChange={e=>setFilters({...filters, account_id:e.target.value})}>
                                             <option selected value="">Selecionar Conta</option>
                                             {data._accounts.map(i=>(
                                                   <option key={i.id} selected={filters.account_id === i.id} value={i.id}>{i.name} {i.description ? `(${i.description})`:''}</option>
                                             ))}
                                    </select>
                              </div>

                              <div className="inputs">
                              <span>Cliente</span>
                              <select value={filters.reference_id} onChange={e=>setFilters({...filters, reference_id:e.target.value})}>
                                        <option selected value="">Selecionar cliente</option>
                                        {data._clients_list.sort((a, b) => (a.deleted_at === null ? -1 : 1) - (b.deleted_at === null ? -1 : 1)).map(i=>(
                                             <option key={i.id} selected={filters.reference_id === i.id} value={i.id}>{i.name} {i.deleted_at ? '(Arquivado)':''}</option>
                                        ))}
                              </select>
                              </div>
                              
                              
                           </div>

                           <div className="options">
                           <span onClick={()=>setFilters(initial_filters)}>
                              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-122q-121-15-200.5-105.5T160-440q0-66 26-126.5T260-672l57 57q-38 34-57.5 79T240-440q0 88 56 155.5T440-202v80Zm80 0v-80q87-16 143.5-83T720-440q0-100-70-170t-170-70h-3l44 44-56 56-140-140 140-140 56 56-44 44h3q134 0 227 93t93 227q0 121-79.5 211.5T520-122Z"/></svg> </span>
                           </div>

                           </div>




                           
               <div className="dasboard-item-list-container">
               <div className="top-section !mb-0">
                         <div className="right-side flex justify-end w-full">
                                        {printing ? <>
                                                  <Loader/>
                                        </> : <>
                                            
                                             <span onClick={()=>{
                                                  Export('excel')
                                             }} className="export export-excel page" style={{background:'rgb(74, 205, 53)'}}>
                                             <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24"><path fill="#fff" d="M18 22a2 2 0 0 0 2-2v-5l-5 4v-3H8v-2h7v-3l5 4V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12zM13 4l5 5h-5V4z"></path></svg>
                                             </span>
                                             
                                        </>}
                         </div>


                    </div>

                    </div>


                                 
                       </div>



                       <div className="stat_page_3">
                           <div className="chart-container">
                                 <div className="box-top-title">
                                   Número de transações por contas
                                 </div>
                                 <div>
                                   <PieChart data={(data._transations_stats?.accountTotals || []).map((i=>({...i,total:i.totalTransactions})))}/>
                                 </div>
                                 <div className="chart">
                                    {/**<ChartMoreClients data={(data._transations_stats?.accountTotals || []).map((i=>({...i,times:i.totalTransactions})))} /> */}
                                 </div>
                           </div>

                           <div className="loans-status">
                              <div className="box-top-title p-2">Contas</div>
                              <div className="list">
                              <div class="relative overflow-x-auto  sm:rounded-lg">
                                 <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                                    <thead class="text-xs text-gray-700 bg-gray-50">
                                          <tr>
                                             <th scope="col" class="px-4 py-3">
                                                Conta
                                             </th>
                                             <th scope="col" class="px-4 py-3">
                                                Entrada
                                             </th>
                                             <th scope="col" class="px-4 py-3">
                                                Saida
                                             </th>
                                             <th scope="col" class="px-4 py-3">
                                                Disponível
                                             </th>
                                          </tr>
                                    </thead>
                                    <tbody>
                                    {(data._transations_stats?.accountTotals || []).map(i=>(
                                             <tr class="bg-white border-b">
                                                <th scope="row" class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                   {i.name}
                                                </th>
                                                <td class="px-4 py-4">
                                                   {data._cn(i.totalIn.toFixed(2))}
                                                </td>
                                                <td class="px-4 py-4">
                                                  {i.totalOut ? '-':''}   {data._cn(i.totalOut.toFixed(2))}
                                                </td>
                                                <td class="px-4 py-4">
                                                   {data._cn(i.availableBalance.toFixed(2))}
                                                </td>
                                             </tr>
                                          ))}
                                          
                                    </tbody>
                                 </table>
                              </div>
                              </div>
                           </div>
                       </div>




                       <div className="stat_page_3">
                           <div className="chart-container">
                                 <div className="box-top-title">
                                   Número de transações por categoria
                                 </div>
                                 <div>
                                   <PieChart data={(data._transations_stats?.categoryTotals || []).map((i=>({...i,total:i.totalTransactions})))}/>
                                 </div>
                                 <div className="chart">
                                    {/** <ChartMoreClients data={(data._transations_stats?.categoryTotals || []).map((i=>({...i,times:i.totalTransactions})))} /> */}
                                 </div>
                           </div>

                           <div className="loans-status">
                              <div className="box-top-title p-2">Categorias</div>
                              <div className="list">
                              <div class="relative overflow-x-auto  sm:rounded-lg">
                                 <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                                    <thead class="text-xs text-gray-700 bg-gray-50">
                                          <tr>
                                             <th scope="col" class="px-4 py-3">
                                                Conta
                                             </th>
                                             <th scope="col" class="px-4 py-3">
                                                Entrada
                                             </th>
                                             <th scope="col" class="px-4 py-3">
                                                Saida
                                             </th>
                                             <th scope="col" class="px-4 py-3">
                                                Disponível
                                             </th>
                                          </tr>
                                    </thead>
                                    <tbody>
                                    {(data._transations_stats?.categoryTotals || []).map(i=>(
                                             <tr class="bg-white border-b">
                                                <th scope="row" class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                   {i.name}
                                                </th>
                                                <td class="px-4 py-4">
                                                   {data._cn(i.totalIn.toFixed(2))}
                                                </td>
                                                <td class="px-4 py-4">
                                                  {i.totalOut ? '-':''}   {data._cn(i.totalOut.toFixed(2))}
                                                </td>
                                                <td class="px-4 py-4">
                                                   {data._cn(i.availableBalance.toFixed(2))}
                                                </td>
                                             </tr>
                                          ))}
                                          
                                    </tbody>
                                 </table>
                                </div>
                              </div>
                           </div>
                       </div>
     


                       <div className="tables">
                        
                           <div className="c-table">
                                       <div className="table-top">
                                             <span className="name">Lucro previsto e obtido</span>
                                       </div>
                                       <MonthlyTable first_column_name={`Lucro`} content={([
                                          {name:'Previsto',months:data._transations_stats?.payments_profit?.expected?.map(i=>({...i,availableBalance:i.total})) || []},
                                          {name:'Obtido',months:data._transations_stats?.payments_profit?.achieved?.map(i=>({...i,availableBalance:i.total})) || []},
                                       ])}/>
                           </div>

                           <div className="c-table">
                                       <div className="table-top">
                                             <span className="name">Movimentação semanal por conta</span>
                                       </div>
                                       <WeeklyTable first_column_name={`Conta`} content={(data._transations_stats?.accountStats?.weeklyData || [])}/>
                           </div>

                           <div className="c-table">
                                       <div className="table-top">
                                             <span className="name">Movimentação semanal por categoria</span>
                                       </div>
                                       <WeeklyTable first_column_name={`Categoria`} content={(data._transations_stats?.categoryStats?.weeklyData || [])}/>
                           </div>

                           <div className="c-table">
                                       <div className="table-top">
                                             <span className="name">Movimentação mensal por conta</span>
                                       </div>
                                       <MonthlyTable first_column_name={`Conta`} content={(data._transations_stats?.accountStats?.monthlyData || [])}/>
                           </div>

                           <div className="c-table">
                                       <div className="table-top">
                                             <span className="name">Movimentação mensal por categoria</span>
                                       </div>
                                       <MonthlyTable first_column_name={`Categoria`} content={(data._transations_stats?.categoryStats?.monthlyData || [])}/>
                           </div>

                      </div>


                       
 


                  </div>
            </div>
        </div>
    </main>
    </>
  );
}
