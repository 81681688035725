import '../css/main.css'
import './style.css'
import Menu from '../common/menu.js';
import TopBar from "../common/topBar.js";
import Create from './create.js'
import DeleteDialog from './delete-dialog.js'
import { useEffect, useState } from 'react';
import BaiscTable from '../tables/default-table.js';
import BasicPagination from '../pagination/basic.js';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.js';
import { useData } from '../../contexts/DataContext.js';
import DefaultLayout from '../layouts/DefaultLayout.js';
import toast from 'react-hot-toast';
import Loader from '../loaders/loader.jsx';
import * as XLSX from 'xlsx';

export default function Clients() {

const [activePage, setActivePage] = useState('gestores');
const [create, setCreate] = useState(false);
const [edit, setEdit] = useState(null);
const [del, setDel] = useState(null);
const [tableSearch,setTableSeach]=useState('');
const {pathname} = useLocation()
const {user,selectedMicrocredit} = useAuth()
const data=useData()
const [loading,setLoading]=useState(false)
const [printing,setPrinting]=useState(false)


let required_data=['plans']
const [currentPage,setCurrentPage]=useState(1)
const [updateFilters,setUpdateFilters]=useState(null)
const [search,setSearch]=useState('')
const [filterOptions,setFilterOptions]=useState([])
let initial_filters={start_date:'',end_date:''}
const [filters,setFilters]=useState(initial_filters);


useEffect(()=>{ 
   if(!user) return
   data.handleLoaded('remove',required_data)
   data._get(required_data) 
 },[user,pathname,search,currentPage,updateFilters])


 useEffect(()=>{
   data.handleLoaded('remove',required_data)
 },[updateFilters])

 useEffect(()=>{
   if(data.updateTable){
        data.setUpdateTable(null)
        data.handleLoaded('remove',required_data)
        data._get(required_data) 
   }
},[data.updateTable])

useEffect(()=>{data.setUpdateTable(Math.random())},[filters])





return (
    <>

     <DefaultLayout>

     <main className="dashboard-container">
          
     {<Create showD={(create || edit) ? true : false}  create={create} setCreate={setCreate} setEdit={setEdit} edit={edit} del={del}/>}
     {<DeleteDialog showD={del ? true : false} del={del}  setDel={setDel}/>}

     <div className="dashboard">
     <Menu/>
     <div className="main-dashboard">
          
          <TopBar activePage={'Planos'}/>

          <div className="center">

               <div className="dasboard-item-list-container">

               <BaiscTable canAdd={user?.role=="admin"} addRes={()=>{
                   
                }}  loaded={data._loaded.includes('plans')} header={[
                         'Nome',
                         'Preço',
                    ]
               }

               body={(data._plans).map((i,_i)=>(
                    
                    <BaiscTable.Tr>
                         <BaiscTable.Td onClick={()=>setEdit(i)}>{i.name}</BaiscTable.Td>
                         <BaiscTable.Td onClick={()=>setEdit(i)}>{i.price}</BaiscTable.Td>
                    </BaiscTable.Tr>
                   
               ))}
          />

        
               </div>

          </div>

          
     </div>
     </div>
     </main>

     </DefaultLayout>
    </>
  );
}
