import '../css/main.css'
import './style.css'
import StackedBar from './charts/StackedBar.js';
import Menu from '../common/menu.js';
import TopBar from "../common/topBar";
import { useEffect,useState } from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import PaymentsTable from './tables/payments';
import LastTransations from './tables/last_transations.js';
import { useLocation } from 'react-router-dom';



export default function Operator() {

   const data = useData();
   const {user,selectedMicrocredit,userRole} = useAuth();
   const {pathname} = useLocation()

   let required_data=['dashboard']
   
   const [updateFilters,setUpdateFilters]=useState(null)
   const [search,setSearch]=useState('')
   const [filterOptions,setFilterOptions]=useState([])
   let initial_filters={start_date:'',end_date:''}
   const [filters,setFilters]=useState(initial_filters);
   const [topFilters,setTopFilters]=useState({
      filtered_contract_year:new Date().getFullYear(),
      filtered_contract_month:new Date().getMonth()
   })
   
   useEffect(()=>{ 
      if(!user) return
      data.handleLoaded('remove',required_data)
      data._get(required_data,{
            dashboard:{microcredit_id:selectedMicrocredit,search:search,...topFilters,...filters,...data.getParamsFromFilters(filterOptions)},
      }) 
    },[user,pathname,search,updateFilters,selectedMicrocredit,topFilters])
   
   
    useEffect(()=>{
      data.handleLoaded('remove',required_data)
    },[updateFilters])
   
    useEffect(()=>{
      if(data.updateTable){
           data.setUpdateTable(null)
           data.handleLoaded('remove',required_data)
           data._get(required_data,{
                dashboard:{microcredit_id:selectedMicrocredit,search:search,...topFilters,...filters,...data.getParamsFromFilters(filterOptions)},
           }) 
      }
   },[data.updateTable,filters])

   useEffect(()=>{data.setUpdateTable(Math.random())},[filters,topFilters])


   const [selectedTransationsPerformance,setSelectedTransationsPerformance]=useState('week')

   return (
    <>
      <main className={`dashboard-container ${data.loaded.includes('dashboard') ? '' :'loading' }`}>

        <div className="dashboard">
            <Menu/>
            <div className="main-dashboard">
            <TopBar homePage={true} activePage={'Dashboard'}/>

                  <div className="center pb-10">
                        <div className="stat_page_1  min-h-[270px]">
                            <div className="left-side">
                            <div className="items">
                               <div className="box alerts skeleton-bg relative load-on-filtering">
                                     <div className={`${!data.loaded.includes('dashboard') ? 'hidden':''} absolute -translate-y-[50%] border top-0 left-4 bg-white shadow rounded-full flex items-center overflow-hidden`}>
                                           <select onChange={(e)=>setTopFilters({...topFilters,filtered_contract_month:e.target.value})} value={topFilters.filtered_contract_month} className="text-[0.8rem] outline-none border-r border-r-black mr-2">
                                               {(data.months).map((i,_i)=>(
                                                  <option value={_i}>{i}</option>
                                               ))}
                                           </select>
                                           <select onChange={(e)=>setTopFilters({...topFilters,filtered_contract_year:e.target.value})} value={topFilters.filtered_contract_year} className="text-[0.8rem] outline-none">
                                               {(data._dashboard.contractsYearsFromStart || [new Date().getFullYear()]).map((i,_i)=>(
                                                  <option value={i}>{i}</option>
                                               ))}
                                           </select>
                                       </div>
                                       <div className="name">
                                            <label>Finananciado
                                            <span className="total block w-full !text-[14px]">{data._cn((data._dashboard?.contracts_withnodatefilters?.disbursementValue || 0).toFixed(2))} MT</span>
                                  
                                            </label>
                                            <div className="icon blue-icon">
                                            <svg  style={{opacity:.8}} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="m480-320 56-56-64-64h168v-80H472l64-64-56-56-160 160 160 160Zm0 240q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                                            </div>
                                       </div>
                                       <span className="block w-full absolute bottom-2 text-[14px]"> {data.months[topFilters.filtered_contract_month]?.slice(0,3)} - {data._cn((data._dashboard?.contracts?.disbursementValue || 0).toFixed(2))} MT</span>
                                    </div>
                                   
                                    <div className="box revenues skeleton-bg load-on-filtering">
                                       <div className="name">
                                            <label>Esperado
                                           
                                              <span className="total block w-full !text-[14px]">{data._cn((data._dashboard?.contracts_withnodatefilters?.expected || 0).toFixed(2))} MT</span>
                                  
                                            </label>
                                            <div className="icon orange-icon">
                                            <svg style={{opacity:.8}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-200h80v-40h40q17 0 28.5-11.5T600-280v-120q0-17-11.5-28.5T560-440H440v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T360-520v120q0 17 11.5 28.5T400-360h120v40H360v80h80v40ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-560v-160H240v640h480v-480H520ZM240-800v160-160 640-640Z"></path></svg>
                                            </div>
                                       </div>
                                       <span className="block w-full absolute bottom-2 text-[14px]"> {data.months[topFilters.filtered_contract_month]?.slice(0,3)} - {data._cn((data._dashboard?.contracts?.expected || 0).toFixed(2))} MT</span>
                                    </div>
                                    <div className="box loans skeleton-bg load-on-filtering relative">
                                       <div className="name">
                                            <label className="break-all">
                                             Em falta
                                            
                                             <span className="total block w-full !text-[14px]">{data._cn((data._dashboard?.contracts_withnodatefilters?.left || 0).toFixed(2))} MT</span>
                                  
                                            </label>
                                          
                                            <div className="icon red-icon">
                                               <svg style={{opacity:.8}} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M574-618q-12-30-35.5-47T482-682q-18 0-35 5t-31 19l-58-58q14-14 38-25.5t44-14.5v-84h80v82q45 9 79 36.5t51 71.5l-76 32ZM792-56 608-240q-15 15-41 24.5T520-204v84h-80v-86q-56-14-93.5-51T292-350l80-32q12 42 40.5 72t75.5 30q18 0 33-4.5t29-13.5L56-792l56-56 736 736-56 56Z"/></svg>
                                            </div>

                                            <span className="block w-full absolute bottom-2 text-[14px] font-medium"> {data.months[topFilters.filtered_contract_month]?.slice(0,3)} - {data._cn((data._dashboard?.contracts?.left || 0).toFixed(2))} MT</span>
                                       </div>
                                     </div>

                                    <div className={`box microcredit skeleton-bg`}>
                         
                                          <div className="name">
                                                <label>Saldo disponível</label>
                                                <div className="icon green-icon">
                                                     <svg style={{opacity:.8}} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M200-200v-560 560Zm0 80q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v100h-80v-100H200v560h560v-100h80v100q0 33-23.5 56.5T760-120H200Zm320-160q-33 0-56.5-23.5T440-360v-240q0-33 23.5-56.5T520-680h280q33 0 56.5 23.5T880-600v240q0 33-23.5 56.5T800-280H520Zm280-80v-240H520v240h280Zm-160-60q25 0 42.5-17.5T700-480q0-25-17.5-42.5T640-540q-25 0-42.5 17.5T580-480q0 25 17.5 42.5T640-420Z"/></svg>
                                                </div>
                                          </div>
                                          <span className="total">{data._cn((data._dashboard?.transations?.transations_performance?.available_credit || 0).toFixed(2))} MT</span>
                                    
                                       </div>
                               </div> 
                            </div>

                            <div className="right-side">
                               <div className="chart-c skeleton-bg">
                                     <div className="box-top-title">{'Pagamentos próximos / em atraso' /*Clientes mais frequentes*/}</div>
                                     <div className="chart">
                                      <PaymentsTable items={data._dashboard?.contracts?.delayed_and_upcomming_installents || []}/> 
                                     </div>
                               </div> 
                            </div>
                        </div>

                      <div className="stat_page_2">
                                                    <div className="items">
                                                         <div className="box skeleton-bg">
                                                           <div className="icon blue-icon">
                                                             <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"></path></svg>
                                                          </div>
                                                           <div className="center-content">
                                                              <div className="name">Clientes</div>
                                                              <span className="total">{data._dashboard?.total_clients}</span>
                                                           </div>
                                                           {/** <div className="add-btn" onClick={()=>{}}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path  fill="#789DEA" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg>
                                                           </div> */}
                                                           
                                                        </div>
                                                          <div className="box skeleton-bg">
                                                           <div className="icon blue-icon">
                                                           <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"></path></svg>
                                                           </div>
                                                           <div className="center-content">

                                                              <div className="name">Gestores</div>
                                                              <div className="total">{data._dashboard?.total_managers}</div>

                                                           </div>
                                                          
                                                          
                                                        </div>
                       
                       
                                                          <div className="box skeleton-bg">
                                                           <div className="icon blue-icon">
                                                                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"/></svg>
                                                           </div>
                                                           <div className="center-content">
                                                              <div className="name">Operadores</div>
                                                              <div className="total">{data._dashboard?.total_operators}</div>
                                                           </div>
                                                          {/**<div className="add-btn" onClick={()=>{}}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path  fill="#789DEA" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg>
                                                           </div> */}
                                                        </div>
                       
                                                    </div>
                                </div>


                                <div className="stat_page_2">
                                                    <div className="items">
                                                         <div className="box skeleton-bg">
                                                           <div className="icon green-icon">
                                                           <svg style={{opacity:.8}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-200h80v-40h40q17 0 28.5-11.5T600-280v-120q0-17-11.5-28.5T560-440H440v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T360-520v120q0 17 11.5 28.5T400-360h120v40H360v80h80v40ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-560v-160H240v640h480v-480H520ZM240-800v160-160 640-640Z"></path></svg>
                                                           </div>
                                                           <div className="center-content">
                                                              <div className="name">Contratos activos</div>
                                                              <div className="total">{data._dashboard?.contracts?.approved_contracts}</div>
                                                           </div>
                                                          
                                                           
                                                        </div>
                                                          <div className="box skeleton-bg">
                                                           <div className="icon orange-icon">
                                                           <svg style={{opacity:.8}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-200h80v-40h40q17 0 28.5-11.5T600-280v-120q0-17-11.5-28.5T560-440H440v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T360-520v120q0 17 11.5 28.5T400-360h120v40H360v80h80v40ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-560v-160H240v640h480v-480H520ZM240-800v160-160 640-640Z"></path></svg>
                                                           </div>
                                                           <div className="center-content">

                                                              <div className="name">Contratos pendentes</div>
                                                              <div className="total">{data._dashboard?.contracts?.pending_contracts}</div>

                                                           </div>
                                                          
                                                          
                                                        </div>
                       
                       
                                                          <div className="box skeleton-bg">
                                                           <div className="icon red-icon">
                                                           <svg style={{opacity:.8}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-200h80v-40h40q17 0 28.5-11.5T600-280v-120q0-17-11.5-28.5T560-440H440v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T360-520v120q0 17 11.5 28.5T400-360h120v40H360v80h80v40ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-560v-160H240v640h480v-480H520ZM240-800v160-160 640-640Z"></path></svg>
                                                          </div>
                                                           <div className="center-content">
                                                              <div className="name">Contratos em atraso</div>
                                                              <div className="total">{data._dashboard?.contracts?.delayed_contracts}</div>
                                                           </div>
                                                          
                                                        </div>
                       
                                                    </div>
                                </div>
                      



                       <div className="stat_page_3">
                           <div className="chart-container skeleton-bg">
                                 <div className="box-top-title">{'Desempenho'}
                                    <select value={selectedTransationsPerformance} onChange={(e)=>{
                                        setSelectedTransationsPerformance(e.target.value)
                                    }}>
                                        <option value="week">Semanal</option>
                                        <option value="month">Mensal</option>
                                        <option value="year">Anual</option>
                                    </select>
                                    </div>
                                 <div className="chart">
                                    <StackedBar data={
                                       [
                                          (data._dashboard?.transations?.transations_performance?.[`${selectedTransationsPerformance}_inflows`] || []),
                                          (data._dashboard?.transations?.transations_performance?.[`${selectedTransationsPerformance}_outflows`] || [])
                                       ]
                                      } label={'Transações'} /> 
                                 </div>
                           </div>
                           <div className="loans-status skeleton-bg">
                              <div className="box-top-title">Transações recentes</div>
                              <div className="list">
                                   <LastTransations items={data._dashboard?.transations?.latest || []}/> 
                              </div>
                           </div>
                       </div>


                       <div className="revenue-chart">
                         <div className="chart-container skeleton-bg">
                                 <div className="box-top-title">{'Lucro previsto e obtido'}</div>
                                 <div className="chart">
                                    <StackedBar labels={[
                                       'Previsto','Obtido'
                                    ]} data={
                                      [
                                        (data._dashboard?.contracts?.payments_profit?.expected || []),
                                        (data._dashboard?.contracts?.payments_profit?.achieved || [])
                                      ]
                                    } label={'Lucro'} /> 
                                 </div>
                           </div>
                       </div>

                  </div>

                  
            </div>
        </div>
    </main>
    </>
  );
}
