
import { useEffect, useState} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import DefaultFormSkeleton from '../Skeleton/defaultForm.js';
import SetUserPermissions from '../modals/user-permissions.jsx';

const Create = ({create,setCreate, edit , setEdit,showD }) => {

const data = useData();


const default_permissions={
  "clients": ["read","create","update"],
  "contracts": ["read","create","update"],
  "contract_settings": ["read","create","update"],
  "cash_management_transactions": ["read","create","update"],
  "cash_management_settings": ["read","create","update"],
  "cash_management_reports": ["read"],
  "inventory": ["read","create","update"],
  "inventory_settings": ["read","create","update"],
  "risk_management": ["read"],
  "risk_management_settings": ["read","update"],
  "blacklist": ["read"],
  "legal_guidelines": ["read","add","remove"],
  "contract_template": ["update","print"],
  "clearance_certificate_template": ["update","print"],
  "debt_confession_agreement_template": ["update","print"],
  "payment_plan":["print"],
  "asset_and_guarantee_sheet":['print'],
  "restructure_credit":['emit']
}

const permissions={
  
  "clients": ["read","create","update","delete"],
  "operators": ["read","create","update","delete"],
  "contracts": ["read","create","update","delete","approve","auto_approval","reject"],
  "contract_settings": ["read","create","update","delete"],
  "cash_management_transactions": ["read","create","update","delete"],
  "cash_management_settings": ["read","create","update","delete"],
  "cash_management_reports": ["read"],
  "inventory": ["read","create","update","delete"],
  "inventory_settings": ["read","create","update"],
  "risk_management": ["read"],
  "risk_management_settings": ["read","update"],
  "blacklist": ["read","add","remove"],
  "legal_guidelines": ["read","add","remove"],
  "payments": ["read"],

  "contract_template": ["update","print"],
  "clearance_certificate_template": ["update","print"],
  "debt_confession_agreement_template": ["update","print"],

  "payment_plan":["print"],
  "asset_and_guarantee_sheet":['print'],
  "restructure_credit":['emit']

}


const initial_form={name:'',email:'',primary_contact:'',microcredit_id:'',job_title:'',permissions:default_permissions} 
const [itemToEditLoaded,setItemToEditLoaded]=useState(false)
const {pathname} = useLocation()

const [formData,setFormData]=useState(initial_form)

const [loading,setLoading]=useState(false)

const {user,selectedMicrocredit,userInfo,userRole} = useAuth()

let required_data=['microcredits_list']
useEffect(()=>{   
      if(!user) return
      data.handleLoaded('remove',required_data)
      data._get(required_data) 
},[user,pathname])


useEffect(()=>{
    if(edit){
       setCreate(false)
    }else{
       setFormData({...initial_form,microcredit_id:selectedMicrocredit || null})
    }
},[edit])


useEffect(()=>{
    if(create){
       setEdit(false)
    }

    setFormData({...initial_form,microcredit_id:selectedMicrocredit || null})
},[create])


useEffect(()=>{
   console.log(formData)
},[formData])

const [showPermissions,setShowPermissions]=useState(false)


let permissions_description = {
  "clients": "Clientes",
  "operators": "Operadores",
  "contracts": "Contratos",
  "contract_settings": "Configurações de Contrato",
  "contract_template": "Modelo de Contrato",
  "clearance_certificate_template": "Modelo de Certificado de Quitação",
  "debt_confession_agreement_template": "Modelo de Confissão de Dívida",
  "debt_acknowledgment_agreement_template": "Modelo de Reconhecimento de Dívida",
  "cash_management_transactions": "Transações de Gestão de Caixa",
  "cash_management_settings": "Configurações de Gestão de Caixa",
  "cash_management_reports": "Relatório de Gestão de Caixa",
  "inventory": "Inventário",
  "inventory_settings": "Configurações de Inventário",
  "risk_management": "Gestão de Riscos",
  "risk_management_settings": "Configurações de Gestão de Riscos",
  "blacklist": "Lista Negra",
  "legal_guidelines": "Diretrizes Legais",
  "payments": "Pagamentos",
   "payment_plan":"plano de pagamento",
   "asset_and_guarantee_sheet":"ficha de bens e garantias",
   "restructure_credit":"Reestruturar crédito"
};


let permissions_item_description = {
  "create": "Criar",
  "update": "Editar",
  "auto_approval": "Aprovação Automática",
  "read": "Visualizar",
  "delete": "Excluir",
  "approve": "Aprovar",
  "reject": "Rejeitar",
  "add":"Adicionar",
  "remove":"Remover",
  "edit": "Editar",
  "print":"Imprimir",
  "emit":"Emitir",
 
};


async function handle_operator(){

   if(!formData.name || !formData.email || !formData.primary_contact || !formData.microcredit_id) {
       toast.error('Preencha todos os campos!')
       return 
   }

   if(!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email.trim()))){
        toast.error('Email inválido')
        return
   }

   setLoading(true)
  
   try{

     if(edit){

       await data.makeRequest({method:'post',url:`api/operator/update/`+edit.id,withToken:true,data:{
         ...formData,
       }, error: ``},0);
       
       toast.success('Actualizado com sucesso!')
       setLoading(false)

     }else{

       await data.makeRequest({method:'post',url:`api/operator/create`,withToken:true,data:{
         ...formData
       }, error: ``},0)

       toast.success('Criado com sucesso!')
       setLoading(false)

     }

     setEdit(null)
     setCreate(null)
     data.setUpdateTable(Math.random())

   }catch(e){

       let msg="Acorreu um erro, tente novamente"
       console.log(e)
       setLoading(false)

       if(e.response){
         if(e.response.status==409){
             msg="Email já existe na microcrédito"
         }   
         if(e.response.status==400){
             msg="Dados inválidos"
         }
         if(e.response.status==500){
             msg="Erro, inesperado. Contacte seu administrador"
         }

       }else if(e.code=='ERR_NETWORK'){
             msg="Verfique sua internet e tente novamente"
       }

       toast.error(msg)
       setLoading(false)

     }
     
}


useEffect(()=>{
  if(!edit){
      setItemToEditLoaded(false)
  }
},[edit])


useEffect(()=>{

    if(!user || !edit?.id){
        return
    }

    (async()=>{
      
      try{
    
      let response=await data.makeRequest({method:'get',url:`api/operator/`+edit.id,withToken:true, error: ``},100);
      setItemToEditLoaded(true)
      setFormData({...response,microcredit_id:response.user_microcredits.id})
  
      }catch(e){

        console.log({e})

        let msg="Acorreu um erro, tente novamente"

        setLoading(false)

        if(e.response){

          if(e.response.status==409){
            msg="Email já existe"
          } 
          
          if(e.response.status==404){
              msg="Item não encontrado"
              setEdit(null)
          }
          if(e.response.status==500){
              msg="Erro, inesperado. Contacte seu administrador"
          }

        }else if(e.code=='ERR_NETWORK'){
              msg="Verfique sua internet e tente novamente"
        }

    }
    
  })()

},[user,pathname,edit])



return (

   
   <>
     
    <SetUserPermissions permissions_item_description={permissions_item_description} showPermissions={showPermissions} setShowPermissions={setShowPermissions} permissions={permissions} permissions_description={permissions_description} formData={formData} setFormData={setFormData}/>

    <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
  <div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
  <div className={`create-microcredit ${loading && 'loading'} ${(edit && !itemToEditLoaded) ? 'loading-editing-item':''}`}>
                 
                  {(edit && !itemToEditLoaded) && <DefaultFormSkeleton/>}
                  <div className="top-title">{edit ? 'Editar' :' Adicionar'} operador</div>  
                  <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                  <div onClick={()=>{
                       setShowPermissions(true)
                  }} className={`status !bg-custom_blue-400 !text-white`}>Permissões</div>
                  <div className="input-container">

                     <div className="inputs">
                           <span>Nome <label class="mandatory">*</label></span>
                           <input value={formData.name} onChange={e=>setFormData({...formData,name:e.target.value})} type="text" placeholder="Nome do operador"/>
                     </div>

                     <div className="inputs">
                           <span>Email <label class="mandatory">*</label></span>
                           <input value={formData.email} onChange={e=>setFormData({...formData,email:e.target.value})} type="text" placeholder="Email do operador"/>
                     </div>

                     <div className="inputs">
                            <span>Contacto<label class="mandatory">*</label></span>
                            <input value={formData.primary_contact} onChange={e=>setFormData({...formData,primary_contact:e.target.value.replace(/[^0-9]/g, '')})} type="text" placeholder="Contacto do operador"/>
                     </div>
                   

                     <div className="inputs">
                           <span>Cargo</span>
                           <input value={formData.job_title} onChange={e=>setFormData({...formData,job_title:e.target.value})} type="text" placeholder="Cargo"/>
                     </div>


                    {(user?.role=="admin") &&  <div className="inputs">
                             <span>Microcredito <label class="mandatory">*</label></span>
                             <select value={formData.microcredit_id} onChange={e=>setFormData({...formData, microcredit_id:e.target.value})}>
                                 <option disabled selected value="">Selecione uma microcredito</option>
                                  {data._microcredits_list.map(i=>(
                                        <option key={i.id} selected={formData.microcredit_id === i.id} value={i.id}>{i.name}</option>
                                  ))}
                             </select>
                    </div>}
                  
                  </div>


              <div className="options">
                  <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>
                  <div className="div_btn">
                    {(userRole!="operator" || !(!userInfo?.permissions?.operators?.includes('update') && edit)) && <button className="save" onClick={handle_operator}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>}
                     <span className="loader"></span>
                  </div>
              </div>


        </div>
         </div>
   </>


)
};

export default Create;
