import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useData } from '../../contexts/DataContext'
import { useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import ButtonLoader from '../loaders/button'
import Messages from '../Messages/basic-message'

function ChangePasswordModal({show,setShow}) {
 const data=useData()
 const {user} = useAuth()


 const [loading,setLoading]=useState(false)
 const [form,setForm]=useState({})
 const [message,setMessage]=useState('')
 const {pathname} = useLocation()



 async function SubmitForm(){

     setLoading(true)

     try{

       if(form.confirm_password != form.new_password){
         setMessage('As senhas inseridas devem ser iguais')
         setLoading(false)
         return
       }
     
       if(form.new_password.length <= 7){
         setMessage('A senha deve ter no mínimo 8 caracteres')
         setLoading(false)
         return
       }

       setMessage('')

       await data.makeRequest({method:'post',url:`api/change-password`,withToken:true,data:{
         new_password:form.new_password,
         last_password:form.last_password,
       }, error: ``},0);

     
       setLoading(false)
       toast.success('Atualizado com sucesso!')
      
       setShow(false)
       
       
     }catch(e){
       setLoading(false)
       let msg="Acorreu um erro, tente novamente"
       toast.remove()
       if(e.response){
        
        if(e.response.status==409){
          msg="Última senha registada está incorreta"
        } 
        if(e.response.status==500){
            msg="Erro, inesperado. Contacte seu administrador"
        }

      }else if(e.code=='ERR_NETWORK'){
            msg="Verfique sua internet e tente novamente"
      }
      setMessage(msg)
     }

 }

 
 return (
<div style={{zIndex:99999}} id="authentication-modal" tabindex="-1" aria-hidden="true" class={`bg-[rgba(0,0,0,0.7)] overflow-y-auto overflow-x-hidden ${show ? '' :'translate-y-10 opacity-0 pointer-events-none'} transition-all delay-150 ease-linear flex fixed top-0 right-0 left-0  justify-center items-center w-full md:inset-0 h-[100vh] max-h-full`}>
    <div class="relative p-4 w-full max-w-[500px] max-h-full">
      
        <div class="relative bg-white rounded-lg shadow">
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                <h3 class="text-xl font-semibold text-gray-900">{"Redifinir senha"}</h3>
                <button onClick={()=>{
                    setShow(false)
                    setMessage('')
                }} type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
           
            <div class="p-4 md:p-5">
                <div class="space-y-4" action="#">
                    <h2>Redifina a senha</h2>
                   
                    <Messages type={'red'} setMessage={setMessage} message={message}/>

                    <div>
                            
                             <div className="mb-4">
                              <label for="last_password" class="block mb-2 text-sm font-medium">{'Sua última senha'}</label>
                              <input value={form.last_password} onChange={(e)=>setForm({...form,last_password:e.target.value})} type="password" id="last_password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder=""/>
                             </div>
                             <div className="mb-4">
                              <label for="last_password" class="block mb-2 text-sm font-medium">{'Nova senha'}</label>
                              <input value={form.new_password} placeholder={'A senha deve ter no mínimo 8 caracteres'} onChange={(e)=>setForm({...form,new_password:e.target.value})} type="password" id="last_password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                             </div>
                             <div className="mb-4">
                              <label for="last_password" class="block mb-2 text-sm font-medium">{'Repetir nova senha'}</label>
                              <input value={form.confirm_password} placeholder={'A senha deve ter no mínimo 8 caracteres'} onChange={(e)=>setForm({...form,confirm_password:e.target.value})} type="password" id="last_password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                             </div>
                            
                    </div>
                   
                    <button onClick={SubmitForm} type="submit" class={`text-white ${loading ? 'pointer-events-none':''}  ${(!form.last_password || !form.new_password || !form.confirm_password) ? ' bg-gray-400  pointer-events-none':'bg-blue-700 hover:bg-blue-800'} flex items-center justify-center focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center`}>
                       {loading && <ButtonLoader/>}
                      <span>{loading ? 'A carregar' +"..." : 'Enviar'}</span>
                    </button>


                     <div class="text-sm font-medium text-gray-500 text-center">
                        <a onClick={()=>{
                             data.setIsLoading(true)
                             window.location.href="/login?recover-password=true"
                        }} href="#" class="text-blue-700   hover:underline">{'Esqueceu a última senha?'}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 

  )
}

export default ChangePasswordModal