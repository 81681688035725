
import './style.css'
import Menu from '../../common/menu.js';
import TopBar from "../../common/topBar";
import React,{ useState , useEffect} from 'react';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import ContractVariables from '../../modals/contract-variables.jsx';
import Loader from '../../loaders/loader.jsx';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Managers() {
const {user,selectedMicrocredit,userInfo,userRole} = useAuth();
const data = useData();
const [googleDocsId,setGoogleDocsId]=useState(null)
const [showVariables,setShowVariables]=useState(null)
const [loadingTemplate,setLoadingTemplate]=useState(true)
const [loadingTimePassed,setLoadingTimePassed]=useState(false)
const {pathname}=useLocation()
const navigate=useNavigate()

useEffect(()=>{
  if(!selectedMicrocredit) return
  setLoadingTemplate(true)
  let document_field_name=pathname.includes('/loans/template/certificate') ? 'certificate_document_id' : pathname.includes('/loans/template/payment-plan') ? 'payment_plan_document_id' : pathname.includes('/loans/template/agreement') ? 'debt_acknowledgment_agreement_document_id': 'google_docs_document_id'
  setGoogleDocsId(user.microcredits.filter(i=>i.id==selectedMicrocredit)[0][document_field_name])
  setTimeout(()=>{
         setLoadingTimePassed(true)
  },20000)
},[selectedMicrocredit,pathname])

useEffect(()=>{

  if(googleDocsId && data.isMobile()){
     window.location.href=(`https://docs.google.com/document/d/${googleDocsId}/edit?tab=t.0`)
     navigate('/loans')
  }

},[googleDocsId])




useEffect(()=>{
  if(pathname.includes('/loans/template/contract') && (userRole=="operator" && !userInfo?.permissions?.contract_template?.includes('update'))){
     navigate('/dashboard')
  }
},[userInfo,pathname,googleDocsId])


useEffect(()=>{
  if(pathname.includes('/loans/template/agreement') && (userRole=="operator" && !userInfo?.permissions?.debt_confession_agreement_template?.includes('update'))){
     navigate('/dashboard')
  }
},[userInfo,pathname,googleDocsId])


useEffect(()=>{

  if(pathname.includes('/loans/template/agreement') && (userRole=="operator" && !userInfo?.permissions?.clearance_certificate_template?.includes('update'))){
     navigate('/dashboard')
  }

},[userInfo,pathname,googleDocsId])






let page_name=pathname.includes('/loans/template/certificate') ?  'Modelo de certidão de quitação' : pathname.includes('/loans/template/payment-plan') ? 'Modelo de plano de pagamento' : pathname.includes('/loans/template/agreement') ? 'Modelo de termo de confissão de dívida'  : 'Modelo de contratos'

return (
    <>
    <ContractVariables show={showVariables} setShow={setShowVariables}/>
    <main className="dashboard-container">

        <div className="dashboard">

            <Menu/>
            <div className="main-dashboard">
               
                  <TopBar activePage={page_name}/>

                  <div className="center">
                   
                    <div className={`contract-template-box`}>
                      <div className="flex mb-2 items-center justify-between w-full bg-white rounded-[0.4rem] p-2">
                      <div>
                          <h3 className="font-medium">{page_name}</h3>
                      </div>

                      <div className="flex items-center">
                          
                          {loadingTemplate && <Loader/>}
                          
                          <button onClick={()=>{
                            setShowVariables(true)
                          }} id="confirm-button" type="button" class="py-2 px-4 text-sm font-medium text-center rounded-[0.3rem] text-white bg-custom_blue-400  bg-honolulu_blue-500 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300">Lista de variáveis</button>
                        
                         <div onClick={()=>{
                               let id=JSON.stringify(googleDocsId)
                               setGoogleDocsId(null)
                               setLoadingTemplate(true)
                               setTimeout(()=>{
                                setGoogleDocsId(JSON.parse(id))
                               },1000)
                         }} className="opacity-50 hover:opacity-100 cursor-pointer">
                           <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z"></path></svg>
                         </div>

                      </div>
                    
                    </div>
                    <div className="template-container rounded-[0.4rem] overflow-hidden">
                             
                      {googleDocsId && <iframe onLoad={()=>setLoadingTemplate(false)}
                        src={`https://docs.google.com/document/d/${googleDocsId}/edit?tab=t.0`}
                        width="100%"
                        height="500px"
                        frameBorder="0"
                        title="Google Docs Viewer"
                      ></iframe>}
                             
                    </div>


                      </div>

                  </div>

                  
            </div>
        </div>
    </main>
    </>
  );
}
