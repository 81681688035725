
import { useEffect, useState} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import DefaultFormSkeleton from '../Skeleton/defaultForm';

const CreateClient = ({create,setCreate, edit , setEdit,showD, createRes }) => {

const data = useData();

const initial_form={name:'',price:''} 
const [itemToEditLoaded,setItemToEditLoaded]=useState(false)
const {pathname} = useLocation()
const {user} = useAuth()
const [formData,setFormData]=useState(initial_form)
const [loading,setLoading]=useState(false)



useEffect(()=>{

    if(edit){
       setCreate(false)
       setFormData(edit)
    }else{
       setFormData({...initial_form})
    }


},[edit])


useEffect(()=>{
    if(create){
       setEdit(false)
       setFormData({...initial_form})
    }
},[create])




async function handle_client(){

   if(!formData.name || !formData.price) {
       toast.error('Preencha todos os campos!')
       return 
   }

   setLoading(true)
  
   try{

     if(edit){

       await data.makeRequest({method:'post',url:`api/plan/update/`+edit.id,withToken:true,data:{
         ...formData,
       }, error: ``},0);
       
       toast.success('Actualizado com sucesso!')
       setLoading(false)
     }else{

        await data.makeRequest({method:'post',url:`api/paln/create`,withToken:true,data:{
         ...formData
       }, error: ``},0)
     
       toast.success('Criado com sucesso!')
       setLoading(false)

     }

     setEdit(null)
     setCreate(null)
     data.setUpdateTable(Math.random())

   }catch(e){

       let msg="Acorreu um erro, tente novamente"
       console.log(e)
       setLoading(false)

       if(e.response){
         if(e.response.status==409){
             msg="Nome já existe"
         }   
         if(e.response.status==400){
             msg="Dados inválidos"
         }
         if(e.response.status==500){
             msg="Erro, inesperado. Contacte seu administrador"
         }

       }else if(e.code=='ERR_NETWORK'){
             msg="Verfique sua internet e tente novamente"
       }

       toast.error(msg)
       setLoading(false)

     }
     
}


useEffect(()=>{
  if(!edit){
      setItemToEditLoaded(false)
  }
},[edit])








return (
       <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
        <div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
        <div className={`create-microcredit  ${loading && 'loading'}`}>
                      
                         <div className="top-title">{edit ? 'Editar' :' Adicionar'} gestor</div>  
                        <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                               <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                        </div>
      
                        <div className="input-container">

                          <div className="inputs">
                                <span>Nome <label class="mandatory">*</label></span>
                                <span class="set-empty" style={{display:'none'}}>
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="15"  viewBox="0 0 350.000000 346.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"><path d="M1615 3274 c-394 -38 -691 -175 -963 -443 -325 -322 -474 -691 -459 -1136 11 -320 80 -524 282 -832 l73 -111 -160 -158 -161 -158 99 -92 99 -93 84 82 c112 110 128 124 186 179 l51 47 119 -80 c303 -203 524 -273 860 -273 233 0 368 23 560 96 430 162 802 572 926 1020 49 178 64 449 34 653 -33 234 -125 448 -286 666 -27 37 -49 70 -49 75 0 5 70 79 155 164 l156 156 -98 97 -98 97 -153 -155 c-84 -85 -155 -155 -158 -155 -3 0 -59 36 -124 79 -225 149 -383 216 -585 251 -110 18 -315 31 -390 24z m356 -295 c114 -24 258 -84 401 -166 64 -37 124 -73 134 -80 16 -11 -79 -109 -871 -903 -489 -490 -894 -889 -899 -887 -16 5 -155 244 -192 329 -47 110 -74 221 -84 352 -32 393 91 729 370 1006 273 272 574 390 950 373 58 -3 144 -13 191 -24z m837 -589 c72 -121 114 -219 149 -345 27 -96 28 -110 28 -315 -1 -201 -3 -221 -27 -313 -57 -209 -155 -381 -311 -543 -191 -201 -393 -318 -645 -375 -126 -29 -395 -32 -511 -5 -170 38 -338 108 -486 203 -38 25 -72 47 -74 49 -2 1 399 403 890 893 l894 890 19 -22 c10 -12 43 -65 74 -117z"/></g></svg>
                                </span>
                                <input disabled className="opacity-55" type="text" value={formData.name} onChange={e=>setFormData({...formData,name:e.target.value})}  placeholder="Nome do cliente"/>
                          </div>
                          <div className="inputs">
                                  <span>Price</span>
                                  <input value={formData.price} onChange={e=>setFormData({...formData,price:e.target.value})}/>
                          </div>

                        </div>
      
      
                    <div className="options">
                        <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>
                        <div className="div_btn">
                           <button className="save" onClick={handle_client}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>
                           <span className="loader"></span>
                        </div>
                    </div>
      
              </div>
               </div>
      
      
      )





};

export default CreateClient;
