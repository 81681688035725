
import { useEffect, useState} from 'react';
import { useData } from '../../../contexts/DataContext';
import { useLocation} from 'react-router-dom';
const Create = ({create,setCreate,showD,utils,formData,setFormData}) => {

const [installmentForm,setInstallmentForm]=useState({
   payment_histories:[]
})
const [loading,setLoading]=useState(false)
const {pathname}=useLocation();
const data = useData();
const [updatePayments,setUpdatePayments]=useState(Math.random())
const [left,setLeft]=useState(0)


let initial_payment_history_form_item={id:Math.random(),payday:new Date().toISOString().split('T')[0],amount:'',account_id:'',reimbursement_performed_by_id:''}


useEffect(()=>{
   if(!create?.id) return
   setInstallmentForm({
       ...create,
       payment_histories:create.payment_histories.length ? create.payment_histories : (formData.restructuring_to_id ? [] :  [initial_payment_history_form_item])
   })

},[create])


function save(){
    setFormData({...formData,installments:[...formData.installments.map(i=>{
        return i.id==installmentForm.id ? {
         ...installmentForm,
         status:installmentForm.paid >= installmentForm.total_to_pay ? 'paid' : installmentForm.status
      } : i
    })]})
    setCreate(false)
}

function cancel_action() {
   setCreate(false)
}

useEffect(()=>{

   if(!installmentForm?.id) return
   let res=utils.add_contract_payments([{...installmentForm,payment_histories:installmentForm.payment_histories.filter(i=>i.payday && i.amount)}])
   setInstallmentForm({...res[0],payment_histories:installmentForm.payment_histories})

    
},[updatePayments])


useEffect(()=>{

   if(!installmentForm?.id) return
   let _left=parseFloat(installmentForm.total_to_pay || 0) - parseFloat(installmentForm.paid || 0)
   setLeft(_left < 0  ? 0 : _left)

},[installmentForm])
  

return (
 <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
  <div className="bg-close" onClick={cancel_action}></div>
 <div  className={`create-microcredit ${loading && 'loading'}`}>
   
                  <div className="top-title">{installmentForm.status!="paid" ? 'Fazer pagamento' : 'Ver pagamento'}</div>  
                 
                  <div className="btn-close" onClick={cancel_action}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div> 

                  <div className="status"  style={{color:'#111',background:installmentForm.status=='pending' || installmentForm.status=='parcial' ? '#F9CE66': installmentForm.status=="paid" ? '#4ACD35' : '#FF6B6B'}}>{installmentForm.status=='pending' ? 'Pendente': installmentForm.status=="paid" ? 'Pago' : installmentForm.status=="parcial" ? 'Parcialmente' : 'Atrasado'}</div>

                  <div className="payment-top-cards">

                         <div className="item">
                            <span className="name">Valor inicial</span><span className="value">{(installmentForm.initial_amount || 0).toFixed(2)}</span>
                         </div>

                         <div className="item">
                             <span className="name">Valor por pagar</span><span className="value">{(installmentForm.total_to_pay || 0).toFixed(2)}</span>
                         </div>

                         <div className="item">
                             <span className="name">Juros de mora</span><span className="value">{(installmentForm.late_payment_interest || 0).toFixed(2)}</span>
                         </div>
                         
                          <div className="item">
                             <span className="name">Pago</span><span className="value">{(installmentForm.paid || 0).toFixed(2)}</span>
                         </div>

                         <div className="item">
                            <span className="name">Em falta</span><span className="value">{left.toFixed(2)}</span>
                         </div>

                         <div className="item">
                            <span className="name">Dias em atraso</span><span className="value">{installmentForm.delay < 0  ? 0 : installmentForm.delay}</span>
                         </div>
                  </div>


                  <div className="mb-5 mt-10 overflow-auto max-h-[200px]">
                      {installmentForm.payment_histories.map(i=>(
                          <div className="flex items-center border-b py-2">
                           
                            <div className="flex items-center gap-y-2 flex-wrap">

                              <div className="flex-col flex px-2 w-[50%]">
                              <span className="text-[0.8rem] text-gray-500">Montante pago</span>
                              <input disabled={formData.restructuring_to_id} type="number" value={i.amount} onChange={(e)=>{
                                    setInstallmentForm({...installmentForm,status:'pending',payment_histories:installmentForm.payment_histories.map(f=>{
                                       return {...f,amount:f.id==i.id ? e.target.value : f.amount}
                                    })})
                                    setUpdatePayments(Math.random())
                              }}  className="border border-gray-300 rounded-[0.3rem] py-1 px-2 w-full"/>
                              </div>
                              <div className="flex-col flex px-2 w-[50%]">
                              <span className="text-[0.8rem] text-gray-500">Data de pagamento</span>
                              <input disabled={formData.restructuring_to_id} value={i.payday.split('T')[0]} onChange={(e)=>{
                                    setInstallmentForm({...installmentForm,status:'pending',payment_histories:installmentForm.payment_histories.map(f=>{
                                       return {...f,payday:f.id==i.id && e.target.value ? e.target.value : f.payday}
                                    })})
                                    setUpdatePayments(Math.random())
                              }} type="date" className="border border-gray-300 rounded-[0.3rem] py-1 px-2 w-full"/>
                              </div>
                              <div className="flex-col flex  px-2 w-[50%]">
                              <span className="text-[0.8rem] text-gray-500">Conta de reembolso</span>
                              <select disabled={formData.restructuring_to_id} value={i.account_id} onChange={(e)=>{
                                    setInstallmentForm({...installmentForm,status:'pending',payment_histories:installmentForm.payment_histories.map(f=>{
                                       return {...f,account_id:f.id==i.id ? e.target.value : f.account_id}
                                    })})
                                    setUpdatePayments(Math.random())
                              }} className="border border-gray-300 rounded-[0.3rem] py-2 px-2 w-full">
                                 <option disabled selected value="">Selecione</option>
                                    {data._accounts.map((i,_i)=>(
                                       <option value={i.id} key={'_account-'+_i}>{i.name}</option>
                                    ))}
                                 </select>
                              </div>



                              <div className="flex-col flex  px-2 w-[50%]">
                              <span className="text-[0.8rem] text-gray-500">Responsável pelo reembolso</span>
                              <select disabled={formData.restructuring_to_id} value={i.reimbursement_performed_by_id} onChange={(e)=>{
                                    setInstallmentForm({...installmentForm,status:'pending',payment_histories:installmentForm.payment_histories.map(f=>{
                                       return {...f,reimbursement_performed_by_id:f.id==i.id ? e.target.value : f.reimbursement_performed_by_id}
                                    })})
                                    setUpdatePayments(Math.random())
                              }} className="border border-gray-300 rounded-[0.3rem] py-2 px-2 w-full">
                                 <option disabled selected value="">Selecione</option>
                                    {data._microcredit_managers_and_operators.map((i,_i)=>(
                                       <option value={i.id} key={'_user-'+_i}>{i.name}</option>
                                    ))}
                                 </select>
                              </div>

                          

                           </div>

                          {!formData.restructuring_to_id && <div onClick={()=>{
                                      setInstallmentForm({...installmentForm,status:'pending',payment_histories:installmentForm.payment_histories.filter(f=>f.id!=i.id)})
                                      setUpdatePayments(Math.random())
                                 }} className="translate-y-2 px-2 cursor-pointer hover:opacity-70">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 -960 960 960" ><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"></path></svg>
                           </div>}

                          </div>
                      ))}

                  </div>

                  <div className="w-full flex justify-end mb-5">
                        {!formData.restructuring_to_id && <button onClick={()=>{
                               setInstallmentForm({...installmentForm,status:'pending',payment_histories:[...installmentForm.payment_histories,
                                 {...initial_payment_history_form_item,id:Math.random()}
                               ]})
                           }} style={{border:0,color:'#fff'}} type="button"  class={`text-white cursor-pointer hover:opacity-65 bg-custom_blue-400 focus:outline-none  font-medium rounded-[0.3rem] text-sm px-2 py-1 text-center inline-flex items-center`}>
                                 Adicionar pagamento
                           </button>}
                  </div>
                

                  {installmentForm.status!="paid" && <div className="options">
                                 <button className="cancel" onClick={cancel_action}>Cancelar</button>
                                 <div style={installmentForm.payment_histories.some(i=>!i.amount || !i.payday || !i.account_id || !i.reimbursement_performed_by_id) ? {opacity:0.3,pointerEvents:'none'} : {}} className="div_btn">
                                    <button className="save" onClick={save}><span>Processar</span></button>
                                    <span className="loader"></span>
                                 </div>
                  </div>}

        </div>
   </div>
)
};

export default Create;
